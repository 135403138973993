import React, { useEffect, useState } from "react";
import {
  ICourseProgress,
  IWellnessCourse,
  SetErrorHandlerType,
} from "../../../../../Types";
import "./CourseParticipants.css";
import { useAppDispatch, useAppSelector } from "../../../../../hooks";
import { getCourseProgress } from "../../../../../redux/actions/wellnessActions";
import LoadingSpinner from "../../../../../components/loading-spinner/LoadingSpinner";

// Interfaces
interface IProps {
  selectedCourseForPreview: IWellnessCourse;
  setErrorHandlerObj: SetErrorHandlerType;
}

const filterOptions = ["All", "Completed", "Ongoing"];

function CourseParticipants({
  selectedCourseForPreview,
  setErrorHandlerObj,
}: IProps) {
  // Functions, States and Variables
  const dispatch = useAppDispatch();
  const courseProgress: ICourseProgress[] | [] = useAppSelector(
    (state) => state.wellness.courseProgress
  );
  // States
  const [currentCourseProgress, setCurrentCourseProgress] = useState<
    ICourseProgress[] | null
  >(null);
  const [isLoading, setIsLoading] = useState(false);

  // Functions, States and Variables
  const [filterParam, setFilterParam] = useState("all");

  //   Functions
  const handleFilterParticipantsBasedOnStatus = function () {
    let filteredParticipantsArr = [];
    switch (filterParam) {
      case "all":
        filteredParticipantsArr = courseProgress;
        setCurrentCourseProgress(filteredParticipantsArr);
        break;
      case "ongoing":
        filteredParticipantsArr = courseProgress?.filter(
          (courseObj) => courseObj.status === "Ongoing"
        );
        setCurrentCourseProgress(filteredParticipantsArr);
        break;
      case "completed":
        filteredParticipantsArr = courseProgress?.filter(
          (courseObj) => courseObj.status === "Completed"
        );
        setCurrentCourseProgress(filteredParticipantsArr);
        break;
      default:
        setCurrentCourseProgress(null);
        break;
    }
  };

  //   UseEffects
  useEffect(() => {
    // console.log(courseProgress);
    if (courseProgress) handleFilterParticipantsBasedOnStatus();
  }, [filterParam, courseProgress]);

  useEffect(() => {
    dispatch(
      getCourseProgress(
        selectedCourseForPreview._id,
        setIsLoading,
        setErrorHandlerObj
      )
    );
  }, []);

  return (
    <div className="course-preview--course-participants">
      {/* Title */}
      <div className="course-participants--title">
        Course Participants ({courseProgress?.length?.toLocaleString()})
      </div>

      <div className="participants-action-buttons">
        {/* All Button */}
        {filterOptions?.map((option, index) => (
          <button
            key={index + 1}
            onClick={() => setFilterParam(option.toLowerCase())}
            className={`participant-filter-button ${
              filterParam === option.toLowerCase() &&
              "active--participant-filter-button"
            }`}
          >
            {option}
          </button>
        ))}
      </div>

      {/* Sub Title  */}
      <div className="course-participants--sub-title">
        Number: {currentCourseProgress?.length?.toLocaleString()}
      </div>

      {!isLoading ? (
        <div className="course-participants--value">
          {currentCourseProgress?.map((participantObj, index) => (
            <div key={index + 1} className="participant-completion-wrapper">
              <div className="participant-name">
                {participantObj.userId.firstName}{" "}
                {participantObj.userId.lastName}
              </div>

              <div className="participant-completion-level-wrapper">
                <div className="participant-completion-bar">
                  <div
                    className="completion-bar-level"
                    style={{ width: `${participantObj.progress}%` }}
                  ></div>
                </div>
                <div className="participant-completion-value">
                  {participantObj.progress?.toFixed(1)}%
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="below-table-loader-wrapper">
          <LoadingSpinner />
        </div>
      )}
    </div>
  );
}

export default CourseParticipants;

import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PlanSetupForm from "../plan-setup-form/PlanSetupForm";
import Top from "../../../components/app-top/Top";
import Sidebar from "../../../components/app-sidebar/Sidebar";
import ErrorHandler from "../../../components/error/ErrorHandler";
import Main from "../../../components/layout/main-container/Main";
import useDisplayMessage from "../../../hooks/useDisplayMessage";
import { IPlanFormInputStates } from "../../../Types";

function EditPlan() {
  // Functions, states and variables
  const location = useLocation();
  const navigate = useNavigate();
  //   States
  const [planId, setPlanId] = useState("");
  const [planFormInputStates, setPlanFormInputStates] =
    useState<IPlanFormInputStates>({
      name: "",
      cost: "",
      details: "",
    });
  //   Hooks
  // Handles Display the error and success message and scrolls to top
  const {
    errorHandlerObj,
    setErrorHandlerObj,
    successHandlerObj,
    setSuccessHandlerObj,
  } = useDisplayMessage();

  useEffect(() => {
    if (location.state) {
      const { currentPlan }: any = location.state;
      setPlanId(currentPlan._id);
      setPlanFormInputStates({
        name: currentPlan.name,
        cost: currentPlan.cost,
        details: currentPlan.details,
      });
    } else {
      navigate(-1);
    }
  }, [location]);

  return (
    <div className="app-page-container">
      <Top />
      <Sidebar activeName={"Subscriptions"} />

      <Main>
        {/* Error handler component */}
        <ErrorHandler
          errorHandlerObj={errorHandlerObj}
          className="app--actions-handler-wrapper"
        />

        <section className={`app-main-section--inner`}>
          {planFormInputStates.name !== "" && (
            <PlanSetupForm
              pageType={"edit"}
              planId={planId}
              planFormInputStates={planFormInputStates}
              setPlanFormInputStates={setPlanFormInputStates}
              setErrorHandlerObj={setErrorHandlerObj}
            />
          )}
        </section>
      </Main>
    </div>
  );
}

export default EditPlan;

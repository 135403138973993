import React, { MouseEvent, useEffect, useState } from "react";
import plusIcon from "../../../../../../../images/plus-icon.svg";
import trashIcon from "../../../../../../../images/trash-icon-secondary.svg";
import { INoteCourseInputState } from "../../../../../../../Types";
import TextInput from "../../../../../../../components/input-components/text-input/TextInput";
import { TextEditorWithToolbar } from "../../../../../../../components/text-editor/TextEditorWithTooolbar";

// Interfaces
interface IProps {
  courseContentInputStates: INoteCourseInputState[];
  setCourseContentInputStates: React.Dispatch<
    React.SetStateAction<INoteCourseInputState[]>
  >;
}

function NoteCourseForm({
  courseContentInputStates,
  setCourseContentInputStates,
}: IProps) {
  // Functions, States and Variables
  const [activeIndex, setActiveIndex] = useState(0);
  const [textEditorNote, setTextEditorNote] = useState<any>(null);
  //   Handle Update Course Content Input State
  const handleUpdateCourseContentInputState = function (
    fieldName: string,
    fieldValue: string,
    index: number
  ) {
    const courseContentInputStatesCopy = [...courseContentInputStates];
    const updatedFieldObjectForIndex: INoteCourseInputState = {
      ...courseContentInputStates[index],
      [fieldName]: fieldValue,
    };
    console.log(updatedFieldObjectForIndex);
    courseContentInputStatesCopy.splice(index, 1, updatedFieldObjectForIndex);
    setCourseContentInputStates(courseContentInputStatesCopy);
  };

  // handle Add New Note Course Object
  const handleAddNewNoteCourse = function (e: MouseEvent<HTMLButtonElement>) {
    const isNoteCourseObjectEmpty = courseContentInputStates?.some(
      (courseContent) => courseContent.noteTitle === ""
    );

    // Check if no input state is empty
    if (!isNoteCourseObjectEmpty) {
      e.preventDefault();
      const newCourseContentObject = {
        noteTitle: "",
        noteValue: "",
      };
      const updatedCourseContentInputStates = [
        ...courseContentInputStates,
        newCourseContentObject,
      ];
      setCourseContentInputStates(updatedCourseContentInputStates);
    }
  };

  // Handle Remove Course From Course Course Content Input States
  const handleRemoveCourseVideo = function (
    e: MouseEvent<HTMLButtonElement>,
    index: number
  ) {
    e.preventDefault();
    // Remove object from videos array
    setCourseContentInputStates((prevStateInputs) => {
      const updatedFormData = [...prevStateInputs];
      // Splice out the selected file object out of the file arr
      updatedFormData.splice(index, 1);
      return updatedFormData;
    });
  };

  // Useeffects
  useEffect(() => {
    if (textEditorNote !== null) {
      console.log(courseContentInputStates, textEditorNote, activeIndex);
      handleUpdateCourseContentInputState(
        "noteValue",
        textEditorNote,
        activeIndex
      );
    }
  }, [textEditorNote]);

  return (
    <div className="course-file-form-group-container">
      {courseContentInputStates?.map((courseContent, index) => (
        <div key={index + 1} className="course-file-form-group-wrapper">
          {/* Form header container */}
          <div className="form-header-container">
            <div className="form-header-action-wrapper">Note {index + 1}</div>
            {/* Delete Form Object Wrapper */}
            {courseContentInputStates.length > 1 &&
              index !== courseContentInputStates.length && (
                <button
                  onClick={(e) => handleRemoveCourseVideo(e, index)}
                  className="delete-form-object-wrapper"
                >
                  <img src={trashIcon} alt="" />
                  Delete Note
                </button>
              )}
          </div>

          {/* Course Content Title */}
          <div className="form-group">
            <TextInput
              type={"text"}
              id={`course-content-title-input-${index}`}
              className={"course-content-title-input"}
              value={courseContent?.noteTitle || ""}
              onChange={(e) =>
                handleUpdateCourseContentInputState(
                  "noteTitle",
                  e.target.value,
                  index
                )
              }
              required={true}
              label={"Note Title"}
            />
          </div>

          {/* Upload box container */}
          <div
            onFocus={() => {
              setActiveIndex(index);
              setTextEditorNote(courseContent.noteValue);
            }}
            className="upload-course-content-box-container form-group"
          >
            <label>Course Note</label>
            <TextEditorWithToolbar
              value={
                activeIndex === index ? textEditorNote : courseContent.noteValue
              }
              setValue={setTextEditorNote}
              placeholder={"Write note here..."}
            />
          </div>
        </div>
      ))}

      <div className="">
        <button
          className="form-header-action-wrapper"
          onClick={handleAddNewNoteCourse}
        >
          <img src={plusIcon} alt="" /> Add Note
        </button>
      </div>
    </div>
  );
}

export default NoteCourseForm;

import { MouseEvent, useEffect, useState } from "react";
import "./PlanSetupForm.css";
import PrimaryBackButton from "../../../components/primary-back-button/PrimaryBackButton";
import { useNavigate } from "react-router-dom";
import PlanDetailsForm from "./plan-details-form/PlanDetailsForm";
import { IPlanFormInputStates, SetErrorHandlerType } from "../../../Types";
import {
  createSubscriptionPlan,
  updateSubscriptionPlan,
} from "../../../redux/actions/subscriptionActions";
import { useAppDispatch } from "../../../hooks";

// Interfaces
interface IProps {
  pageType?: string;
  planId?: string;
  planFormInputStates: IPlanFormInputStates;
  setPlanFormInputStates: React.Dispatch<
    React.SetStateAction<IPlanFormInputStates>
  >;
  setErrorHandlerObj: SetErrorHandlerType;
}

function PlanSetupForm({
  pageType = "create",
  planId = "",
  planFormInputStates,
  setPlanFormInputStates,
  setErrorHandlerObj,
}: IProps) {
  // Functions, States and variables
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  //   States
  const [isLoading, setIsLoading] = useState(false);

  // Functions
  const handleSavePlan = function (e: MouseEvent<HTMLButtonElement>) {
    const data = {
      name: planFormInputStates.name,
      cost: Number(planFormInputStates.cost),
      details: planFormInputStates.details,
    };

    if (planFormInputStates.name !== "" && planFormInputStates.cost !== "") {
      e.preventDefault();

      if (pageType === "create") {
        dispatch(
          createSubscriptionPlan(
            data,
            setIsLoading,
            setErrorHandlerObj,
            navigate
          )
        );
      } else {
        dispatch(
          updateSubscriptionPlan(
            planId,
            data,
            setIsLoading,
            setErrorHandlerObj,
            navigate
          )
        );
      }
    }
  };

  return (
    <section className="plan-setup-form-section">
      {/* Top Wrapper */}
      <div className="plan-setup--top-wrapper">
        <div className="primary-back-button-container">
          {/* Back button Wrapper */}
          <PrimaryBackButton
            onClick={() => {
              navigate("/admin/subscriptions");
            }}
          />
          {/* Top Title */}
          <div className="primary-back-button-title">
            {pageType === "edit" ? "Edit" : "Add New"} Plan
          </div>
        </div>
      </div>

      <div className="setup-plan-form-container">
        <form>
          {/* Setup Plan Form */}
          <PlanDetailsForm
            isLoading={isLoading}
            planFormInputStates={planFormInputStates}
            setPlanFormInputStates={setPlanFormInputStates}
            handleSavePlan={handleSavePlan}
          />
        </form>
      </div>
    </section>
  );
}

export default PlanSetupForm;

import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { IProductDeal } from "../../../Types";
import Top from "../../../components/app-top/Top";
import ProductDealSetup from "../deals-setup/ProductDealSetup";

function ProductDealEdit() {
  // Funtion, State and Variables
  const location = useLocation();
  const navigate = useNavigate();
  // States
  const [currentDealObj, setCurrentDealObj] = useState<IProductDeal | null>(
    null
  );
  const [pageContent, setPageContent] = useState("deal-info");
  // Delete deal modal
  const [isDeleteDealModalOpened, setIsDeleteDealModalOpened] = useState(false);

  // useEffects
  useEffect(() => {
    if (location.state) {
      const { deal: selectedDealForEdit }: any = location.state;
      if (selectedDealForEdit) setCurrentDealObj(selectedDealForEdit);
    } else {
      navigate(-1);
    }
  }, [location]);

  return (
    <div
      className={`dashboard-setup-container ${
        isDeleteDealModalOpened ? "overflow-hidden" : ""
      }`}
    >
      <Top />

      <div
        onClick={() => {
          setIsDeleteDealModalOpened(false);
        }}
        className={`gray-overlay ${isDeleteDealModalOpened ? "" : "none"}`}
      ></div>

      {/*Dashboard Setup Section */}
      <section className={`dashboard-setup-form-section`}>
        {currentDealObj !== null && (
          <ProductDealSetup
            currentDealObj={currentDealObj}
            pageType={"edit"}
            pageContent={pageContent}
            isDeleteDealModalOpened={isDeleteDealModalOpened}
            setIsDeleteDealModalOpened={setIsDeleteDealModalOpened}
            setPageContent={setPageContent}
          />
        )}
      </section>
    </div>
  );
}

export default ProductDealEdit;

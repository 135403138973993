import { useContext, useEffect, useState } from "react";
import "./VouchersTable.css";
import { Table } from "react-bootstrap";
import { SearchContext } from "../../../components/contexts/SearchContext";
import LoadingSpinner from "../../../components/loading-spinner/LoadingSpinner";
import {
  ISingleVoucher,
  IVoucherFilterFormInputs,
  IVoucherObject,
  SetErrorHandlerType,
  SetIsLoadingType,
  SetSuccessHandlerType,
} from "../../../Types";
import isEmpty from "../../../validation/isEmpty";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  createSelectedVouchers,
  getAllVouchers,
} from "../../../redux/actions/vouchersActions";
import VoucherFilter from "./voucher-filter/VoucherFilter";
import BEPagination from "../../../components/pagination/be-pagination/BEPagination";
import { truncateString } from "../../../utils/truncateString";
import { useNavigate } from "react-router-dom";

// Interfaces
interface IProps {
  isLoading: boolean;
  fromSelectVoucherForDeal?: boolean;
  setIsLoading: SetIsLoadingType;
  setIsTableActionLoading?: SetIsLoadingType;
  setErrorHandlerObj: SetErrorHandlerType;
  setSuccessHandlerObj: SetSuccessHandlerType;
}

function VouchersTable({
  isLoading,
  fromSelectVoucherForDeal,
  setIsLoading,
  setIsTableActionLoading,
  setErrorHandlerObj,
  setSuccessHandlerObj,
}: IProps) {
  // Functions, States and Variables
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { searchedValue }: any = useContext(SearchContext);
  const vouchers = useAppSelector((state) => state.vouchers.vouchers);
  //   States
  // const [vouchersDateFilter, setVouchersDateFilter] = useState("");
  // const [allVouchers, setAllVouchers] = useState<IVoucherObject[] | null>(null);
  const [currentVouchersPosts, setCurrentVouchersPosts] = useState<
    IVoucherObject[] | null
  >(null);
  const [voucherFilterFormInputStates, setVoucherFilterFormInputStates] =
    useState<IVoucherFilterFormInputs>({
      country: "",
      category: "",
      searchParam: "",
    });

  // Pagination
  const [pageNumberIndex, setPageNumberIndex] = useState(1);

  //   handle Filter Table
  const handleFilterTable = function () {
    const allVouchers: IVoucherObject[] | [] = vouchers.data;
    // Search through voucher name and merchant shop name
    const vouchersData = allVouchers?.filter(
      ({ voucher }) =>
        voucher?.name?.toLowerCase().includes(searchedValue.toLowerCase()) ||
        voucher?.source?.toLowerCase().includes(searchedValue.toLowerCase())
    );
    // console.log(vouchersData);
    // Update table state
    setCurrentVouchersPosts(vouchersData);
  };

  // Handle Create Selected Voucher
  const handleAddToSelectedVouchers = function (voucher: ISingleVoucher) {
    setErrorHandlerObj({ hasError: false, message: "" });

    const data = {
      voucher,
    };
    // Call the API to add to selected vouchers
    if (setIsTableActionLoading)
      dispatch(
        createSelectedVouchers(
          data,
          setIsTableActionLoading,
          setErrorHandlerObj,
          setSuccessHandlerObj
        )
      );
  };

  const handleFetchVouchers = function () {
    const voucherFilterParam = voucherFilterFormInputStates;
    dispatch(
      getAllVouchers(
        pageNumberIndex,
        voucherFilterParam,
        setIsLoading,
        setErrorHandlerObj
      )
    );
  };

  // Handle Navigate to deal info form page when a form is selected if component is rendering in the deals page
  const handleNavigateToDealInfoPage = function (voucherObj: IVoucherObject) {
    navigate("/admin/deals/create", { state: { voucherObj } });
  };
  //   UseEffects
  useEffect(() => {
    // Fetch voucher based on voucher filter parameters and selected paginated page
    handleFetchVouchers();
  }, [pageNumberIndex]);

  useEffect(() => {
    if (vouchers) handleFilterTable();
  }, [vouchers, searchedValue]);

  return (
    <div>
      {/* Voucher Filter Section */}
      <VoucherFilter
        voucherFilterFormInputStates={voucherFilterFormInputStates}
        setVoucherFilterFormInputStates={setVoucherFilterFormInputStates}
        handleFetchVouchers={handleFetchVouchers}
        setPageNumberIndex={setPageNumberIndex}
        setErrorHandlerObj={setErrorHandlerObj}
      />

      {/* Voucher Category Wrapper */}
      <div className="primary-table-container">
        <Table responsive="lg" className="primary-table vocuhers-table">
          <thead
            style={{
              textAlign: "left",
            }}
          >
            <tr>
              {[
                "SOURCE",
                "VOUCHER NAME",
                "CURRENCY CODE",
                "MINIMUM AMOUNT",
                "MAXIMUM AMOUNT",
                "",
              ].map((COLUMN, index) => (
                <th key={index + 1}>{COLUMN}</th>
              ))}
            </tr>
          </thead>

          <tbody>
            {currentVouchersPosts?.map((voucherObj, index) => (
              <tr
                key={index + 1}
                onClick={() => {
                  if (fromSelectVoucherForDeal)
                    handleNavigateToDealInfoPage(voucherObj);
                }}
              >
                {/*  Source Column */}
                <td className="td-voucher">
                  <div className="td-voucher--wrapper">
                    {voucherObj.voucher.source}
                  </div>
                </td>

                {/* Voucher Name Column */}
                <td className="td-name">
                  <div className="td-name--wrapper">
                    {voucherObj.voucher?.name
                      ? truncateString(voucherObj.voucher.name, 30)
                      : "NIL"}
                  </div>
                </td>

                {/* Currency Code Column */}
                <td className="td-voucher">
                  <div className="td-voucher--wrapper">
                    {voucherObj.voucher.currency_code}
                  </div>
                </td>

                {/*  Minimum Amount Column */}
                <td className="td-amount">
                  <div className="td-amount--wrapper">
                    {voucherObj.voucher?.minimum_value?.toLocaleString()}
                  </div>
                </td>

                {/*  Maximum Amount Column */}
                <td className="td-amount">
                  <div className="td-amount--wrapper">
                    {voucherObj.voucher?.maximum_value?.toLocaleString()}
                  </div>
                </td>

                {/*  Add to selected vouchers Column */}
                <td className="td-hot-deal">
                  {!fromSelectVoucherForDeal && (
                    <div className="td-hot-deal-button--wrapper">
                      <div className="make-hot-deal-button-wrapper">
                        <button
                          onClick={() =>
                            handleAddToSelectedVouchers(voucherObj.voucher)
                          }
                        >
                          Add to selected vouchers
                        </button>
                      </div>
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div className="below-table-loader-wrapper">
          {isLoading && <LoadingSpinner />}
        </div>
      </div>
      {!isEmpty(currentVouchersPosts) &&
        !(isLoading && isEmpty(currentVouchersPosts)) && (
          <BEPagination
            currentPage={vouchers?.currentPage || 1}
            lastPage={vouchers.totalPages}
            currentPosts={currentVouchersPosts}
            setPageNumberIndex={setPageNumberIndex}
          />
        )}
    </div>
  );
}

export default VouchersTable;

import styles from "./ConfirmActionModal.module.css";
import attentionIcon from "../../images/attention-icon.svg";
import LoadingSpinner from "../loading-spinner/LoadingSpinner";

// Interfaces
interface IProps {
  isModalOpened: boolean;
  isLoading: boolean;
  confirmationText: string;
  setIsModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
  handleConfirmAction: () => void;
}
function ConfirmActionModal({
  isModalOpened,
  isLoading,
  confirmationText,
  setIsModalOpened,
  handleConfirmAction,
}: IProps) {
  return (
    <div
      className={`${styles.confirm_action_modal_container} ${
        isModalOpened ? "" : "none"
      }`}
    >
      <div className={styles.confirm_action_modal_container__inner}>
        <div className="attention-img-wrapper">
          <img src={attentionIcon} alt="" />
        </div>

        <div className={styles.confirm_action_header_text}>
          Kindly confirm this action
        </div>
        <div className={styles.confirm_action_sub_text}>{confirmationText}</div>

        <div className={styles.modal_action_button_wrapper}>
          {/* Cancel Modal Button */}
          {!isLoading && (
            <div className={styles.cancel_modal_button_wrapper}>
              <button onClick={() => setIsModalOpened(false)}>Cancel</button>
            </div>
          )}

          {/* Conffirm Action Button */}
          <div className={styles.confirm_modal_action_button}>
            {isLoading && <LoadingSpinner />}
            <button onClick={() => handleConfirmAction()}>Confirm</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConfirmActionModal;

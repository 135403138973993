import React, { useContext, useEffect, useState } from "react";
import "./Purchases.css";
import Sidebar from "../../components/app-sidebar/Sidebar";
import Top from "../../components/app-top/Top";
import { SearchContext } from "../../components/contexts/SearchContext";
import ErrorHandler from "../../components/error/ErrorHandler";
import DropdownInput from "../../components/input-components/dropdown-input/DropdownInput";
import SuccessHandler from "../../components/success/SuccessHandler";
import { useAppDispatch, useAppSelector } from "../../hooks";
import PurchasesTable from "./purchases-table/PurchasesTable";
import { getAllOrders } from "../../redux/actions/orderActions";
import useDisplayMessage from "../../hooks/useDisplayMessage";
import {
  IDropdownOptions,
  ISingleCompany,
  ISingleVoucherPurchase,
} from "../../Types";
import { getAllCompanies } from "../../redux/actions/companyActions";
import { ISingleMerchant } from "../requests/Requests";
import { getAllMerchants } from "../../redux/actions/merchantActions";
import Main from "../../components/layout/main-container/Main";

function Purchases() {
  const orders: ISingleVoucherPurchase[] | [] = useAppSelector(
    (state) => state.orders.orders
  );
  const companies: ISingleCompany[] | [] = useAppSelector(
    (state) => state.companies.companies
  );
  const merchants: ISingleMerchant[] | [] = useAppSelector(
    (state) => state.merchants.merchants
  );
  const purchasesAmount = 30000;
  // Functions, States and Variables
  const dispatch = useAppDispatch();
  // States
  const { searchedValue }: any = useContext(SearchContext);
  const [allPurchases, setAllPurchases] = useState<
    ISingleVoucherPurchase[] | null
  >(null);
  const [merchantFilter, setMerchantFilter] = useState("all");
  const [companyFilter, setCompanyFilter] = useState("all");
  const [companyFilterOptions, setCompanyFilterOptions] = useState<
    IDropdownOptions[] | []
  >([]);
  const [merchantFilterOptions, setMerchantFilterOptions] = useState<
    IDropdownOptions[] | []
  >([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [currentPurchasesPosts, setCurrentPurchasesPosts] = useState<
    ISingleVoucherPurchase[] | null
  >(null);

  const indexOfLastPost = currentPage * rowsPerPage;
  const indexOfFirstPost = indexOfLastPost - rowsPerPage;
  const [isLoading, setIsLoading] = useState(false);

  // Functions
  // handle Filter Table
  const handleFilterTable = function (
    companyFilterParam: string,
    merchantFilterParam: string
  ) {
    if (companyFilterParam === "all" && merchantFilterParam === "all") {
      let allOrdersData = orders?.filter((order) => {
        // Searches through the merchant, company and buyer name
        // TODO Add company name to filter param when connecting to API
        return (
          order?.items?.merchantId?.shopName
            .toLowerCase()
            .includes(searchedValue.toLowerCase()) ||
          order?.userId?.firstName
            .toLowerCase()
            .includes(searchedValue.toLowerCase()) ||
          order?.userId?.lastName
            .toLowerCase()
            .includes(searchedValue.toLowerCase())
        );
      });
      setAllPurchases(allOrdersData);

      // Update table state to all rows
      if (!allOrdersData) return false;
      const currentRows = allOrdersData?.slice(
        indexOfFirstPost,
        indexOfLastPost
      );
      setCurrentPurchasesPosts(currentRows);
    } else if (companyFilterParam === "all" && merchantFilterParam !== "all") {
      // FIlter out orders from based on merchant's id only
      let filteredOrdersData = orders
        ?.filter((order) => order.items.merchantId?._id === merchantFilterParam)
        ?.filter((order) => {
          // Searches through the store names
          return (
            order?.items?.merchantId?.shopName
              .toLowerCase()
              .includes(searchedValue.toLowerCase()) ||
            order?.userId?.firstName
              .toLowerCase()
              .includes(searchedValue.toLowerCase()) ||
            order?.userId?.lastName
              .toLowerCase()
              .includes(searchedValue.toLowerCase())
          );
        });
      setAllPurchases(filteredOrdersData);
    } else if (companyFilterParam !== "all" && merchantFilterParam === "all") {
      // FIlter out orders from based on company's id only
      let filteredOrdersData = orders
        ?.filter((order) => order.companyId._id === companyFilterParam)
        ?.filter((order) => {
          // Searches through the store names
          return (
            order?.items?.merchantId?.shopName
              .toLowerCase()
              .includes(searchedValue.toLowerCase()) ||
            order?.userId?.firstName
              .toLowerCase()
              .includes(searchedValue.toLowerCase()) ||
            order?.userId?.lastName
              .toLowerCase()
              .includes(searchedValue.toLowerCase())
          );
        });
      setAllPurchases(filteredOrdersData);
    } else {
      // FIlter out orders from based on company's id only
      let filteredOrdersData = orders
        ?.filter((order) => order.items.merchantId?._id === merchantFilterParam)
        ?.filter((order) => order.companyId._id === companyFilterParam)
        ?.filter((order) => {
          // Searches through the store names
          return (
            order?.items?.merchantId?.shopName
              .toLowerCase()
              .includes(searchedValue.toLowerCase()) ||
            order?.userId?.firstName
              .toLowerCase()
              .includes(searchedValue.toLowerCase()) ||
            order?.userId?.lastName
              .toLowerCase()
              .includes(searchedValue.toLowerCase())
          );
        });
      setAllPurchases(filteredOrdersData);
    }
  };

  // Hooks
  // Handles Display the error and success message and scrolls to top
  const {
    errorHandlerObj,
    setErrorHandlerObj,
    successHandlerObj,
    setSuccessHandlerObj,
  } = useDisplayMessage();

  useEffect(() => {
    dispatch(getAllOrders(setIsLoading, setErrorHandlerObj));
    dispatch(getAllCompanies(setIsLoading, setErrorHandlerObj));
    dispatch(getAllMerchants(setIsLoading, setErrorHandlerObj));
  }, []);

  useEffect(() => {
    console.log(orders);
    setAllPurchases(orders);
  }, [orders]);

  useEffect(() => {
    if (allPurchases) {
      const currentRows = allPurchases?.slice(
        indexOfFirstPost,
        indexOfLastPost
      );
      setCurrentPurchasesPosts(currentRows);
    }
  }, [allPurchases, currentPage]);

  useEffect(() => {
    handleFilterTable(companyFilter || "all", merchantFilter || "all");
    setCurrentPage(1);
  }, [searchedValue]);

  useEffect(() => {
    // Set Company Filter Options
    const allCompaniesOptions = companies?.map((company) => {
      return {
        key: company._id,
        value: company.companyName,
      };
    });
    allCompaniesOptions.unshift({
      key: "all",
      value: "Company",
    });
    setCompanyFilterOptions(allCompaniesOptions);
  }, [companies]);

  useEffect(() => {
    // Set Merchant Filter Options
    const allMerchantsOptions = merchants?.map((merchant) => {
      return {
        key: merchant._id,
        value: merchant.shopName,
      };
    });
    allMerchantsOptions.unshift({
      key: "all",
      value: "Merchant",
    });
    setMerchantFilterOptions(allMerchantsOptions);
  }, [merchants]);

  return (
    <div className="app-page-container">
      <Top />
      <Sidebar activeName={"Purchases"} />

      <Main>
        {/* Error handler component */}
        <ErrorHandler
          errorHandlerObj={errorHandlerObj}
          className="app--actions-handler-wrapper"
        />
        {/* Success handler component */}
        <SuccessHandler
          successHandlerObj={successHandlerObj}
          className="app--actions-handler-wrapper"
        />

        <section className={`app-main-section--inner`}>
          {/* Top  Container*/}
          <div className="purchases-and-orders--top-container">
            {/* Total Orders */}
            <div className="purchases-and-orders--top--box-container">
              {/* Box Title Wrapper */}
              <div className="box-title-wrapper">TOTAL PURCHASES</div>
              {/* Box Value Wrapper */}
              <div className="box-value-wrapper">{orders?.length || 0}</div>
            </div>
            {/* Total Amount in Naira */}
            <div className="purchases-and-orders--top--box-container">
              {/* Box Title Wrapper */}
              <div className="box-title-wrapper">TOTAL AMOUNT IN &#8358;</div>
              {/* Box Value Wrapper */}
              <div className="box-value-wrapper">
                {purchasesAmount?.toLocaleString() || 0}
              </div>
            </div>
          </div>

          <section className={`purchases-and-orders-table-section`}>
            {/* Purchases and Orders Table Top Wrapper */}
            <div className="purchases-table--top-wrapper">
              {/* Company Filter Wrapper */}
              <div className="employers-table-filter-option-wrapper requests--table-filter-option-wrapper">
                <div className="employers-table-filter-row-title requests--table-filter-row-title">
                  Filter:
                </div>
                {/* Filter by company */}
                <div className="orders-table--status-filter-form-group form-group">
                  <DropdownInput
                    className={"orders-status-filter-dropdown-input"}
                    id={"company-filter-input"}
                    label={""}
                    required={true}
                    optionArray={companyFilterOptions}
                    value={companyFilter}
                    onChange={(e) => {
                      setCompanyFilter(e.target.value);
                      handleFilterTable(e.target.value, merchantFilter);
                    }}
                  />
                </div>

                {/* Filter by merchant */}
                <div className="orders-table--status-filter-form-group form-group">
                  <DropdownInput
                    className={"orders-status-filter-dropdown-input"}
                    id={"merchant-filter-input"}
                    label={""}
                    required={true}
                    optionArray={merchantFilterOptions}
                    value={merchantFilter}
                    onChange={(e) => {
                      setMerchantFilter(e.target.value);
                      handleFilterTable(companyFilter, e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>

            {/* Purchases and orders Table Container */}
            <section className={`purchases-and-orders-table-wrapper`}>
              <PurchasesTable
                allPurchases={allPurchases}
                currentPurchasesPosts={currentPurchasesPosts}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                rowsPerPage={rowsPerPage}
                isLoading={isLoading}
              />
            </section>
          </section>
        </section>
      </Main>
    </div>
  );
}

export default Purchases;

import axios from "axios";
import { api } from "../../api/config";
import { SetErrorHandlerType, SetSuccessHandlerType } from "../../Types";
import { handleAPIError } from "../../utils/handleAPIError";

const url = `${api}/api/v1/merchant`;

const token: any = localStorage.getItem("jwtToken");
axios.defaults.headers.common["authorization"] = token;

//   Approve Merchant Request
export const approveMerchantRequest =
  (
    merchantId: string,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setErrorHandlerObj: SetErrorHandlerType,
    setSuccessHandlerObj: SetSuccessHandlerType,
    setIsApproved: React.Dispatch<React.SetStateAction<boolean>>,
    setIsApproveRequestModalOpened: React.Dispatch<
      React.SetStateAction<boolean>
    >
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .patch(`${url}/approve/${merchantId}`)
      .then((res) => {
        // console.log(res.data);
        setSuccessHandlerObj({
          isSuccess: true,
          message: "Merchant approved successfully!",
        });
        setIsApproved(true);
        setIsLoading(false);
        // Close confirmation Modal
        setIsApproveRequestModalOpened(false);
      })
      .catch((err) => {
        // Close confirmation Modal
        setIsApproveRequestModalOpened(false);
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

//   Decline Merchant Request
export const declineMerchantRequest =
  (
    data: {
      reason: string;
    },
    merchantId: string,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setErrorHandlerObj: SetErrorHandlerType,
    setSuccessHandlerObj: SetSuccessHandlerType,
    setIsDeclined: React.Dispatch<React.SetStateAction<boolean>>,
    setIsDeclineRequestModalOpened: React.Dispatch<
      React.SetStateAction<boolean>
    >
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .patch(`${url}/reject/${merchantId}`, data)
      .then((res) => {
        // console.log(res.data);
        setSuccessHandlerObj({
          isSuccess: true,
          message: "Merchant declined successfully!",
        });
        setIsDeclined(true);
        setIsLoading(false);
        // Close confirmation modal
        setIsDeclineRequestModalOpened(false);
      })
      .catch((err) => {
        // Close confirmation modal
        setIsDeclineRequestModalOpened(false);
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

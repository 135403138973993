import React, { useContext, useEffect, useState } from "react";
import "./PurchaseDealsTableSection.css";
import PrimaryButton from "../../../components/buttons/primary-button/PrimaryButton";
import closeIcon from "../../../images/close-icon.svg";
import DropdownInput from "../../../components/input-components/dropdown-input/DropdownInput";
import {
  IAPIResponseData,
  IProductPurchaseDeal,
  SetErrorHandlerType,
  SetSuccessHandlerType,
} from "../../../Types";
import { SearchContext } from "../../../components/contexts/SearchContext";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { getPurchaseDeals } from "../../../redux/actions/productDealActions";
import PurchaseDealsTable from "./table/PurchaseDealsTable";
import isEmpty from "../../../validation/isEmpty";
import BEPagination from "../../../components/pagination/be-pagination/BEPagination";

const voucherFilterOptions = [
  {
    key: "all",
    value: "All",
  },
  {
    key: "redeemed",
    value: "Redeemed",
  },
  {
    key: "non-redeemed",
    value: "Non Redeemed",
  },
];

interface IProps {
  isPurchaseDealSearched: boolean;
  setIsPurchaseDealSearched: React.Dispatch<React.SetStateAction<boolean>>;
  setErrorHandlerObj: SetErrorHandlerType;
  setSuccessHandlerObj: SetSuccessHandlerType;
  setIsSearchVoucherModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
  setIsRedeemVoucherModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedVoucherCodeForRedemption: React.Dispatch<
    React.SetStateAction<string>
  >;
}

function PurchaseDealsTableSection({
  isPurchaseDealSearched,
  setIsPurchaseDealSearched,
  setErrorHandlerObj,
  setSuccessHandlerObj,
  setIsSearchVoucherModalOpened,
  setIsRedeemVoucherModalOpened,
  setSelectedVoucherCodeForRedemption,
}: IProps) {
  // Functions, States and Variables
  const dispatch = useAppDispatch();
  const { searchedValue }: any = useContext(SearchContext);
  const vouchersToBeRedeemed: IAPIResponseData = useAppSelector(
    (state) => state.productDeals.purchaseDeals
  );
  const searchedVoucher = useAppSelector(
    (state) => state.productDeals.searchedPurchaseDeal
  );
  // States
  const [isLoading, setIsLoading] = useState(false);
  const [currentPosts, setCurrentPosts] = useState<
    IProductPurchaseDeal[] | null
  >(null);
  const [pageNumberIndex, setPageNumberIndex] = useState(1);
  const [vouchersFilterParam, setVouchersFilterParam] =
    useState("non-redeemed");

  // Functions
  const handleOpenVoucherSearchModal = function () {
    setIsSearchVoucherModalOpened(true);
  };

  // handle Filter Vouchers TO Be Redeemed
  const handleFilterVouchersToBeRedeemed = function () {
    const vouchersData: IProductPurchaseDeal[] | [] = vouchersToBeRedeemed.data;
    let filteredVouchers = vouchersData
      ?.filter((voucher) => voucher.isActive === true)
      ?.filter((voucher) => {
        if (vouchersFilterParam === "redeemed") {
          return voucher.isRedemed === true;
        } else if (vouchersFilterParam === "non-redeemed") {
          return voucher.isRedemed === false;
        } else {
          return voucher;
        }
      })
      ?.filter(
        (voucher) =>
          voucher.deal.productName
            .toLowerCase()
            .includes(searchedValue.toLowerCase()) ||
          voucher.deal.merchantName
            .toLowerCase()
            .includes(searchedValue.toLowerCase())
      );
    // Update table state to filtered || searched rows
    if (!filteredVouchers) return false;
    setCurrentPosts(filteredVouchers);
  };

  //   Fetch Purchase Deals
  const handleFetchPurchaseDeals = function () {
    dispatch(
      getPurchaseDeals(pageNumberIndex, setIsLoading, setErrorHandlerObj)
    );
  };

  //   UseEffects
  useEffect(() => {
    // Fetch purchase deals based on selected paginated page
    handleFetchPurchaseDeals();
  }, [pageNumberIndex]);

  useEffect(() => {
    // If Voucher is searched, display searched voucher data, else, display all vouchers to be redeemed
    if (isPurchaseDealSearched) {
      setCurrentPosts(searchedVoucher ? [searchedVoucher] : null);
    } else {
      handleFilterVouchersToBeRedeemed();
    }
  }, [
    vouchersToBeRedeemed,
    searchedVoucher,
    isPurchaseDealSearched,
    searchedValue,
    vouchersFilterParam,
  ]);

  return (
    <>
      <section className="primary-table-section purchase-deals-table-section">
        {/* Table Top Wrapper */}
        <div className="primary-table--top-wrapper">
          {/*  Table Filter Wrapper */}
          {!isPurchaseDealSearched ? (
            <div className="primary-table-filter-option-wrapper vouchers-to-be-redeemed-table-filter-wrapper">
              <div className="primary-table-filter-row-title">Filter:</div>
              {/* Date */}
              <div className="primary-table--date-filter-form-group form-group">
                <DropdownInput
                  className={"order-date-filter-dropdown-input"}
                  id={"order-date-filter-input"}
                  label={""}
                  required={true}
                  optionArray={voucherFilterOptions}
                  value={vouchersFilterParam}
                  onChange={(e) => setVouchersFilterParam(e.target.value)}
                />
              </div>
            </div>
          ) : (
            <div className=""></div>
          )}
          <div className="primary-btn-container">
            {!isPurchaseDealSearched ? (
              <PrimaryButton
                className="open-search-modal-button-wrapper"
                placeholder="Search for a Voucher"
                onClick={() => handleOpenVoucherSearchModal()}
              />
            ) : (
              <PrimaryButton
                className="cancel-search-modal-button-wrapper"
                placeholder="Cancel Search"
                onClick={() => setIsPurchaseDealSearched(false)}
              >
                <img src={closeIcon} alt="" />
              </PrimaryButton>
            )}
          </div>
        </div>

        {/*  Purchased Deals Table Container */}
        <PurchaseDealsTable
          currentPosts={currentPosts}
          isLoading={isLoading}
          isPurchaseDealSearched={isPurchaseDealSearched}
          setIsRedeemVoucherModalOpened={setIsRedeemVoucherModalOpened}
          setSelectedVoucherCodeForRedemption={
            setSelectedVoucherCodeForRedemption
          }
          setErrorHandlerObj={setErrorHandlerObj}
          setSuccessHandlerObj={setSuccessHandlerObj}
        />

        {/* Pagination */}
        {!isPurchaseDealSearched &&
          !isEmpty(vouchersToBeRedeemed) &&
          !(isLoading && isEmpty(vouchersToBeRedeemed)) && (
            <BEPagination
              currentPage={vouchersToBeRedeemed?.currentPage || 1}
              lastPage={vouchersToBeRedeemed.totalPages}
              currentPosts={vouchersToBeRedeemed}
              setPageNumberIndex={setPageNumberIndex}
            />
          )}
      </section>
    </>
  );
}

export default PurchaseDealsTableSection;

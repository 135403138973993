import React, { useEffect, useState } from "react";
import "./StockRequestPreview.css";
import { ISingleStock } from "../Requests";
import angleLeftIcon from "../../../images/angle-left.svg";
import moment from "moment";
import pinIcon from "../../../images/pin-icon.svg";
import OtherStocksRequestsTable from "./OtherStocksRequestsTable";
import isEmpty from "../../../validation/isEmpty";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { getAllProductsPerMerchant } from "../../../redux/actions/productActions";
import { SetErrorHandlerType, SetSuccessHandlerType } from "../../../Types";

interface IProps {
  setPageContent: React.Dispatch<React.SetStateAction<string>>;
  selectedStockRequestForPreview: ISingleStock | null;
  setSelectedStockRequestForPreview: React.Dispatch<
    React.SetStateAction<ISingleStock | null>
  >;
  isApproveRequestModalOpened: boolean;
  setIsApproveRequestModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
  isDeclineRequestModalOpened: boolean;
  setIsDeclineRequestModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedStockRequest: (
    value: React.SetStateAction<ISingleStock | null>
  ) => void;
  setErrorHandlerObj: SetErrorHandlerType;
  setSuccessHandlerObj: SetSuccessHandlerType;
  isApproved: boolean;
  isDeclined: boolean;
}

function StockRequestPreview({
  setPageContent,
  selectedStockRequestForPreview,
  setSelectedStockRequestForPreview,
  isApproveRequestModalOpened,
  isDeclineRequestModalOpened,
  setIsApproveRequestModalOpened,
  setIsDeclineRequestModalOpened,
  setSelectedStockRequest,
  setErrorHandlerObj,
  setSuccessHandlerObj,
  isApproved,
  isDeclined,
}: IProps) {
  // Functions, States and Variables
  const allProductsPerMerchant: ISingleStock[] | [] = useAppSelector(
    (state) => state.products.productsPerMerchant
  );
  const dispatch = useAppDispatch();
  // States
  const [otherStockRequests, setOtherStockRequests] = useState<
    ISingleStock[] | null
  >(null);
  const [currentOtherStockRequestPosts, setCurrentOtherStockRequestPosts] =
    useState<ISingleStock[] | null>(null);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastPost = currentPage * rowsPerPage;
  const indexOfFirstPost = indexOfLastPost - rowsPerPage;
  const [isOtherProductsLoading, setIsOtherProductsLoading] = useState(false);

  // UseEffects

  useEffect(() => {
    if (selectedStockRequestForPreview) {
      dispatch(
        getAllProductsPerMerchant(
          selectedStockRequestForPreview.merchant._id,
          setIsOtherProductsLoading,
          setErrorHandlerObj
        )
      );
    }
  }, [selectedStockRequestForPreview]);

  useEffect(() => {
    const allUnapprovedOtherRequests = allProductsPerMerchant?.filter(
      (request) => request.isApproved === false
    );
    // TODO Get all requests per this merchants Id and Filter out the ones that are not approved
    if (
      selectedStockRequestForPreview &&
      !isEmpty(allUnapprovedOtherRequests)
    ) {
      setOtherStockRequests(allUnapprovedOtherRequests);
    }
  }, [selectedStockRequestForPreview, allProductsPerMerchant]);

  useEffect(() => {
    if (otherStockRequests) {
      const currentRows = otherStockRequests?.slice(
        indexOfFirstPost,
        indexOfLastPost
      );

      setCurrentOtherStockRequestPosts(currentRows);
    }
  }, [otherStockRequests]);

  useEffect(() => {
    if (isApproved && selectedStockRequestForPreview) {
      dispatch(
        getAllProductsPerMerchant(
          selectedStockRequestForPreview.merchant._id,
          setIsOtherProductsLoading,
          setErrorHandlerObj
        )
      );
    }
  }, [isApproved]);

  useEffect(() => {
    if (isDeclined && selectedStockRequestForPreview) {
      dispatch(
        getAllProductsPerMerchant(
          selectedStockRequestForPreview.merchant._id,
          setIsOtherProductsLoading,
          setErrorHandlerObj
        )
      );
    }
  }, [isDeclined]);

  return (
    <div className="stock-request-preview-container--inner">
      {/* Stock Request preview Top */}
      <div className={`stock-request-preview-top-container`}>
        <div className="stock-request-preview-top--left-wrapper">
          {/* Back button Wrapper */}
          <div className="back-button-wrapper">
            <button
              onClick={() => {
                setPageContent("stock");
                setSelectedStockRequestForPreview(null);
                setOtherStockRequests(null);
                setCurrentOtherStockRequestPosts(null);
                setCurrentPage(1);
              }}
            >
              <img src={angleLeftIcon} alt="" />
            </button>
          </div>
          {/* Reference Number */}
          <div className="stock-preview-top-title">
            #CR
            {selectedStockRequestForPreview?._id.substring(
              selectedStockRequestForPreview?._id.length - 6
            )}
          </div>
        </div>
      </div>

      {/* Stock Preview Row One Section / Merchant Info Section */}
      <section className="stock-preview--row-one-section">
        <div className="stock-preview--row-one-section--inner">
          {/* Shop Name and Profile Image Wrapper */}
          <div className="stock-preview--row-one--left-wrapper">
            <div className="stock-preview--merchant-img-wrapper">
              <img
                src={selectedStockRequestForPreview?.merchant.profilePicture}
                alt=""
              />
            </div>

            <div className="stock-preview--merchant-shop-name">
              {selectedStockRequestForPreview?.merchant.shopName}
            </div>
          </div>

          {/* Merchant Personal Info Wrapper */}
          <div className="stock-preview--row-one--right-wrapper">
            {/* Stock Date Created */}
            <div className="stock-request-preview--date-created stock-request-preview--merchant-detail-wrapper">
              <div className="info-detail-title">Date Created</div>
              <div className="info-detail-value">
                {moment(selectedStockRequestForPreview?.createdAt).format(
                  "DD, MMM YY"
                )}
              </div>
            </div>
            {/* Merchant Email */}
            <div className="stock-request-preview--email stock-request-preview--merchant-detail-wrapper">
              <div className="info-detail-title">Email</div>
              <div className="info-detail-value">
                {selectedStockRequestForPreview?.merchant.email}
              </div>
            </div>

            {/* Merchant Phone Number */}
            <div className="stock-request-preview--phone stock-request-preview--merchant-detail-wrapper">
              <div className="info-detail-title">Phone Number</div>
              <div className="info-detail-value">
                {selectedStockRequestForPreview?.merchant.phoneNumber}
              </div>
            </div>

            {/* Stock Category */}
            <div className="stock-request-preview--category stock-request-preview--merchant-detail-wrapper">
              <div className="info-detail-title">Category</div>
              <div className="info-detail-value">
                {selectedStockRequestForPreview?.category.name}
              </div>
            </div>

            {/* Merchant Address */}
            <div className="stock-request-preview--address stock-request-preview--merchant-detail-wrapper">
              <div className="info-detail-title">Address</div>
              <div className="info-detail-value">
                {selectedStockRequestForPreview?.merchant.address}
              </div>
            </div>

            {/* Dummy Wrapper */}
            <div className="stock-request-preview--dummy-wrapper stock-request-preview--merchant-detail-wrapper"></div>

            {/* Merchant Address */}
            <div className="stock-request-preview--address stock-request-preview--merchant-detail-wrapper">
              <div className="info-detail-title">Document</div>
              <div className="info-detail-value">
                <div className="cac-document-link--wrapper">
                  {selectedStockRequestForPreview?.merchant.cacDocument ? (
                    <a
                      href={selectedStockRequestForPreview.merchant.cacDocument}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={pinIcon} alt="" />
                      CAC Document.
                      {selectedStockRequestForPreview.merchant.cacDocument.substring(
                        selectedStockRequestForPreview.merchant.cacDocument
                          .length - 3
                      )}
                    </a>
                  ) : (
                    <div className="no-document-link--wrapper">No Document</div>
                  )}
                </div>{" "}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Stock Preview Row Two Section / Other Merchant's Stock Awaiting Approval */}
      <section className="stock-preview--row-two-section">
        <div className="stock-preview--row-two-title-wrapper">
          Awaiting Approval
        </div>

        <div className="other-stock-awaiting-approval-table-container">
          <OtherStocksRequestsTable
            allProductsPerMerchant={allProductsPerMerchant}
            otherStockRequests={otherStockRequests}
            setOtherStockRequests={setOtherStockRequests}
            currentOtherStockRequestPosts={currentOtherStockRequestPosts}
            setCurrentOtherStockRequestPosts={setCurrentOtherStockRequestPosts}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            rowsPerPage={rowsPerPage}
            indexOfFirstPost={indexOfFirstPost}
            indexOfLastPost={indexOfLastPost}
            isLoading={isOtherProductsLoading}
            isApproveRequestModalOpened={isApproveRequestModalOpened}
            setIsApproveRequestModalOpened={setIsApproveRequestModalOpened}
            isDeclineRequestModalOpened={isDeclineRequestModalOpened}
            setIsDeclineRequestModalOpened={setIsDeclineRequestModalOpened}
            setSelectedStockRequest={setSelectedStockRequest}
          />
        </div>
      </section>
    </div>
  );
}

export default StockRequestPreview;

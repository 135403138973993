import React from "react";
import { useContext, useEffect, useState } from "react";
import "./AdminsTableSection.css";
import { useNavigate } from "react-router-dom";
import { IAdministrator } from "../../../../Types";
import { SearchContext } from "../../../../components/contexts/SearchContext";
import isEmpty from "../../../../validation/isEmpty";
import PrimaryButton from "../../../../components/buttons/primary-button/PrimaryButton";
import AdminsTable from "./table/AdminsTable";

interface IProps {
  admins: [] | IAdministrator[];
  isLoading: boolean;
}
function AdminsTableSection({ admins, isLoading }: IProps) {
  // Functions, States and Variables
  const navigate = useNavigate();
  // States
  const { searchedValue }: any = useContext(SearchContext);
  const [allAdmins, setAllAdmins] = useState<IAdministrator[] | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [currentAdminsPosts, setCurrentDealsPosts] = useState<
    IAdministrator[] | null
  >(null);
  const indexOfLastPost = currentPage * rowsPerPage;
  const indexOfFirstPost = indexOfLastPost - rowsPerPage;

  // Functions
  //   handle Filter Table
  const handleFilterTable = function () {
    if (!admins || isEmpty(admins)) return false;

    const allAdminsData = admins.filter(
      (admin) =>
        admin.firstName?.toLowerCase().includes(searchedValue.toLowerCase()) ||
        admin.lastName.toLowerCase().includes(searchedValue.toLowerCase())
    );
    setAllAdmins(allAdminsData);
    // Update table state to users rows
    if (!allAdmins || !allAdminsData) return false;
    const currentRows = allAdminsData.slice(indexOfFirstPost, indexOfLastPost);
    setCurrentDealsPosts(currentRows);
  };

  const handleNavigateToAddNewAdmin = function () {
    navigate("/admin/administrators/add-new");
  };

  //   UseEffects
  useEffect(() => {
    if (admins) setAllAdmins(admins);
  }, [admins]);

  useEffect(() => {
    if (allAdmins) {
      const currentRows = allAdmins?.slice(indexOfFirstPost, indexOfLastPost);
      setCurrentDealsPosts(currentRows);
    }
  }, [allAdmins, currentPage]);

  useEffect(() => {
    handleFilterTable();
    setCurrentPage(1);
  }, [searchedValue]);

  return (
    <section className={`users-table-section`}>
      {/* Deals Table Top Wrapper */}
      <div className="admins-table--top-wrapper">
        {/* Add New category Button Wrapper */}
        <PrimaryButton
          className="add-new-course-btn-wrapper"
          placeholder="Add New Administrator"
          onClick={() => {
            handleNavigateToAddNewAdmin();
          }}
        />
      </div>

      {/* Users Table Container */}
      <section className={`users-table-wrapper`}>
        <AdminsTable
          allAdmins={allAdmins}
          currentAdminsPosts={currentAdminsPosts}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          rowsPerPage={rowsPerPage}
          indexOfFirstPost={indexOfFirstPost}
          indexOfLastPost={indexOfLastPost}
          isLoading={isLoading}
        />
      </section>
    </section>
  );
}

export default AdminsTableSection;

import React, { MouseEvent, useState } from "react";
import TextInput from "../../../components/input-components/text-input/TextInput";
import { IAdminData } from "./AdminFormSetup";
import LoadingSpinner from "../../../components/loading-spinner/LoadingSpinner";
import eyeOpenIcon from "../../../images/eye-open-icon.svg";
import eyeCloseIcon from "../../../images/eye-close-icon.svg";
import PhoneInput from "../../../components/input-components/phone-input/PhoneInput";
import DropdownInput from "../../../components/input-components/dropdown-input/DropdownInput";
import { adminRolesOptions } from "../../../utils/adminRolesOptions";
import PrimaryButton from "../../../components/buttons/primary-button/PrimaryButton";
// Interfaces
interface IProps {
  pageType: string;
  adminFormInputStates: IAdminData;
  isLoading: boolean;
  setAdminFormInputStates: React.Dispatch<React.SetStateAction<IAdminData>>;
  handleCreateNewAdmin: (e: MouseEvent<HTMLButtonElement>) => void;
}

function FormSection({
  pageType,
  adminFormInputStates,
  isLoading,
  setAdminFormInputStates,
  handleCreateNewAdmin,
}: IProps) {
  // Functions, states and variables
  // States
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  //   Functions
  const handleUpdateFormInputState = function (
    fieldName: string,
    fieldValue: string
  ) {
    const updatedFormInputStateObject = {
      ...adminFormInputStates,
      [fieldName]: fieldValue,
    };
    setAdminFormInputStates(updatedFormInputStateObject);
  };
  return (
    <section className="administrators-form-container">
      <form>
        <div className="form-group-wrapper">
          {/* Firstname form group */}
          {pageType === "create" && (
            <div className="form-group">
              <TextInput
                type={"text"}
                id={"admin-firstname-text-input"}
                className={"admin-firstname-input"}
                value={adminFormInputStates.firstName}
                onChange={(e) =>
                  handleUpdateFormInputState("firstName", e.target.value)
                }
                required={true}
                label={"First Name"}
              />
            </div>
          )}

          {/* Lastname form group */}
          {pageType === "create" && (
            <div className="form-group">
              <TextInput
                type={"text"}
                id={"admin-lastname-text-input"}
                className={"admin-lastname-input"}
                value={adminFormInputStates.lastName}
                onChange={(e) =>
                  handleUpdateFormInputState("lastName", e.target.value)
                }
                required={true}
                label={"Last Name"}
              />
            </div>
          )}

          {/* Email form group */}
          {pageType === "create" && (
            <div className="form-group">
              <TextInput
                type={"email"}
                id={"admin-email-text-input"}
                className={"admin-email-input"}
                value={adminFormInputStates.email}
                onChange={(e) =>
                  handleUpdateFormInputState("email", e.target.value)
                }
                required={true}
                label={"Email"}
              />
            </div>
          )}

          {/* Phone Number form group */}
          {pageType === "create" && (
            <div className="form-group">
              <PhoneInput
                type={"tel"}
                id={"admin-phone-number-text-input"}
                className={"admin-phone-number-input"}
                placeholder={""}
                value={adminFormInputStates.phoneNumber}
                onChange={(e) =>
                  handleUpdateFormInputState("phoneNumber", e.target.value)
                }
                required={true}
                label={"Phone Number"}
                maxLength={11}
              />
            </div>
          )}

          {/* Role form group */}
          <div className="form-group">
            <DropdownInput
              id={"admin-role-input"}
              className={"admin-role-input"}
              label={"Role"}
              required={true}
              optionArray={[
                { key: "", value: "", default: "default" },
                ...adminRolesOptions,
              ]}
              value={adminFormInputStates.role}
              onChange={(e) => {
                handleUpdateFormInputState("role", e.target.value);
              }}
            />
          </div>

          {/* Password form group */}
          {pageType === "create" && (
            <div className="form-group">
              <img
                src={isPasswordVisible ? eyeOpenIcon : eyeCloseIcon}
                alt=""
                className="password-icon-wrapper"
                onClick={() => setIsPasswordVisible(!isPasswordVisible)}
              />

              <label htmlFor={"employer-signup-password-input"}>Password</label>
              <input
                type={isPasswordVisible ? "text" : "password"}
                id={"employer-signup-password-input"}
                className={"employer-signup-password-text-input"}
                value={adminFormInputStates.password}
                onChange={(e) =>
                  handleUpdateFormInputState("password", e.target.value)
                }
                required={true}
              />
            </div>
          )}
        </div>

        {/* Submit form button */}
        <div className="admin-form-primary-btn-container primary-button-container">
          {isLoading && <LoadingSpinner />}
          <PrimaryButton
            className="admin-setup-form--action-btn"
            placeholder="Submit"
            onClick={(e) => handleCreateNewAdmin(e)}
          />
        </div>
      </form>
    </section>
  );
}

export default FormSection;

import React, { useContext, useEffect, useState } from "react";
import "./StockRequestsTable.css";
import { Table } from "react-bootstrap";
import DropdownInput from "../../../components/input-components/dropdown-input/DropdownInput";
import FEPagination from "../../../components/pagination/fe-pagination/FEPagination";
import isEmpty from "../../../validation/isEmpty";
import LoadingSpinner from "../../../components/loading-spinner/LoadingSpinner";
import closeIcon from "../../../images/close-icon.svg";
import tickIcon from "../../../images/tick-icon.svg";
import { SearchContext } from "../../../components/contexts/SearchContext";
import { ISingleStock } from "../Requests";

interface IProps {
  stockRequests: [] | ISingleStock[];
  allStockRequests: ISingleStock[] | null;
  setAllStockRequests: React.Dispatch<
    React.SetStateAction<ISingleStock[] | null>
  >;
  currentStockRequestPosts: ISingleStock[] | null;
  setCurrentStockRequestPosts: (
    value: React.SetStateAction<ISingleStock[] | null>
  ) => void;
  setPageContent: React.Dispatch<React.SetStateAction<string>>;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  rowsPerPage: number;
  indexOfFirstPost: number;
  indexOfLastPost: number;
  isLoading: boolean;
  isApproveRequestModalOpened: boolean;
  setIsApproveRequestModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
  isDeclineRequestModalOpened: boolean;
  setIsDeclineRequestModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedStockRequest: (
    value: React.SetStateAction<ISingleStock | null>
  ) => void;
  setSelectedStockRequestForPreview: React.Dispatch<
    React.SetStateAction<ISingleStock | null>
  >;
}

const StockRequestsTable: React.FC<IProps> = function ({
  stockRequests,
  allStockRequests,
  setAllStockRequests,
  currentStockRequestPosts,
  setCurrentStockRequestPosts,
  setPageContent,
  setCurrentPage,
  currentPage,
  rowsPerPage,
  indexOfFirstPost,
  indexOfLastPost,
  isLoading,
  isApproveRequestModalOpened,
  isDeclineRequestModalOpened,
  setIsApproveRequestModalOpened,
  setIsDeclineRequestModalOpened,
  setSelectedStockRequest,
  setSelectedStockRequestForPreview,
}) {
  // Functions and States
  const { searchedValue }: any = useContext(SearchContext);
  // States
  const [stockRequestsDateFilter, setStockRequestsDateFilter] = useState("");
  // Functions
  const paginateForStockRequests = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  //   handle Filter Table
  const handleFilterTable = function () {
    if (!stockRequests || isEmpty(stockRequests)) return false;

    const stockRequestsData = stockRequests?.filter(
      (request) =>
        request.name.toLowerCase().includes(searchedValue.toLowerCase()) ||
        request.name.toLowerCase().includes(searchedValue.toLowerCase())
    );

    setAllStockRequests(stockRequestsData);
    // Update table state to stock requests rows
    if (!allStockRequests || !stockRequestsData) return false;
    const currentRows = stockRequestsData.slice(
      indexOfFirstPost,
      indexOfLastPost
    );

    setCurrentStockRequestPosts(currentRows);
  };

  useEffect(() => {
    handleFilterTable();
    setCurrentPage(1);
  }, [searchedValue]);

  return (
    <div className="stock-requests-table-section--inner">
      {/* Requests Table Filter Wrapper */}
      <div className="requests--table-filter-option-wrapper">
        <div className="requests--table-filter-row-title">Filter:</div>
        {/* Filter by stock request application date dropdown */}
        <div className="requests--date-filter-form-group form-group">
          <DropdownInput
            className={"salary-advance-date-filter-dropdown-input"}
            id={"salary-advance-date-filter-input"}
            label={""}
            required={true}
            optionArray={[
              { key: "", value: "Date", default: "Default" },
              {
                key: "yesterday",
                value: "Yesterday",
              },
            ]}
            value={stockRequestsDateFilter}
            onChange={(e) => setStockRequestsDateFilter(e.target.value)}
          />
        </div>
      </div>

      {/* All Stock Requests Table Container */}
      <div className="primary-table-container">
        <Table responsive="lg" className="primary-table stock-requests-table">
          <thead
            style={{
              textAlign: "left",
            }}
          >
            <tr>
              {["ITEM NAME", "MERCHANT", "CATGEORY", "PRICE", "", ""].map(
                (COLUMN, index) => (
                  <th key={index + 1}>{COLUMN}</th>
                )
              )}
            </tr>
          </thead>

          <tbody>
            {currentStockRequestPosts?.map((stock, i) => (
              <tr key={i + 1}>
                {/* Item Name Column */}
                <td
                  className="td-name"
                  onClick={() => {
                    setPageContent("stock-preview");
                    setSelectedStockRequestForPreview(stock);
                  }}
                >
                  <div className="td-name--wrapper">
                    {/* Stock image wrapper */}
                    <div className="profile-image-wrapper">
                      <img src={stock.image} alt="" />
                    </div>
                    {stock.name}
                  </div>
                </td>

                {/*  Merchant Name Column */}
                <td
                  className="td-merchant-name"
                  onClick={() => {
                    setPageContent("stock-preview");
                    setSelectedStockRequestForPreview(stock);
                  }}
                >
                  <div className="td-merchant-name--wrapper">
                    {stock.merchant?.shopName || ""}
                  </div>
                </td>

                {/*  Category Column */}
                <td
                  className="td-category"
                  onClick={() => {
                    setPageContent("stock-preview");
                    setSelectedStockRequestForPreview(stock);
                  }}
                >
                  <div className="td-category--wrapper">
                    {stock.category.name}
                  </div>
                </td>

                {/*  Price Column */}
                <td
                  className="td-stock"
                  onClick={() => {
                    setPageContent("stock-preview");
                    setSelectedStockRequestForPreview(stock);
                  }}
                >
                  <div className="td-stock--wrapper">
                    &#8358;{stock.price.toLocaleString()}
                  </div>
                </td>

                {/* Decline Request Btn Column*/}
                <td className="td-decline-request">
                  {!stock.isRejected ? (
                    <div className="td-decline-request--wrapper">
                      <button
                        onClick={() => {
                          setIsDeclineRequestModalOpened(true);
                          setSelectedStockRequest(stock);
                        }}
                      >
                        <img src={closeIcon} alt="" />
                        Decline
                      </button>
                    </div>
                  ) : (
                    <div className="item-rejected-or-declined-wrapper">
                      Declined
                    </div>
                  )}
                </td>

                {/* Approve Request Btn Column*/}
                <td className="td-approve-request">
                  <div className="td-approve-request--wrapper">
                    <button
                      onClick={() => {
                        setIsApproveRequestModalOpened(true);
                        setSelectedStockRequest(stock);
                      }}
                    >
                      <img src={tickIcon} alt="" />
                      Approve
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div className="below-table-loader-wrapper">
          {isLoading && <LoadingSpinner />}
        </div>
      </div>

      {allStockRequests && !isEmpty(currentStockRequestPosts) && (
        <FEPagination
          paginate={paginateForStockRequests}
          rowsPerPage={rowsPerPage}
          totalPosts={allStockRequests.length}
          currentPage={currentPage}
        />
      )}
    </div>
  );
};

export default StockRequestsTable;

import { useEffect } from "react";
import Sidebar from "../../components/app-sidebar/Sidebar";
import Top from "../../components/app-top/Top";
import Main from "../../components/layout/main-container/Main";
import ErrorHandler from "../../components/error/ErrorHandler";
import SuccessHandler from "../../components/success/SuccessHandler";
import useDisplayMessage from "../../hooks/useDisplayMessage";
import { useLocation } from "react-router-dom";
import SubscriptionTop from "./comps/subscription-top/SubscriptionTop";
import SubscriptionList from "./comps/subscription-list/SubscriptionList";

function Subscriptions() {
  // Functions, States and Variables
  const location = useLocation();
  //   Hooks
  // Handles Display the error and success message and scrolls to top
  const {
    errorHandlerObj,
    setErrorHandlerObj,
    successHandlerObj,
    setSuccessHandlerObj,
  } = useDisplayMessage();

  //   UseEffects
  useEffect(() => {
    if (location.state) {
      const { message }: any = location.state;
      if (message) setSuccessHandlerObj({ isSuccess: true, message });
    }
  }, [location]);

  return (
    <div className="app-page-container">
      <Top />
      <Sidebar activeName={"Subscriptions"} />

      <Main>
        {/* Error handler component */}
        <ErrorHandler
          errorHandlerObj={errorHandlerObj}
          className="app--actions-handler-wrapper"
        />
        {/* Success handler component */}
        <SuccessHandler
          successHandlerObj={successHandlerObj}
          className="app--actions-handler-wrapper"
        />

        <section className={`app-main-section--inner`}>
          {/* Subscriptions Top */}
          <SubscriptionTop />

          {/* Subscription Lists */}
          <SubscriptionList setErrorHandlerObj={setErrorHandlerObj} />
        </section>
      </Main>
    </div>
  );
}

export default Subscriptions;

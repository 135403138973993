import { useContext, useEffect, useState } from "react";
import Sidebar from "../../components/app-sidebar/Sidebar";
import Top from "../../components/app-top/Top";
import { SearchContext } from "../../components/contexts/SearchContext";
import ErrorHandler from "../../components/error/ErrorHandler";
import DropdownInput from "../../components/input-components/dropdown-input/DropdownInput";
import SuccessHandler from "../../components/success/SuccessHandler";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  getAllCompanies,
  getAllStaffCount,
} from "../../redux/actions/companyActions";
import useDisplayMessage from "../../hooks/useDisplayMessage";
import isEmpty from "../../validation/isEmpty";
import EmployerPreview from "./employer-preview/EmployerPreview";
import EmployersTable from "./employers-table/EmployersTable";
import "./Employers.css";
import UpdateFreeTrialModal from "./modals/UpdateFreeTrialModal";
import { ISingleCompany } from "../../Types";
import Main from "../../components/layout/main-container/Main";

function Employers() {
  // Functions, States and Variables
  const companies: ISingleCompany[] | [] = useAppSelector(
    (state) => state.companies.companies
  );
  const staffCount = useAppSelector((state) => state.companies.totalStaffCount);
  const dispatch = useAppDispatch();
  // States
  const { searchedValue }: any = useContext(SearchContext);
  const [pageContent, setPageContent] = useState("main");
  const [allCompanies, setAllCompanies] = useState<ISingleCompany[] | null>(
    null
  );
  const [employersDateFilter, setEmployersDateFilter] = useState("");
  const [selectedCompanyForPreview, setSelectedCompanyForPreview] =
    useState<ISingleCompany | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [currentCompaniesPosts, setCurrentCompaniesPosts] = useState<
    ISingleCompany[] | null
  >(null);
  const indexOfLastPost = currentPage * rowsPerPage;
  const indexOfFirstPost = indexOfLastPost - rowsPerPage;
  const [isLoading, setIsLoading] = useState(false);
  // Employer Preview
  const [isPreviewedEmployerUpdated, setIsPreviewedEmployerUpdated] =
    useState(false);

  // Functions
  //   handle Filter Table
  const handleFilterTable = function () {
    if (!companies || isEmpty(companies)) return false;

    const allCompaniesData = companies?.filter((company) =>
      company.companyName.toLowerCase().includes(searchedValue.toLowerCase())
    );

    setAllCompanies(allCompaniesData);
    // Update table state to company rows
    if (!allCompanies || !allCompaniesData) return false;
    const currentRows = allCompaniesData.slice(
      indexOfFirstPost,
      indexOfLastPost
    );

    setCurrentCompaniesPosts(currentRows);
  };

  // Hooks
  // Handles Display the error and success message and scrolls to top
  const {
    errorHandlerObj,
    setErrorHandlerObj,
    successHandlerObj,
    setSuccessHandlerObj,
  } = useDisplayMessage();

  // UseEffects
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [pageContent]);

  // If response is success, remove failure message
  useEffect(() => {
    if (successHandlerObj.isSuccess)
      dispatch(getAllCompanies(setIsLoading, setErrorHandlerObj));
  }, [successHandlerObj]);

  // If previewed employer is updated
  useEffect(() => {
    // If Operation data is updated for a company
    if (isPreviewedEmployerUpdated) {
      dispatch(getAllCompanies(setIsLoading, setErrorHandlerObj));
    }
  }, [isPreviewedEmployerUpdated]);

  useEffect(() => {
    dispatch(getAllCompanies(setIsLoading, setErrorHandlerObj));
    dispatch(getAllStaffCount(setIsLoading, setErrorHandlerObj));
  }, []);

  useEffect(() => {
    setAllCompanies(companies);

    // If previewed employer is updated,
    if (isPreviewedEmployerUpdated) {
      const currentPreviewedCompany = companies?.filter(
        (company: ISingleCompany) =>
          company._id === selectedCompanyForPreview?._id
      );
      // Update selected company for preview
      if (currentPreviewedCompany)
        setSelectedCompanyForPreview(currentPreviewedCompany[0]);
    }
  }, [companies]);

  // For Employers
  useEffect(() => {
    if (allCompanies) {
      const currentRows = allCompanies?.slice(
        indexOfFirstPost,
        indexOfLastPost
      );

      setCurrentCompaniesPosts(currentRows);
    }
  }, [allCompanies, currentPage]);

  useEffect(() => {
    handleFilterTable();
    setCurrentPage(1);
  }, [searchedValue]);

  // useEffect(() => {
  //   if (isLoanAccessUpdated)
  //     dispatch(getAllCompanies(setIsLoading, setErrorHandlerObj));
  // }, [isLoanAccessUpdated]);
  const [isUpdateFreeTrialModalOpened, setIsUpdateFreeTrialModalOpened] =
    useState(false);

  return (
    <div
      className={`app-page-container  ${
        isUpdateFreeTrialModalOpened ? "overflow-hidden" : ""
      }`}
    >
      <Top />
      <Sidebar activeName={"Employers"} />

      {/* Gray Overlay */}
      <div
        onClick={() => {
          setIsUpdateFreeTrialModalOpened(false);
        }}
        className={`gray-overlay ${isUpdateFreeTrialModalOpened ? "" : "none"}`}
      ></div>

      {/* Modals */}
      {selectedCompanyForPreview && (
        <UpdateFreeTrialModal
          selectedCompanyForPreview={selectedCompanyForPreview}
          isModalOpened={isUpdateFreeTrialModalOpened}
          setIsModalOpened={setIsUpdateFreeTrialModalOpened}
          setErrorHandlerObj={setErrorHandlerObj}
          setSuccessHandlerObj={setSuccessHandlerObj}
          setIsPreviewedEmployerUpdated={setIsPreviewedEmployerUpdated}
        />
      )}
      <Main>
        {/* Error handler component */}
        <ErrorHandler
          errorHandlerObj={errorHandlerObj}
          className="app--actions-handler-wrapper"
        />
        {/* Success handler component */}
        <SuccessHandler
          successHandlerObj={successHandlerObj}
          className="app--actions-handler-wrapper"
        />

        {/* Main Employers Table Section  */}
        <section
          className={`app-main-section--inner ${
            pageContent === "main" ? "" : "none"
          }`}
        >
          {/* Top  Container*/}
          <div className="employers--top-container">
            {/* Top Companies Box Container */}
            <div className="employers--top--box-container top-companies-box-container">
              {/* Box Title Wrapper */}
              <div className="box-title-wrapper">TOP COMPANIES</div>
              {/* Box Value Wrapper */}
              <div className="box-value-wrapper">{companies?.length || 0}</div>
            </div>
            {/* Top Employees amount Box Container */}
            <div className="employers--top--box-container top-employees-amount-box-container">
              {/* Box Title Wrapper */}
              <div className="box-title-wrapper">TOTAL AMOUNT OF EMPLOYEES</div>
              {/* Box Value Wrapper */}
              <div className="box-value-wrapper">{staffCount}</div>
            </div>
          </div>

          {/* Employers Table Section*/}
          <section className={`employers-table-section`}>
            {/* Employers Table Top Wrapper */}
            <div className="employers-table--top-wrapper">
              {/* Employers Table Filter Wrapper */}
              <div className="employers-table-filter-option-wrapper requests--table-filter-option-wrapper">
                <div className="employers-table-filter-row-title requests--table-filter-row-title">
                  Filter:
                </div>
                {/* Filter by date added dropdown */}
                <div className="employers-table--date-filter-form-group form-group">
                  <DropdownInput
                    className={"employers-date-filter-dropdown-input"}
                    id={"employers-date-filter-input"}
                    label={""}
                    required={true}
                    optionArray={[
                      { key: "", value: "Date", default: "Default" },
                      {
                        key: "yesterday",
                        value: "Yesterday",
                      },
                    ]}
                    value={employersDateFilter}
                    onChange={(e) => setEmployersDateFilter(e.target.value)}
                  />
                </div>
              </div>

              {/* Add New Employer Button Wrapper  */}
              <div className="add-new-button-wrapper">
                <button
                  onClick={() => {
                    // navigate("/admin/employers/add-new");
                    window.open(
                      `${process.env.REACT_APP_COMPANY_URL}/employer/signup`,
                      "_blank"
                    );
                  }}
                >
                  Add New
                </button>
              </div>
            </div>

            {/* Employers Table Container */}
            <section className={`employers-table-wrapper`}>
              <EmployersTable
                allCompanies={allCompanies}
                currentCompaniesPosts={currentCompaniesPosts}
                setPageContent={setPageContent}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                rowsPerPage={rowsPerPage}
                isLoading={isLoading}
                setSelectedCompanyForPreview={setSelectedCompanyForPreview}
              />
            </section>
          </section>
        </section>

        {/* Employer Preview Section  */}
        <section
          className={`employers-container-main-section ${
            pageContent === "preview" ? "" : "none"
          }`}
        >
          <EmployerPreview
            pageContent={pageContent}
            selectedCompanyForPreview={selectedCompanyForPreview}
            setPageContent={setPageContent}
            setIsPreviewedEmployerUpdated={setIsPreviewedEmployerUpdated}
            setIsUpdateFreeTrialModalOpened={setIsUpdateFreeTrialModalOpened}
          />
        </section>
      </Main>
    </div>
  );
}

export default Employers;

import React, { useState } from "react";
import AdminFormSetup from "../admin-form-setup/AdminFormSetup";

function AddNewAdmin() {
  // Functions, States and Variabes

  return <AdminFormSetup pageType="create" />;
}

export default AddNewAdmin;

import React, { useEffect, useState } from "react";
import "./SelectCompaniesToAccess.css";
import angleDown from "../../../../../images/angle-down-secondary.svg";
import cancelIcon from "../../../../../images/cancel-secondary.svg";
import searchIcon from "../../../../../images/search-icon.svg";
import { useAppDispatch, useAppSelector } from "../../../../../hooks";
import { getAllCompanies } from "../../../../../redux/actions/companyActions";
import {
  ISingleCompany,
  IWellnessCourse,
  SetErrorHandlerType,
  SetSuccessHandlerType,
} from "../../../../../Types";
import PrimaryButton from "../../../../../components/buttons/primary-button/PrimaryButton";
import LoadingSpinner from "../../../../../components/loading-spinner/LoadingSpinner";
import { restrictCourseAccess } from "../../../../../redux/actions/wellnessActions";

// Interfaces
interface IProps {
  selectedCourseForPreview: IWellnessCourse | null;
  setSuccessHandlerObj: SetSuccessHandlerType;
  setErrorHandlerObj: SetErrorHandlerType;
}

function SelectCompaniesToAccess({
  selectedCourseForPreview,
  setSuccessHandlerObj,
  setErrorHandlerObj,
}: IProps) {
  // Function, States and Variables
  const dispatch = useAppDispatch();
  const companies: ISingleCompany[] | [] = useAppSelector(
    (state) => state.companies.companies
  );
  // States
  const [selectedCompaniesToAccess, setSelectedCompaniesToAccess] = useState<
    string[]
  >([]);
  const [searchValue, setSearchValue] = useState("");
  const [filteredCompanies, setFilteredCompanies] = useState<
    ISingleCompany[] | null
  >(null);
  const [isFormContainerOpened, setIsFormContainerOpened] = useState(true);
  const [companiesCheckedState, setCompaniesCheckedState] = useState<boolean[]>(
    []
  );
  const [isLoading, setIsLoading] = useState(false);

  // Functions
  // Handle Check or Uncheck on Companies Checkbox
  const handleChangeOnCheckbox = (companyId: string) => {
    // If Country is already selected || is  among selected countries
    if (selectedCompaniesToAccess.includes(companyId)) {
      const index = selectedCompaniesToAccess?.findIndex(
        (selectedCompany) => selectedCompany === companyId
      );
      if (index > -1) {
        const selectedCompaniesDuplicate = [...selectedCompaniesToAccess];
        // Remove Company from Selected Companies Array and Update Array
        selectedCompaniesDuplicate.splice(index, 1);
        setSelectedCompaniesToAccess(selectedCompaniesDuplicate);
      }
    } else {
      // If country is not selected || is not among selected countries
      setSelectedCompaniesToAccess([...selectedCompaniesToAccess, companyId]);
    }
  };

  // handle Get Company Name From Id
  const handleGetCompanyNameFromId = function (companyId: string) {
    const currentCompany = companies?.filter(
      (companyObj) => companyObj._id === companyId
    );
    return currentCompany[0]?.companyName || "";
  };

  const handleUpdateCourseRestrictionAccess = function () {
    // Set error state to default
    setErrorHandlerObj({ hasError: false, message: "" });
    const selectedCompanyIds = selectedCompaniesToAccess?.map((companyId) => {
      return { companyId: companyId };
    });
    const data = { companies: selectedCompanyIds };
    if (selectedCourseForPreview)
      dispatch(
        restrictCourseAccess(
          selectedCourseForPreview._id,
          data,
          setIsLoading,
          setErrorHandlerObj,
          setSuccessHandlerObj
        )
      );
    //
  };

  // useEffect
  useEffect(() => {
    // Update Access Object with pre existing company ids with course access
    if (selectedCourseForPreview?.companies) {
      const currentCompaniesWithAccessToCourse =
        selectedCourseForPreview.companies?.map(
          (companyObj) => companyObj.companyId
        );
      setSelectedCompaniesToAccess(currentCompaniesWithAccessToCourse);
    }
  }, [selectedCourseForPreview]);

  useEffect(() => {
    if (selectedCompaniesToAccess && filteredCompanies) {
      // This UseEffect handles update of companies check state when selected companies and filtered companies should change
      const updatedCompaniesCheckState = filteredCompanies?.map(
        (companyObj) => {
          return selectedCompaniesToAccess.includes(companyObj._id);
        }
      );
      setCompaniesCheckedState(updatedCompaniesCheckState);
    }
  }, [selectedCompaniesToAccess, filteredCompanies]);

  useEffect(() => {
    // This useeffect handles update of filtered companies when search values should change, handles company filter
    const updatedFilteredCompanies = companies?.filter((companyObj) =>
      companyObj.companyName.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredCompanies(updatedFilteredCompanies);
  }, [companies, searchValue]);

  useEffect(() => {
    dispatch(getAllCompanies(setIsLoading, setErrorHandlerObj));
  }, []);

  return (
    <div className="select-companies-to-access-container">
      {/* Toggle Form Wrapper */}
      <div className="toggle-form-section-button-wrapper">
        <button
          onClick={(e) => {
            e.preventDefault();
            setIsFormContainerOpened(!isFormContainerOpened);
          }}
        >
          Select companies to grant access to course
          <img
            src={angleDown}
            className={isFormContainerOpened ? "icon-rotated-up" : ""}
            alt=""
          />
        </button>
      </div>

      {/* Selected Companies Overview Wrapper */}
      <div className="selected-companies-overview-wrapper">
        {/* Selected Companies Wrapper */}
        {selectedCompaniesToAccess?.map((companyId, index) => (
          <div key={index + 1} className="primary--selected-object-wrapper">
            <div className="left-col">
              <div className="object-main-text">
                {handleGetCompanyNameFromId(companyId)}
              </div>
            </div>
            <div className="right-col">
              <button
                onClick={(e) => {
                  // Since category already exists in selected categories, it will be removed
                  e.preventDefault();
                  handleChangeOnCheckbox(companyId);
                }}
              >
                <img src={cancelIcon} alt="" />
              </button>
            </div>
          </div>
        ))}
      </div>

      {/* Select Companies Form Section */}
      <div
        className={`select-companies-to-access-form-section ${
          isFormContainerOpened ? "" : "collapsed"
        }`}
      >
        <div className="select-companies-to-access-form-section--inner">
          <div className="form-search-wrapper">
            <img src={searchIcon} alt="" />
            <input
              type="search"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </div>

          <div className="select-entity-checkboxes-wrapper">
            {filteredCompanies &&
              companiesCheckedState?.map((checkedValue, index) => (
                <div key={index + 1} className="companies-checkbox">
                  <input
                    type="checkbox"
                    id={`select-entity-checkbox-${index}`}
                    checked={companiesCheckedState[index]}
                    onChange={() => {
                      handleChangeOnCheckbox(filteredCompanies[index]?._id);
                    }}
                  />
                  <label htmlFor={`select-entity-checkbox-${index}`}>
                    {filteredCompanies[index]?.companyName}
                  </label>
                </div>
              ))}
          </div>
        </div>
      </div>

      {/* Submit Button */}
      <div className="select-entity-action-btn-container">
        <div className="primary-button-container">
          <PrimaryButton
            className="course-access-form--action-btn"
            placeholder="Update Access"
            onClick={() => handleUpdateCourseRestrictionAccess()}
          />
          {isLoading && <LoadingSpinner />}
        </div>
      </div>
    </div>
  );
}

export default SelectCompaniesToAccess;

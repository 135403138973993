import attentionIcon from "../../../../images/attention-icon.svg";
import LoadingSpinner from "../../../../components/loading-spinner/LoadingSpinner";

// Interfaces
interface IProps {
  isModalOpened: boolean;
  isLoading: boolean;
  setIsModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
  handleDeleteDeal: () => void;
}
function DeleteDealModal({
  isModalOpened,
  isLoading,
  setIsModalOpened,
  handleDeleteDeal,
}: IProps) {
  return (
    <div
      className={`delete-deal-modal-container ${isModalOpened ? "" : "none"}`}
    >
      <div className="delete-deal-modal-container--inner">
        <div className="attention-img-wrapper">
          <img src={attentionIcon} alt="" />
        </div>

        <div className="confirm-action-header-text">
          Kindly confirm this action
        </div>
        <div className="confirm-action-sub-text">
          Would you like to delete this deal
        </div>

        <div className="modal-action-button-wrapper">
          {/* Cancel Modal Button */}
          {!isLoading && (
            <div className="cancel-modal-button-wrapper">
              <button onClick={() => setIsModalOpened(false)}>Cancel</button>
            </div>
          )}

          {/* Conffirm Action Button */}
          <div className="confirm-modal-action-button">
            {isLoading && <LoadingSpinner />}
            <button onClick={() => handleDeleteDeal()}>Confirm</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeleteDealModal;

import React, { useEffect, useState } from "react";
import Sidebar from "../../components/app-sidebar/Sidebar";
import Top from "../../components/app-top/Top";
import ErrorHandler from "../../components/error/ErrorHandler";
import SubstituteLoadingSpinner from "../../components/substitute-loading-spinner/SubstituteLoadingSpinner";
import SuccessHandler from "../../components/success/SuccessHandler";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  approveWithdrawalRequest,
  getAllCompanyWithdrawalRequests,
} from "../../redux/actions/withdrawalRequestsActions";
import useDisplayMessage from "../../hooks/useDisplayMessage";
import { ISingleUser } from "../users/Users";
import BNPLTable from "./bnpl-table/BNPLTable";
import DeclineEmployeeRequestsModal from "./modals/DeclineEmployeeRequestsModal";
import OTPModal from "./modals/OTPModal";
import ShowUserBalanceModal from "./modals/ShowUserBalanceModal";
import OnDemandTable from "./on-demand-table/OnDemandTable";
import SalaryAdvanceTable from "./salary-advance-table/SalaryAdvanceTable";
import "./Withdrawals.css";
import { ISingleEmployee } from "../../Types";
import Main from "../../components/layout/main-container/Main";

export interface IAllRequests {
  account: {
    accountNumber: string;
    bank: string;
    bankCode?: string;
    accountName: string;
  };
  _id: string;
  amount: number;
  type: string;
  status: number;
  isPaid: boolean;
  isRejected: boolean;
  isOTPProvided: boolean;
  isApproved: boolean;
  isApprovedByAdmin: boolean;
  userId: ISingleUser;
  companyId?: { companyName: string };
  staffId: ISingleEmployee;
  createdAt: string;
  adminRejectionReason?: string;
  hrRejectionReason?: string;
  tenure?: string;
  repayments?: {
    amount: number;
    date: string;
  }[];
}

export interface IUserLoanBalance {
  bnpl: number;
  salaryAdvance: number;
  onDemand: number;
}

function Withdrawals() {
  // Functions, States and Variables
  const dispatch = useAppDispatch();
  const allRequests: [] | IAllRequests[] = useAppSelector(
    (state) => state.withdrawalRequest.requests
  );
  // States
  const [pageContent, setPageContent] = useState("on-demand");
  const [allSalaryAdvanceRequests, setAllSalaryAdvanceRequests] = useState<
    IAllRequests[] | null
  >(null);
  const [allBNPLRequests, setAllBNPLRequests] = useState<IAllRequests[] | null>(
    null
  );
  const [allOnDemandRequests, setAllOnDemandRequests] = useState<
    IAllRequests[] | null
  >(null);
  const [selectedEmployeeRequest, setSelectedEmployeeRequest] =
    useState<IAllRequests | null>(null);

  const [currentPageForSalaryAdvance, setCurrentPageForSalaryAdvance] =
    useState(1);
  const [currentPageForBNPL, setCurrentPageForBNPL] = useState(1);
  const [currentPageForOnDemand, setCurrentPageForOnDemand] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentSalaryAdvancePosts, setCurrentSalaryAdvancePosts] = useState<
    IAllRequests[] | null
  >(null);
  const [currentBNPLPosts, setCurrentBNPLPosts] = useState<
    IAllRequests[] | null
  >(null);
  const [currentOnDemandPosts, setCurrentOnDemandPosts] = useState<
    IAllRequests[] | null
  >(null);

  const [isApproved, setIsApproved] = useState(false);
  const [isDeclined, setIsDeclined] = useState(false);
  const [isOTPModalOpened, setIsOTPModalOpened] = useState(false);
  const [
    isDeclineEmployeeRequestModalOpened,
    setIsDeclineEmployeeRequestModalOpened,
  ] = useState(false);
  const [isShowUserBalanceModalOpened, setIsShowUserBalanceModalOpened] =
    useState(false);
  const [userLoanBalance, setUserLoanBalance] =
    useState<IUserLoanBalance | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isApproveRequestLoading, setIsApproveRequestLoading] = useState(false);

  // Salary Advance
  const indexOfLastPostForSalaryAdvance =
    currentPageForSalaryAdvance * rowsPerPage;
  const indexOfFirstPostForSalaryAdvance =
    indexOfLastPostForSalaryAdvance - rowsPerPage;
  // BNPL
  const indexOfLastPostForBNPL = currentPageForBNPL * rowsPerPage;
  const indexOfFirstPostForBNPL = indexOfLastPostForBNPL - rowsPerPage;
  // On Demand
  const indexOfLastPostForOnDemand = currentPageForOnDemand * rowsPerPage;
  const indexOfFirstPostForOnDemand = indexOfLastPostForOnDemand - rowsPerPage;

  // Functions
  // handle Change Page Content To Salary Advance
  const handleChangePageContentToSalaryAdvance = function () {
    setPageContent("salary-advance");

    // Update table state to salary advance rows
    if (!allSalaryAdvanceRequests) return false;
    const currentRows = allSalaryAdvanceRequests.slice(
      indexOfFirstPostForSalaryAdvance,
      indexOfLastPostForSalaryAdvance
    );

    setCurrentSalaryAdvancePosts(currentRows);
  };

  // handle Change Page Content To BNPL
  const handleChangePageContentToBNPL = function () {
    setPageContent("bnpl");

    // Update table state to bnpl rows
    if (!allBNPLRequests) return false;
    const currentRows = allBNPLRequests.slice(
      indexOfFirstPostForBNPL,
      indexOfLastPostForBNPL
    );

    setCurrentBNPLPosts(currentRows);
  };

  // Handle Approve Withdrawal Requests
  const handleApproveWithdrawalRequest = function (requestId: string) {
    // Call the dispatch function to approve request, in which on success opens otp modal
    dispatch(
      approveWithdrawalRequest(
        requestId,
        setIsApproveRequestLoading,
        setErrorHandlerObj,
        setSuccessHandlerObj,
        setIsOTPModalOpened
      )
    );
  };

  // Hooks
  const {
    errorHandlerObj,
    setErrorHandlerObj,
    successHandlerObj,
    setSuccessHandlerObj,
  } = useDisplayMessage();

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [pageContent]);

  useEffect(() => {
    dispatch(getAllCompanyWithdrawalRequests(setIsLoading, setErrorHandlerObj));
  }, []);

  useEffect(() => {
    console.log(allRequests, "here");
    if (allRequests) {
      const salaryAdvanceData = allRequests.filter((request) => {
        return request.type === "salaryAdvance";
      });
      setAllSalaryAdvanceRequests(salaryAdvanceData);

      const bnplData = allRequests.filter((request) => {
        return request.type === "bnpl";
      });
      setAllBNPLRequests(bnplData);

      const onDemandData = allRequests.filter((request) => {
        return request.type === "onDemand";
      });

      setAllOnDemandRequests(onDemandData);
    }
  }, [allRequests]);

  // For Salary Advance Requests
  useEffect(() => {
    if (allSalaryAdvanceRequests) {
      const currentRows = allSalaryAdvanceRequests?.slice(
        indexOfFirstPostForSalaryAdvance,
        indexOfLastPostForSalaryAdvance
      );

      setCurrentSalaryAdvancePosts(currentRows);
    }
  }, [allSalaryAdvanceRequests, currentPageForSalaryAdvance]);

  // For BNPL Requests
  useEffect(() => {
    if (allBNPLRequests) {
      const currentRows = allBNPLRequests?.slice(
        indexOfFirstPostForBNPL,
        indexOfLastPostForBNPL
      );

      setCurrentBNPLPosts(currentRows);
    }
  }, [allBNPLRequests, currentPageForBNPL]);

  // For On Demand Requests
  useEffect(() => {
    if (allOnDemandRequests) {
      const currentRows = allOnDemandRequests?.slice(
        indexOfFirstPostForOnDemand,
        indexOfLastPostForOnDemand
      );

      setCurrentOnDemandPosts(currentRows);
    }
  }, [allOnDemandRequests, currentPageForOnDemand]);

  useEffect(() => {
    // If Both Modals are closed, set selected employee to null
    if (
      !isOTPModalOpened &&
      !isDeclineEmployeeRequestModalOpened &&
      !isShowUserBalanceModalOpened
    ) {
      setSelectedEmployeeRequest(null);
    }
  }, [
    isOTPModalOpened,
    isDeclineEmployeeRequestModalOpened,
    isShowUserBalanceModalOpened,
  ]);

  useEffect(() => {
    // Refetch data on approve of request
    if (isApproved)
      dispatch(
        getAllCompanyWithdrawalRequests(setIsLoading, setErrorHandlerObj)
      );
  }, [isApproved]);

  useEffect(() => {
    // Refetch data on decline of request
    if (isDeclined)
      dispatch(
        getAllCompanyWithdrawalRequests(setIsLoading, setErrorHandlerObj)
      );
  }, [isDeclined]);
  // States
  return (
    <div
      className={`app-page-container ${
        isOTPModalOpened ||
        isDeclineEmployeeRequestModalOpened ||
        isShowUserBalanceModalOpened
          ? "overflow-hidden"
          : ""
      }`}
    >
      <Top />
      <Sidebar activeName={"Withdrawals"} />

      {/* Gray Overlay */}
      <div
        onClick={() => {
          setIsOTPModalOpened(false);
          setIsDeclineEmployeeRequestModalOpened(false);
          setIsShowUserBalanceModalOpened(false);
        }}
        className={`gray-overlay ${
          isOTPModalOpened ||
          isDeclineEmployeeRequestModalOpened ||
          isShowUserBalanceModalOpened
            ? ""
            : "none"
        }`}
      ></div>

      {/* Confirm Approve Request / OTP Modal Modal -- For all Withdrawal Requests */}
      <OTPModal
        isOTPModalOpened={isOTPModalOpened}
        setIsOTPModalOpened={setIsOTPModalOpened}
        selectedEmployeeRequest={selectedEmployeeRequest}
        setSelectedEmployeeRequest={setSelectedEmployeeRequest}
        setIsApproved={setIsApproved}
        setErrorHandlerObj={setErrorHandlerObj}
        setSuccessHandlerObj={setSuccessHandlerObj}
        pageContent={pageContent}
        isApproveRequestLoading={isApproveRequestLoading}
      />

      {/* Confirm Decline Request For All Withdrawal Requests */}
      <DeclineEmployeeRequestsModal
        isDeclineEmployeeRequestModalOpened={
          isDeclineEmployeeRequestModalOpened
        }
        setIsDeclineEmployeeRequestModalOpened={
          setIsDeclineEmployeeRequestModalOpened
        }
        selectedEmployeeRequest={selectedEmployeeRequest}
        setIsDeclined={setIsDeclined}
        setErrorHandlerObj={setErrorHandlerObj}
        setSuccessHandlerObj={setSuccessHandlerObj}
        pageContent={pageContent}
      />

      {/* Show Users Loan Balance Modal */}
      <ShowUserBalanceModal
        isShowUserBalanceModalOpened={isShowUserBalanceModalOpened}
        setIsShowUserBalanceModalOpened={setIsShowUserBalanceModalOpened}
        userLoanBalance={userLoanBalance}
        setErrorHandlerObj={setErrorHandlerObj}
        setSuccessHandlerObj={setSuccessHandlerObj}
        selectedEmployeeRequest={selectedEmployeeRequest}
        pageContent={pageContent}
      />

      <Main>
        {/* Error handler component */}
        <ErrorHandler
          errorHandlerObj={errorHandlerObj}
          className="app--actions-handler-wrapper"
        />
        {/* Success handler component */}
        <SuccessHandler
          successHandlerObj={successHandlerObj}
          className="app--actions-handler-wrapper"
        />

        <section className={`app-main-section--inner`}>
          <div className="requests-top-wrapper">
            {/* Requests filter section */}
            <div className="requests-filter-container">
              {/* On-Demand Request option */}
              <div
                className={`requests-filter-option filter-option--on-demand ${
                  pageContent === "on-demand" ? "active-filter-option" : ""
                }`}
                onClick={() => setPageContent("on-demand")}
              >
                On-Demand
              </div>
              {/* Salary Advance Request option */}
              <div
                className={`requests-filter-option filter-option--salary-advance ${
                  pageContent === "salary-advance" ? "active-filter-option" : ""
                }`}
                onClick={() => handleChangePageContentToSalaryAdvance()}
              >
                Salary Advance
              </div>
              {/* BNPL Request option */}
              {/* <div
                className={`requests-filter-option filter-option--BNPL ${
                  pageContent === "bnpl" ? "active-filter-option" : ""
                }`}
                onClick={() => handleChangePageContentToBNPL()}
              >
                BNPL
              </div> */}
            </div>
          </div>

          {/* If Request is being approved */}
          {isApproveRequestLoading && (
            <div className="approve-withdrawal-request-loading-spinner-wrapper">
              {<SubstituteLoadingSpinner />}
            </div>
          )}

          {/* On Demand Requests Table Section */}
          <section
            className={`on-demand-requests-table-section ${
              pageContent === "on-demand" ? "" : "none"
            }`}
          >
            <OnDemandTable
              allOnDemandRequests={allOnDemandRequests}
              currentOnDemandPosts={currentOnDemandPosts}
              currentPage={currentPageForOnDemand}
              setCurrentPage={setCurrentPageForOnDemand}
              selectedEmployeeRequest={selectedEmployeeRequest}
              setSelectedEmployeeRequest={setSelectedEmployeeRequest}
              setCurrentOnDemandPosts={setCurrentOnDemandPosts}
              allRequests={allRequests}
              setAllOnDemandRequests={setAllOnDemandRequests}
              rowsPerPage={rowsPerPage}
              indexOfFirstPost={indexOfFirstPostForOnDemand}
              indexOfLastPost={indexOfLastPostForOnDemand}
              isLoading={isLoading}
              setIsOTPModalOpened={setIsOTPModalOpened}
              setIsDeclineEmployeeRequestModalOpened={
                setIsDeclineEmployeeRequestModalOpened
              }
              setIsShowUserBalanceModalOpened={setIsShowUserBalanceModalOpened}
              setUserLoanBalance={setUserLoanBalance}
              setErrorHandlerObj={setErrorHandlerObj}
              setIsApproveRequestLoading={setIsApproveRequestLoading}
              isApproveRequestLoading={isApproveRequestLoading}
              handleApproveWithdrawalRequest={handleApproveWithdrawalRequest}
            />
          </section>

          {/* Salary Advance Requests Table Section */}
          <section
            className={`salary-advance-requests-table-section ${
              pageContent === "salary-advance" ? "" : "none"
            }`}
          >
            <SalaryAdvanceTable
              allSalaryAdvanceRequests={allSalaryAdvanceRequests}
              currentSalaryAdvancePosts={currentSalaryAdvancePosts}
              setPageContent={setPageContent}
              currentPage={currentPageForSalaryAdvance}
              setCurrentPage={setCurrentPageForSalaryAdvance}
              selectedEmployeeRequest={selectedEmployeeRequest}
              setSelectedEmployeeRequest={setSelectedEmployeeRequest}
              setCurrentSalaryAdvancePosts={setCurrentSalaryAdvancePosts}
              allRequests={allRequests}
              setAllSalaryAdvanceRequests={setAllSalaryAdvanceRequests}
              rowsPerPage={rowsPerPage}
              indexOfFirstPost={indexOfFirstPostForSalaryAdvance}
              indexOfLastPost={indexOfLastPostForSalaryAdvance}
              isLoading={isLoading}
              setIsOTPModalOpened={setIsOTPModalOpened}
              setIsDeclineEmployeeRequestModalOpened={
                setIsDeclineEmployeeRequestModalOpened
              }
              setIsShowUserBalanceModalOpened={setIsShowUserBalanceModalOpened}
              setUserLoanBalance={setUserLoanBalance}
              setErrorHandlerObj={setErrorHandlerObj}
              setIsApproveRequestLoading={setIsApproveRequestLoading}
              isApproveRequestLoading={isApproveRequestLoading}
              handleApproveWithdrawalRequest={handleApproveWithdrawalRequest}
            />
          </section>

          {/* BNPL Requests Table Section */}
          <section
            className={`bnpl-requests-table-section ${
              pageContent === "bnpl" ? "" : "none"
            }`}
          >
            <BNPLTable
              allBNPLRequests={allBNPLRequests}
              currentBNPLPosts={currentBNPLPosts}
              setPageContent={setPageContent}
              currentPage={currentPageForBNPL}
              setCurrentPage={setCurrentPageForBNPL}
              selectedEmployeeRequest={selectedEmployeeRequest}
              setSelectedEmployeeRequest={setSelectedEmployeeRequest}
              allRequests={allRequests}
              setCurrentBNPLPosts={setCurrentBNPLPosts}
              setAllBNPLRequests={setAllBNPLRequests}
              rowsPerPage={rowsPerPage}
              indexOfFirstPost={indexOfFirstPostForBNPL}
              indexOfLastPost={indexOfLastPostForBNPL}
              isLoading={isLoading}
              setIsOTPModalOpened={setIsOTPModalOpened}
              setIsDeclineEmployeeRequestModalOpened={
                setIsDeclineEmployeeRequestModalOpened
              }
              setIsShowUserBalanceModalOpened={setIsShowUserBalanceModalOpened}
              setUserLoanBalance={setUserLoanBalance}
              setErrorHandlerObj={setErrorHandlerObj}
              setIsApproveRequestLoading={setIsApproveRequestLoading}
              isApproveRequestLoading={isApproveRequestLoading}
              handleApproveWithdrawalRequest={handleApproveWithdrawalRequest}
            />
          </section>
        </section>
      </Main>
    </div>
  );
}

export default Withdrawals;

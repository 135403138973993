import React, { useState } from "react";
import "./AccountSetup.css";
import SetupLayout from "./setup-layout/SetupLayout";
import Top from "../app-top/Top";

interface IProps {
  pageType: string;
  pageStateObj: {};
}
const AccountSetup = function ({ pageType, pageStateObj }: IProps) {
  // Since this comp is reusable, it takes a pageType prop specifying the page u the comp
  // Funtion, State and Variables
  // States
  const [pageContent, setPageContent] = useState("currency-rates");

  // Function

  return (
    <div className="dashboard-setup-container">
      <Top />

      {/*Dashboard Setup Section */}
      <section className={`dashboard-setup-form-section`}>
        <SetupLayout
          pageContent={pageContent}
          setPageContent={setPageContent}
          pageType={pageType}
          pageStateObj={pageStateObj}
        />
      </section>
    </div>
  );
};

export default AccountSetup;

import React, { MouseEvent, useEffect, useState } from "react";
import "../course-content-form/CourseContentForm.css";
import TextInput from "../../../../../components/input-components/text-input/TextInput";
import PrimaryButton from "../../../../../components/buttons/primary-button/PrimaryButton";
import plusIcon from "../../../../../images/plus-icon.svg";
import trashIcon from "../../../../../images/trash-icon-secondary.svg";
import { IAssessmentQuestionInputStates } from "../../../../../Types";
import isEmpty from "../../../../../validation/isEmpty";

// Interfaces
interface IProps {
  assessmentQuestionsInputStates: IAssessmentQuestionInputStates[];
  setAssessmentQuestionsInputStates: React.Dispatch<
    React.SetStateAction<IAssessmentQuestionInputStates[]>
  >;
  handleChangePageToAssessmentQuestions: (
    e: MouseEvent<HTMLButtonElement>
  ) => void;
}

function AssessmentQuestionsForm({
  assessmentQuestionsInputStates,
  setAssessmentQuestionsInputStates,
  handleChangePageToAssessmentQuestions,
}: IProps) {
  // Functions, States and variables
  // States
  const [currentAssessmentOptions, setCurrentAssessmentOptions] =
    useState<any>(null);
  const [activeIndex, setActiveIndex] = useState<any>("");
  // Functions
  //   Handle Update Assessment Questions Input State
  const handleUpdateCourseAssessmentInputState = function (
    fieldName: string,
    fieldValue: any,
    index: number
  ) {
    const formInputStatesCopy = [...assessmentQuestionsInputStates];

    let updatedFieldObjectForIndex: IAssessmentQuestionInputStates = {
      ...assessmentQuestionsInputStates[index],
    };
    updatedFieldObjectForIndex = {
      ...assessmentQuestionsInputStates[index],
      [fieldName]: fieldValue,
    };
    formInputStatesCopy.splice(index, 1, updatedFieldObjectForIndex);
    setAssessmentQuestionsInputStates(formInputStatesCopy);
  };

  // Handle Remove Question from Assessment Questions Input States
  const handleRemoveQuestion = function (
    e: MouseEvent<HTMLButtonElement>,
    index: number
  ) {
    e.preventDefault();
    // Remove object from videos array
    setAssessmentQuestionsInputStates((prevStateInputs) => {
      const updatedFormData = [...prevStateInputs];
      // Splice out the selected file object out of the file arr
      updatedFormData.splice(index, 1);
      return updatedFormData;
    });
  };

  // handle Add New Question  Object
  const handleAddNewQuestion = function (e: MouseEvent<HTMLButtonElement>) {
    const isQuestionObjectEmpty = assessmentQuestionsInputStates?.some(
      (formObject) => formObject.question === "" || isEmpty(formObject.options)
    );

    // Check if no input state is empty
    if (!isQuestionObjectEmpty) {
      e.preventDefault();
      const newCourseContentObject = {
        question: "",
        options: [],
        answer: "",
      };
      const updatedAssessmentQuestionsInputStates = [
        ...assessmentQuestionsInputStates,
        newCourseContentObject,
      ];
      setAssessmentQuestionsInputStates(updatedAssessmentQuestionsInputStates);
    }
  };

  // Useeffects
  useEffect(() => {
    console.log(assessmentQuestionsInputStates);
    if (currentAssessmentOptions !== null) {
      const currentAssessmentOptionsArr =
        currentAssessmentOptions === ""
          ? []
          : currentAssessmentOptions?.split(",");
      handleUpdateCourseAssessmentInputState(
        "options",
        currentAssessmentOptionsArr,
        activeIndex
      );
    }
  }, [currentAssessmentOptions]);

  return (
    <section className="course-details-form-section">
      <div className="form-section-title-label">Assessment Questions</div>

      <div className="course-file-form-group-container">
        {assessmentQuestionsInputStates?.map((questionObj, index) => (
          <div key={index + 1} className="course-file-form-group-wrapper">
            {/* Form header container */}
            <div className="form-header-container">
              <div className="form-header-action-wrapper">
                Question {index + 1}
              </div>
              {/* Delete Form Object Wrapper */}
              {assessmentQuestionsInputStates.length > 1 &&
                index !== assessmentQuestionsInputStates.length && (
                  <button
                    onClick={(e) => handleRemoveQuestion(e, index)}
                    className="delete-form-object-wrapper"
                  >
                    <img src={trashIcon} alt="" />
                    Delete Question
                  </button>
                )}
            </div>

            <div className="course-assessment--form-wrapper">
              {/* Question Form Group */}
              <div className="form-group">
                <TextInput
                  type={"text"}
                  id={`assessment-question-input-${index}`}
                  className={"assessment-question-input"}
                  value={questionObj?.question || ""}
                  onChange={(e) =>
                    handleUpdateCourseAssessmentInputState(
                      "question",
                      e.target.value,
                      index
                    )
                  }
                  required={true}
                  label={"Question"}
                />
              </div>

              {/* Options Form Group */}
              <div
                className="form-group"
                onFocus={() => {
                  setActiveIndex(index);
                  setCurrentAssessmentOptions(
                    questionObj?.options?.join(", ") || ""
                  );
                }}
              >
                <TextInput
                  type={"text"}
                  id={`assessment-option-input-${index}`}
                  className={"assessment-option-input"}
                  placeholder="e.g ant, bag, cap, dog"
                  value={
                    activeIndex === index
                      ? currentAssessmentOptions
                      : questionObj?.options.join(", ") || ""
                  }
                  onChange={(e) => {
                    setCurrentAssessmentOptions(e.target.value);
                  }}
                  required={true}
                  label={"Options"}
                />
              </div>

              <div className="course-form-radio-input-wrapper" key={index + 1}>
                {questionObj?.options?.map((optionValue, ind) => (
                  <div key={ind + 1} className={"radio-form-group"}>
                    <input
                      type="radio"
                      className="form-check-input"
                      id={`question_${index}_radio-${ind}`}
                      checked={questionObj.answer === optionValue}
                      onChange={(e) =>
                        handleUpdateCourseAssessmentInputState(
                          "answer",
                          optionValue,
                          index
                        )
                      }
                    />
                    <label htmlFor={`question_${index}_radio-${ind}`}>
                      {optionValue}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}

        <div className="">
          <button
            className="form-header-action-wrapper"
            onClick={handleAddNewQuestion}
          >
            <img src={plusIcon} alt="" /> Add Question
          </button>
        </div>
      </div>

      {/* Submit Button */}
      <div className="primary-button-container course-form--submit-btn-wrapper">
        <PrimaryButton
          className="course-content-form--action-btn"
          placeholder="Assessment Details"
          onClick={(e) => handleChangePageToAssessmentQuestions(e)}
        />
      </div>
    </section>
  );
}

export default AssessmentQuestionsForm;

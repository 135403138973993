import React, { useEffect, useState } from "react";
import "./Sidebar.css";
import { Link, useNavigate } from "react-router-dom";
import { allNavLinks, IAllNavLinks } from "../../utils/allNavLinks";
import jwtDecode from "jwt-decode";
import { handleGetAdminRoleAccessFromRoleKey } from "../../utils/adminRolesOptions";

interface IProps {
  activeName: string;
}

const Sidebar: React.FC<IProps> = function ({ activeName }) {
  const navigate = useNavigate();

  // Function and variables
  const [navLinksArr, setNavLinksArr] = useState<IAllNavLinks[] | null>(null);

  // Functions
  const handleSetNavbarAccessBasedOnAdminRole = function (adminRole: string) {
    let adminNavLinkAccessArr: IAllNavLinks[] | [] = [];
    if (!adminRole) adminNavLinkAccessArr = allNavLinks;
    if (adminRole) {
      // If admin role exists, get the access arr and then get the access link objects
      const currentAdminAccessArr =
        handleGetAdminRoleAccessFromRoleKey(adminRole);
      const accessNavLinks: any = allNavLinks?.map((link) => {
        if (currentAdminAccessArr.includes(link.name)) return link;
      });
      adminNavLinkAccessArr = accessNavLinks.filter(Boolean);
    }

    // Update the Nav Links State
    setNavLinksArr((prevNavLinks) => {
      if (!adminNavLinkAccessArr) return null;
      const index = adminNavLinkAccessArr?.findIndex(
        (links) => links.name === activeName
      );
      let newAllNavLinks = [...adminNavLinkAccessArr];
      let currentActiveLink = adminNavLinkAccessArr
        ?.filter((links) => links.name === adminNavLinkAccessArr[index].name)
        ?.map((links) => {
          return {
            ...links,
            active: true,
          };
        });

      newAllNavLinks.splice(index, 1, currentActiveLink[0]);

      return newAllNavLinks;
    });
  };

  useEffect(() => {
    const token = localStorage.getItem("jwtToken");
    const decoded: any = jwtDecode(token ?? "");
    if (!token) {
      navigate("/");
    }
    // Set navigation or sidebar links based on the role of the user/admin
    handleSetNavbarAccessBasedOnAdminRole(decoded.role || "");
  }, []);

  return (
    <div className="sidebar-container">
      <div className="sidebar-container--inner">
        {/* Navigation Links Container */}
        <nav className="navigation-links-container">
          {/* Dashboard Link Wrapper */}
          {navLinksArr?.map((link, index) => (
            <div
              key={index + 1}
              className={`nav-link-container ${link.name.toLowerCase()}-link-wrapper  ${
                link.active ? "active-link-wrapper" : ""
              }`}
            >
              <Link to={link.link}>
                <img src={link.active ? link.activeIcon : link.icon} alt="" />
                {link.name}
              </Link>
            </div>
          ))}
        </nav>
      </div>
    </div>
  );
};

export default Sidebar;

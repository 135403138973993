import React from "react";
import AddNewCourse from "../add-new-course-setup/AddNewCourse";

function CreateCourse() {
  return (
    <>
      <AddNewCourse />
    </>
  );
}

export default CreateCourse;

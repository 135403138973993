import React, { MouseEvent, useEffect, useState } from "react";
import "../../../products/modals/Modals.css";
import {
  ICourseCategory,
  SetErrorHandlerType,
  SetSuccessHandlerType,
} from "../../../../Types";
import { useAppDispatch } from "../../../../hooks";
import SubstituteLoadingSpinner from "../../../../components/substitute-loading-spinner/SubstituteLoadingSpinner";
import TextInput from "../../../../components/input-components/text-input/TextInput";
import {
  createCourseCategory,
  updateCourseCategory,
} from "../../../../redux/actions/wellnessActions";

interface IProps {
  isModalOpened: boolean;
  selectedCategoryForEdit: ICourseCategory | null;
  setIsModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedCategoryForEdit: React.Dispatch<
    React.SetStateAction<ICourseCategory | null>
  >;
  setSuccessHandlerObj: SetSuccessHandlerType;
  setErrorHandlerObj: SetErrorHandlerType;
}

function StoreCourseCategoryModal({
  isModalOpened,
  selectedCategoryForEdit,
  setIsModalOpened,
  setSelectedCategoryForEdit,
  setSuccessHandlerObj,
  setErrorHandlerObj,
}: IProps) {
  // Functions and states
  const dispatch = useAppDispatch();

  // States
  const [categoryName, setCategoryName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [modalActionType, setModalActionType] = useState("");

  //   Functions;
  const handleStoreCourseCategory = function (
    e: MouseEvent<HTMLButtonElement>
  ) {
    setSuccessHandlerObj({ isSuccess: false, message: "" });
    setErrorHandlerObj({ hasError: false, message: "" });
    if (categoryName !== "") {
      e.preventDefault();
      const data = {
        name: categoryName,
      };
      if (!selectedCategoryForEdit) {
        //  Call the API to create a course category
        dispatch(
          createCourseCategory(
            data,
            setIsLoading,
            setErrorHandlerObj,
            setSuccessHandlerObj,
            setIsModalOpened
          )
        );
      } else {
        // Call the API to edit selected course category
        if (selectedCategoryForEdit)
          dispatch(
            updateCourseCategory(
              selectedCategoryForEdit._id,
              data,
              setIsLoading,
              setErrorHandlerObj,
              setSuccessHandlerObj,
              setIsModalOpened
            )
          );
      }
    }
  };

  // UseEffects
  useEffect(() => {
    if (isModalOpened) {
      // Cleanup function that clears input state when modal is closed
      return () => {
        setCategoryName("");
        setSelectedCategoryForEdit(null);
      };
    }
  }, [isModalOpened]);

  useEffect(() => {
    setModalActionType("create");
    if (selectedCategoryForEdit) {
      setCategoryName(selectedCategoryForEdit.name);
      setModalActionType("edit");
    }
  }, [selectedCategoryForEdit]);

  return (
    <div
      className={`product-and-categories-modal-container ${
        isModalOpened ? "" : "none"
      }`}
    >
      <div className="product-and-categories-modal-container--inner">
        <div className="product-and-categories-modal-header">
          {modalActionType === "create" ? "Add New Category" : "Edit Category"}
          {isLoading && <SubstituteLoadingSpinner />}
        </div>

        {/* Course Category form wrapper */}
        <div className="brand-and-category-name-form-wrapper">
          <form>
            {/* Category Name form group */}
            <div className="category-name-form-group form-group">
              <TextInput
                type={"text"}
                id={"category-name-text-input"}
                className={"category-name-input"}
                placeholder={""}
                value={categoryName}
                onChange={(e) => setCategoryName(e.target.value)}
                required={true}
                label={"Category Name"}
              />
            </div>

            <div className="product-and-categories-modal-action-buttons-wrapper">
              {/* Submit Btn Wrapper */}
              <div className="submit-product-and-categories-btn-wrapper">
                <button onClick={(e) => handleStoreCourseCategory(e)}>
                  Done
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default StoreCourseCategoryModal;

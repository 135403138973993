import React, { useContext, useEffect, useState } from "react";
import "./Users.css";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../components/app-sidebar/Sidebar";
import Top from "../../components/app-top/Top";
import { SearchContext } from "../../components/contexts/SearchContext";
import { useAppDispatch, useAppSelector } from "../../hooks";
import ErrorHandler from "../../components/error/ErrorHandler";
import SuccessHandler from "../../components/success/SuccessHandler";
import UsersTable from "./users-table/UsersTable";
import isEmpty from "../../validation/isEmpty";
import UserPreview from "./user-preview/UserPreview";
import { getAllUsers } from "../../redux/actions/usersActions";
import useDisplayMessage from "../../hooks/useDisplayMessage";
import Main from "../../components/layout/main-container/Main";

// Interfaces
export interface ISingleUser {
  address: string;
  bankAccounts: {
    accountName: string;
    accountNumber: string;
    bank: string;
    bankCode: string;
    isDefault: boolean;
    _id: string;
  }[];
  bvn: string;
  cart: string[];
  companyCode: string;
  companyId?: string;
  createdAt: string;
  dateOfBirth: string;
  email: string;
  firstName: string;
  idNumber: string;
  idType: string;
  isActive: boolean;
  isAdmin: boolean;
  isIDImageVerified: boolean;
  isIDVerified: boolean;
  isSelfieVerified: boolean;
  isSuperAdmin: boolean;
  isVerified: boolean;
  jobId: string;
  lastName: string;
  phoneNumber: string;
  sex: string;
  staffId: string;
  wishlist: string[];
  withdrawals: string[];
  _id: string;
  image?: string;
  role?: string;
}

function Users() {
  // Functions, States and Variables
  const users: ISingleUser[] | [] = useAppSelector(
    (state) => state.allUsers.users
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  // States
  const { searchedValue }: any = useContext(SearchContext);
  const [pageContent, setPageContent] = useState("main");
  const [allUsers, setAllUsers] = useState<ISingleUser[] | null>(null);
  const [selectedUsersForPreview, setSelectedUsersForPreview] =
    useState<ISingleUser | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [currentUsersPosts, setCurrentUsersPosts] = useState<
    ISingleUser[] | null
  >(null);
  const indexOfLastPost = currentPage * rowsPerPage;
  const indexOfFirstPost = indexOfLastPost - rowsPerPage;
  const [isLoading, setIsLoading] = useState(false);

  // Functions
  //   handle Filter Table
  const handleFilterTable = function () {
    if (!users || isEmpty(users)) return false;

    const allUsersData = users?.filter(
      (user) =>
        user.firstName.toLowerCase().includes(searchedValue.toLowerCase()) ||
        user.lastName.toLowerCase().includes(searchedValue.toLowerCase())
    );
    setAllUsers(allUsersData);
    // Update table state to users rows
    if (!allUsers || !allUsersData) return false;
    const currentRows = allUsersData.slice(indexOfFirstPost, indexOfLastPost);
    setCurrentUsersPosts(currentRows);
  };

  // Hooks
  const {
    errorHandlerObj,
    setErrorHandlerObj,
    successHandlerObj,
    setSuccessHandlerObj,
  } = useDisplayMessage();

  // UseEffects
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [pageContent]);

  useEffect(() => {
    dispatch(getAllUsers(setIsLoading, setErrorHandlerObj));
    // dispatch(getAllStaffCount(setIsLoading, setErrorHandlerObj));
  }, []);

  useEffect(() => {
    setAllUsers(users);
  }, [users]);

  useEffect(() => {
    if (allUsers) {
      const currentRows = allUsers?.slice(indexOfFirstPost, indexOfLastPost);
      setCurrentUsersPosts(currentRows);
    }
  }, [allUsers, currentPage]);

  useEffect(() => {
    handleFilterTable();
    setCurrentPage(1);
  }, [searchedValue]);

  return (
    <div className="app-page-container">
      <Top />
      <Sidebar activeName={"Users"} />

      <Main>
        {/* Error handler component */}
        <ErrorHandler
          errorHandlerObj={errorHandlerObj}
          className="app--actions-handler-wrapper"
        />
        {/* Success handler component */}
        <SuccessHandler
          successHandlerObj={successHandlerObj}
          className="app--actions-handler-wrapper"
        />

        <section
          className={`app-main-section--inner ${
            pageContent === "main" ? "" : "none"
          }`}
        >
          {/* Top  Container*/}
          <div className="users--top-container">
            {/* Top Companies Box Container */}
            <div className="users--top--box-container top-companies-box-container">
              {/* Box Title Wrapper */}
              <div className="box-title-wrapper">TOTAL COUNT OF USERS</div>
              {/* Box Value Wrapper */}
              <div className="box-value-wrapper">{users?.length || 0}</div>
            </div>
          </div>

          {/* Users Table Section */}
          <section className={`users-table-section`}>
            {/* Users Table Top Wrapper */}
            <div className="users-table--top-wrapper"></div>

            {/* Users Table Container */}
            <section className={`users-table-wrapper`}>
              <UsersTable
                allUsers={allUsers}
                currentUsersPosts={currentUsersPosts}
                setPageContent={setPageContent}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                rowsPerPage={rowsPerPage}
                indexOfFirstPost={indexOfFirstPost}
                indexOfLastPost={indexOfLastPost}
                isLoading={isLoading}
                setSelectedUsersForPreview={setSelectedUsersForPreview}
              />
            </section>
          </section>
        </section>

        {/* User Preview Section  */}
        <section
          className={`users-container-main-section ${
            pageContent === "preview" ? "" : "none"
          }`}
        >
          <UserPreview
            pageContent={pageContent}
            selectedUsersForPreview={selectedUsersForPreview}
            setPageContent={setPageContent}
          />
        </section>
      </Main>
    </div>
  );
}

export default Users;

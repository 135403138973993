import {
  GET_ALL_COMPANIES,
  GET_ALL_STAFF_COUNT,
  GET_ALL_UNAPPROVED_COMPANIES,
} from "../Constants";

const initialState = {
  companies: [],
  totalStaffCount: 0,
  unapprovedCompanies: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (
  state = initialState,
  action: { type?: string; payload?: any }
) {
  switch (action.type) {
    case GET_ALL_COMPANIES:
      return {
        ...state,
        companies: action.payload,
      };
    case GET_ALL_STAFF_COUNT:
      return {
        ...state,
        totalStaffCount: action.payload,
      };
    case GET_ALL_UNAPPROVED_COMPANIES:
      return {
        ...state,
        unapprovedCompanies: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
}

import React, { MouseEvent, useEffect, useState } from "react";
import "./EmployerPreview.css";
import ErrorHandler from "../../../components/error/ErrorHandler";
import SuccessHandler from "../../../components/success/SuccessHandler";
import angleLeft from "../../../images/angle-left.svg";
import moment from "moment";
import { useAppDispatch } from "../../../hooks";
import { updateCompanyLoanAccess } from "../../../redux/actions/LoanAccessActions";
import SubstituteLoadingSpinner from "../../../components/substitute-loading-spinner/SubstituteLoadingSpinner";
import copyIcon from "../../../images/copy-icon.svg";
import CompanyStaffTable from "./company-staff-table/CompanyStaffTable";
import TextInput from "../../../components/input-components/text-input/TextInput";
import {
  changeCompanyLoanLimit,
  suspendCompany,
  unsuspendCompany,
} from "../../../redux/actions/companyActions";
import LoadingSpinner from "../../../components/loading-spinner/LoadingSpinner";
import { ISingleCompany } from "../../../Types";

// Interfaces
interface IProps {
  pageContent: string;
  selectedCompanyForPreview: ISingleCompany | null;
  setPageContent: React.Dispatch<React.SetStateAction<string>>;
  setIsPreviewedEmployerUpdated: React.Dispatch<React.SetStateAction<boolean>>;
  setIsUpdateFreeTrialModalOpened: React.Dispatch<
    React.SetStateAction<boolean>
  >;
}

function EmployerPreview({
  pageContent,
  selectedCompanyForPreview,
  setPageContent,
  setIsPreviewedEmployerUpdated,
  setIsUpdateFreeTrialModalOpened,
}: IProps) {
  // Functions, States and Variables
  const dispatch = useAppDispatch();
  //   States
  const [companyLoanPercent, setCompanyLoanPercent] = useState("");
  const [employerLoanAccess, setEmployerLoanAccess] = useState([
    { name: "On Demand", access: false },
    { name: "Salary Advance", access: false },
    { name: "BNPL", access: false },
  ]);
  const [isLoanAccessInputMutated, setIsLoanAccessInputMutated] =
    useState(false);
  const [errorHandlerObj, setErrorHandlerObj] = useState({
    hasError: false,
    message: "",
  });
  const [successHandlerObj, setSuccessHandlerObj] = useState({
    isSuccess: false,
    message: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isChangePercentLoading, setIsChangePercentLoading] = useState(false);
  const [isSuspendAccountLoading, setIsSuspendAccountLoading] = useState(false);

  //   Functions
  //   Change Loan Access Input Value
  const handleChangeLoanAccessValue = function (index: number) {
    // Set Loan access input mutated
    setIsLoanAccessInputMutated(true);
    //
    const updatedEmployerLoanAccess = employerLoanAccess.map(
      (loanAccess, position) =>
        position === index
          ? { ...loanAccess, access: !loanAccess.access }
          : { ...loanAccess }
    );

    setEmployerLoanAccess(updatedEmployerLoanAccess);
  };

  //   Update Company Loan Access
  const handleUpdateCompanyLoanAccess = function () {
    // Set Is previewed company updated state to default false
    setIsPreviewedEmployerUpdated(false);
    setErrorHandlerObj({ hasError: true, message: "" });
    setSuccessHandlerObj({ isSuccess: true, message: "" });
    const data = {
      isOnDemandEnabled: employerLoanAccess[0].access,
      isSalaryAdvanceEnabled: employerLoanAccess[1].access,
      isBNPLEnabled: employerLoanAccess[2].access,
    };

    if (selectedCompanyForPreview) {
      dispatch(
        updateCompanyLoanAccess(
          selectedCompanyForPreview._id,
          data,
          setIsLoading,
          setErrorHandlerObj,
          setSuccessHandlerObj,
          setIsPreviewedEmployerUpdated
        )
      );
    }
  };

  // Change Loan Percentage
  const handleSubmitLoanPercentage = function (
    e: MouseEvent<HTMLButtonElement>
  ) {
    // Set Is previewed company updated state to default false
    setIsPreviewedEmployerUpdated(false);
    setErrorHandlerObj({ hasError: true, message: "" });
    setSuccessHandlerObj({ isSuccess: true, message: "" });
    if (companyLoanPercent !== "" && selectedCompanyForPreview) {
      e.preventDefault();
      const data = {
        adminLoanPercent: parseInt(companyLoanPercent),
      };
      // Call the API to change company loan percent
      dispatch(
        changeCompanyLoanLimit(
          data,
          selectedCompanyForPreview._id,
          setIsChangePercentLoading,
          setErrorHandlerObj,
          setSuccessHandlerObj,
          setIsPreviewedEmployerUpdated
        )
      );
    }
  };

  // handle Copy Code To Clipboard
  const handleCopyCodeToClipboard = function () {
    if (selectedCompanyForPreview) {
      navigator.clipboard.writeText(selectedCompanyForPreview.companyCode);
      setSuccessHandlerObj({
        isSuccess: true,
        message: "Code copied to clipboard!",
      });
    }
  };

  // Add Ordinal Format to Number
  const ordinalSuffixOf = function (i: number) {
    var j = i % 10,
      k = i % 100;
    if (j === 1 && k !== 11) {
      return i + "st";
    }
    if (j === 2 && k !== 12) {
      return i + "nd";
    }
    if (j === 3 && k !== 13) {
      return i + "rd";
    }
    return i + "th";
  };

  // handle Suspend Company
  const handleSuspendCompany = function () {
    // Set Is previewed company updated state to default false
    setIsPreviewedEmployerUpdated(false);
    setErrorHandlerObj({ hasError: true, message: "" });
    setSuccessHandlerObj({ isSuccess: true, message: "" });
    if (selectedCompanyForPreview) {
      //  Call the suspend company API
      dispatch(
        suspendCompany(
          selectedCompanyForPreview._id,
          setIsSuspendAccountLoading,
          setErrorHandlerObj,
          setSuccessHandlerObj,
          setIsPreviewedEmployerUpdated
        )
      );
    }
  };

  // handle Unsuspend Company
  const handleUnsuspendCompany = function () {
    // Set Is previewed company updated state to default false
    setIsPreviewedEmployerUpdated(false);
    setErrorHandlerObj({ hasError: true, message: "" });
    setSuccessHandlerObj({ isSuccess: true, message: "" });
    if (selectedCompanyForPreview) {
      //  Call the unsuspend company API
      dispatch(
        unsuspendCompany(
          selectedCompanyForPreview._id,
          setIsSuspendAccountLoading,
          setErrorHandlerObj,
          setSuccessHandlerObj,
          setIsPreviewedEmployerUpdated
        )
      );
    }
  };

  //   UseEffects
  useEffect(() => {
    if (errorHandlerObj.hasError || successHandlerObj.isSuccess) {
      //scroll to page top
      window.scrollTo({ top: 0 });
    }
  }, [errorHandlerObj, successHandlerObj]);

  // If response is failure, remove success message
  useEffect(() => {
    if (errorHandlerObj.hasError) {
      setSuccessHandlerObj({ isSuccess: false, message: "" });
    }
  }, [errorHandlerObj]);

  // If response is success, remove failure message
  useEffect(() => {
    if (successHandlerObj.isSuccess) {
      setErrorHandlerObj({ hasError: false, message: "" });
    }
  }, [successHandlerObj]);

  //
  useEffect(() => {
    if (selectedCompanyForPreview) {
      // Set Is Loan Access Mutated to default false
      setIsLoanAccessInputMutated(false);
      // ORDER: On Demand, Salary Advance, BNPL
      setEmployerLoanAccess((prevLoanAccess) => {
        const newPrevLoanAcess = [...prevLoanAccess];
        // On Demand
        newPrevLoanAcess[0] = {
          name: "On Demand",
          access: selectedCompanyForPreview.loan.isOnDemandEnabled,
        };
        // Salary Advance
        newPrevLoanAcess[1] = {
          name: "Salary Advance",
          access: selectedCompanyForPreview.loan.isSalaryAdvanceEnabled,
        };
        // BNPL
        newPrevLoanAcess[2] = {
          name: "BNPL",
          access: selectedCompanyForPreview.loan.isBNPLEnabled,
        };
        return newPrevLoanAcess;
      });

      // Set the company loan limit state if it exists
      setCompanyLoanPercent(
        selectedCompanyForPreview?.adminLoanPercent?.toString() || ""
      );
    }
  }, [selectedCompanyForPreview]);
  return (
    <div className="app-main-section--inner employer-preview-container">
      {/* Error handler component */}
      <ErrorHandler
        errorHandlerObj={errorHandlerObj}
        className="employer-preview--actions-handler-wrapper"
      />

      {/* Success handler component */}
      <SuccessHandler
        successHandlerObj={successHandlerObj}
        className="employer-preview--actions-handler-wrapper"
      />

      {/* Employer Preview Top Section */}
      <section className="employer-preview-top-section">
        {/* Top Left Wrapper */}
        <div className="employer-preview-top--left-wrapper">
          {/* Back Button Wrapper */}
          <div className="employer-preview-back-button-wrapper">
            <button
              onClick={() => {
                setPageContent("main");
              }}
            >
              <img src={angleLeft} alt="" />
            </button>
          </div>

          <div className="employer-preview-top--left__left">
            {/* Update free trial button */}
            <div className="update-free-trial-button-wrapper secondary-button-wrapper">
              <button onClick={() => setIsUpdateFreeTrialModalOpened(true)}>
                Update free trial
              </button>
            </div>

            {/* Suspend and Unsuspend button */}
            <div className="suspend-unsuspend-company-buttons-wrapper">
              <button
                onClick={() => {
                  selectedCompanyForPreview?.isSuspended
                    ? handleUnsuspendCompany()
                    : handleSuspendCompany();
                }}
                className={
                  selectedCompanyForPreview?.isSuspended
                    ? "unsuspend-company-button"
                    : "suspend-company-button"
                }
              >
                {selectedCompanyForPreview?.isSuspended
                  ? "Unsuspend company"
                  : "  Suspend company"}
              </button>

              {isSuspendAccountLoading && <SubstituteLoadingSpinner />}
            </div>
          </div>
        </div>
        {/* Top Boxes Container */}
        <div className="employer-preview-top-boxes-container">
          {/* Company Code Box Wrapper */}
          <div className="employer-preview-box-wrapper">
            {/* Box Title Wrapper */}
            <div className="box-title-wrapper">
              COMPANY CODE
              <div className="copy-to-clipboard-button-wrapper">
                <button onClick={() => handleCopyCodeToClipboard()}>
                  <img src={copyIcon} alt="" />
                </button>
              </div>
            </div>
            {/* Box Value Wrapper */}
            <div className="box-value-wrapper">
              {selectedCompanyForPreview?.companyCode}
            </div>
          </div>

          {/* Employee Strength Box Wrapper */}
          <div className="employer-preview-box-wrapper">
            {/* Box Title Wrapper */}
            <div className="box-title-wrapper">EMPLOYEE STRENGTH</div>
            {/* Box Value Wrapper */}
            <div className="box-value-wrapper">
              {selectedCompanyForPreview?.staff.length || 0}
            </div>
          </div>

          {/* Amount of Groups Box Wrapper */}
          <div className="employer-preview-box-wrapper">
            {/* Box Title Wrapper */}
            <div className="box-title-wrapper">AMOUNT OF GROUPS</div>
            {/* Box Value Wrapper */}
            <div className="box-value-wrapper">
              {selectedCompanyForPreview?.groups.length || 0}
            </div>
          </div>

          {/* Update Loan Percent Wrapper */}
          <div className="employer-preview-box-wrapper loan-percent-input-box-wrapper">
            {/* Loan Percent form group */}
            <form>
              <div className="loan-percent-form-group form-group">
                <TextInput
                  type={"number"}
                  id={"loan-percent-text-input"}
                  className={"loan-percent-input"}
                  placeholder={""}
                  value={companyLoanPercent}
                  onChange={(e) => {
                    setCompanyLoanPercent(e.target.value);
                  }}
                  required={true}
                  label={"Change loan percentage"}
                  // max={"100"}
                  // min={"0"}
                />
              </div>

              <div className="submit-loan-percentage-button-wrapper">
                {isChangePercentLoading && <LoadingSpinner />}
                <button onClick={(e) => handleSubmitLoanPercentage(e)}>
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>

      {/* Employer Preview Information Section */}
      <section className="employer-preview-information-section">
        <div className="section-title-wrapper">Information</div>

        <div className="employer-preview-information-container">
          {/* Left Wrapper */}
          <div className="employer-preview-information--left-wrapper">
            {/* Company Profile Picture */}
            <div className="employer-info--company-picture-wrapper">
              {selectedCompanyForPreview?.profilePicture && (
                <img src={selectedCompanyForPreview.profilePicture} alt="" />
              )}
            </div>

            {/* Company Name */}
            <div className="employer-info--company-name-wrapper">
              {selectedCompanyForPreview?.companyName}
            </div>
          </div>
          {/* right Wrapper */}
          <div className="employer-preview-information--right-wrapper">
            {/* Date Created */}
            <div className="company-info-detail-wrapper">
              <div className="company-info--key-wrapper">Date Created</div>
              <div className="company-info--value-wrapper">
                {selectedCompanyForPreview &&
                  moment(selectedCompanyForPreview.createdAt).format(
                    "Do MMM, YYYY"
                  )}
              </div>
            </div>

            {/* Email */}
            <div className="company-info-detail-wrapper">
              <div className="company-info--key-wrapper">Email</div>
              <div className="company-info--value-wrapper">
                {selectedCompanyForPreview?.email}
              </div>
            </div>

            {/* Phone Number */}
            <div className="company-info-detail-wrapper">
              <div className="company-info--key-wrapper">Phone Number</div>
              <div className="company-info--value-wrapper">
                {selectedCompanyForPreview?.phoneNumber}
              </div>
            </div>

            {/* Loan Percent */}
            <div className="company-info-detail-wrapper">
              <div className="company-info--key-wrapper">Loan Percent</div>
              <div className="company-info--value-wrapper">
                {selectedCompanyForPreview?.loanPercent}
              </div>
            </div>

            {/* Loan Percent */}
            <div className="company-info-detail-wrapper">
              <div className="company-info--key-wrapper">
                Admin Loan Percent
              </div>
              <div className="company-info--value-wrapper">
                {selectedCompanyForPreview?.adminLoanPercent}
              </div>
            </div>

            {/* Free  trail */}
            <div className="company-info-detail-wrapper">
              <div className="company-info--key-wrapper">Free Trial</div>
              <div className="company-info--value-wrapper">
                {selectedCompanyForPreview?.freeTrail?.isEnabled}
              </div>
              <div className="company-info--value-wrapper">
                {moment(
                  selectedCompanyForPreview?.freeTrail?.trailExpiryDate
                ).format("Do MMM, YYYY")}
              </div>
            </div>

            {/* Payday */}
            <div className="company-info-detail-wrapper">
              <div className="company-info--key-wrapper">Payday</div>
              <div className="company-info--value-wrapper">
                {selectedCompanyForPreview?.payDay
                  ? ordinalSuffixOf(selectedCompanyForPreview.payDay)
                  : "N/A"}
              </div>
            </div>

            <div className="loan-availability-wrapper">
              <div className="company-info--key-wrapper">Loan Access</div>
              <div className="company-info--value-wrapper loan-access--value-wrapper">
                {/* Loan Display Input  */}
                <div className="loan-access-display-input-wrapper">
                  {employerLoanAccess.map((loanAccess, index) => (
                    <div key={index + 1} className="single-loan-aceess-wrapper">
                      <input
                        type="checkbox"
                        id={`custom-checkbox-${index}`}
                        checked={loanAccess.access}
                        readOnly={true}
                        onChange={() => handleChangeLoanAccessValue(index)}
                      />
                      <label htmlFor={`custom-checkbox-${index}`}>
                        {loanAccess.name}
                      </label>
                    </div>
                  ))}
                </div>

                {/* Update Loan Access for Company Wrapper */}
                <div className="update-loan-access-button-wrapper">
                  <button
                    disabled={!isLoanAccessInputMutated}
                    onClick={() => handleUpdateCompanyLoanAccess()}
                  >
                    Update Loan Access
                  </button>
                  {isLoading && <SubstituteLoadingSpinner />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Employer Preview Staff Section */}
      <section className="employer-preview-staff-section">
        <div className="section-title-wrapper">Staff</div>

        <CompanyStaffTable
          pageContent={pageContent}
          selectedCompanyForPreview={selectedCompanyForPreview}
        />
      </section>
    </div>
  );
}

export default EmployerPreview;

import axios from "axios";
import { GET_ALL_SETTLEMENTS } from "../Constants";
import { api } from "../../api/config";
import { SetErrorHandlerType, SetSuccessHandlerType } from "../../Types";
import { handleAPIError } from "../../utils/handleAPIError";

const url = `${api}/api/v1/company`;

const token: any = localStorage.getItem("jwtToken");
axios.defaults.headers.common["authorization"] = token;

// Get All Company  Settlemets
export const getAllCompanySettlements =
  (
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setErrorHandlerObj: SetErrorHandlerType
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .get(`${url}/all-settlements`)
      .then((res) => {
        // console.log(res.data.data);
        dispatch({
          type: GET_ALL_SETTLEMENTS,
          payload: res.data.data,
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Dowload Invoice for  Settlemets
export const dowloadInvoiceForSettlement =
  (
    settlementId: string,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setErrorHandlerObj: SetErrorHandlerType,
    setCurrentInvoiceBuffer: React.Dispatch<
      React.SetStateAction<string | ArrayBuffer | null>
    >
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .get(`${url}/settlement/admin/invoice/${settlementId}`)
      .then((res) => {
        // console.log(res.data.data);
        setCurrentInvoiceBuffer(res.data.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Mark Settlement as Paid
export const markSettlementAsPaid =
  (
    settlementId: string,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setErrorHandlerObj: SetErrorHandlerType,
    setSuccessHandlerObj: SetSuccessHandlerType
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .post(`${url}/settlement/pay/${settlementId}`)
      .then((res) => {
        setSuccessHandlerObj({
          isSuccess: true,
          message: "Settlement marked as paid successfully!",
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

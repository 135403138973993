import React from "react";
import { ICategories, IProducts } from "../../Products";

interface IProps {
  products: [] | IProducts[];
  categories: [] | ICategories[];
}

function ProductsHeaderSection({ products, categories }: IProps) {
  return (
    <section className="products--row-one-section">
      {/* Total Quantity Of Products Box Container */}
      {/* <div className="products--header-box-container total-quantity-box-container">
        <div className="box--title-wrapper">TOTAL QUANTITY</div>
        <div className="box--value-wrapper">{products?.length || 0}</div>
      </div> */}
      {/* Total Categories Box Container */}
      <div className="products--header-box-container total-categories-box-container">
        {/* Box Title */}
        <div className="box--title-wrapper">PRODUCT CATEGORIES</div>
        {/* Box Value */}
        <div className="box--value-wrapper">{categories?.length || 0}</div>
      </div>
      {/* Total Number of Vouchers */}
      {/* <div className="products--header-box-container total-categories-box-container">
        <div className="box--title-wrapper">TOTAL NUMBER OF VOUCHERS</div>
        <div className="box--value-wrapper">
          {vouchers?.length || 0}
        </div>
      </div> */}
    </section>
  );
}

export default ProductsHeaderSection;

import React, { useState } from "react";
import "./SearchVoucherModal.css";
import TextInput from "../../../../components/input-components/text-input/TextInput";
import PrimaryButton from "../../../../components/buttons/primary-button/PrimaryButton";
import LoadingSpinner from "../../../../components/loading-spinner/LoadingSpinner";
import { useAppDispatch } from "../../../../hooks";
import { getSearchedPurchaseDeal } from "../../../../redux/actions/productDealActions";

function SearchVoucherModal({
  isModalOpened,
  setIsModalOpened,
  setErrorHandlerObj,
  setIsVoucherSearched,
}) {
  // Functions, States and Variables
  const dispatch = useAppDispatch();

  // States
  const [voucherCode, setVoucherCode] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // Functions
  const handleSearchForVoucher = function (e) {
    if (voucherCode !== "") {
      e.preventDefault();
      // Call the API to search for voucher
      dispatch(
        getSearchedPurchaseDeal(
          voucherCode,
          setIsLoading,
          setErrorHandlerObj,
          setIsVoucherSearched,
          setIsModalOpened
        )
      );
    }
  };

  return (
    <div
      className={`search-voucher-modal-container ${
        isModalOpened ? "" : "none"
      }`}
    >
      <div className="search-voucher-modal-container--inner">
        <div className="search-voucher-header-text">
          Fill in the voucher code for the purchase deal below
        </div>

        <form>
          {/* Voucher Code form group */}
          <div className="form-group">
            <TextInput
              type={"text"}
              className={"voucher-code-input"}
              placeholder={""}
              value={voucherCode}
              onChange={(e) => setVoucherCode(e.target.value)}
              required={true}
              label={"Voucher Code"}
            />
          </div>

          <div className="primary-button-container search-for-voucher-button-container">
            {isLoading && <LoadingSpinner />}
            <PrimaryButton
              className="search-for-voucher-button-wrapper"
              placeholder="Search for Deal"
              onClick={(e) => handleSearchForVoucher(e)}
            />
          </div>
        </form>
      </div>
    </div>
  );
}

export default SearchVoucherModal;

import axios from "axios";
import { api } from "../../api/config";
import {
  SetErrorHandlerType,
  SetIsLoadingType,
  SetSuccessHandlerType,
} from "../../Types";
import { handleAPIError } from "../../utils/handleAPIError";
import { GET_ALL_NOTIFICATIONS } from "../Constants";
import { NavigateFunction } from "react-router-dom";
// Types
export interface INotifyData {
  details: string;
}

const url = `${api}/api/v1/admin`;

const token: any = localStorage.getItem("jwtToken");
axios.defaults.headers.common["authorization"] = token;

// Get All Notifications
export const getAllNotifications =
  (setIsLoading: SetIsLoadingType, setErrorHandlerObj: SetErrorHandlerType) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .get(`${url}/notification`)
      .then((res) => {
        dispatch({
          type: GET_ALL_NOTIFICATIONS,
          payload: res.data,
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Notify All Users
export const notifyAllUsers =
  (
    data: INotifyData,
    setIsLoading: SetIsLoadingType,
    setErrorHandlerObj: SetErrorHandlerType,
    setSuccessHandlerObj: SetSuccessHandlerType,
    handleEmptyFormInputStates: () => void
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .post(`${url}/notification/create`, data)
      .then((res) => {
        setIsLoading(false);
        setSuccessHandlerObj({
          isSuccess: true,
          message: "Notification sent successfully!",
        });
        // Clear or empty notifications form input
        handleEmptyFormInputStates();
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

import React, { MouseEvent, useState } from "react";
import "./Modals.css";
import attentionIcon from "../../../images/attention-icon.svg";
import LoadingSpinner from "../../../components/loading-spinner/LoadingSpinner";
import { IBrands } from "../Products";
import { deleteABrand } from "../../../redux/actions/brandActions";
import { useAppDispatch } from "../../../hooks";

interface IProps {
  isDeleteBrandModalOpened: boolean;
  setIsDeleteBrandModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
  selectedBrandForDeleting: IBrands | null;
  setSuccessHandlerObj: React.Dispatch<
    React.SetStateAction<{
      isSuccess: boolean;
      message: string;
    }>
  >;
  setErrorHandlerObj: React.Dispatch<
    React.SetStateAction<{
      hasError: boolean;
      message: string;
    }>
  >;
}

const DeleteBrandModal: React.FC<IProps> = function ({
  isDeleteBrandModalOpened,
  setIsDeleteBrandModalOpened,
  selectedBrandForDeleting,
  setSuccessHandlerObj,
  setErrorHandlerObj,
}) {
  // Functions, States and Variables
  const dispatch = useAppDispatch();
  // States
  const [isLoading, setIsLoading] = useState(false);

  // Functions
  const handleConfirmDeleteBrand = function (e: MouseEvent<HTMLButtonElement>) {
    setSuccessHandlerObj({ isSuccess: false, message: "" });
    setErrorHandlerObj({ hasError: false, message: "" });
    e.preventDefault();
    if (selectedBrandForDeleting) {
      // TODO Call the dispatch fxn to delete brand
      dispatch(
        deleteABrand(
          selectedBrandForDeleting._id,
          setIsLoading,
          setErrorHandlerObj,
          setSuccessHandlerObj,
          setIsDeleteBrandModalOpened
        )
      );
    }
  };
  return (
    <div
      className={`decline-request-modal-container modal-container ${
        isDeleteBrandModalOpened ? "" : "none"
      }`}
    >
      <div className="approve-request-modal-container--inner modal-container--inner">
        <div className="attention-img-wrapper">
          <img src={attentionIcon} alt="" />
        </div>

        <div className="approve-request-modal-header-text modal-header-text">
          Kindly confirm this action
        </div>
        <div className="approve-request-modal-sub-text modal-sub-text">
          Would you like to delete this brand{" "}
          <span>{selectedBrandForDeleting?.name}</span>
        </div>

        <div className="modal-action-button-wrapper">
          {/* Cancel Modal Button */}
          <div className="cancel-modal-button-wrapper">
            <button onClick={() => setIsDeleteBrandModalOpened(false)}>
              Cancel
            </button>
          </div>
          {/* Conffirm Action Button */}
          <div className="confirm-modal-action-button">
            <button onClick={(e) => handleConfirmDeleteBrand(e)}>
              Confirm
            </button>
            <div className="confirm-modal-button-loader-wrapper">
              {isLoading && <LoadingSpinner />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteBrandModal;

import React, { useState } from "react";
import { Table } from "react-bootstrap";
import LoadingSpinner from "../../../../components/loading-spinner/LoadingSpinner";
import isEmpty from "../../../../validation/isEmpty";
import SubstituteLoadingSpinner from "../../../../components/substitute-loading-spinner/SubstituteLoadingSpinner";
import FEPagination from "../../../../components/pagination/fe-pagination/FEPagination";
import {
  IProductPurchaseDeal,
  SetErrorHandlerType,
  SetSuccessHandlerType,
} from "../../../../Types";
import moment from "moment";

// Interfaces
interface IProps {
  currentPosts: IProductPurchaseDeal[] | null;
  isLoading: boolean;
  isPurchaseDealSearched: boolean;
  setIsRedeemVoucherModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedVoucherCodeForRedemption: React.Dispatch<
    React.SetStateAction<string>
  >;
  setErrorHandlerObj: SetErrorHandlerType;
  setSuccessHandlerObj: SetSuccessHandlerType;
}

function PurchaseDealsTable({
  currentPosts,
  isLoading,
  isPurchaseDealSearched,
  setIsRedeemVoucherModalOpened,
  setSelectedVoucherCodeForRedemption,
  setErrorHandlerObj,
  setSuccessHandlerObj,
}: IProps) {
  // Functions, States and variables
  // States
  const [isSecondaryLoading, setIsSecondaryLoading] = useState(false);
  // Functions
  const handleOpenRedeemVoucherModal = function (voucherCode: string) {
    setIsRedeemVoucherModalOpened(true);
    setSelectedVoucherCodeForRedemption(voucherCode);
  };

  return (
    <>
      {/* All Voucher Stock Table Container */}
      <div className="primary-table-container">
        {
          <div className="table-top-loader-wrapper">
            {isSecondaryLoading && <SubstituteLoadingSpinner />}
          </div>
        }
        <Table responsive="lg" className="primary-table">
          <thead
            style={{
              textAlign: "left",
            }}
          >
            <tr>
              {[
                "PRODUCT NAME",
                "VOUCHER CODE",
                "USER NAME",
                "PHONE",
                "EMAIL",
                "MERCHANT NAME",
                "CURRENCY",
                "INITIAL AMOUNT",
                "DEAL AMOUNT",
                "EXPIRY DATE",
                "",
              ].map((COLUMN, index) => (
                <th key={index + 1}>{COLUMN}</th>
              ))}
            </tr>
          </thead>

          <tbody>
            {currentPosts?.map((deal, index) => (
              <tr key={index + 1}>
                {/* Product Name Column */}
                <td className="td-name">
                  <div className="td-name--wrapper">
                    {/* Stock image wrapper */}
                    <div className="profile-image-wrapper">
                      <img src={deal.deal.productImage} alt="" />
                    </div>
                    {deal.deal.productName}
                  </div>
                </td>

                {/* Vocuher code Column */}
                <td className="td-primary">
                  <div className="td-primary--wrapper">
                    {deal.voucherCode}
                  </div>
                </td>

                {/* User Name Column */}
                <td className="td-primary">
                  <div className="td-primary--wrapper">
                    {deal.user.firstName} {deal.user.lastName}
                  </div>
                </td>

                {/* User Name Column */}
                <td className="td-primary">
                  <div className="td-primary--wrapper">
                    {deal.user.phoneNumber}
                  </div>
                </td>

                {/* User Name Column */}
                <td className="td-primary">
                  <div className="td-primary--wrapper">
                    {deal.user.email}
                  </div>
                </td>
                {/* MERCHANT Name Column */}
                <td className="td-primary">
                  <div className="td-primary--wrapper">
                    {deal.deal.merchantName}
                  </div>
                </td>

                {/* CURRENCY Column */}
                <td className="td-primary">
                  <div className="td-primary--wrapper">
                    {deal?.deal.currency || "NGN"}
                  </div>
                </td>

                {/* INITIAL Amount Column */}
                <td className="td-primary">
                  <div className="td-primary--wrapper">
                    {deal.deal.actualCost?.toLocaleString()}
                  </div>
                </td>

                {/* DEAL Amount Column */}
                <td className="td-primary">
                  <div className="td-primary--wrapper">
                    {deal.deal.dealCost?.toLocaleString()}
                  </div>
                </td>

                {/* Exoiry date Column */}
                <td className="td-primary">
                  <div className="td-primary--wrapper">
                    {" "}
                    {moment(deal.deal.expiryDate).format("Do MMM, YY")}
                  </div>
                </td>

                {/* Redeem Voucher Code*/}
                <td className="td-primary-table">
                  {!deal.isRedemed ? (
                    <div className="td-redeem-voucher--button-wrapper">
                      <button
                        onClick={() =>
                          handleOpenRedeemVoucherModal(deal.voucherCode)
                        }
                      >
                        Redeem Voucher
                      </button>
                    </div>
                  ) : (
                    <span className="redeemed-voucher-status">Redeemed</span>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div className="stock-loader-wrapper">
          {/* Loading Spinner Wrapper */}
          {isLoading && <LoadingSpinner />}
        </div>
      </div>
    </>
  );
}

export default PurchaseDealsTable;

import { GET_ALL_CATEGORIES } from "../Constants";

const initialState = {
  categories: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (
  state = initialState,
  action: { type?: string; payload?: any }
) {
  switch (action.type) {
    case GET_ALL_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
}

import { useState } from "react";

// Interfaces
interface IProps {
  pageType: string;
  pageContent: string;
  setPageContent: React.Dispatch<React.SetStateAction<string>>;
  setIsDeleteDealModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
}

function DealsSetupNav({
  pageType,
  pageContent,
  setPageContent,
  setIsDeleteDealModalOpened,
}: IProps) {
  // Functions, States and Variables

  // States
  const [tabOptions, setTabOptions] = useState([
    // {
    //   key: "voucher-info",
    //   value: "Voucher",
    // },
    {
      key: "deal-info",
      value: "Deal Info",
    },
  ]);
  // Functions
  // handle Open Delete Deal Modal
  const handleOpenDeleteDealModal = function () {
    setIsDeleteDealModalOpened(true);
  };

  return (
    <div className="dashboard-setup-layout--left-section deal-setup-nav-container">
      {/* Deal Setup Nav Link */}
      {tabOptions.map((tabOption, index) => (
        <div key={index + 1} className="form-navigation-link">
          <button
            className={`${
              pageContent === tabOption.key && "active-form-nav-link"
            }`}
            onClick={() => setPageContent(tabOption.key)}
          >
            {tabOption.value}
          </button>
        </div>
      ))}

      {/* Delete deal btn wrapper */}
      {pageType === "edit" && (
        <div className="log-out-btn-wrapper">
          <button onClick={(e) => handleOpenDeleteDealModal()}>
            Delete deal
          </button>
        </div>
      )}
    </div>
  );
}

export default DealsSetupNav;

import React, { useState, useEffect } from "react";
import AccountSetup from "../../components/account-setup/AccountSetup";
import { useAppDispatch } from "../../hooks";
import { logoutUser } from "../../redux/actions/authActions";
import useInactivityLogout from "../../hooks/useInactivityLogout";
import { useNavigate } from "react-router-dom";
// import { useAppDispatch } from "../../hooks";
// import { getCurrentUser } from "../../redux/actions/authActions";

function AccountSettings() {
  // Funtion, State and Variables
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  // States
  const [pageStateObj, setPageStateObj] = useState<{} | null>({});

  useEffect(() => {
    const token = localStorage.getItem("jwtToken");
    if (token) {
      // Get Current User data
      // dispatch(getCurrentUser(setIsLoading, setErrorHandlerObj));
    }
  }, []);

  // Functions
  // handle Log Out User
  const handleLogOutUser = function () {
    dispatch(logoutUser(navigate));
  };

  useInactivityLogout({
    inactivityExpireTime: 120,
    onExpired: handleLogOutUser,
  });

  return (
    <>
      {pageStateObj !== null ? (
        <AccountSetup pageType={"settings"} pageStateObj={pageStateObj} />
      ) : (
        ""
      )}
    </>
  );
}

export default AccountSettings;

import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { SearchContext } from "../../../../components/contexts/SearchContext";
import LoadingSpinner from "../../../../components/loading-spinner/LoadingSpinner";
import FEPagination from "../../../../components/pagination/fe-pagination/FEPagination";
import SubstituteLoadingSpinner from "../../../../components/substitute-loading-spinner/SubstituteLoadingSpinner";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { getAllOrdersPerUser } from "../../../../redux/actions/orderActions";
import { numberWithCommas } from "../../../../utils/numberWithCommas";
import isEmpty from "../../../../validation/isEmpty";
import { ISinglePurchaseAndOrder } from "../../../purchases-and-orders/PurchasesAndOrders";
import { ISingleUser } from "../../Users";
import "./RecentPurchasesTable.css";

// Interfaces
interface IProps {
  selectedUsersForPreview: ISingleUser | null;
  setErrorHandlerObj: React.Dispatch<
    React.SetStateAction<{
      hasError: boolean;
      message: string;
    }>
  >;
}

function RecentPurchasesTable({
  selectedUsersForPreview,
  setErrorHandlerObj,
}: IProps) {
  // Functions, States and Variables
  let orders: ISinglePurchaseAndOrder[] | [] = useAppSelector(
    (state) => state.orders.ordersPerUser
  );
  const dispatch = useAppDispatch();
  const { searchedValue }: any = useContext(SearchContext);
  // States
  const [allOrders, setAllOrders] = useState<ISinglePurchaseAndOrder[] | null>(
    null
  );
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [currentUserOrdersPosts, setCurrentUserOrdersPosts] = useState<
    ISinglePurchaseAndOrder[] | null
  >(null);
  const indexOfLastPost = currentPage * rowsPerPage;
  const indexOfFirstPost = indexOfLastPost - rowsPerPage;

  // Functions
  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  //   handle Filter Table
  const handleFilterTable = function () {
    // Search Parameter: Shop Name and First Two Product Names
    if (!orders || isEmpty(orders)) return false;
    const allOrdersData = orders.filter(
      (order) =>
        order?.items[0]?.merchantId?.shopName
          .toLowerCase()
          .includes(searchedValue.toLowerCase()) ||
        order?.items[0]?.productId?.name
          .toLowerCase()
          .includes(searchedValue.toLowerCase()) ||
        order?.items[1]?.productId?.name
          .toLowerCase()
          .includes(searchedValue.toLowerCase())
    );
    setAllOrders(allOrdersData);
    // Update table state to user's orders rows
    if (!allOrdersData) return false;
    const currentRows = allOrdersData.slice(indexOfFirstPost, indexOfLastPost);
    // console.log(allOrders, "heree");
    setCurrentUserOrdersPosts(currentRows);
  };

  const handleGetAllOrderItems = function (order: ISinglePurchaseAndOrder) {
    const allItemNameInOrder = order.items?.map((item) => {
      const itemNameArr = [];
      itemNameArr.push(item.productId?.name || "");
      return itemNameArr;
    });

    return allItemNameInOrder?.join(", ");
  };

  // UseEffects
  useEffect(() => {
    if (selectedUsersForPreview) {
      dispatch(
        getAllOrdersPerUser(
          selectedUsersForPreview._id,
          setIsLoading,
          setErrorHandlerObj
        )
      );
    }
  }, [selectedUsersForPreview]);

  useEffect(() => {
    console.log(orders, "me here");
    setAllOrders(orders);
  }, [orders]);

  useEffect(() => {
    handleFilterTable();
    setCurrentPage(1);
  }, [searchedValue]);

  useEffect(() => {
    if (allOrders) {
      console.log(allOrders, "got uuuu");
      const currentRows = allOrders?.slice(indexOfFirstPost, indexOfLastPost);
      setCurrentUserOrdersPosts(currentRows);
    }
  }, [currentPage, allOrders]);
  return (
    <div className="company-staff-table-container">
      <div className="company-staff-table-container--inner"></div>{" "}
      <div className="primary-table-container">
        <Table responsive="lg" className="primary-table">
          <thead
            style={{
              textAlign: "left",
            }}
          >
            <tr>
              {[
                "ITEMS",
                "MERCHANT",
                "AMOUNT",
                "PAYMENT TYPE",
                "DATE",
                "STATUS",
              ].map((COLUMN, index) => (
                <th key={index + 1}>{COLUMN}</th>
              ))}
            </tr>
          </thead>

          <tbody>
            {!isLoading &&
              currentUserOrdersPosts?.map((order, i) => (
                <tr key={i + 1}>
                  {/*  ITEMS Column */}
                  <td className="td-items">
                    <div className="td-items--wrapper">
                      {handleGetAllOrderItems(order)}
                    </div>
                  </td>

                  {/*  Merchant Column */}
                  <td className="td-merchant">
                    <div className="td-merchant--wrapper">
                      {order.items[0].merchantId?.shopName || "--"}
                    </div>
                  </td>

                  {/*  Amount Column */}
                  <td className="td-amount">
                    <div className="td-amount--wrapper">
                      &#8358;{order.amountPaid.toLocaleString()}
                    </div>
                  </td>

                  {/*  Payment Type Column */}
                  <td className="td-payment-type">
                    <div className="td-payment-type--wrapper">
                      {order?.paymentMethod || "--"}
                    </div>
                  </td>

                  {/*  Date Added Column */}
                  <td className="td-date-added">
                    <div className="td-date-added--wrapper">
                      {moment(order.createdAt).format("Do MMM, YYYY")}
                    </div>
                  </td>

                  {/* Order Status Column*/}
                  <td className="td-order-status ">
                    <div
                      className={`td-order-status--wrapper  ${
                        order?.isCancelled
                          ? "order-cancelled-wrapper"
                          : !order?.isCompleted
                          ? "order-pending-wrapper"
                          : "order-completed-wrapper"
                      }`}
                    >
                      {order?.isCancelled
                        ? "Cancelled"
                        : !order?.isCompleted
                        ? "Pending"
                        : "Completed"}
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        <div className="below-table-loader-wrapper">
          {isLoading && <LoadingSpinner />}
        </div>
      </div>
      {allOrders && !isEmpty(currentUserOrdersPosts) && (
        <FEPagination
          paginate={paginate}
          rowsPerPage={rowsPerPage}
          totalPosts={allOrders.length}
          currentPage={currentPage}
        />
      )}
    </div>
  );
}

export default RecentPurchasesTable;

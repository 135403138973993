import axios from "axios";
import { api } from "../../api/config";
import {
  SetErrorHandlerType,
  SetIsLoadingType,
  SetSuccessHandlerType,
} from "../../Types";
import { handleAPIError } from "../../utils/handleAPIError";
import { GET_ALL_DEALS } from "../Constants";
import { NavigateFunction } from "react-router-dom";
// Types
interface ICreateDealData {
  voucher: any;
  expiryDate: string;
  // categoryId: string;
  currency: string;
  amount: number;
  initialAmount: number;
}

interface IUpdateDealData {
  amount: number;
  initialAmount: number;
  expiryDate: string;
}
const url = `${api}/api/v1`;

const token: any = localStorage.getItem("jwtToken");
axios.defaults.headers.common["authorization"] = token;

// Get All Deals
export const getDeals =
  (setIsLoading: SetIsLoadingType, setErrorHandlerObj: SetErrorHandlerType) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .get(`${url}/deals`)
      .then((res) => {
        dispatch({ type: GET_ALL_DEALS, payload: res.data.data?.reverse() });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Create Deal
export const createDeal =
  (
    data: ICreateDealData,
    setIsLoading: SetIsLoadingType,
    setErrorHandlerObj: SetErrorHandlerType,
    navigate: NavigateFunction
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .post(`${url}/deals`, data)
      .then((res) => {
        setIsLoading(false);
        navigate("/admin/deals", {
          state: { message: "Deal created successfully!" },
        });
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Update deal
export const updateDeal =
  (
    dealId: string,
    data: IUpdateDealData,
    setIsLoading: SetIsLoadingType,
    setErrorHandlerObj: SetErrorHandlerType,
    setSuccessHandlerObj: SetSuccessHandlerType
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .patch(`${url}/deals/${dealId}`, data)
      .then((res) => {
        setSuccessHandlerObj({
          isSuccess: true,
          message: "Deal Updated successfully!",
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Delete rate
export const deleteDeal =
  (
    dealId: string,
    // data: IUpdateDealData,
    setIsLoading: SetIsLoadingType,
    setErrorHandlerObj: SetErrorHandlerType,
    navigate: NavigateFunction,
    setIsDeleteDealModalOpened: React.Dispatch<React.SetStateAction<boolean>>
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .post(`${url}/deals/delete/${dealId}`)
      .then((res) => {
        // Navigate to deals table screen displaying the success message
        navigate("/admin/deals", {
          state: { message: "Deal deleted successfully!" },
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setIsDeleteDealModalOpened(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

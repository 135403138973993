import axios from "axios";
import {
  GET_ALL_COMPANIES,
  GET_ALL_STAFF_COUNT,
  GET_ALL_UNAPPROVED_COMPANIES,
} from "../Constants";
import { api } from "../../api/config";
import {
  ISingleCompany,
  SetErrorHandlerType,
  SetSuccessHandlerType,
} from "../../Types";
import { handleAPIError } from "../../utils/handleAPIError";

// interfaces
interface IUpdateFreeTrial {
  isEnabled: boolean;
  trailExpiryDate: string;
}

const url = `${api}/api/v1/admin`;

const token: any = localStorage.getItem("jwtToken");
axios.defaults.headers.common["authorization"] = token;

// Get all companies
export const getAllCompanies =
  (
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setErrorHandlerObj: SetErrorHandlerType
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .get(`${api}/api/v1/company`)
      .then((res) => {
        const allApprovedCompanies = res.data?.filter(
          (company: ISingleCompany) => company.isApproved === true
        );
        // // console.log(res.data, allApprovedCompanies);
        dispatch({ type: GET_ALL_COMPANIES, payload: allApprovedCompanies });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Get all staff count
export const getAllStaffCount =
  (
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setErrorHandlerObj: SetErrorHandlerType
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .get(`${api}/api/v1/admin/staff/count`)
      .then((res) => {
        // // console.log(res.data);
        dispatch({ type: GET_ALL_STAFF_COUNT, payload: res.data.data });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Get all unapproved companies
export const getAllUnapprovedCompanies =
  (
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setErrorHandlerObj: SetErrorHandlerType
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .get(`${api}/api/v1/company/unapproved`)
      .then((res) => {
        // // console.log(res.data);
        dispatch({
          type: GET_ALL_UNAPPROVED_COMPANIES,
          payload: res.data,
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

//  change company loan limit
export const changeCompanyLoanLimit =
  (
    data: { adminLoanPercent: number },
    companyId: string,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setErrorHandlerObj: SetErrorHandlerType,
    setSuccessHandlerObj: SetSuccessHandlerType,
    setIsPreviewedEmployerUpdated: React.Dispatch<React.SetStateAction<boolean>>
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .post(`${url}/company/loan-limit/${companyId}`, data)
      .then((res) => {
        // // console.log(res.data);
        setSuccessHandlerObj({
          isSuccess: true,
          message: "Company loan percent changed successfully!",
        });
        setIsLoading(false);

        // If company's loan limit is updated
        setIsPreviewedEmployerUpdated(true);
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

//   Approve Company Request
export const approveCompanyRequest =
  (
    companyId: string,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setErrorHandlerObj: SetErrorHandlerType,
    setSuccessHandlerObj: SetSuccessHandlerType,
    setIsApproved: React.Dispatch<React.SetStateAction<boolean>>,
    setIsApproveRequestModalOpened: React.Dispatch<
      React.SetStateAction<boolean>
    >
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .patch(`${url}/company/approve/${companyId}`)
      .then((res) => {
        // // console.log(res.data);
        setSuccessHandlerObj({
          isSuccess: true,
          message: "Company approved successfully!",
        });
        setIsApproved(true);
        setIsLoading(false);
        // Close confirmation Modal
        setIsApproveRequestModalOpened(false);
      })
      .catch((err) => {
        // Close confirmation Modal
        setIsApproveRequestModalOpened(false);
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

//   Decline Company Request
export const declineCompanyRequest =
  (
    data: {
      reason: string;
    },
    companyId: string,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setErrorHandlerObj: SetErrorHandlerType,
    setSuccessHandlerObj: SetSuccessHandlerType,
    setIsDeclined: React.Dispatch<React.SetStateAction<boolean>>,
    setIsDeclineRequestModalOpened: React.Dispatch<
      React.SetStateAction<boolean>
    >
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .patch(`${url}/company/reject/${companyId}`, data)
      .then((res) => {
        // // console.log(res.data);
        setSuccessHandlerObj({
          isSuccess: true,
          message: "Company declined successfully!",
        });
        setIsDeclined(true);
        setIsLoading(false);
        // Close confirmation modal
        setIsDeclineRequestModalOpened(false);
      })
      .catch((err) => {
        // Close confirmation modal
        setIsDeclineRequestModalOpened(false);
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

//   Suspend Company
export const suspendCompany =
  (
    companyId: string,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setErrorHandlerObj: SetErrorHandlerType,
    setSuccessHandlerObj: SetSuccessHandlerType,
    setIsPreviewedEmployerUpdated: React.Dispatch<React.SetStateAction<boolean>>
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .patch(`${url}/company/suspend/${companyId}`)
      .then((res) => {
        // // console.log(res.data);
        setSuccessHandlerObj({
          isSuccess: true,
          message: "Company suspended successfully!",
        });
        setIsLoading(false);
        // If previewed company's data is updated
        setIsPreviewedEmployerUpdated(true);
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

//   Unsuspend Company
export const unsuspendCompany =
  (
    companyId: string,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setErrorHandlerObj: SetErrorHandlerType,
    setSuccessHandlerObj: SetSuccessHandlerType,
    setIsPreviewedEmployerUpdated: React.Dispatch<React.SetStateAction<boolean>>
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .patch(`${url}/company/unsuspend/${companyId}`)
      .then((res) => {
        setSuccessHandlerObj({
          isSuccess: true,
          message: "Company unsuspended successfully!",
        });
        setIsLoading(false);
        // If previewed company's data is updated
        setIsPreviewedEmployerUpdated(true);
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

//
export const updateFreeTrial =
  (
    companyId: string,
    data: IUpdateFreeTrial,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setErrorHandlerObj: SetErrorHandlerType,
    setSuccessHandlerObj: SetSuccessHandlerType,
    setIsPreviewedEmployerUpdated: React.Dispatch<
      React.SetStateAction<boolean>
    >,
    setIsModalOpened: React.Dispatch<React.SetStateAction<boolean>>
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .post(`${url}/company/update-free-trail/${companyId}`, data)
      .then((res) => {
        setSuccessHandlerObj({
          isSuccess: true,
          message: "Company free trial updated successfully!",
        });
        setIsLoading(false);
        // Close Modal
        setIsModalOpened(false);
        // If previewed company's data is updated
        setIsPreviewedEmployerUpdated(true);
      })
      .catch((err) => {
        setIsLoading(false);
        // Close Modal
        setIsModalOpened(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

import React from "react";
import { Table } from "react-bootstrap";
import FEPagination from "../../../components/pagination/fe-pagination/FEPagination";
import moment from "moment";
import isEmpty from "../../../validation/isEmpty";
import LoadingSpinner from "../../../components/loading-spinner/LoadingSpinner";
import { ISingleCompany } from "../../../Types";

interface IProps {
  allCompanies: ISingleCompany[] | null;
  currentCompaniesPosts: ISingleCompany[] | null;
  setPageContent: React.Dispatch<React.SetStateAction<string>>;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  rowsPerPage: number;
  isLoading: boolean;
  setSelectedCompanyForPreview: React.Dispatch<
    React.SetStateAction<ISingleCompany | null>
  >;
}

const EmployersTable = function ({
  allCompanies,
  currentCompaniesPosts,
  setPageContent,
  currentPage,
  setCurrentPage,
  rowsPerPage,
  isLoading,
  setSelectedCompanyForPreview,
}: IProps) {
  // Functions and States

  // Functions
  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  return (
    <>
      {/* Employers Table Container */}
      <div className="primary-table-container">
        <Table responsive="lg" className="primary-table">
          <thead
            style={{
              textAlign: "left",
            }}
          >
            <tr>
              {[
                "COMPANY NAME",
                "COMPANY CODE",
                "EMAIL",
                "STAFF NUMBER",
                "DATE ADDED",
                "LOAN PERCENT",
              ].map((COLUMN, index) => (
                <th key={index + 1}>{COLUMN}</th>
              ))}
            </tr>
          </thead>

          <tbody>
            {currentCompaniesPosts?.map((company, i) => (
              <tr
                key={i + 1}
                onClick={() => {
                  //  Set Selected company and change page to preview
                  setSelectedCompanyForPreview(company);
                  setPageContent("preview");
                }}
              >
                {/* Company Name Column */}
                <td className="td-company-name">
                  <div className="td-company-name--wrapper">
                    {/* profile image wrapper */}
                    {/* <div className="profile-image-wrapper">
                      <img src={company?.imgURL} alt="" />
                    </div> */}
                    {company.companyName}
                  </div>
                </td>

                {/*  Company Code Column */}
                <td className="td-company-code">
                  <div className="td-company-code--wrapper">
                    {company.companyCode}
                  </div>
                </td>

                {/*  Company Email Column */}
                <td className="td-company-email">
                  <div className="td-company-email--wrapper">
                    {company.email}
                  </div>
                </td>

                {/*  staff Number Column */}
                <td className="td-staff-number">
                  <div className="td-staff-number--wrapper">
                    {company.staff?.length.toLocaleString() || 0}
                  </div>
                </td>

                {/*  Date Added Column */}
                <td className="td-date-added">
                  <div className="td-date-added--wrapper">
                    {moment(company.createdAt).format("Do MMM, YYYY")}
                  </div>
                </td>

                {/* LOan Percent Column*/}
                <td className="td-loan-percent">
                  <div className="td-loan-percent--wrapper">
                    {company?.adminLoanPercent
                      ? company.adminLoanPercent
                      : company.loanPercent || "No loan percent yet"}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div className="below-table-loader-wrapper">
          {isLoading && <LoadingSpinner />}
        </div>
      </div>

      {allCompanies && !isEmpty(currentCompaniesPosts) && (
        <FEPagination
          paginate={paginate}
          rowsPerPage={rowsPerPage}
          totalPosts={allCompanies.length}
          currentPage={currentPage}
        />
      )}
    </>
  );
};

export default EmployersTable;

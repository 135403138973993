import React, { ChangeEvent, MouseEvent, useEffect, useState } from "react";
import "./Modals.css";
import TextInput from "../../../components/input-components/text-input/TextInput";
import SubstituteLoadingSpinner from "../../../components/substitute-loading-spinner/SubstituteLoadingSpinner";
import { ICategories } from "../Products";
import { useAppDispatch } from "../../../hooks";
import { createABrand } from "../../../redux/actions/brandActions";
import uploadIcon from "../../../images/upload-icon.svg";
import axios from "axios";
import { api } from "../../../api/config";
import {
  createACategory,
  updateACategory,
} from "../../../redux/actions/categoryActions";

interface IProps {
  isCreateEditProductActionModalOpened: boolean;
  setIsCreateEditProductActionModalOpened: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  selectedCategoryForEdit: ICategories | null;
  modalActionType: string;
  pageContent: string;
  setSuccessHandlerObj: React.Dispatch<
    React.SetStateAction<{
      isSuccess: boolean;
      message: string;
    }>
  >;
  setErrorHandlerObj: React.Dispatch<
    React.SetStateAction<{
      hasError: boolean;
      message: string;
    }>
  >;
}

function CreateEditProductActionModal({
  isCreateEditProductActionModalOpened,
  setIsCreateEditProductActionModalOpened,
  selectedCategoryForEdit,
  modalActionType,
  pageContent,
  setSuccessHandlerObj,
  setErrorHandlerObj,
}: IProps) {
  // Functions and states
  const dispatch = useAppDispatch();
  // States
  const [brandName, setBrandName] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [categoryImage, setCategoryImage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isFileUploadLoading, setIsFileUploadLoading] = useState(false);

  //   Functions;
  const handleConfirmCreateEditBrandOrCategory = function (
    e: MouseEvent<HTMLButtonElement>
  ) {
    setSuccessHandlerObj({ isSuccess: false, message: "" });
    setErrorHandlerObj({ hasError: false, message: "" });
    // If page content is categories
    if (pageContent === "categories") {
      if (categoryName !== "" && categoryImage !== "") {
        e.preventDefault();

        const data = {
          name: categoryName,
          imageUrl: categoryImage,
        };
        // Check if action type is create or edit
        if (modalActionType === "create") {
          // Call the API to Create a New Category
          dispatch(
            createACategory(
              data,
              setIsLoading,
              setErrorHandlerObj,
              setSuccessHandlerObj,
              setIsCreateEditProductActionModalOpened
            )
          );
        } else {
          // TODO Call the API to Edit Selected Category
          if (selectedCategoryForEdit) {
            dispatch(
              updateACategory(
                data,
                selectedCategoryForEdit?._id,
                setIsLoading,
                setErrorHandlerObj,
                setSuccessHandlerObj,
                setIsCreateEditProductActionModalOpened
              )
            );
          }
        }
      }
    } else {
      // If page content is Brands
      if (brandName !== "") {
        e.preventDefault();

        const data = {
          name: brandName,
        };

        // Since Brands can only be created and not eddited, no need for modal action type check
        //  Call the API to create a new brand
        dispatch(
          createABrand(
            data,
            setIsLoading,
            setErrorHandlerObj,
            setSuccessHandlerObj,
            setIsCreateEditProductActionModalOpened
          )
        );
      }
    }
  };

  // Handle Upload Category Image
  const handleUploadCategoryImage = async function (
    e: ChangeEvent<HTMLInputElement>
  ) {
    if (e.target.files) {
      setCategoryImage(e.target.files[0].name);

      const token = localStorage.getItem("jwtToken");
      if (token) axios.defaults.headers.common["authorization"] = token;

      try {
        const selectedFile = e.target.files[0];
        const formData = new FormData();
        formData.append("file", selectedFile);

        setIsFileUploadLoading(true);
        const { data } = await axios.post(`${api}/api/v1/upload`, formData);

        if (data) setCategoryImage(data);
        setIsFileUploadLoading(false);
      } catch (ex) {
        setIsFileUploadLoading(false);

        setErrorHandlerObj({
          hasError: true,
          message: "Category Image upload failed!",
        });
      }
    }
  };

  useEffect(() => {
    // OnClose of Modal,  set input states to default
    if (!isCreateEditProductActionModalOpened) {
      setBrandName("");
      setCategoryName("");
      setCategoryImage("");
    }
  }, [isCreateEditProductActionModalOpened]);

  useEffect(() => {
    if (isCreateEditProductActionModalOpened && modalActionType === "edit") {
      // Set Category name to selected category name on edit
      if (pageContent === "categories" && selectedCategoryForEdit) {
        setCategoryName(selectedCategoryForEdit?.name || "");
        setCategoryImage(selectedCategoryForEdit?.imageUrl || "");
      }
    }
  }, [isCreateEditProductActionModalOpened, selectedCategoryForEdit]);

  return (
    <div
      className={`product-and-categories-modal-container ${
        isCreateEditProductActionModalOpened ? "" : "none"
      }`}
    >
      <div className="product-and-categories-modal-container--inner">
        <div className="product-and-categories-modal-header">
          {modalActionType === "create" ? "Add New" : "Edit"}{" "}
          {pageContent === "categories" ? "Category" : "Brand"}{" "}
          {isLoading && <SubstituteLoadingSpinner />}
        </div>

        {/* Brand and Category Name form wrapper */}
        <div className="brand-and-category-name-form-wrapper">
          <form>
            {/* Category Name form group */}
            {pageContent === "categories" ? (
              <>
                <div className="category-name-form-group form-group">
                  <TextInput
                    type={"text"}
                    id={"category-name-text-input"}
                    className={"category-name-input"}
                    placeholder={""}
                    value={categoryName}
                    onChange={(e) => setCategoryName(e.target.value)}
                    required={true}
                    label={"Category Name"}
                  />
                </div>

                {/* Category Image File Form Group */}
                <div className="category-image-file-form-group--inner">
                  <div className="file-input-wrapper">
                    <div className="file-input-title">
                      {isFileUploadLoading
                        ? "Uploading..."
                        : categoryImage
                        ? "Change Category Image"
                        : "Upload Category Image"}
                    </div>
                    {isFileUploadLoading && (
                      <div className="upload-loading-spinner-wrapper"></div>
                    )}
                    <input
                      type="file"
                      id={"categories-image-file-input"}
                      className={"categories-image-input"}
                      placeholder={""}
                      onChange={(e) => {
                        handleUploadCategoryImage(e);
                      }}
                      required
                    />
                  </div>
                  {categoryImage && (
                    <div className="preview-category-image-icon">
                      <a
                        href={`${categoryImage}`}
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        <img src={uploadIcon} alt="" />
                      </a>
                    </div>
                  )}
                </div>
              </>
            ) : (
              <div className="brand-name-form-group form-group">
                <TextInput
                  type={"text"}
                  id={"brand-name-text-input"}
                  className={"brand-name-input"}
                  placeholder={""}
                  value={brandName}
                  onChange={(e) => setBrandName(e.target.value)}
                  required={true}
                  label={"Brand Name"}
                />
              </div>
            )}

            <div className="product-and-categories-modal-action-buttons-wrapper">
              {/* Submit Create Group Btn Wrapper */}
              <div className="submit-product-and-categories-btn-wrapper">
                <button
                  onClick={(e) => handleConfirmCreateEditBrandOrCategory(e)}
                >
                  Done
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default CreateEditProductActionModal;

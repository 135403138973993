import React from "react";
import { courseContentTypeOptions } from "../../../../../../utils/courseContentTypeOptions";
import PrimaryButton from "../../../../../../components/buttons/primary-button/PrimaryButton";

// Interfaces
interface IProps {
  courseContentType: string;
  setCourseContentType: React.Dispatch<React.SetStateAction<string>>;
}

function ContentTypeOptions({
  courseContentType,
  setCourseContentType,
}: IProps) {
  return (
    <section className="course-content-type-section">
      <div className="course-content-type-container">
        {courseContentTypeOptions?.map((contentType, index) => (
          <PrimaryButton
            key={index + 1}
            placeholder={contentType.value}
            className={
              courseContentType === contentType.key
                ? "active-content-type-btn"
                : ""
            }
            onClick={(e) => {
              e.preventDefault();
              setCourseContentType(contentType.key);
            }}
          />
        ))}
      </div>
    </section>
  );
}

export default ContentTypeOptions;

import React, { MouseEvent, useEffect, useState } from "react";
import "./AddNewCourse.css";
import { useLocation, useNavigate } from "react-router-dom";
import Sidebar from "../../../components/app-sidebar/Sidebar";
import Top from "../../../components/app-top/Top";
import ErrorHandler from "../../../components/error/ErrorHandler";
import PrimaryBackButton from "../../../components/primary-back-button/PrimaryBackButton";
import {
  IAssessmentDetailsInputStates,
  IAssessmentQuestionInputStates,
  ICourseAssessment,
  ICourseContent,
  ICourseDetailsFormData,
  ICourseFormTab,
  IWellnessCourse,
} from "../../../Types";
import useDisplayMessage from "../../../hooks/useDisplayMessage";
import CourseContentForm from "./form-section/course-content-form/CourseContentForm";
import CourseDetailsForm from "./form-section/course-details-form/CourseDetailsForm";
import AssessmentDetailsForm from "./form-section/assessment-details-form/AssessmentDetailsForm";
import { formTab } from "../../../utils/courseFormTabContent";
import AssessmentQuestionsForm from "./form-section/assessment-questions-form/AssessmentQuestionsForm";
import isEmpty from "../../../validation/isEmpty";
import SuccessHandler from "../../../components/success/SuccessHandler";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  createCourse,
  getACourse,
  getCourseAssessmentForCourse,
  getCourseContentForCourse,
  updateCourse,
} from "../../../redux/actions/wellnessActions";
import Main from "../../../components/layout/main-container/Main";

// Interfaces
interface IProps {
  pageType?: string;
}

function AddNewCourse({ pageType = "create" }: IProps) {
  // Functions, States and Variables
  const navigate = useNavigate();
  const courseContents: ICourseContent[] | [] = useAppSelector(
    (state) => state.wellness.courseContent
  );
  const courseAssessments: ICourseAssessment[] | [] = useAppSelector(
    (state) => state.wellness.courseAssessment
  );

  const location = useLocation();
  const dispatch = useAppDispatch();
  const singleCourse: IWellnessCourse[] | [] = useAppSelector(
    (state) => state.wellness.course
  );
  //   States
  const [currentCourseId, setCurrentCourseId] = useState("");
  const [currentCourseAssessmentId, setCurrentCourseAssessmentId] =
    useState("");
  const [currentCourseContentId, setCurrentCourseContentId] = useState("");
  const [activePageNumber, setActivePageNumber] = useState(1);
  const [formPagesTab, setFormPagesTab] = useState<ICourseFormTab[]>(formTab);
  const [courseContentType, setCourseContentType] = useState("video_course");
  const [courseDetailsInputStates, setCourseDetailsInputStates] =
    useState<ICourseDetailsFormData>({
      courseName: "",
      coursePrice: "",
      courseDuration: "",
      courseDescription: "",
      courseCategoryId: "",
      courseImage: "",
    });

  const [courseContentInputStates, setCourseContentInputStates] = useState<any>(
    []
  );
  // Assessment Questions
  const [assessmentQuestionsInputStates, setAssessmentQuestionsInputStates] =
    useState<IAssessmentQuestionInputStates[]>([
      {
        question: "",
        options: [],
        answer: "",
      },
    ]);
  // Assessment Details
  const [assessmentDetailsInputStates, setAssessmentDetailsInputStates] =
    useState<IAssessmentDetailsInputStates>({
      noOfQuestions: "",
      duration: "",
      scorePerQuestion: "",
      totalScore: "",
      passingScore: "",
    });
  const [isLoading, setIsLoading] = useState(false);
  const [isGetCourseLoading, setIsGetCourseLoading] = useState(false);

  //   Functions
  // handle Change Current Form Page
  const handleChangeCurrentFormPage = function (
    e: MouseEvent<HTMLButtonElement>,
    pageNumber: number
  ) {
    if (activePageNumber === 1 && pageNumber === 2) {
      // Only save course details before progressing or navigating for "Create course" process
      if (pageType === "edit") {
        e.preventDefault();
        setActivePageNumber(pageNumber);
      } else {
        handleChangePageToCourseContents(e);
      }
    } else if (activePageNumber === 3 && pageNumber === 4) {
      handleChangePageToAssessmentQuestions(e);
    } else {
      e.preventDefault();
      setActivePageNumber(pageNumber);
    }
  };

  //   Navigate to Page Two
  const handleChangePageToCourseContents = function (
    e: MouseEvent<HTMLButtonElement>
  ) {
    // Mapp through the keys object and make sure none is empty
    const isFormEmpty = Object.keys(courseDetailsInputStates)?.some(
      (inputKeys) => {
        return (
          courseDetailsInputStates[
            inputKeys as keyof typeof courseDetailsInputStates
          ] === ""
        );
      }
    );

    if (!isFormEmpty) {
      e.preventDefault();
      const data = {
        name: courseDetailsInputStates.courseName,
        duration: courseDetailsInputStates.courseDuration,
        description: courseDetailsInputStates.courseDescription,
        image: courseDetailsInputStates.courseImage,
        courseCategoryId: courseDetailsInputStates.courseCategoryId,
        price: Number(courseDetailsInputStates.coursePrice),
      };
      //  Call the API to create or update course
      if (currentCourseId) {
        // Call the API to update course
        dispatch(
          updateCourse(
            currentCourseId,
            data,
            setIsLoading,
            setErrorHandlerObj,
            setSuccessHandlerObj,
            setActivePageNumber
          )
        );
      } else {
        // Call the API to create course
        dispatch(
          createCourse(
            data,
            setIsLoading,
            setErrorHandlerObj,
            setSuccessHandlerObj,
            setActivePageNumber,
            setCurrentCourseId
          )
        );
      }
    }
  };

  //   Navigate to Page Four
  const handleChangePageToAssessmentQuestions = function (
    e: MouseEvent<HTMLButtonElement>
  ) {
    // Mapp through the keys object and make sure none is empty
    const isFormEmpty = assessmentQuestionsInputStates?.some(
      (formObject) => formObject.question === "" || isEmpty(formObject.options)
    );
    const isAnswerNotSelected = assessmentQuestionsInputStates?.some(
      (questionObj) => questionObj.answer === ""
    );

    if (!isFormEmpty) {
      e.preventDefault();

      if (isAnswerNotSelected) {
        setErrorHandlerObj({
          hasError: true,
          message: "Kindly select an answer for every question!",
        });
      } else {
        setActivePageNumber(4);
      }
    }
  };

  //   Hooks
  // Handles Display the error and success message and scrolls to top
  const {
    errorHandlerObj,
    setErrorHandlerObj,
    successHandlerObj,
    setSuccessHandlerObj,
  } = useDisplayMessage();

  // UseEffects
  useEffect(() => {
    let newCourseContentObject = [];
    if (courseContentType === "video_course") {
      newCourseContentObject = [
        {
          videoTitle: "",
          videoUrl: "",
          size: "",
          duration: "",
        },
      ];
    } else if (courseContentType === "note_course") {
      newCourseContentObject = [
        {
          noteTitle: "",
          noteValue: "",
        },
      ];
    } else {
      newCourseContentObject = [
        {
          slideTitle: "",
          slideUrl: "",
          size: "",
        },
      ];
    }
    setCourseContentInputStates(newCourseContentObject);
  }, [courseContentType]);

  useEffect(() => {
    if (location.state && pageType === "edit") {
      const { courseId: selectedCourseId }: any = location.state;
      dispatch(
        getACourse(selectedCourseId, setIsGetCourseLoading, setErrorHandlerObj)
      );
      // Get Course Contents
      dispatch(
        getCourseContentForCourse(
          selectedCourseId,
          setIsGetCourseLoading,
          setErrorHandlerObj
        )
      );
      // Get Course Assessment
      dispatch(
        getCourseAssessmentForCourse(
          selectedCourseId,
          setIsGetCourseLoading,
          setErrorHandlerObj
        )
      );
      setCurrentCourseId(selectedCourseId);
    }
  }, [location]);

  useEffect(() => {
    if (pageType === "edit" && !isEmpty(singleCourse)) {
      // Update the form to match that of the selected course to be editted which is the single course
      // For Course Details
      setCourseDetailsInputStates({
        courseName: singleCourse[0].name,
        coursePrice: singleCourse[0].price?.toString(),
        courseDuration: singleCourse[0].duration,
        courseDescription: singleCourse[0].description,
        courseCategoryId: singleCourse[0]?.courseCategoryId || "",
        courseImage: singleCourse[0].image,
      });

      // For Course Contents
      if (pageType === "edit" && !isEmpty(courseContents)) {
        setCurrentCourseContentId(courseContents[0]._id);
        const selectedCourseContentType = !isEmpty(courseContents[0]?.slide)
          ? "slides_course"
          : courseContents[0]?.note
          ? "note_course"
          : "video_course";
        setCourseContentType(selectedCourseContentType);
        let newCourseContentObject: any = [];
        if (selectedCourseContentType === "video_course") {
          newCourseContentObject = courseContents?.map((content) => {
            return {
              videoTitle: content.title,
              videoUrl: content.video,
              size: "_",
              duration: "_",
            };
          });
        } else if (selectedCourseContentType === "note_course") {
          newCourseContentObject = courseContents?.map((content) => {
            return {
              noteTitle: content.title,
              noteValue: content.note,
            };
          });
        } else {
          newCourseContentObject = courseContents?.map((content) => {
            return {
              slideTitle: content.title,
              slideUrl: content.video,
              size: "_",
            };
          });
        }
        setCourseContentInputStates(newCourseContentObject);
      }

      // console.log(courseAssessments);
      if (pageType === "edit" && !isEmpty(courseAssessments)) {
        setCurrentCourseAssessmentId(courseAssessments[0]._id);
        // For Assessment Questions
        setAssessmentDetailsInputStates({
          noOfQuestions: courseAssessments[0].noOfQuestions?.toString(),
          duration: courseAssessments[0].duration,
          scorePerQuestion: courseAssessments[0].scorePerQuestion?.toString(),
          totalScore: courseAssessments[0].totalScore?.toString(),
          passingScore: courseAssessments[0].passingScore?.toString(),
        });

        // For Assessment Details
        setAssessmentQuestionsInputStates(courseAssessments[0].questions);
      }
    }
  }, [singleCourse, courseContents, courseAssessments]);

  return (
    <div className="app-page-container">
      <Top />
      <Sidebar activeName={"Learning"} />

      <Main>
        {/* Error handler component */}
        <ErrorHandler
          errorHandlerObj={errorHandlerObj}
          className="app--actions-handler-wrapper"
        />
        {/* Success handler component */}
        <SuccessHandler
          successHandlerObj={successHandlerObj}
          className="app--actions-handler-wrapper"
        />

        {/* Main Container || Only shows when page view is "main"*/}
        <section className={`app-main-section--inner`}>
          {/* Top Wrapper */}
          <div className="course-preview--top-wrapper">
            <div className="primary-back-button-container">
              {/* Back button Wrapper */}
              <PrimaryBackButton
                onClick={() => {
                  navigate("/admin/wellness");
                }}
              />
              {/* Top Title */}
              <div className="primary-back-button-title">
                {pageType === "edit" ? "Edit" : "Add New"} Course
              </div>
            </div>
          </div>

          <div className="add-course-form-container">
            <form>
              {/* Form To Nav Container */}
              <div className="add-course-form--top-nav-container">
                <div className="add-course-form--top-nav-container--inner">
                  {formPagesTab?.map((formTab, index) => (
                    <div
                      key={index + 1}
                      className={`add-course-form--form-pages-link-wrapper ${
                        activePageNumber === formTab.id
                          ? "active-form-page"
                          : ""
                      } ${formTab.isFormFilled ? "page-form-filled" : ""}`}
                    >
                      <button
                        onClick={(e) =>
                          handleChangeCurrentFormPage(e, formTab.id)
                        }
                      >
                        <div className="page-number-wrapper">{formTab.id}</div>
                        {formTab.name}
                      </button>
                    </div>
                  ))}
                </div>
              </div>

              {/* Course Details Form Section */}
              {activePageNumber === 1 && (
                <CourseDetailsForm
                  isLoading={isLoading}
                  courseDetailsInputStates={courseDetailsInputStates}
                  setCourseDetailsInputStates={setCourseDetailsInputStates}
                  setErrorHandlerObj={setErrorHandlerObj}
                  handleChangePageToCourseContents={
                    handleChangePageToCourseContents
                  }
                />
              )}

              {/* Course Content Form Section */}
              {activePageNumber === 2 && (
                <CourseContentForm
                  currentCourseId={currentCourseId}
                  currentCourseContentId={currentCourseContentId}
                  courseContentType={courseContentType}
                  courseContentInputStates={courseContentInputStates}
                  setCourseContentType={setCourseContentType}
                  setCourseContentInputStates={setCourseContentInputStates}
                  setActivePageNumber={setActivePageNumber}
                  setSuccessHandlerObj={setSuccessHandlerObj}
                  setErrorHandlerObj={setErrorHandlerObj}
                />
              )}

              {/* Assessment Questions Form Section */}
              {activePageNumber === 3 && (
                <AssessmentQuestionsForm
                  assessmentQuestionsInputStates={
                    assessmentQuestionsInputStates
                  }
                  setAssessmentQuestionsInputStates={
                    setAssessmentQuestionsInputStates
                  }
                  handleChangePageToAssessmentQuestions={
                    handleChangePageToAssessmentQuestions
                  }
                />
              )}

              {/* Assessment Details Form Section */}
              {activePageNumber === 4 && (
                <AssessmentDetailsForm
                  courseId={currentCourseId}
                  currentCourseAssessmentId={currentCourseAssessmentId}
                  assessmentDetailsInputStates={assessmentDetailsInputStates}
                  assessmentQuestionsInputStates={
                    assessmentQuestionsInputStates
                  }
                  setAssessmentDetailsInputStates={
                    setAssessmentDetailsInputStates
                  }
                  setErrorHandlerObj={setErrorHandlerObj}
                  setSuccessHandlerObj={setSuccessHandlerObj}
                />
              )}
            </form>
          </div>
        </section>
      </Main>
    </div>
  );
}

export default AddNewCourse;

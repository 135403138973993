export const courseContentTypeOptions = [
  {
    key: "video_course",
    value: "Video Course",
  },
  {
    key: "note_course",
    value: "Note Course",
  },
  {
    key: "slides_course",
    value: "Slides Course",
  },
];

import React, { useContext, useEffect, useState } from "react";
// import "./StockRequestsTable.css";
import { Table } from "react-bootstrap";
import FEPagination from "../../../components/pagination/fe-pagination/FEPagination";
import isEmpty from "../../../validation/isEmpty";
import closeIcon from "../../../images/close-icon.svg";
import tickIcon from "../../../images/tick-icon.svg";
import { SearchContext } from "../../../components/contexts/SearchContext";
import { ISingleStock } from "../Requests";
import SubstituteLoadingSpinner from "../../../components/substitute-loading-spinner/SubstituteLoadingSpinner";

interface IProps {
  allProductsPerMerchant: [] | ISingleStock[];
  otherStockRequests: ISingleStock[] | null;
  setOtherStockRequests: React.Dispatch<
    React.SetStateAction<ISingleStock[] | null>
  >;
  currentOtherStockRequestPosts: ISingleStock[] | null;
  setCurrentOtherStockRequestPosts: (
    value: React.SetStateAction<ISingleStock[] | null>
  ) => void;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  rowsPerPage: number;
  indexOfFirstPost: number;
  indexOfLastPost: number;
  isLoading: boolean;
  isApproveRequestModalOpened: boolean;
  setIsApproveRequestModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
  isDeclineRequestModalOpened: boolean;
  setIsDeclineRequestModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedStockRequest: (
    value: React.SetStateAction<ISingleStock | null>
  ) => void;
}

const OtherStocksRequestsTable: React.FC<IProps> = function ({
  allProductsPerMerchant,
  otherStockRequests,
  setOtherStockRequests,
  currentOtherStockRequestPosts,
  setCurrentOtherStockRequestPosts,
  setCurrentPage,
  currentPage,
  rowsPerPage,
  indexOfFirstPost,
  indexOfLastPost,
  isLoading,
  isApproveRequestModalOpened,
  isDeclineRequestModalOpened,
  setIsApproveRequestModalOpened,
  setIsDeclineRequestModalOpened,
  setSelectedStockRequest,
}) {
  // Functions and States
  const { searchedValue }: any = useContext(SearchContext);
  // States
  // Functions
  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  //   handle Filter Table
  const handleFilterTable = function () {
    const allUnapprovedOtherRequests = allProductsPerMerchant?.filter(
      (request) => request.isApproved === false
    );
    if (!allUnapprovedOtherRequests || isEmpty(allUnapprovedOtherRequests))
      return false;

    const stockRequestsData = allUnapprovedOtherRequests?.filter(
      (request) =>
        request.name.toLowerCase().includes(searchedValue.toLowerCase()) ||
        request.name.toLowerCase().includes(searchedValue.toLowerCase())
    );

    setOtherStockRequests(stockRequestsData);

    // Update table state to stock requests rows
    if (!otherStockRequests || !stockRequestsData) return false;
    const currentRows = stockRequestsData.slice(
      indexOfFirstPost,
      indexOfLastPost
    );

    console.log(stockRequestsData, "data");
    setCurrentOtherStockRequestPosts(currentRows);
  };

  useEffect(() => {
    handleFilterTable();
    setCurrentPage(1);
  }, [searchedValue]);

  return (
    <div className="stock-requests-table-section--inner">
      {/* All Stock Requests Table Container */}
      <div className="requests--table-container">
        <Table responsive="lg" className="requests--table stock-requests-table">
          <thead
            style={{
              textAlign: "left",
            }}
          >
            <tr>
              {[
                "ITEM NAME",
                "MERCHANT",
                "CATGEORY",
                "PRICE",
                "OTHER PENDING",
                "",
                "",
              ].map((COLUMN, index) => (
                <th key={index + 1}>{COLUMN}</th>
              ))}
            </tr>
          </thead>

          <tbody>
            {!isLoading &&
              currentOtherStockRequestPosts?.map((stock, i) => (
                <tr key={i + 1}>
                  {/* Item Name Column */}
                  <td className="td-name">
                    <div className="td-name--wrapper">
                      {/* Stock image wrapper */}
                      <div className="profile-image-wrapper">
                        <img src={stock.image} alt="" />
                      </div>
                      {stock.name}
                    </div>
                  </td>

                  {/*  Merchant Name Column */}
                  <td className="td-merchant-name">
                    <div className="td-merchant-name--wrapper">
                      {stock.merchant.shopName}
                    </div>
                  </td>

                  {/*  Category Column */}
                  <td className="td-category">
                    <div className="td-category--wrapper">
                      {stock.category.name}
                    </div>
                  </td>

                  {/*  Price Column */}
                  <td className="td-stock">
                    <div className="td-stock--wrapper">
                      &#8358;{stock.price.toLocaleString()}
                    </div>
                  </td>

                  {/*  Other Pending Column */}
                  <td className="td-cac-document">
                    <div className="td-cac-document--wrapper">
                      {otherStockRequests?.length}
                    </div>
                  </td>

                  {/* Decline Request Btn Column*/}
                  <td className="td-decline-request">
                    <div className="td-decline-request--wrapper">
                      <button
                        onClick={() => {
                          setIsDeclineRequestModalOpened(true);
                          setSelectedStockRequest(stock);
                        }}
                      >
                        <img src={closeIcon} alt="" />
                        Decline
                      </button>
                    </div>
                  </td>

                  {/* Approve Request Btn Column*/}
                  <td className="td-approve-request">
                    <div className="td-approve-request--wrapper">
                      <button
                        onClick={() => {
                          setIsApproveRequestModalOpened(true);
                          setSelectedStockRequest(stock);
                        }}
                      >
                        <img src={tickIcon} alt="" />
                        Approve
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        <div className="below-table-loader-sub-wrapper">
          {isLoading && <SubstituteLoadingSpinner />}
        </div>
      </div>

      {otherStockRequests && !isEmpty(currentOtherStockRequestPosts) && (
        <FEPagination
          paginate={paginate}
          rowsPerPage={rowsPerPage}
          totalPosts={otherStockRequests.length}
          currentPage={currentPage}
        />
      )}
    </div>
  );
};

export default OtherStocksRequestsTable;

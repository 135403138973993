import React, { MouseEvent, useEffect, useState } from "react";
import "./Administrators.css";
import PhoneInput from "../../../../input-components/phone-input/PhoneInput";
import TextInput from "../../../../input-components/text-input/TextInput";
import LoadingSpinner from "../../../../loading-spinner/LoadingSpinner";
import eyeOpenIcon from "../../../../../images/eye-open-icon.svg";
import eyeCloseIcon from "../../../../../images/eye-close-icon.svg";
import {
  SetErrorHandlerType,
  SetSuccessHandlerType,
} from "../../../../../Types";
import { useAppDispatch } from "../../../../../hooks";
import { createNewAdmin } from "../../../../../redux/actions/usersActions";

// Interfaces
export interface IAdminData {
  firstName: string;
  lastName: string;
  // companyCode: string;
  phoneNumber: string;
  email: string;
  password: string;
}

interface IProps {
  setErrorHandlerObj: SetErrorHandlerType;
  setSuccessHandlerObj: SetSuccessHandlerType;
}
function Administrators({ setErrorHandlerObj, setSuccessHandlerObj }: IProps) {
  // Functions, states and variables
  const dispatch = useAppDispatch();
  //   States
  const [adminData, setAdminData] = useState<IAdminData>({
    firstName: "",
    lastName: "",
    // companyCode: "",
    phoneNumber: "",
    email: "",
    password: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isNewAdminCreated, setIsNewAdminCreated] = useState(false);

  //   Functions
  const handleCreateNewAdmin = function (e: MouseEvent<HTMLButtonElement>) {
    setIsNewAdminCreated(false);
    setErrorHandlerObj({ hasError: false, message: "" });
    setSuccessHandlerObj({ isSuccess: false, message: "" });

    const isAdminFormEmpty = Object.keys(adminData)?.some((inputKeys) => {
      return adminData[inputKeys as keyof typeof adminData] === "";
    });

    if (!isAdminFormEmpty) {
      e.preventDefault();
      dispatch(
        createNewAdmin(
          adminData,
          setIsLoading,
          setErrorHandlerObj,
          setSuccessHandlerObj,
          setIsNewAdminCreated
        )
      );
    }
  };

  useEffect(() => {
    if (isNewAdminCreated)
      setAdminData({
        firstName: "",
        lastName: "",
        // companyCode: "",
        phoneNumber: "",
        email: "",
        password: "",
      });
  }, [isNewAdminCreated]);

  return (
    <div className="administrators-container">
      <section className="administrators-form-container">
        <form>
          <div className="form-header-wrapper">Create a new administrator</div>
          {/* Firstname form group */}
          <div className="admin-firstname-form-group form-group">
            <TextInput
              type={"text"}
              id={"admin-firstname-text-input"}
              className={"admin-firstname-input"}
              value={adminData.firstName}
              onChange={(e) => {
                setAdminData((prevStateInputs) => {
                  if (!prevStateInputs) return prevStateInputs;
                  const newAdminDataArr: IAdminData = {
                    ...prevStateInputs,
                    firstName: e.target.value,
                  };
                  return newAdminDataArr;
                });
              }}
              required={true}
              label={"First Name"}
            />
          </div>

          {/* Lastname form group */}
          <div className="admin-lastname-form-group form-group">
            <TextInput
              type={"text"}
              id={"admin-lastname-text-input"}
              className={"admin-lastname-input"}
              value={adminData.lastName}
              onChange={(e) => {
                setAdminData((prevStateInputs) => {
                  if (!prevStateInputs) return prevStateInputs;
                  const newAdminDataArr: IAdminData = {
                    ...prevStateInputs,
                    lastName: e.target.value,
                  };
                  return newAdminDataArr;
                });
              }}
              required={true}
              label={"Last Name"}
            />
          </div>

          {/* Email form group */}
          <div className="admin-email-form-group form-group">
            <TextInput
              type={"email"}
              id={"admin-email-text-input"}
              className={"admin-email-input"}
              value={adminData.email}
              onChange={(e) => {
                setAdminData((prevStateInputs) => {
                  if (!prevStateInputs) return prevStateInputs;
                  const newAdminDataArr: IAdminData = {
                    ...prevStateInputs,
                    email: e.target.value,
                  };
                  return newAdminDataArr;
                });
              }}
              required={true}
              label={"Email"}
            />
          </div>

          {/* Company Code form group */}
          {/* <div className="admin-company-code-form-group form-group">
            <TextInput
              type={"text"}
              id={"admin-company-code-text-input"}
              className={"admin-company-code-input"}
              value={adminData.companyCode}
              onChange={(e) => {
                setAdminData((prevStateInputs) => {
                  if (!prevStateInputs) return prevStateInputs;
                  const newAdminDataArr: IAdminData = {
                    ...prevStateInputs,
                    companyCode: e.target.value,
                  };
                  return newAdminDataArr;
                });
              }}
              required={true}
              label={"Company Code"}
            />
          </div> */}

          {/* Phone Number form group */}
          <div className="admin-phone-number-form-group form-group">
            <PhoneInput
              type={"tel"}
              id={"admin-phone-number-text-input"}
              className={"admin-phone-number-input"}
              placeholder={""}
              value={adminData.phoneNumber}
              onChange={(e) => {
                setAdminData((prevStateInputs) => {
                  if (!prevStateInputs) return prevStateInputs;
                  const newAdminDataArr: IAdminData = {
                    ...prevStateInputs,
                    phoneNumber: e.target.value,
                  };
                  return newAdminDataArr;
                });
              }}
              required={true}
              label={"Phone Number"}
              maxLength={11}
            />
          </div>

          {/* Password form group */}
          <div className="password-form-group form-group">
            <img
              src={isPasswordVisible ? eyeOpenIcon : eyeCloseIcon}
              alt=""
              className="password-icon-wrapper"
              onClick={() => setIsPasswordVisible(!isPasswordVisible)}
            />

            <label htmlFor={"employer-signup-password-input"}>Password</label>
            <input
              type={isPasswordVisible ? "text" : "password"}
              id={"employer-signup-password-input"}
              className={"employer-signup-password-text-input"}
              value={adminData.password}
              onChange={(e) => {
                setAdminData((prevStateInputs) => {
                  if (!prevStateInputs) return prevStateInputs;
                  const newAdminDataArr: IAdminData = {
                    ...prevStateInputs,
                    password: e.target.value,
                  };
                  return newAdminDataArr;
                });
              }}
              required={true}
            />
          </div>

          {/* Submit form button */}
          <div className="submit-setup-form-button-wrapper">
            {isLoading && <LoadingSpinner />}
            <button onClick={handleCreateNewAdmin}>Submit</button>
          </div>
        </form>
      </section>
    </div>
  );
}

export default Administrators;

import axios from "axios";
import {
  GET_ALL_PRODUCTS,
  GET_ALL_UNAPPROVED_PRODUCTS,
  GET_ALL_PRODUCTS_PER_MERCHANT,
} from "../Constants";
import { api } from "../../api/config";
import { SetErrorHandlerType, SetSuccessHandlerType } from "../../Types";
import { handleAPIError } from "../../utils/handleAPIError";

const url = `${api}/api/v1/products`;

const token: any = localStorage.getItem("jwtToken");
axios.defaults.headers.common["authorization"] = token;

// Get all PRODUCTS
export const getAllProducts =
  (
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setErrorHandlerObj: SetErrorHandlerType
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .get(`${url}`)
      .then((res) => {
        // // console.log(res.data);
        dispatch({ type: GET_ALL_PRODUCTS, payload: res.data?.reverse() });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Get all PRODUCTS per MERCHANT
export const getAllProductsPerMerchant =
  (
    merchantId: string,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setErrorHandlerObj: SetErrorHandlerType
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .get(`${api}/api/v1/admin/merchant-product/${merchantId}`)
      .then((res) => {
        // console.log(res.data);
        dispatch({ type: GET_ALL_PRODUCTS_PER_MERCHANT, payload: res.data });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Get all unapproved products
export const getAllUnapprovedProducts =
  (
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setErrorHandlerObj: SetErrorHandlerType
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .get(`${url}/unapproved`)
      .then((res) => {
        // console.log(res.data);

        dispatch({
          type: GET_ALL_UNAPPROVED_PRODUCTS,
          payload: res.data?.reverse(),
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

//   Approve Product
export const approveProduct =
  (
    productId: string,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setErrorHandlerObj: SetErrorHandlerType,
    setSuccessHandlerObj: SetSuccessHandlerType,
    setIsApproved: React.Dispatch<React.SetStateAction<boolean>>,
    setIsApproveRequestModalOpened: React.Dispatch<
      React.SetStateAction<boolean>
    >
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .patch(`${url}/approve/${productId}`)
      .then((res) => {
        // console.log(res.data);
        setSuccessHandlerObj({
          isSuccess: true,
          message: "Product approved successfully!",
        });
        setIsApproved(true);
        setIsLoading(false);
        // Close confirmation Modal
        setIsApproveRequestModalOpened(false);
      })
      .catch((err) => {
        // Close confirmation Modal
        setIsApproveRequestModalOpened(false);
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

//  Reject/ Unapprove Stock
export const unapproveProduct =
  (
    data: {
      reason: string;
    },
    productId: string,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setErrorHandlerObj: SetErrorHandlerType,
    setSuccessHandlerObj: SetSuccessHandlerType,
    setIsDeclined: React.Dispatch<React.SetStateAction<boolean>>,
    setIsDeclineRequestModalOpened: React.Dispatch<
      React.SetStateAction<boolean>
    >
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .patch(`${url}/un-approve/${productId}`, data)
      .then((res) => {
        // console.log(res.data);
        setSuccessHandlerObj({
          isSuccess: true,
          message: "Product unapproved successfully!",
        });
        setIsDeclined(true);
        setIsLoading(false);
        // Close confirmation modal
        setIsDeclineRequestModalOpened(false);
      })
      .catch((err) => {
        // Close confirmation modal
        setIsDeclineRequestModalOpened(false);
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

//  Make Product Hot deal
export const makeProductHotDeal =
  (
    productId: string,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setErrorHandlerObj: SetErrorHandlerType,
    setSuccessHandlerObj: SetSuccessHandlerType
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .patch(`${url}/make-hot-deal/${productId}`)
      .then((res) => {
        // console.log(res.data);
        setSuccessHandlerObj({
          isSuccess: true,
          message: "Product added to hot deals successfully!",
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

//  Remove Product from Product Hot deal
export const removeProductFromHotDeal =
  (
    productId: string,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setErrorHandlerObj: SetErrorHandlerType,
    setSuccessHandlerObj: SetSuccessHandlerType
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .patch(`${url}/remove-hot-deal/${productId}`)
      .then((res) => {
        // console.log(res.data);
        setSuccessHandlerObj({
          isSuccess: true,
          message: "Product removed from hot deals successfully!",
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

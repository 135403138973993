import React from "react";
import "./UsersTable.css";
import { Table } from "react-bootstrap";
import FEPagination from "../../../components/pagination/fe-pagination/FEPagination";
import moment from "moment";
import isEmpty from "../../../validation/isEmpty";
import LoadingSpinner from "../../../components/loading-spinner/LoadingSpinner";
import { ISingleUser } from "../Users";

interface IProps {
  allUsers: ISingleUser[] | null;
  currentUsersPosts: ISingleUser[] | null;
  setPageContent: React.Dispatch<React.SetStateAction<string>>;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  rowsPerPage: number;
  indexOfLastPost: number;
  indexOfFirstPost: number;
  isLoading: boolean;
  setSelectedUsersForPreview: React.Dispatch<
    React.SetStateAction<ISingleUser | null>
  >;
}

const UsersTable = function ({
  allUsers,
  currentUsersPosts,
  setPageContent,
  currentPage,
  setCurrentPage,
  rowsPerPage,
  isLoading,
  setSelectedUsersForPreview,
}: IProps) {
  // Functions and States

  // Functions
  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  return (
    <>
      {/* Users Table Container */}
      <div className="primary-table-container">
        <Table responsive="lg" className="primary-table">
          <thead
            style={{
              textAlign: "left",
            }}
          >
            <tr>
              {["USER", "COMPANY CODE", "EMAIL", "PHONE NUMBER", "SEX"].map(
                (COLUMN, index) => (
                  <th key={index + 1}>{COLUMN}</th>
                )
              )}
            </tr>
          </thead>

          <tbody>
            {currentUsersPosts?.map((user, i) => (
              <tr
                key={i + 1}
                onClick={() => {
                  //  Set Selected company and change page to preview
                  setSelectedUsersForPreview(user);
                  setPageContent("preview");
                }}
              >
                {/* USER Name Column */}
                <td className="td-company-name">
                  <div className="td-company-name--wrapper">
                    {user.firstName} {user.lastName}
                  </div>
                </td>

                {/*  Company Code Column */}
                <td className="td-company-code">
                  <div className="td-company-code--wrapper">
                    {user?.companyCode || "N/A"}
                  </div>
                </td>

                {/*  USer Email Column */}
                <td className="td-email">
                  <div className="td-email--wrapper">{user.email}</div>
                </td>

                {/* User Phone Number Column */}
                <td className="td-user-phone-number">
                  <div className="td-user-phone-number--wrapper">
                    {user.phoneNumber}
                  </div>
                </td>

                {/*  Number of transactions Column */}
                <td className="td-number-of-transactions">
                  <div className="td-number-of-transactions--wrapper">
                    {user?.sex || "--"}{" "}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div className="below-table-loader-wrapper">
          {isLoading && <LoadingSpinner />}
        </div>
      </div>

      {allUsers && !isEmpty(currentUsersPosts) && (
        <FEPagination
          paginate={paginate}
          rowsPerPage={rowsPerPage}
          totalPosts={allUsers.length}
          currentPage={currentPage}
        />
      )}
    </>
  );
};

export default UsersTable;

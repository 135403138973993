import React, { useEffect, useState } from "react";
import "./UserPreview.css";
import { useNavigate } from "react-router-dom";
import ErrorHandler from "../../../components/error/ErrorHandler";
import SuccessHandler from "../../../components/success/SuccessHandler";
import angleLeft from "../../../images/angle-left.svg";
import moment from "moment";
import { useAppDispatch } from "../../../hooks";
import { ISingleUser } from "../Users";
import pinIcon from "../../../images/pin-icon.svg";
import { activateUser, deleteUser } from "../../../redux/actions/usersActions";
import SubstituteLoadingSpinner from "../../../components/substitute-loading-spinner/SubstituteLoadingSpinner";
import RecentPurchasesTable from "./recent-purchases-table/RecentPurchasesTable";

// Interfaces
interface IProps {
  pageContent: string;
  selectedUsersForPreview: ISingleUser | null;
  setPageContent: React.Dispatch<React.SetStateAction<string>>;
}

function UserPreview({
  pageContent,
  selectedUsersForPreview,
  setPageContent,
}: IProps) {
  // Functions, States and Variables
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  //   States
  const [errorHandlerObj, setErrorHandlerObj] = useState({
    hasError: false,
    message: "",
  });
  const [successHandlerObj, setSuccessHandlerObj] = useState({
    isSuccess: false,
    message: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isPurchasesLoading, setIsPurchasesLoading] = useState(false);

  //   Functions
  const handleDeleteUser = function () {
    // Set success and error message to default false
    setErrorHandlerObj({ hasError: false, message: "" });
    setSuccessHandlerObj({ isSuccess: false, message: "" });
    if (selectedUsersForPreview) {
      dispatch(
        deleteUser(
          selectedUsersForPreview._id,
          setIsLoading,
          setErrorHandlerObj,
          setSuccessHandlerObj
        )
      );
    }
  };

  const handleActivateUser = function () {
    // Set success and error message to default false
    setErrorHandlerObj({ hasError: false, message: "" });
    setSuccessHandlerObj({ isSuccess: false, message: "" });
    if (selectedUsersForPreview) {
      dispatch(
        activateUser(
          selectedUsersForPreview._id,
          setIsLoading,
          setErrorHandlerObj,
          setSuccessHandlerObj
        )
      );
    }
  };

  //   UseEffects
  useEffect(() => {
    if (errorHandlerObj.hasError || successHandlerObj.isSuccess) {
      //scroll to page top
      window.scrollTo({ top: 0 });
    }
  }, [errorHandlerObj, successHandlerObj]);

  // If response is failure, remove success message
  useEffect(() => {
    if (errorHandlerObj.hasError) {
      setSuccessHandlerObj({ isSuccess: false, message: "" });
    }
  }, [errorHandlerObj]);

  // If response is success, remove failure message
  useEffect(() => {
    if (successHandlerObj.isSuccess) {
      setErrorHandlerObj({ hasError: false, message: "" });
    }
  }, [successHandlerObj]);
  return (
    <div className="user-preview-container">
      <div className="user-preview-container--inner">
        {/* Error handler component */}
        <ErrorHandler
          errorHandlerObj={errorHandlerObj}
          className="user-preview--actions-handler-wrapper"
        />

        {/* Success handler component */}
        <SuccessHandler
          successHandlerObj={successHandlerObj}
          className="user-preview--actions-handler-wrapper"
        />

        {/* user Preview Top Section */}
        <section className="user-preview-top-section">
          {/* Back Button Wrapper */}
          <div className="user-preview-back-button-wrapper">
            <button
              onClick={() => {
                setPageContent("main");
              }}
            >
              <img src={angleLeft} alt="" />
            </button>
          </div>

          <div className="activate-delete-user-buttons-wrapper">
            {isLoading && <SubstituteLoadingSpinner />}
            {selectedUsersForPreview?.isActive ? (
              <button
                className="delete-user-btn"
                onClick={() => handleDeleteUser()}
              >
                Delete user
              </button>
            ) : (
              <button
                className="activate-user-btn"
                onClick={() => handleActivateUser()}
              >
                Activate user
              </button>
            )}
          </div>
        </section>

        {/* user Preview Information Section */}
        <section className="user-preview-information-section">
          <div className="section-title-wrapper">Information</div>

          <div className="user-preview-information-container">
            {/* Left Wrapper */}
            <div className="user-preview-information--left-wrapper">
              {/* Company Profile Picture */}
              <div className="user-info--company-picture-wrapper">
                {selectedUsersForPreview?.image && (
                  <img src={selectedUsersForPreview.image} alt="" />
                )}
              </div>

              {/* Company Name */}
              <div className="user-info--company-name-wrapper">
                {selectedUsersForPreview?.firstName}{" "}
                {selectedUsersForPreview?.lastName}
              </div>
            </div>
            {/* right Wrapper */}
            <div className="user-preview-information--right-wrapper">
              {/* Date Created */}
              <div className="company-info-detail-wrapper">
                <div className="company-info--key-wrapper">Date Created</div>
                <div className="company-info--value-wrapper">
                  {selectedUsersForPreview &&
                    moment(selectedUsersForPreview.createdAt).format(
                      "DD, MMM YYYY"
                    )}
                </div>
              </div>

              {/* Email */}
              <div className="company-info-detail-wrapper">
                <div className="company-info--key-wrapper">Email</div>
                <div className="company-info--value-wrapper">
                  {selectedUsersForPreview?.email}
                </div>
              </div>

              {/* Phone Number */}
              <div className="company-info-detail-wrapper">
                <div className="company-info--key-wrapper">Phone Number</div>
                <div className="company-info--value-wrapper">
                  {selectedUsersForPreview?.phoneNumber}
                </div>
              </div>

              {/* Company Name */}
              <div className="company-info-detail-wrapper">
                <div className="company-info--key-wrapper">Company</div>
                <div className="company-info--value-wrapper">
                  {selectedUsersForPreview?.companyCode || "N/A"}
                </div>
              </div>

              {/* Address */}
              <div className="company-info-detail-wrapper">
                <div className="company-info--key-wrapper">Address</div>
                <div className="company-info--value-wrapper">
                  {selectedUsersForPreview?.address || "N/A"}
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* User Preview Recent Purchases Section */}
        <section className="user-preview-recent-purchases-section">
          <div className="section-title-wrapper">Recent Purchases</div>
          <RecentPurchasesTable
            selectedUsersForPreview={selectedUsersForPreview}
            setErrorHandlerObj={setErrorHandlerObj}
          />
        </section>
      </div>
    </div>
  );
}

export default UserPreview;

import axios from "axios";
import { api } from "../../api/config";
import { SetErrorHandlerType, SetSuccessHandlerType } from "../../Types";
import { handleAPIError } from "../../utils/handleAPIError";

const url = `${api}/api/v1/admin/company/loan-access`;

const token: any = localStorage.getItem("jwtToken");
axios.defaults.headers.common["authorization"] = token;

interface ILoanAccessData {
  isOnDemandEnabled: boolean;
  isSalaryAdvanceEnabled: boolean;
  isBNPLEnabled: boolean;
}
// Update Company Loan Access
export const updateCompanyLoanAccess =
  (
    companyId: string,
    data: ILoanAccessData,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setErrorHandlerObj: SetErrorHandlerType,
    setSuccessHandlerObj: SetSuccessHandlerType,
    setIsPreviewedEmployerUpdated: React.Dispatch<React.SetStateAction<boolean>>
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .post(`${url}/${companyId}`, data)
      .then((res) => {
        setSuccessHandlerObj({
          isSuccess: true,
          message: "Company loan access updated successfully!",
        });
        setIsLoading(false);
        // Set Is Loan Access Updated to true
        setIsPreviewedEmployerUpdated(true);
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

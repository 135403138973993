import useDisplayMessage from "../../hooks/useDisplayMessage";
import Top from "../../components/app-top/Top";
import Sidebar from "../../components/app-sidebar/Sidebar";
import Main from "../../components/layout/main-container/Main";
import ErrorHandler from "../../components/error/ErrorHandler";
import SuccessHandler from "../../components/success/SuccessHandler";
import PrimaryBackButton from "../../components/primary-back-button/PrimaryBackButton";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import PurchaseDealsTableSection from "./purchase-deals-table-section/PurchaseDealsTableSection";
import SearchVoucherModal from "./modal/search-voucher-modal/SearchVoucherModal";
import ConfirmActionModal from "../../components/confirm-action-modal/ConfirmActionModal";
import { redeemPurchaseDeal } from "../../redux/actions/productDealActions";
import { useAppDispatch } from "../../hooks";

function ProductPurchaseDeals() {
  // Functions, States and Variables
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  //   States
  const [isLoading, setIsLoading] = useState(false);
  const [isPurchaseDealSearched, setIsPurchaseDealSearched] = useState(false);
  const [isSearchVoucherModalOpened, setIsSearchVoucherModalOpened] =
    useState(false);
  const [isRedeemVoucherModalOpened, setIsRedeemVoucherModalOpened] =
    useState(false);
  const [
    selectedVoucherCodeForRedemption,
    setSelectedVoucherCodeForRedemption,
  ] = useState("");
  //   Hooks
  // Handles Display the error and success message and scrolls to top
  const {
    errorHandlerObj,
    setErrorHandlerObj,
    successHandlerObj,
    setSuccessHandlerObj,
  } = useDisplayMessage();

  //   Functions
  const handleRedeemProductPurchaseDeal = function () {
    const voucherCode = selectedVoucherCodeForRedemption;
    // Call the API to redeem voucher through the OTP inputed
    dispatch(
      redeemPurchaseDeal(
        voucherCode,
        setIsLoading,
        setErrorHandlerObj,
        setSuccessHandlerObj,
        setIsRedeemVoucherModalOpened
      )
    );
  };

  return (
    <div
      className={`app-page-container  ${
        isSearchVoucherModalOpened || isRedeemVoucherModalOpened
          ? "overflow-hidden"
          : ""
      }`}
    >
      <Top />
      <Sidebar activeName={"Product Deals"} />

      {/* Modals */}
      <div
        onClick={() => {
          setIsSearchVoucherModalOpened(false);
          setIsRedeemVoucherModalOpened(false);
        }}
        className={`gray-overlay ${
          isSearchVoucherModalOpened || isRedeemVoucherModalOpened ? "" : "none"
        }`}
      ></div>

      {/* Search Voucher By Voucher Code Modal */}
      <SearchVoucherModal
        isModalOpened={isSearchVoucherModalOpened}
        setIsModalOpened={setIsSearchVoucherModalOpened}
        setErrorHandlerObj={setErrorHandlerObj}
        setIsVoucherSearched={setIsPurchaseDealSearched}
      />

      {/* Redeem Voucher By Filling in OTP Send to User Modal */}
      <ConfirmActionModal
        isLoading={isLoading}
        isModalOpened={isRedeemVoucherModalOpened}
        setIsModalOpened={setIsRedeemVoucherModalOpened}
        confirmationText="Are you sure you want to redeem this product purchase deal?"
        handleConfirmAction={handleRedeemProductPurchaseDeal}
      />

      <Main>
        {/* Error handler component */}
        <ErrorHandler
          errorHandlerObj={errorHandlerObj}
          className="app--actions-handler-wrapper"
        />
        {/* Success handler component */}
        <SuccessHandler
          successHandlerObj={successHandlerObj}
          className="app--actions-handler-wrapper"
        />

        <section className={`app-main-section--inner`}>
          {/* Deals top container */}
          {/* Top Wrapper */}
          <div className="plan-setup--top-wrapper">
            <div className="primary-back-button-container">
              {/* Back button Wrapper */}
              <PrimaryBackButton
                onClick={() => {
                  navigate("/admin/product-deals");
                }}
              />
              {/* Top Title */}
              <div className="primary-back-button-title">Purchase Deals</div>
            </div>
          </div>

          {/* Purchase Deals Table Section */}
          <PurchaseDealsTableSection
            isPurchaseDealSearched={isPurchaseDealSearched}
            setIsPurchaseDealSearched={setIsPurchaseDealSearched}
            setErrorHandlerObj={setErrorHandlerObj}
            setSuccessHandlerObj={setSuccessHandlerObj}
            setIsSearchVoucherModalOpened={setIsSearchVoucherModalOpened}
            setIsRedeemVoucherModalOpened={setIsRedeemVoucherModalOpened}
            setSelectedVoucherCodeForRedemption={
              setSelectedVoucherCodeForRedemption
            }
          />
        </section>
      </Main>
    </div>
  );
}

export default ProductPurchaseDeals;

import { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./Top.css";
import credleyLogo from "../../images/credley-logo.svg";
import settingsIcon from "../../images/settings-icon.svg";
import searchIcon from "../../images/search-icon.svg";
import { SearchContext } from "../contexts/SearchContext";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getCurrentUser } from "../../redux/actions/authActions";
import { handleGetRoleNameFromKey } from "../../utils/adminRolesOptions";

function Top() {
  const { searchedValue, setSearchedValue }: any = useContext(SearchContext);
  const userInfo = useAppSelector((state) => state.user.user);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  // Functions, States and Variables
  const [user, setUser] = useState<any>(null);
  const [errorHandlerObj, setErrorHandlerObj] = useState({
    hasError: false,
    message: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setSearchedValue("");
  }, [location]);

  useEffect(() => {
    const token = localStorage.getItem("jwtToken");
    // Check if User exists || is logged in
    if (token) {
      // Get Current User data
      dispatch(getCurrentUser(setIsLoading, setErrorHandlerObj));
    } else {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    setUser(userInfo);
  }, [userInfo]);

  return (
    <div className="app-top-container">
      <div className="app-top-container--inner">
        {/* Company Logo section / Left Section  */}
        <div
          className="company-logo-wrapper"
          onClick={() => navigate("/admin/dashboard")}
        >
          <img src={credleyLogo} width={100} height={30} alt="" />
        </div>

        {/* Search Bar Container / Middle Section */}
        <div className="top-search-bar-container">
          <div className="search-icon-wrapper">
            <img src={searchIcon} alt="" />
          </div>
          <input
            type="search"
            id="app-top-search-bar-input"
            className="top-search-input"
            placeholder="Search"
            value={searchedValue}
            onChange={(e) => setSearchedValue(e.target.value)}
          />
        </div>

        {/* Profile section Container / Right Section */}
        <div className="top-profile-container">
          {/* Profile image wrapper */}
          <div className="profile-img-wrapper user-initials-wrapper">
            {/* <img src={profileImg} alt="" /> */}
            {user?.firstName?.slice(0, 1)}
            {user?.lastName?.slice(0, 1)}
          </div>

          {/* Merchant Name wrapper */}
          <div className="merchant-name-wrapper">
            <div className="merchant-user-name">
              {`${user?.firstName || "Administrator"} ${user?.lastName || ""}`}
            </div>
            <div className="merchant-title">
              {handleGetRoleNameFromKey(user?.role) || "Super Admin"}
            </div>
          </div>

          {/* Settings icon wrapper */}
          <div className="settings-icon-wrapper">
            <Link to={"/settings"}>
              <img src={settingsIcon} alt="" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Top;

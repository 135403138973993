import React from "react";
import "./TextEditor.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export const TextEditorWithToolbar = function ({
  value: currentValue,
  setValue,
  placeholder,
}) {
  // Functions, States and Variables
  return (
    <>
      <div className="text-editor-with-toolbar-container">
        <ReactQuill
          theme="snow"
          value={currentValue}
          onChange={setValue}
          placeholder={placeholder}
        />
      </div>
    </>
  );
};

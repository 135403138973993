import {
  ICourseCategory,
  SetErrorHandlerType,
  SetSuccessHandlerType,
} from "../../../../../Types";
import moment from "moment";
import { Table } from "react-bootstrap";
import FEPagination from "../../../../../components/pagination/fe-pagination/FEPagination";
import isEmpty from "../../../../../validation/isEmpty";
import LoadingSpinner from "../../../../../components/loading-spinner/LoadingSpinner";
import pencilIcon from "../../../../../images/pencil-icon.svg";
import trashIcon from "../../../../../images/trash-icon.svg";
import { useAppDispatch } from "../../../../../hooks";
import { deleteCourseCategory } from "../../../../../redux/actions/wellnessActions";
import { useState } from "react";
import SubstituteLoadingSpinner from "../../../../../components/substitute-loading-spinner/SubstituteLoadingSpinner";

// Interface
interface IProps {
  allCourseCategories: ICourseCategory[] | null;
  isLoading: boolean;
  currentCourseCategoryPosts: ICourseCategory[] | null;
  currentPage: number;
  rowsPerPage: number;
  indexOfLastPost: number;
  indexOfFirstPost: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  setIsStoreCategoryModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedCategoryForEditting: React.Dispatch<
    React.SetStateAction<ICourseCategory | null>
  >;
  setErrorHandlerObj: SetErrorHandlerType;
  setSuccessHandlerObj: SetSuccessHandlerType;
}

function CategoryTable({
  allCourseCategories,
  isLoading,
  currentCourseCategoryPosts,
  currentPage,
  rowsPerPage,
  setCurrentPage,
  setIsStoreCategoryModalOpened,
  setSelectedCategoryForEditting,
  setErrorHandlerObj,
  setSuccessHandlerObj,
}: IProps) {
  // Functions and States
  const dispatch = useAppDispatch();

  // States
  const [isSecondaryLoading, setIsSecondaryLoading] = useState(false);
  // Functions
  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleOpenEditCategoryModal = function (category: ICourseCategory) {
    setIsStoreCategoryModalOpened(true);
    setSelectedCategoryForEditting(category);
  };

  const handleDeleteCategory = function (category: ICourseCategory) {
    dispatch(
      deleteCourseCategory(
        category._id,
        setIsSecondaryLoading,
        setErrorHandlerObj,
        setSuccessHandlerObj
      )
    );
  };
  return (
    <>
      {/* Admin Table Container */}
      <div className="primary-table-container">
        <div className="table-top-loader-wrapper">
          {isSecondaryLoading && <SubstituteLoadingSpinner />}
        </div>
        <Table responsive="lg" className="primary-table">
          <thead
            style={{
              textAlign: "left",
            }}
          >
            <tr>
              {["NAME", "DATE CREATED", "", ""].map((COLUMN, index) => (
                <th key={index + 1}>{COLUMN}</th>
              ))}
            </tr>
          </thead>

          <tbody>
            {currentCourseCategoryPosts?.map((category, i) => (
              <tr key={i + 1}>
                {/* Name Column */}
                <td className="td-primary">
                  <div className="td-primary--wrapper">{category.name}</div>
                </td>

                {/* Date Created Column */}
                <td className="td-primary">
                  <div className="td-primary--wrapper">
                    {" "}
                    {moment(category.createdAt).format("Do MMM, YYYY")}
                  </div>
                </td>

                {/* Edit Button Column */}
                <td className="td-edit-button">
                  <div className="td-edit-button--wrapper">
                    <button
                      onClick={() => handleOpenEditCategoryModal(category)}
                    >
                      <img src={pencilIcon} alt="" />
                      Edit
                    </button>
                  </div>
                </td>

                {/* Edit Btn Column*/}
                <td className="td-delete-button">
                  <div className="td-delete-button--wrapper">
                    <button
                      onClick={() => {
                        handleDeleteCategory(category);
                      }}
                    >
                      <img src={trashIcon} alt="" />
                      Delete
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div className="below-table-loader-wrapper">
          {isLoading && <LoadingSpinner />}
        </div>
      </div>

      {allCourseCategories && !isEmpty(currentCourseCategoryPosts) && (
        <FEPagination
          paginate={paginate}
          rowsPerPage={rowsPerPage}
          totalPosts={allCourseCategories.length}
          currentPage={currentPage}
        />
      )}
    </>
  );
}

export default CategoryTable;

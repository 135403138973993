import React from "react";

// Interfaces
interface IProps {
  pageContent: string;
  setPageContent: React.Dispatch<React.SetStateAction<string>>;
}

const tabOptions = [
  { key: "categories", value: "Categories" },
  // { key: "products", value: "Products" },
  { key: "vouchers", value: "Vouchers" },
  { key: "selected-vouchers", value: "Selected Vouchers" },
];
function ProductsNav({ pageContent, setPageContent }: IProps) {
  // Functions, States and Variables
  // States

  //   Functions
  const handleChangePageContent = function (currentPageContent: string) {
    setPageContent(currentPageContent);
  };
  return (
    <div className="products--row-two-top-wrapper">
      {/* Products filter section */}
      <div className="products-filter-container">
        {tabOptions?.map((tabOption, index) => (
          <div
            key={index + 1}
            className={`products-filter-option filter-option--${
              tabOption.key
            } ${pageContent === tabOption.key ? "active-filter-option" : ""}`}
            onClick={() => handleChangePageContent(tabOption.key)}
          >
            {tabOption.value}
          </div>
        ))}
      </div>
    </div>
  );
}

export default ProductsNav;

import React, { MouseEvent, useEffect, useState } from "react";
import "./AwaitingVerification.css";
import AuthNavbar from "../auth-navbar/AuthNavbar";
import ErrorHandler from "../error/ErrorHandler";
import SuccessHandler from "../success/SuccessHandler";
import attentionIcon from "../../images/attention-icon.svg";
import { useAppDispatch } from "../../hooks";
import LoadingSpinner from "../loading-spinner/LoadingSpinner";
import TextInput from "../input-components/text-input/TextInput";
import SubstituteLoadingSpinner from "../substitute-loading-spinner/SubstituteLoadingSpinner";
import { useNavigate } from "react-router-dom";
import {
  resendVerificationLink,
  verifyCurrentUser,
} from "../../redux/actions/authActions";
import jwtDecode from "jwt-decode";

function AwaitingVerification() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  // Functions, States and Variables
  const [token, setToken] = useState("");
  const [errorHandlerObj, setErrorHandlerObj] = useState({
    hasError: false,
    message: "",
  });
  const [successHandlerObj, setSuccessHandlerObj] = useState({
    isSuccess: false,
    message: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isVerificationLoading, setIsVerificationLoading] = useState(false);

  // Handle Resend Verification Link
  const handleResendVerificationLink = function (
    e: MouseEvent<HTMLButtonElement>
  ) {
    setSuccessHandlerObj({ isSuccess: false, message: "" });
    setErrorHandlerObj({ hasError: false, message: "" });
    const token = localStorage.getItem("jwtToken") ?? "";
    const decoded: any = jwtDecode(token);

    if (decoded) {
      const data = {
        email: decoded.email,
      };
      //  Call the Resend Verification Link API
      dispatch(
        resendVerificationLink(
          data,
          setIsLoading,
          setErrorHandlerObj,
          setSuccessHandlerObj
        )
      );
    } else {
      // If there is no jwtToken, navigate to signin
      navigate("/");
    }
  };

  // Handle Verify user's account
  const handleVerifyAccount = function (e: MouseEvent<HTMLButtonElement>) {
    setSuccessHandlerObj({ isSuccess: false, message: "" });
    setErrorHandlerObj({ hasError: false, message: "" });
    if (token !== "") {
      e.preventDefault();

      const jwtToken = localStorage.getItem("jwtToken") ?? "";
      const decoded: any = jwtDecode(jwtToken);

      const data = {
        token,
        email: decoded.email,
      };

      if (decoded) {
        //  Call the verify user API with token and redirect user to signin
        dispatch(
          verifyCurrentUser(
            data,
            setIsVerificationLoading,
            setErrorHandlerObj,
            setSuccessHandlerObj,
            navigate
          )
        );
      } else {
        // If there is no jwtToken, navigate to signin
        navigate("/");
      }
    }
  };

  useEffect(() => {
    if (errorHandlerObj.hasError || successHandlerObj.isSuccess) {
      //scroll to page top
      window.scrollTo({ top: 0 });
    }
  }, [errorHandlerObj, successHandlerObj]);

  // If response is failure, remove success message
  useEffect(() => {
    if (errorHandlerObj.hasError) {
      setSuccessHandlerObj({ isSuccess: false, message: "" });
    }
  }, [errorHandlerObj]);

  // If response is success, remove failure message
  useEffect(() => {
    if (successHandlerObj.isSuccess) {
      setErrorHandlerObj({ hasError: false, message: "" });
    }
  }, [successHandlerObj]);

  return (
    <div className="awaiting-verification-container">
      <AuthNavbar />

      <div className="">
        {/* Error handler component */}
        <ErrorHandler
          errorHandlerObj={errorHandlerObj}
          className="verification-message-wrapper"
        />

        {/* Success handler component */}
        <SuccessHandler
          successHandlerObj={successHandlerObj}
          className="verification-message-wrapper"
        />
      </div>
      <section className="awaiting-verification-wrapper">
        {/* Container Header  */}
        <div className="dashboard-awaiting-data-section-header">
          <div className="congratulations-image-wrapper">
            <img src={attentionIcon} alt="" />
          </div>

          <div className="dashboard-awaiting-data-section-header-title">
            Your account is not verified!
          </div>

          <div className="dashboard-awaiting-data-section-header-sub-title">
            Kindly fill in the token sent to your mail to verify your account!{" "}
            <br />
            <div className="verification-token-form-container">
              <form>
                <div className="verification-token-form-group form-group">
                  <TextInput
                    type={"number"}
                    id={"verification-token-text-input-1"}
                    className={"verification-token-input"}
                    placeholder={""}
                    value={token}
                    onChange={(e) => {
                      setToken(e.target.value);
                    }}
                    required={true}
                    label={"Token"}
                  />
                </div>

                <div className="submit-verification-token-button-wrapper">
                  <button onClick={(e) => handleVerifyAccount(e)}>
                    {isVerificationLoading ? "Verifying..." : "Verify Account"}
                  </button>
                  <div className="verification-loader-wrapper">
                    {isVerificationLoading && <SubstituteLoadingSpinner />}
                  </div>
                </div>
              </form>
            </div>
            <div className="resend-verification-link-text-wrapper">
              If token wasn't recieved or token is expired, click the button
              below to resend verification link
            </div>
            {/* Resend verification link Button Wrapper */}
            <div className="resend-verification-link--button-wrapper">
              <button onClick={(e) => handleResendVerificationLink(e)}>
                Resend verification link
              </button>
              <div className="verification-loader-wrapper">
                {isLoading && <SubstituteLoadingSpinner />}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default AwaitingVerification;

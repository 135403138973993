import React from "react";
import { useContext, useEffect, useState } from "react";
import "./CategoryTableSection.css";
import { SearchContext } from "../../../../components/contexts/SearchContext";
import isEmpty from "../../../../validation/isEmpty";
import CategoryTable from "./table/CategoryTable";
import {
  ICourseCategory,
  SetErrorHandlerType,
  SetSuccessHandlerType,
} from "../../../../Types";

interface IProps {
  courseCategories: [] | ICourseCategory[];
  isLoading: boolean;
  setIsStoreCategoryModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedCategoryForEditting: React.Dispatch<
    React.SetStateAction<ICourseCategory | null>
  >;
  setErrorHandlerObj: SetErrorHandlerType;
  setSuccessHandlerObj: SetSuccessHandlerType;
}
function CategoriesTableSection({
  courseCategories,
  isLoading,
  setIsStoreCategoryModalOpened,
  setSelectedCategoryForEditting,
  setErrorHandlerObj,
  setSuccessHandlerObj,
}: IProps) {
  // Functions, States and Variables
  const { searchedValue }: any = useContext(SearchContext);
  // States
  const [allCourseCategories, setAllCourseCategories] = useState<
    ICourseCategory[] | null
  >(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [currentCourseCategoryPosts, setCurrentCourseCategoryPosts] = useState<
    ICourseCategory[] | null
  >(null);
  const indexOfLastPost = currentPage * rowsPerPage;
  const indexOfFirstPost = indexOfLastPost - rowsPerPage;

  // Functions
  //   handle Filter Table
  const handleFilterTable = function () {
    if (!courseCategories || isEmpty(courseCategories)) return false;
    const allCategoriesData = courseCategories.filter((category) =>
      category.name?.toLowerCase().includes(searchedValue.toLowerCase())
    );
    setAllCourseCategories(allCategoriesData);
    // Update table state to users rows
    if (!allCourseCategories || !allCategoriesData) return false;
    const currentRows = allCategoriesData.slice(
      indexOfFirstPost,
      indexOfLastPost
    );
    setCurrentCourseCategoryPosts(currentRows);
  };

  //   UseEffects
  useEffect(() => {
    if (courseCategories) setAllCourseCategories(courseCategories);
  }, [courseCategories]);

  useEffect(() => {
    if (allCourseCategories) {
      const currentRows = allCourseCategories?.slice(
        indexOfFirstPost,
        indexOfLastPost
      );
      setCurrentCourseCategoryPosts(currentRows);
    }
  }, [allCourseCategories, currentPage]);

  useEffect(() => {
    handleFilterTable();
    setCurrentPage(1);
  }, [searchedValue]);

  return (
    <section className={`users-table-section`}>
      {/* Users Table Container */}
      <section className={`users-table-wrapper`}>
        <CategoryTable
          allCourseCategories={allCourseCategories}
          isLoading={isLoading}
          currentCourseCategoryPosts={currentCourseCategoryPosts}
          currentPage={currentPage}
          rowsPerPage={rowsPerPage}
          indexOfFirstPost={indexOfFirstPost}
          indexOfLastPost={indexOfLastPost}
          setCurrentPage={setCurrentPage}
          setIsStoreCategoryModalOpened={setIsStoreCategoryModalOpened}
          setSelectedCategoryForEditting={setSelectedCategoryForEditting}
          setErrorHandlerObj={setErrorHandlerObj}
          setSuccessHandlerObj={setSuccessHandlerObj}
        />
      </section>
    </section>
  );
}

export default CategoriesTableSection;

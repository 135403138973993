import { ChangeEvent } from "react";
import { IVideoCourseInputState } from "../Types";
import { fileSizeConverter } from "./fileSizeConverter";

export const handleGetVideoDuration = function (
  event: ChangeEvent<HTMLInputElement>,
  index: number,
  setCourseContentInputStates: React.Dispatch<
    React.SetStateAction<IVideoCourseInputState[]>
  >
) {
  if (event.target.files) {
    const file = event.target.files[0];
    const videoElement = document.createElement("video");

    videoElement.preload = "metadata";
    videoElement.onloadedmetadata = function () {
      const duration = videoElement.duration;
      //   console.log(
      //     "Video duration:",
      //     duration,
      //     "seconds",
      //     secondsToTimeString(duration)
      //   );
      setCourseContentInputStates((prevInputStates) => {
        const courseContentInputStatesCopy = [...prevInputStates];
        const updatedPrevInputObjForIndex = {
          ...courseContentInputStatesCopy[index],
          duration: secondsToTimeString(duration),
          size: fileSizeConverter(
            event.target.files ? event.target.files[0].size : 0
          ),
        };
        console.log(updatedPrevInputObjForIndex);
        courseContentInputStatesCopy.splice(
          index,
          1,
          updatedPrevInputObjForIndex
        );
        return courseContentInputStatesCopy;
      });

      return secondsToTimeString(duration);
    };

    videoElement.src = URL.createObjectURL(file);
  }
};

function secondsToTimeString(seconds: number) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = Math.floor(seconds % 60);

  const formattedHours = String(hours).padStart(2, "0");
  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(remainingSeconds).padStart(2, "0");

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}

import {
  GET_ALL_PRODUCTS,
  GET_ALL_PRODUCTS_PER_MERCHANT,
  GET_ALL_UNAPPROVED_PRODUCTS,
} from "../Constants";

const initialState = {
  products: [],
  productsPerMerchant: [],
  unapprovedProducts: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (
  state = initialState,
  action: { type?: string; payload?: any }
) {
  switch (action.type) {
    case GET_ALL_PRODUCTS:
      return {
        ...state,
        products: action.payload,
      };
    case GET_ALL_UNAPPROVED_PRODUCTS:
      return {
        ...state,
        unapprovedProducts: action.payload,
      };
    case GET_ALL_PRODUCTS_PER_MERCHANT:
      return {
        ...state,
        productsPerMerchant: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
}

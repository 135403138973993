import axios from "axios";
import { ChangeEvent } from "react";
import { SetErrorHandlerType, SetIsLoadingType } from "../Types";
import { api } from "../api/config";

export const handleUploadImage = async function (
  e: ChangeEvent<HTMLInputElement>,
  setIsLoading: SetIsLoadingType,
  setErrorHandlerObj: SetErrorHandlerType,
  handleUpdateImageData: (data: string) => void
) {
  if (e.target.files) {
    const token = localStorage.getItem("jwtToken");

    if (token) {
      axios.defaults.headers.common["authorization"] = token;

      try {
        const selectedFile = e.target.files[0];
        const formData = new FormData();
        formData.append("file", selectedFile);

        setIsLoading(true);
        const { data } = await axios.post(`${api}/api/v1/upload`, formData);

        if (data) {
          handleUpdateImageData(data);
        }

        setIsLoading(false);
      } catch (ex) {
        setIsLoading(false);

        setErrorHandlerObj({
          hasError: true,
          message: "Upload failed!",
        });
      }
    }
  }
};

import React, { useContext, useEffect, useState } from "react";
import "./AuditTrail.css";
import Sidebar from "../../components/app-sidebar/Sidebar";
import Top from "../../components/app-top/Top";
import {
  ISearchContextType,
  SearchContext,
} from "../../components/contexts/SearchContext";
import ErrorHandler from "../../components/error/ErrorHandler";
import DropdownInput from "../../components/input-components/dropdown-input/DropdownInput";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getAllAuditTrails } from "../../redux/actions/auditTrailsActions";
import useDisplayMessage from "../../hooks/useDisplayMessage";
import isEmpty from "../../validation/isEmpty";
import AuditTrailTable from "./audit-trail-table/AuditTrailTable";
import { ISingleAudit } from "../../Types";
import Main from "../../components/layout/main-container/Main";
import BEPagination from "../../components/pagination/be-pagination/BEPagination";

function AuditTrail() {
  // Functions, states and variables
  const auditsObject = useAppSelector((state) => state.audit.auditTrails);
  const { searchedValue }: ISearchContextType | any = useContext(SearchContext);
  const dispatch = useAppDispatch();
  // States
  const [allAuditTrails, setAllAuditTrails] = useState<ISingleAudit[] | null>(
    null
  );
  const [auditDateFilter, setAuditDateFilter] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  useState(false);
  // Functions

  // UseEffects
  // Handles Display the error and success message and scrolls to top
  const {
    errorHandlerObj,
    setErrorHandlerObj,
    successHandlerObj,
    setSuccessHandlerObj,
  } = useDisplayMessage();

  // Pagination
  const [pageNumberIndex, setPageNumberIndex] = useState(1);

  //   handle Filter Table
  const handleFilterTable = function () {
    const audits: ISingleAudit[] | [] = auditsObject.data;
    let allNewAudits = audits?.filter(
      (audit) =>
        audit?.user.firstName
          .toLowerCase()
          .includes(searchedValue.toLowerCase()) ||
        audit?.user.lastName
          .toLowerCase()
          .includes(searchedValue.toLowerCase()) ||
        audit.module.toLowerCase().includes(searchedValue.toLowerCase())
    );
    setAllAuditTrails(allNewAudits);
  };

  const handleFetchAuditTrails = function () {
    dispatch(
      getAllAuditTrails(pageNumberIndex, setIsLoading, setErrorHandlerObj)
    );
  };

  //   UseEffects
  useEffect(() => {
    // Fetch audit trails based on voucher filter parameters and selected paginated page
    handleFetchAuditTrails();
  }, [pageNumberIndex]);

  useEffect(() => {
    if (auditsObject) handleFilterTable();
  }, [auditsObject, searchedValue]);

  return (
    <div className="app-page-container">
      <Top />
      <Sidebar activeName={"Audit Trail"} />

      <Main>
        {/* Error handler component */}
        <ErrorHandler
          errorHandlerObj={errorHandlerObj}
          className="app--actions-handler-wrapper"
        />

        <section className={`app-main-section--inner`}>
          {/* Employers Table Top Wrapper */}
          <div className="employers-table--top-wrapper">
            {/* Employers Table Filter Wrapper */}
            <div className="employers-table-filter-option-wrapper requests--table-filter-option-wrapper">
              <div className="employers-table-filter-row-title requests--table-filter-row-title">
                Filter:
              </div>
              {/* Filter by date added dropdown */}
              <div className="audit-table--date-filter-form-group form-group">
                <DropdownInput
                  className={"audit-date-filter-dropdown-input"}
                  id={"audit-date-filter-input"}
                  label={""}
                  required={true}
                  optionArray={[
                    { key: "", value: "Date", default: "Default" },
                    {
                      key: "yesterday",
                      value: "Yesterday",
                    },
                  ]}
                  value={auditDateFilter}
                  onChange={(e) => setAuditDateFilter(e.target.value)}
                />
              </div>
            </div>
          </div>
          <section className={`audit-trail-table-wrapper`}>
            <AuditTrailTable
              currentPosts={allAuditTrails}
              isLoading={isLoading}
            />
            {!isEmpty(allAuditTrails) &&
              !(isLoading && isEmpty(allAuditTrails)) && (
                <BEPagination
                  currentPage={auditsObject?.currentPage || 1}
                  lastPage={auditsObject.totalPages}
                  currentPosts={allAuditTrails}
                  setPageNumberIndex={setPageNumberIndex}
                />
              )}
          </section>
        </section>
      </Main>
    </div>
  );
}

export default AuditTrail;

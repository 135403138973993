import React from "react";
import "./MerchantsTable.css";
import { Table } from "react-bootstrap";
import FEPagination from "../../../components/pagination/fe-pagination/FEPagination";
import moment from "moment";
import isEmpty from "../../../validation/isEmpty";
import LoadingSpinner from "../../../components/loading-spinner/LoadingSpinner";
import { ISingleMerchant } from "../../requests/Requests";
import pinIcon from "../../../images/pin-icon.svg";

interface IProps {
  allMerchants: ISingleMerchant[] | null;
  currentMerchantsPosts: ISingleMerchant[] | null;
  setPageContent: React.Dispatch<React.SetStateAction<string>>;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  rowsPerPage: number;
  isLoading: boolean;
  setSelectedMerchantForPreview: React.Dispatch<
    React.SetStateAction<ISingleMerchant | null>
  >;
}

const MerchantsTable = function ({
  allMerchants,
  currentMerchantsPosts,
  setPageContent,
  currentPage,
  setCurrentPage,
  rowsPerPage,
  isLoading,
  setSelectedMerchantForPreview,
}: IProps) {
  // Functions and States

  // Functions
  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  return (
    <>
      {/* Employers Table Container */}
      <div className="primary-table-container">
        <Table responsive="lg" className="primary-table">
          <thead
            style={{
              textAlign: "left",
            }}
          >
            <tr>
              {[
                "MERCHANT NAME",
                "PHONE NUMBER",
                "EMAIL",
                "DATE ADDED",
                "CAC DOCUMENT",
              ].map((COLUMN, index) => (
                <th key={index + 1}>{COLUMN}</th>
              ))}
            </tr>
          </thead>

          <tbody>
            {currentMerchantsPosts?.map((merchant, i) => (
              <tr
                key={i + 1}
                onClick={() => {
                  //  Set Selected company and change page to preview
                  setSelectedMerchantForPreview(merchant);
                  setPageContent("preview");
                }}
              >
                {/* Shop Name Column */}
                <td className="td-shop-name">
                  <div className="td-shop-name--wrapper">
                    {merchant.shopName}
                  </div>
                </td>

                {/*  Company Code Column */}
                <td className="td-phone-number">
                  <div className="td-phone-number--wrapper">
                    {merchant.phoneNumber}
                  </div>
                </td>

                {/*  Merchant Email Column */}
                <td className="td-merchant-email">
                  <div className="td-merchant-email--wrapper">
                    {merchant.email}
                  </div>
                </td>

                {/*  Date Added Column */}
                <td className="td-date-added">
                  <div className="td-date-added--wrapper">
                    {moment(merchant.createdAt).format("Do MMM, YYYY")}
                  </div>
                </td>

                {/*  Cac Document Column */}
                <td className="td-cac-document">
                  <div className="td-cac-document--wrapper">
                    {merchant.cacDocument ? (
                      <a
                        href={merchant.cacDocument}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={pinIcon} alt="" />
                        CAC Document.
                        {merchant.cacDocument.substring(
                          merchant.cacDocument.length - 3
                        )}
                      </a>
                    ) : (
                      <div className="no-document-wrapper">No Document</div>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div className="below-table-loader-wrapper">
          {isLoading && <LoadingSpinner />}
        </div>
      </div>

      {allMerchants && !isEmpty(currentMerchantsPosts) && (
        <FEPagination
          paginate={paginate}
          rowsPerPage={rowsPerPage}
          totalPosts={allMerchants.length}
          currentPage={currentPage}
        />
      )}
    </>
  );
};

export default MerchantsTable;

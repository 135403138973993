import React, { MouseEvent } from "react";
import { INotifyData } from "../../../../redux/actions/notificationsActions";
import LoadingSpinner from "../../../../components/loading-spinner/LoadingSpinner";
import PrimaryButton from "../../../../components/buttons/primary-button/PrimaryButton";

// Interfaces
interface IProps {
  isLoading: boolean;
  notifyFormInputStates: INotifyData;
  setNotifyFormInputStates: React.Dispatch<React.SetStateAction<INotifyData>>;
  handleNotifiyAllUsers: (e: MouseEvent<HTMLButtonElement>) => void;
}

function FormSection({
  isLoading,
  notifyFormInputStates,
  setNotifyFormInputStates,
  handleNotifiyAllUsers,
}: IProps) {
  // Functions, states and variables
  //   Functions
  const handleUpdateFormInputState = function (
    fieldName: string,
    fieldValue: string
  ) {
    const updatedFormInputStateObject = {
      ...notifyFormInputStates,
      [fieldName]: fieldValue,
    };
    setNotifyFormInputStates(updatedFormInputStateObject);
  };

  return (
    <section className="notifications-form-container">
      <form>
        <div className="form-group-wrapper">
          {/* Notification Details Form Group */}
          <div className="form-group">
            <label htmlFor={"notification-input"}>Notification Details</label>
            <textarea
              id={"notification-input"}
              placeholder={"Enter notification text"}
              value={notifyFormInputStates.details}
              onChange={(e) =>
                handleUpdateFormInputState("details", e.target.value)
              }
              required
            />
          </div>
        </div>

        {/* Submit form button */}
        <div className="notifications-form-primary-btn-container primary-button-container">
          {isLoading && <LoadingSpinner />}
          <PrimaryButton
            className="notifications-setup-form--action-btn"
            placeholder="Submit"
            onClick={(e) => handleNotifiyAllUsers(e)}
          />
        </div>
      </form>
    </section>
  );
}

export default FormSection;

import { ISubscriptionPlan } from "../../../../../Types";
import tickIcon from "../../../../../images/tick-icon-gray.svg";
import pencilIcon from "../../../../../images/pencil-icon.svg";
import { useNavigate } from "react-router-dom";

// Interfaces
interface IProps {
  plan: ISubscriptionPlan;
  index: number;
  currentActivePlanIndex: number;
  setCurrentActivePlanIndex: React.Dispatch<React.SetStateAction<number>>;
}
function SubscriptionPlanCard({
  plan,
  index,
  currentActivePlanIndex,
  setCurrentActivePlanIndex,
}: IProps) {
  // Functions, States and Variables
  const navigate = useNavigate();
  //   Functions
  const handleFocusOnPlan = function (index: number) {
    setCurrentActivePlanIndex(index);
  };

  const handleGenerateArrOfListsFromListHTMLString = function (
    HTMLString: string
  ) {
    const regex = /<li>(.*?)<\/li>/g;
    const listMatches = HTMLString.match(regex);
    const listArray = listMatches?.map((match) =>
      match.replace(/<\/?li>/g, "")
    );
    if (listArray) return listArray;
  };

  return (
    <div
      onClick={() => handleFocusOnPlan(index)}
      className={`subscription-plan-choice-wrapper ${
        index === currentActivePlanIndex && "focused-plan"
      }`}
    >
      {plan?.isRecommended && (
        <div className="recommended-wrapper">Recommended</div>
      )}
      <div className="subscription-plan-choice-top-wrapper">
        {/* Title */}
        <div className="plan-title">{plan.name}</div>
        {/* Edit Plan Button Wrapper */}
        <div className="td-edit-button--wrapper">
          <button
            onClick={() => {
              navigate("/admin/subscriptions/edit", {
                state: { currentPlan: plan },
              });
            }}
          >
            <img src={pencilIcon} alt="" />
            Edit
          </button>
        </div>
      </div>
      {/* Price */}
      <div className="plan-price">
        ₦{plan.cost.toLocaleString()}/employee/month
      </div>

      {/* Plan Perks */}
      <div className="plan-perks-wrapper">
        {handleGenerateArrOfListsFromListHTMLString(plan.details)?.map(
          (perk, index) => (
            <div key={index + 1} className="plan-perk">
              <img src={tickIcon} alt="" />
              {perk}
            </div>
          )
        )}
      </div>
    </div>
  );
}

export default SubscriptionPlanCard;

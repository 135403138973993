import { IAdministrator } from "../../../../../Types";
import { Table } from "react-bootstrap";
import FEPagination from "../../../../../components/pagination/fe-pagination/FEPagination";
import isEmpty from "../../../../../validation/isEmpty";
import LoadingSpinner from "../../../../../components/loading-spinner/LoadingSpinner";
import pencilIcon from "../../../../../images/pencil-icon.svg";
import { useNavigate } from "react-router-dom";
import { handleGetRoleNameFromKey } from "../../../../../utils/adminRolesOptions";

// Interface
interface IProps {
  allAdmins: IAdministrator[] | null;
  currentAdminsPosts: IAdministrator[] | null;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  rowsPerPage: number;
  indexOfLastPost: number;
  indexOfFirstPost: number;
  isLoading: boolean;
}

function AdminsTable({
  allAdmins,
  currentAdminsPosts,
  currentPage,
  setCurrentPage,
  rowsPerPage,
  isLoading,
}: IProps) {
  // Functions and States
  const navigate = useNavigate();
  // Functions
  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  return (
    <>
      {/* Admin Table Container */}
      <div className="primary-table-container">
        <Table responsive="lg" className="primary-table">
          <thead
            style={{
              textAlign: "left",
            }}
          >
            <tr>
              {["NAME", "EMAIL", "PHONE NUMBER", "ROLE", ""].map(
                (COLUMN, index) => (
                  <th key={index + 1}>{COLUMN}</th>
                )
              )}
            </tr>
          </thead>

          <tbody>
            {currentAdminsPosts?.map((admin, i) => (
              <tr key={i + 1}>
                {/* Name Column */}
                <td className="td-primary">
                  <div className="td-primary--wrapper">
                    {admin.firstName} {admin.lastName}
                  </div>
                </td>

                {/* EMAIL Column */}
                <td className="td-primary">
                  <div className="td-primary--wrapper">{admin.email}</div>
                </td>

                {/* Phone Number Column */}
                <td className="td-primary">
                  <div className="td-primary--wrapper">{admin.phoneNumber}</div>
                </td>

                {/* Role Column */}
                <td className="td-primary">
                  <div className="td-primary--wrapper">
                    {handleGetRoleNameFromKey(admin?.role)}
                  </div>
                </td>

                {/* Edit Button Column */}
                <td className="td-edit-button">
                  <div className="td-edit-button--wrapper">
                    <button
                      onClick={() =>
                        navigate("/admin/administrators/edit", {
                          state: { currentAdmin: admin },
                        })
                      }
                    >
                      <img src={pencilIcon} alt="" />
                      {/* Edit */}
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div className="below-table-loader-wrapper">
          {isLoading && <LoadingSpinner />}
        </div>
      </div>

      {allAdmins && !isEmpty(currentAdminsPosts) && (
        <FEPagination
          paginate={paginate}
          rowsPerPage={rowsPerPage}
          totalPosts={allAdmins.length}
          currentPage={currentPage}
        />
      )}
    </>
  );
}

export default AdminsTable;

import axios from "axios";
import { api } from "../../api/config";
import {
  SetErrorHandlerType,
  SetIsLoadingType,
  SetSuccessHandlerType,
} from "../../Types";
import { handleAPIError } from "../../utils/handleAPIError";
import { GET_RATES } from "../Constants";
// Types
interface IRatesData {
  rate: {
    USD: number;
    CAD: number;
    GBP: number;
  };
}
const url = `${api}/api/v1`;

const token: any = localStorage.getItem("jwtToken");
axios.defaults.headers.common["authorization"] = token;

// Get Rates
export const getRates =
  (setErrorHandlerObj: SetErrorHandlerType) => (dispatch: any) => {
    axios
      .get(`${url}/settings/rate`)
      .then((res) => {
        dispatch({ type: GET_RATES, payload: res.data.data });
      })
      .catch((err) => {
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Update rate
export const updateRates =
  (
    data: IRatesData,
    setIsLoading: SetIsLoadingType,
    setErrorHandlerObj: SetErrorHandlerType,
    setSuccessHandlerObj: SetSuccessHandlerType
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .post(`${url}/admin/update-rate`, data)
      .then((res) => {
        setSuccessHandlerObj({
          isSuccess: true,
          message: "Rates Updated successfully!",
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

import axios from "axios";
import { GET_ALL_WITHDRAWAL_REQUESTS } from "../Constants";
import { api } from "../../api/config";
import { SetErrorHandlerType, SetSuccessHandlerType } from "../../Types";
import { handleAPIError } from "../../utils/handleAPIError";

const url = `${api}/api/v1/admin`;

const token: any = localStorage.getItem("jwtToken");
axios.defaults.headers.common["authorization"] = token;

// Get all company withdrawal requests
export const getAllCompanyWithdrawalRequests =
  (
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setErrorHandlerObj: SetErrorHandlerType
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .get(`${api}/api/v1/company/admin/withdrawal-request`)
      .then((res) => {
        // console.log(res.data);
        dispatch({
          type: GET_ALL_WITHDRAWAL_REQUESTS,
          payload: res.data?.reverse(),
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

//   Approve Withdrawal Request With OTP
export const approveWithdrawalRequestWithOTP =
  (
    requestId: string,
    data: { otp: string },
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setErrorHandlerObj: SetErrorHandlerType,
    setSuccessHandlerObj: SetSuccessHandlerType,
    setIsApproved: React.Dispatch<React.SetStateAction<boolean>>,
    setIsOTPModalOpened: React.Dispatch<React.SetStateAction<boolean>>
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .patch(
        `${url}/withdrawal-request/approve-withdrawal-request-with-otp/${requestId}`,
        data
      )
      .then((res) => {
        // console.log(res.data);
        setSuccessHandlerObj({
          isSuccess: true,
          message: "Employee request approved successfully!",
        });
        setIsApproved(true);
        setIsLoading(false);
        // Close confirmation modal
        setIsOTPModalOpened(false);
      })
      .catch((err) => {
        // Close confirmation modal
        setIsOTPModalOpened(false);
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

//   Approve Withdrawal  Request
export const approveWithdrawalRequest =
  (
    requestId: string,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setErrorHandlerObj: SetErrorHandlerType,
    setSuccessHandlerObj: SetSuccessHandlerType,
    setIsOTPModalOpened: React.Dispatch<React.SetStateAction<boolean>>
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .patch(
        `${url}/withdrawal-request/approve-withdrawal-request/${requestId}`
      )
      .then((res) => {
        // console.log(res.data);

        setIsLoading(false);
        // Open OTP Modal
        setIsOTPModalOpened(true);
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

//   Decline Withdrawal Request
export const declineWithdrawalRequest =
  (
    data: {
      reason: string;
    },
    requestId: string,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setErrorHandlerObj: SetErrorHandlerType,
    setSuccessHandlerObj: SetSuccessHandlerType,
    setIsDeclined: React.Dispatch<React.SetStateAction<boolean>>,
    setIsDeclineEmployeeRequestModalOpened: React.Dispatch<
      React.SetStateAction<boolean>
    >
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .patch(
        `${url}/withdrawal-request/reject-withdrawal-request/${requestId}`,
        data
      )
      .then((res) => {
        // console.log(res.data);
        setSuccessHandlerObj({
          isSuccess: true,
          message: "Request declined successfully!",
        });
        setIsDeclined(true);
        setIsLoading(false);
        // Close confirmation modal
        setIsDeclineEmployeeRequestModalOpened(false);
      })
      .catch((err) => {
        // Close confirmation modal
        setIsDeclineEmployeeRequestModalOpened(false);
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

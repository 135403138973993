import React, { useContext, useEffect, useState } from "react";
import "./CompanyRequestsTable.css";
import { Table } from "react-bootstrap";
import DropdownInput from "../../../components/input-components/dropdown-input/DropdownInput";
import FEPagination from "../../../components/pagination/fe-pagination/FEPagination";
import isEmpty from "../../../validation/isEmpty";
import LoadingSpinner from "../../../components/loading-spinner/LoadingSpinner";
import closeIcon from "../../../images/close-icon.svg";
import tickIcon from "../../../images/tick-icon.svg";
import { SearchContext } from "../../../components/contexts/SearchContext";
import { ISingleCompany } from "../../../Types";

interface IProps {
  companyRequests: ISingleCompany[] | [];
  allCompaniesRequests: ISingleCompany[] | null;
  setAllCompaniesRequests: React.Dispatch<
    React.SetStateAction<ISingleCompany[] | null>
  >;
  currentCompanyRequestPosts: ISingleCompany[] | null;
  setCurrentCompanyRequestPosts: (
    value: React.SetStateAction<ISingleCompany[] | null>
  ) => void;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  rowsPerPage: number;
  indexOfFirstPost: number;
  indexOfLastPost: number;
  isLoading: boolean;
  isApproveRequestModalOpened: boolean;
  setIsApproveRequestModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
  isDeclineRequestModalOpened: boolean;
  setIsDeclineRequestModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedCompanyRequest: (
    value: React.SetStateAction<ISingleCompany | null>
  ) => void;
}

const CompanyRequestsTable = function ({
  companyRequests,
  allCompaniesRequests,
  setAllCompaniesRequests,
  currentCompanyRequestPosts,
  setCurrentCompanyRequestPosts,
  currentPage,
  setCurrentPage,
  rowsPerPage,
  indexOfFirstPost,
  indexOfLastPost,
  isLoading,
  isApproveRequestModalOpened,
  isDeclineRequestModalOpened,
  setIsApproveRequestModalOpened,
  setIsDeclineRequestModalOpened,
  setSelectedCompanyRequest,
}: IProps) {
  // Functions and States
  const { searchedValue }: any = useContext(SearchContext);
  // States
  const [companyRequestsDateFilter, setCompanyRequestsDateFilter] =
    useState("");
  // Functions
  const paginateForCompanyRequests = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  //   handle Filter Table
  const handleFilterTable = function () {
    if (!companyRequests || isEmpty(companyRequests)) return false;

    const companyRequestsData = companyRequests?.filter((request) =>
      request.companyName.toLowerCase().includes(searchedValue.toLowerCase())
    );

    setAllCompaniesRequests(companyRequests);
    // Update table state to company requests rows
    if (!allCompaniesRequests || !companyRequestsData) return false;
    const currentRows = companyRequestsData.slice(
      indexOfFirstPost,
      indexOfLastPost
    );

    setCurrentCompanyRequestPosts(currentRows);
  };

  useEffect(() => {
    handleFilterTable();
    setCurrentPage(1);
  }, [searchedValue]);

  return (
    <div className="company-requests-table-section--inner">
      {/* Company Requests Table Filter Wrapper */}
      <div className="requests--table-filter-option-wrapper">
        <div className="requests--table-filter-row-title">Filter:</div>
        {/* Filter by companys' request application date dropdown */}
        <div className="requests--date-filter-form-group form-group">
          <DropdownInput
            className={"company-request-date-filter-dropdown-input"}
            id={"company-request-date-filter-input"}
            label={""}
            required={true}
            optionArray={[
              { key: "", value: "Date", default: "Default" },
              {
                key: "yesterday",
                value: "Yesterday",
              },
            ]}
            value={companyRequestsDateFilter}
            onChange={(e) => setCompanyRequestsDateFilter(e.target.value)}
          />
        </div>
      </div>

      {/* All Company Requests Table Container */}
      <div className="primary-table-container">
        <Table responsive="lg" className="primary-table">
          <thead
            style={{
              textAlign: "left",
            }}
          >
            <tr>
              {[
                "COMPANY NAME",
                "EMAIL",
                "PHONE NUMBER",
                "COMPANY CODE",
                "",
                "",
              ].map((COLUMN, index) => (
                <th key={index + 1}>{COLUMN}</th>
              ))}
            </tr>
          </thead>

          <tbody>
            {currentCompanyRequestPosts?.map((company, i) => (
              <tr key={i + 1}>
                {/* Company Name Column */}
                <td className="td-name">
                  <div className="td-name--wrapper">{company.companyName}</div>
                </td>

                {/*  Company Email Column */}
                <td className="td-email">
                  <div className="td-email--wrapper">{company.email}</div>
                </td>

                {/*  Merchant Phone number Column */}
                <td className="td-phone-number">
                  <div className="td-phone-number--wrapper">
                    {company.phoneNumber}
                  </div>
                </td>

                {/*  Cac Document Column */}
                <td className="td-company-code">
                  <div className="td-company-code--wrapper">
                    {company.companyCode}
                  </div>
                </td>

                {/* Decline Request Btn Column*/}
                <td className="td-decline-request">
                  {!company.isRejected ? (
                    <div className="td-decline-request--wrapper">
                      <button
                        onClick={() => {
                          setIsDeclineRequestModalOpened(true);
                          setSelectedCompanyRequest(company);
                        }}
                      >
                        <img src={closeIcon} alt="" />
                        Decline
                      </button>
                    </div>
                  ) : (
                    <div className="item-rejected-or-declined-wrapper">
                      Declined
                    </div>
                  )}
                </td>

                {/* Approve Request Btn Column*/}
                <td className="td-approve-request">
                  <div className="td-approve-request--wrapper">
                    <button
                      onClick={() => {
                        setIsApproveRequestModalOpened(true);
                        setSelectedCompanyRequest(company);
                      }}
                    >
                      <img src={tickIcon} alt="" />
                      Approve
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div className="below-table-loader-wrapper">
          {isLoading && <LoadingSpinner />}
        </div>
      </div>

      {allCompaniesRequests && !isEmpty(currentCompanyRequestPosts) && (
        <FEPagination
          paginate={paginateForCompanyRequests}
          rowsPerPage={rowsPerPage}
          totalPosts={allCompaniesRequests.length}
          currentPage={currentPage}
        />
      )}
    </div>
  );
};

export default CompanyRequestsTable;

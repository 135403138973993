import { MouseEvent, useEffect, useState } from "react";
import "./UpdateFreeTrialModal.css";
import LoadingSpinner from "../../../components/loading-spinner/LoadingSpinner";
import TextInput from "../../../components/input-components/text-input/TextInput";
import {
  ISingleCompany,
  SetErrorHandlerType,
  SetSuccessHandlerType,
} from "../../../Types";
import { useAppDispatch } from "../../../hooks";
import { updateFreeTrial } from "../../../redux/actions/companyActions";
import moment from "moment";

// Interfaces
interface IProps {
  isModalOpened: boolean;
  selectedCompanyForPreview: ISingleCompany;
  setIsModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
  setErrorHandlerObj: SetErrorHandlerType;
  setSuccessHandlerObj: SetSuccessHandlerType;
  setIsPreviewedEmployerUpdated: React.Dispatch<React.SetStateAction<boolean>>;
}

interface IUpdateFreeTrialData {
  isEnabled: boolean;
  trailExpiryDate: string;
}

function UpdateFreeTrialModal({
  isModalOpened,
  selectedCompanyForPreview,
  setIsModalOpened,
  setErrorHandlerObj,
  setSuccessHandlerObj,
  setIsPreviewedEmployerUpdated,
}: IProps) {
  // Functions, States and Variables
  const dispatch = useAppDispatch();
  // States
  const [isLoading, setIsLoading] = useState(false);
  const [isEnabled, setIsEnabled] = useState(false);
  const [expiryDate, setExpiryDate] = useState("");

  // Functions
  const handleAPICall = (data: IUpdateFreeTrialData) =>
    dispatch(
      updateFreeTrial(
        selectedCompanyForPreview._id,
        data,
        setIsLoading,
        setErrorHandlerObj,
        setSuccessHandlerObj,
        setIsPreviewedEmployerUpdated,
        setIsModalOpened
      )
    );
  const handleUpdateFreeTrial = function (e: MouseEvent<HTMLButtonElement>) {
    const data = {
      isEnabled,
      trailExpiryDate: expiryDate,
    };
    if (isEnabled && expiryDate !== "") {
      e.preventDefault();
      handleAPICall(data);
    } else if (!isEnabled) {
      e.preventDefault();
      handleAPICall(data);
    }
  };

  useEffect(() => {
    if (isModalOpened && selectedCompanyForPreview.freeTrail) {
      setIsEnabled(selectedCompanyForPreview.freeTrail.isEnabled);
      setExpiryDate(
        moment(selectedCompanyForPreview.freeTrail.trailExpiryDate).format(
          "YYYY-MM-DD"
        )
      );
    }
    // Cleanup function to clear states
    return () => {
      setIsEnabled(false);
      setExpiryDate(expiryDate);
    };
  }, [isModalOpened]);

  return (
    <div
      className={`update-free-trial-modal-container ${
        isModalOpened ? "" : "none"
      }`}
    >
      <div className="update-free-trial-modal-container--inner">
        <div className="modal-header-text">Update Free Trial</div>
        <form>
          <div className="is-enabled-checkbox-wrapper">
            <input
              type="checkbox"
              id={`is-enabled-input`}
              checked={isEnabled}
              onChange={(e) => setIsEnabled(e.target.checked)}
            />
            <label htmlFor={`is-enabled-input`}>Is Free Trial Enabled</label>
          </div>

          {isEnabled && (
            <div className="form-group">
              <TextInput
                type={"date"}
                className={"deals-create-edit-input"}
                value={expiryDate}
                onChange={(e) => setExpiryDate(e.target.value)}
                required={isEnabled}
                label={"Expiry Date"}
              />
            </div>
          )}

          {/* Conffirm Action Button */}
          <div className="confirm-modal-action-button modal__update-free-trial-button">
            <button onClick={(e) => handleUpdateFreeTrial(e)}>Update</button>
            {isLoading && <LoadingSpinner />}
          </div>
        </form>
      </div>
    </div>
  );
}

export default UpdateFreeTrialModal;

import { useState } from "react";
import "./SelectVoucherForDeal.css";
import useDisplayMessage from "../../../../hooks/useDisplayMessage";
import Top from "../../../../components/app-top/Top";
import Sidebar from "../../../../components/app-sidebar/Sidebar";
import Main from "../../../../components/layout/main-container/Main";
import ErrorHandler from "../../../../components/error/ErrorHandler";
import VouchersTable from "../../../products/vouchers-table/VouchersTable";
import { useNavigate } from "react-router-dom";
import PrimaryBackButton from "../../../../components/primary-back-button/PrimaryBackButton";

function SelectVoucherForDeal() {
  // Functions, States and Variables
  const navigate = useNavigate();
  //   States
  const [isLoading, setIsLoading] = useState(false);
  //   Hooks
  // Handles Display the error and success message and scrolls to top
  const {
    errorHandlerObj,
    setErrorHandlerObj,
    successHandlerObj,
    setSuccessHandlerObj,
  } = useDisplayMessage();

  return (
    <div className="app-page-container">
      <Top />
      <Sidebar activeName={"Voucher Deals"} />

      <Main>
        {/* Error handler component */}
        <ErrorHandler
          errorHandlerObj={errorHandlerObj}
          className="app--actions-handler-wrapper"
        />

        <section className={`app-main-section--inner`}>
          {/* Select Vouchers Top Section */}
          <div className="select-voucher-for-deal--top-wrapper">
            <div className="primary-back-button-container">
              {/* Back button Wrapper */}
              <PrimaryBackButton
                onClick={() => {
                  navigate(-1);
                }}
              />
              {/* Top Title */}
              <div className="primary-back-button-title">Select Voucher</div>
            </div>
          </div>

          <VouchersTable
            isLoading={isLoading}
            fromSelectVoucherForDeal={true}
            setIsLoading={setIsLoading}
            setErrorHandlerObj={setErrorHandlerObj}
            setSuccessHandlerObj={setSuccessHandlerObj}
          />
        </section>
      </Main>
    </div>
  );
}

export default SelectVoucherForDeal;

import React, { ChangeEvent, MouseEvent, useEffect, useState } from "react";
import "./CourseDetailsForm.css";
import TextInput from "../../../../../components/input-components/text-input/TextInput";
import uploadIcon from "../../../../../images/upload-icon-secondary.svg";
import PrimaryButton from "../../../../../components/buttons/primary-button/PrimaryButton";
import LoadingSpinner from "../../../../../components/loading-spinner/LoadingSpinner";
import {
  ICourseCategory,
  ICourseDetailsFormData,
  IDropdownOptions,
  SetErrorHandlerType,
} from "../../../../../Types";
import { handleUploadImage } from "../../../../../utils/handleUploadImage";
import { truncateString } from "../../../../../utils/truncateString";
import { useAppDispatch, useAppSelector } from "../../../../../hooks";
import { getCourseCategories } from "../../../../../redux/actions/wellnessActions";
import DropdownInput from "../../../../../components/input-components/dropdown-input/DropdownInput";

// Interfaces
interface IProps {
  isLoading: boolean;
  courseDetailsInputStates: ICourseDetailsFormData;
  setCourseDetailsInputStates: React.Dispatch<
    React.SetStateAction<ICourseDetailsFormData>
  >;
  setErrorHandlerObj: SetErrorHandlerType;
  handleChangePageToCourseContents: (e: MouseEvent<HTMLButtonElement>) => void;
}

function CourseDetailsForm({
  isLoading,
  courseDetailsInputStates,
  setCourseDetailsInputStates,
  setErrorHandlerObj,
  handleChangePageToCourseContents: handleCreateOrUpdateCourse,
}: IProps) {
  // Functions, States and variables
  const dispatch = useAppDispatch();
  const courseCategories: ICourseCategory[] | [] = useAppSelector(
    (state) => state.wellness.courseCategories
  );
  // States
  const [isCourseImageUploadLoading, setIsCourseImageUploadLoading] =
    useState(false);
  const [courseImage, setCourseImage] = useState("");
  const [courseCategoryOptions, setCourseCategoryOptions] = useState<
    IDropdownOptions[] | []
  >([]);
  const [isCategoryLoading, setIsCategoryLoading] = useState(false);

  // Functions
  const handleUploadCourseImage = function (e: ChangeEvent<HTMLInputElement>) {
    if (e.target.files) {
      setCourseImage(e.target.files[0].name);
    }
    handleUploadImage(
      e,
      setIsCourseImageUploadLoading,
      setErrorHandlerObj,
      handleUpdateCourseImageData
    );
  };

  //   Handle Update Course Details Input State
  const handleUpdateCourseDetailsInputState = function (
    fieldName: string,
    fieldValue: string
  ) {
    const updatedCourseDetailsFormObject: ICourseDetailsFormData = {
      ...courseDetailsInputStates,
      [fieldName]: fieldValue,
    };
    console.log(updatedCourseDetailsFormObject);
    setCourseDetailsInputStates(updatedCourseDetailsFormObject);
  };

  // Handle Update Course Image Data
  const handleUpdateCourseImageData = function (data: string) {
    handleUpdateCourseDetailsInputState("courseImage", data);
  };

  // UseEffects
  useEffect(() => {
    dispatch(getCourseCategories(setIsCategoryLoading, setErrorHandlerObj));
  }, []);

  useEffect(() => {
    if (courseCategories) {
      const categoryOptions: IDropdownOptions[] = courseCategories?.map(
        (category) => {
          return {
            key: category._id,
            value: category.name,
          };
        }
      );
      categoryOptions.unshift({ key: "", value: "", default: "default" });
      setCourseCategoryOptions(categoryOptions);
    }
  }, [courseCategories]);

  return (
    <section className="course-details-form-section course---form-section">
      <div className="form-section-title-label">Course Information</div>

      <div className="course-details--form-wrapper course-details--form-wrapper--grid">
        {/* Course Details form group */}
        <div className="form-group">
          <TextInput
            type={"text"}
            className={"course-detail-input"}
            value={courseDetailsInputStates.courseName}
            onChange={(e) =>
              handleUpdateCourseDetailsInputState("courseName", e.target.value)
            }
            required={true}
            label={"Course Name"}
          />
        </div>

        {/* Course Price form group */}
        <div className="form-group">
          <TextInput
            type={"number"}
            className={"course-detail-input"}
            value={courseDetailsInputStates.coursePrice}
            onChange={(e) => {
              handleUpdateCourseDetailsInputState(
                "coursePrice",
                e.target.value
              );
            }}
            required={true}
            label={"Course Price"}
            subLabel={"(in ₦)"}
          />
        </div>

        <div className="form-group">
          <DropdownInput
            id={"course-category-input"}
            className={"course-detail-input"}
            label={"Category"}
            required={true}
            optionArray={courseCategoryOptions}
            value={courseDetailsInputStates.courseCategoryId}
            onChange={(e) => {
              handleUpdateCourseDetailsInputState(
                "courseCategoryId",
                e.target.value
              );
            }}
          />
        </div>

        {/* Course Duration form group */}
        <div className="form-group">
          <TextInput
            type={"text"}
            className={"course-detail-input"}
            value={courseDetailsInputStates.courseDuration}
            onChange={(e) => {
              handleUpdateCourseDetailsInputState(
                "courseDuration",
                e.target.value
              );
            }}
            required={true}
            label={"Course Duration"}
            // subLabel={"(in hours)"}
          />
        </div>

        {/* Course Description form group */}
        <div className="form-group">
          <TextInput
            type={"text"}
            className={"course-detail-input"}
            value={courseDetailsInputStates.courseDescription}
            onChange={(e) => {
              handleUpdateCourseDetailsInputState(
                "courseDescription",
                e.target.value
              );
            }}
            required={true}
            label={"Course Description"}
          />
        </div>
      </div>

      {/* Course Image Form Wrapper */}
      <div className="course-image-form-wrapper">
        <div className="form-section-title-label">Course Image</div>

        <div className="course-file-form-group">
          <img src={uploadIcon} alt="" />

          <div className="input-title">
            {courseDetailsInputStates.courseImage ? "Change" : "Upload"} Course
            Image
          </div>
          <div className="input-sub-title">
            Drag and drop file here or browse your computer
          </div>

          <div className="browse-local-button-wrapper">
            <button>
              {courseDetailsInputStates.courseImage
                ? truncateString(
                    courseImage || courseDetailsInputStates.courseImage,
                    20
                  )
                : "Browse"}
            </button>
            {isCourseImageUploadLoading && <LoadingSpinner />}
          </div>

          <input
            type="file"
            accept="image/png, image/gif, image/jpeg"
            onChange={(e) => handleUploadCourseImage(e)}
            required
          />
        </div>
      </div>

      {/* Submit Button */}
      <div className="course-content-action-btn-container">
        <div className="primary-button-container">
          <PrimaryButton
            className="course-content-form--action-btn"
            placeholder="Course Content"
            onClick={(e) => handleCreateOrUpdateCourse(e)}
          />
          {isLoading && <LoadingSpinner />}
        </div>
      </div>
    </section>
  );
}

export default CourseDetailsForm;

import React, {
  ChangeEvent,
  KeyboardEvent,
  MouseEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import LoadingSpinner from "../../../components/loading-spinner/LoadingSpinner";
import { IAllRequests } from "../Withdrawals";
import TextInput from "../../../components/input-components/text-input/TextInput";
import "./OTPModal.css";
import { useAppDispatch } from "../../../hooks";
import { approveWithdrawalRequestWithOTP } from "../../../redux/actions/withdrawalRequestsActions";
import SubstituteLoadingSpinner from "../../../components/substitute-loading-spinner/SubstituteLoadingSpinner";
import axios from "axios";
import { api } from "../../../api/config";

interface IProps {
  setIsOTPModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
  isOTPModalOpened: boolean;
  selectedEmployeeRequest: IAllRequests | null;
  setSelectedEmployeeRequest: React.Dispatch<
    React.SetStateAction<IAllRequests | null>
  >;
  setIsApproved: React.Dispatch<React.SetStateAction<boolean>>;
  setErrorHandlerObj: React.Dispatch<
    React.SetStateAction<{
      hasError: boolean;
      message: string;
    }>
  >;
  setSuccessHandlerObj: React.Dispatch<
    React.SetStateAction<{
      isSuccess: boolean;
      message: string;
    }>
  >;
  pageContent: string;
  isApproveRequestLoading: boolean;
}

const OTPModal: React.FC<IProps> = function ({
  isOTPModalOpened,
  setIsOTPModalOpened,
  selectedEmployeeRequest,
  setSelectedEmployeeRequest,
  setIsApproved,
  setErrorHandlerObj,
  setSuccessHandlerObj,
  pageContent,
  isApproveRequestLoading,
}) {
  // Functions, States and Variables
  const dispatch = useAppDispatch();
  let currentOTPIndex = 0;
  // States
  const [isOTPRequestLoading, setIsOTPRequestLoading] = useState(false);
  const [otp, setOtp] = useState<string[]>(["", "", "", "", "", ""]);
  const [activeOTPIndex, setActiveOTPIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isResendOTPSuccess, setIsResendOTPSuccess] = useState({
    isSuccess: false,
  });
  const [isResendOTPError, setIsResendOTPError] = useState({
    hasError: false,
  });
  // Refs
  const inputRef = useRef<HTMLInputElement>(null);

  // Function
  const handleConfirmApproveRequest = function (
    e: MouseEvent<HTMLButtonElement>
  ) {
    setErrorHandlerObj({ hasError: false, message: "" });
    setSuccessHandlerObj({ isSuccess: false, message: "" });
    setIsApproved(false);
    const data = {
      otp: otp.join(""),
    };
    console.log(data, otp);

    const isOTPArrEmpty = otp.some((otpValue) => otpValue === "");

    if (!isOTPArrEmpty) {
      e.preventDefault();
    }

    if (selectedEmployeeRequest) {
      dispatch(
        approveWithdrawalRequestWithOTP(
          selectedEmployeeRequest?._id,
          data,
          setIsOTPRequestLoading,
          setErrorHandlerObj,
          setSuccessHandlerObj,
          setIsApproved,
          setIsOTPModalOpened
        )
      );
    }
  };

  // Handle Resend OTP
  const handleResendOTP = function () {
    setIsResendOTPError({ hasError: false });
    setIsResendOTPSuccess({ isSuccess: false });

    const url = `${api}/api/v1/admin`;
    const token: any = localStorage.getItem("jwtToken");
    axios.defaults.headers.common["authorization"] = token;

    if (selectedEmployeeRequest) {
      setIsLoading(true);
      axios
        .patch(
          `${url}/withdrawal-request/approve-withdrawal-request/${selectedEmployeeRequest._id}`
        )
        .then((res) => {
          setIsLoading(false);
          setIsResendOTPSuccess({ isSuccess: true });
        })
        .catch((err) => {
          setIsLoading(false);
          // Set the error handler state
          setErrorHandlerObj({
            hasError: true,
            message:
              err?.response?.data ||
              "Something Went Wrong. Please Check your Connection and try again",
          });
        });
    }
  };
  // handle Update OTP Value
  const handleUpdateOTPValue = function (value: string, index: number) {
    currentOTPIndex = index;
    if (value && currentOTPIndex < 5) {
      setActiveOTPIndex(currentOTPIndex + 1);
    }
    console.log(activeOTPIndex);
    setOtp((prevOtp) => {
      if (!prevOtp) return prevOtp;
      const lastValue = value.substring(value.length - 1);
      const newOtpArr = [...prevOtp];
      console.log(currentOTPIndex);
      if (currentOTPIndex > -1) {
        newOtpArr[currentOTPIndex] = lastValue;
      }
      return newOtpArr;
    });
  };

  // handle keydown on OTP Input
  const handleKeyDownOnOTPInput = function (
    e: KeyboardEvent<HTMLInputElement>,
    index: number
  ) {
    const { key } = e;
    currentOTPIndex = index;
    if (key === "Backspace" && !Boolean(e.currentTarget.value)) {
      setActiveOTPIndex(currentOTPIndex - 1);
      currentOTPIndex = currentOTPIndex - 1;
    }
  };

  // UseEffects
  useEffect(() => {
    inputRef.current?.focus();
  }, [isOTPModalOpened, activeOTPIndex]);

  useEffect(() => {
    if (!isOTPModalOpened) setSelectedEmployeeRequest(null);
  }, [isOTPModalOpened]);

  useEffect(() => {
    if (isResendOTPError.hasError) setIsOTPModalOpened(false);
  }, [isResendOTPError]);
  return (
    <div
      className={`otp-modal-container modal-container ${
        isOTPModalOpened ? "" : "none"
      }`}
    >
      <div className="modal--resend-otp-button-wrapper">
        <button onClick={() => handleResendOTP()}>Resend OTP</button>
        {isResendOTPSuccess.isSuccess ? (
          <div className="resend-otp-success-wrapper">✅ OTP sent</div>
        ) : // ) : isResendOTPError.hasError ? (
        //   <div className="resend-otp-error-wrapper">❌ try again</div>
        isLoading ? (
          <div className="upload-loading-spinner-wrapper"></div>
        ) : (
          ""
        )}
      </div>

      <div className="otp-modal-container--inner modal-container--inner">
        <div className="otp-modal-header-text modal-header-text">
          Kindly verify OTP
          {isOTPRequestLoading && <SubstituteLoadingSpinner />}
        </div>
        <div className="otp-modal-sub-text modal-sub-text">
          Kindly enter the OTP sent to your mail to approve the request of{" "}
          <span>
            {selectedEmployeeRequest?.staffId?.firstName}{" "}
            {selectedEmployeeRequest?.staffId?.lastName}
          </span>{" "}
        </div>

        <form>
          {/* OTP Input Wrapper */}
          <div className="otp-input-wrapper">
            {otp?.map((otpValue, index) => (
              <div key={index + 1} className="form-group otp-input-form-group">
                <input
                  ref={index === activeOTPIndex ? inputRef : null}
                  type="number"
                  value={otpValue}
                  onChange={(e) => handleUpdateOTPValue(e.target.value, index)}
                  onKeyDown={(e) => handleKeyDownOnOTPInput(e, index)}
                  required
                />
              </div>
            ))}
          </div>

          <div className="modal-action-button-wrapper">
            {/* Cancel Modal Button */}
            <div className="cancel-modal-button-wrapper">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setIsOTPModalOpened(false);
                }}
              >
                Cancel
              </button>
            </div>
            {/* Conffirm Action Button */}
            <div className="confirm-modal-action-button">
              <button onClick={(e) => handleConfirmApproveRequest(e)}>
                Confirm
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default OTPModal;

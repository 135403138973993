import React, { useContext, useEffect, useState } from "react";
import "./Settlements.css";
import DropdownInput from "../../components/input-components/dropdown-input/DropdownInput";
import FEPagination from "../../components/pagination/fe-pagination/FEPagination";
import SettlementPreview from "./settlement-preview/SettlementPreview";
import {
  ISearchContextType,
  SearchContext,
} from "../../components/contexts/SearchContext";
import isEmpty from "../../validation/isEmpty";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getAllCompanySettlements } from "../../redux/actions/settlementsActions";
import ErrorHandler from "../../components/error/ErrorHandler";
import SettlementTable from "./settlement-table/SettlementTable";
import Top from "../../components/app-top/Top";
import Sidebar from "../../components/app-sidebar/Sidebar";
import SuccessHandler from "../../components/success/SuccessHandler";
import SubstituteLoadingSpinner from "../../components/substitute-loading-spinner/SubstituteLoadingSpinner";
import useDisplayMessage from "../../hooks/useDisplayMessage";
import Main from "../../components/layout/main-container/Main";
import { ISingleSettlement } from "../../Types";

function Settlements() {
  //Function, States and Variables
  const settlements: ISingleSettlement[] | [] = useAppSelector(
    (state) => state.settlements.settlements
  );
  const { searchedValue }: ISearchContextType | any = useContext(SearchContext);
  const dispatch = useAppDispatch();
  // States
  const [tableFilterOption, setTableFilterOption] = useState("all");
  const [allSettlements, setAllSettlements] = useState<
    ISingleSettlement[] | null
  >(null);
  const [selectedSettlementsForPreview, setSelectedSettlementsForPreview] =
    useState<ISingleSettlement | null>(null);
  const [pageContent, setPageContent] = useState("main");
  const [settlementsDateFilter, setSettlementsDateFilter] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [currentPosts, setCurrentPosts] = useState<ISingleSettlement[] | null>(
    null
  );

  const indexOfLastPost = currentPage * rowsPerPage;
  const indexOfFirstPost = indexOfLastPost - rowsPerPage;
  const [isLoading, setIsLoading] = useState(false);
  const [isMarkSettlementsLoading, setIsMarkSettlementsLoading] =
    useState(false);
  // Functions
  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  // handle Change Filter Option To All
  const handleChangeFilterOptionToAll = function () {
    setTableFilterOption("all");

    // Update settlement state
    let filteredSettlement = settlements?.filter((settlement) =>
      settlement.invoiceNumber
        .toLowerCase()
        .includes(searchedValue.toLowerCase())
    );
    setAllSettlements(filteredSettlement);
  };
  // handle Change Filter Option To Pending
  const handleChangeFilterOptionToPending = function () {
    setTableFilterOption("pending");

    // Update settlement state
    setAllSettlements((prevSettlements) => {
      if (!prevSettlements) return prevSettlements;

      let filteredSettlement = settlements
        ?.filter((settlement) => settlement.status === 1)
        ?.filter((settlement) =>
          settlement.invoiceNumber
            .toLowerCase()
            .includes(searchedValue.toLowerCase())
        );
      return filteredSettlement;
    });
  };
  // handle Change Filter Option To Completed
  const handleChangeFilterOptionToCompleted = function () {
    setTableFilterOption("completed");

    // Update settlement state
    setAllSettlements((prevSettlements) => {
      if (!prevSettlements) return prevSettlements;

      let filteredSettlement = settlements
        ?.filter((settlement) => settlement.status === 2)
        ?.filter((settlement) =>
          settlement.invoiceNumber
            .toLowerCase()
            .includes(searchedValue.toLowerCase())
        );
      return filteredSettlement;
    });
  };

  // Hooks
  const {
    errorHandlerObj,
    setErrorHandlerObj,
    successHandlerObj,
    setSuccessHandlerObj,
  } = useDisplayMessage();

  // If response is success, remove failure message
  useEffect(() => {
    if (successHandlerObj.isSuccess)
      dispatch(getAllCompanySettlements(setIsLoading, setErrorHandlerObj));
  }, [successHandlerObj]);

  useEffect(() => {
    dispatch(getAllCompanySettlements(setIsLoading, setErrorHandlerObj));
  }, []);

  useEffect(() => {
    let allNewSettlements = settlements?.filter((settlement) =>
      settlement.invoiceNumber
        .toLowerCase()
        .includes(searchedValue.toLowerCase())
    );
    setAllSettlements(allNewSettlements);
  }, [settlements, searchedValue]);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [pageContent]);

  useEffect(() => {
    if (allSettlements) {
      const currentRows = allSettlements?.slice(
        indexOfFirstPost,
        indexOfLastPost
      );

      setCurrentPosts(currentRows);
    }
  }, [allSettlements, currentPage]);

  useEffect(() => {
    setCurrentPage(1);
  }, [tableFilterOption]);

  return (
    <div className="app-page-container">
      <Top />
      <Sidebar activeName={"Settlements"} />

      <Main>
        {/* Error handler component */}
        <ErrorHandler
          errorHandlerObj={errorHandlerObj}
          className="app--actions-handler-wrapper"
        />
        {/* Success handler component */}
        <SuccessHandler
          successHandlerObj={successHandlerObj}
          className="app--actions-handler-wrapper"
        />

        <section
          className={`app-main-section--inner ${
            pageContent === "main" ? "" : "none"
          }`}
        >
          <div className="settlements-top-wrapper">
            {/* seettlements filter section */}
            <div className="settlements-filter-container">
              {/* All filter option */}
              <div
                className={`settlements-filter-option filter-option--all ${
                  tableFilterOption === "all" ? "active-filter-option" : ""
                }`}
                onClick={() => handleChangeFilterOptionToAll()}
              >
                All
              </div>
              {/* Pending filter option */}
              <div
                className={`settlements-filter-option filter-option--pending ${
                  tableFilterOption === "pending" ? "active-filter-option" : ""
                }`}
                onClick={() => handleChangeFilterOptionToPending()}
              >
                Pending
              </div>
              {/* Completed filter option */}
              <div
                className={`settlements-filter-option filter-option--completed ${
                  tableFilterOption === "completed"
                    ? "active-filter-option"
                    : ""
                }`}
                onClick={() => handleChangeFilterOptionToCompleted()}
              >
                Completed
              </div>
            </div>
          </div>

          {/* If Settlements is marked as paid */}
          {isMarkSettlementsLoading && (
            <div className="approve-withdrawal-request-loading-spinner-wrapper">
              {<SubstituteLoadingSpinner />}
            </div>
          )}

          {/* Settlements Table Container */}
          <div className="settlements-table-container">
            {/* Section Row One */}
            <div className="settlements-main-container--row-one settlements-top-row-section">
              {/* Settlements Filter Wrapper */}
              <div className="primary-table-filter-option-wrapper">
                <div className="primary-table-filter-row-title">Filter:</div>
                {/* Filter by Settlements date dropdown */}
                <div className="settlements--date-filter-form-group form-group">
                  <DropdownInput
                    className={"settlements-date-filter-dropdown-input"}
                    id={"settlements-date-filter-input"}
                    label={""}
                    required={true}
                    optionArray={[
                      { key: "", value: "Date", default: "Default" },
                      {
                        key: "yesterday",
                        value: "Yesterday",
                      },
                    ]}
                    value={settlementsDateFilter}
                    onChange={(e) => setSettlementsDateFilter(e.target.value)}
                  />
                </div>
              </div>
            </div>

            {/* Table Row */}
            <div className="settlements-main-container--row-two settlements-table-row">
              <SettlementTable
                currentPosts={currentPosts}
                isLoading={isLoading}
                setPageContent={setPageContent}
                setSelectedSettlementsForPreview={
                  setSelectedSettlementsForPreview
                }
                setIsMarkSettlementsLoading={setIsMarkSettlementsLoading}
                setErrorHandlerObj={setErrorHandlerObj}
                setSuccessHandlerObj={setSuccessHandlerObj}
              />
            </div>
          </div>

          {allSettlements && !isEmpty(allSettlements) && (
            <FEPagination
              paginate={paginate}
              rowsPerPage={rowsPerPage}
              totalPosts={allSettlements.length}
              currentPage={currentPage}
            />
          )}
        </section>

        <section
          className={`settlement-preview-container ${
            pageContent === "preview" ? "" : "none"
          }`}
        >
          {selectedSettlementsForPreview && (
            <SettlementPreview
              selectedSettlementInvoiceNumber={
                selectedSettlementsForPreview.invoiceNumber
              }
              selectedSettlementItenerary={
                selectedSettlementsForPreview.invoiceData.products
              }
              setPageContent={setPageContent}
            />
          )}
        </section>
      </Main>
    </div>
  );
}

export default Settlements;

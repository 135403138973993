import axios from "axios";
import { GET_ALL_SUBSCRIPTION_PLANS } from "../Constants";
import { api } from "../../api/config";
import { SetErrorHandlerType, SetIsLoadingType } from "../../Types";
import { handleAPIError } from "../../utils/handleAPIError";
import { NavigateFunction } from "react-router-dom";

// Interfaces
interface ICreatePlan {
  name: string;
  cost: number;
  details: string;
}

const url = `${api}/api/v1/admin`;

const token: any = localStorage.getItem("jwtToken");
axios.defaults.headers.common["authorization"] = token;

// Get All Subscription Plans
export const getAllSubscriptionPlans =
  (setIsLoading: SetIsLoadingType, setErrorHandlerObj: SetErrorHandlerType) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .get(`${api}/api/v1/plans`)
      .then((res) => {
        dispatch({
          type: GET_ALL_SUBSCRIPTION_PLANS,
          payload: res.data.data,
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Create A Subscription Plan
export const createSubscriptionPlan =
  (
    data: ICreatePlan,
    setIsLoading: SetIsLoadingType,
    setErrorHandlerObj: SetErrorHandlerType,
    navigate: NavigateFunction
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .post(`${url}/plans`, data)
      .then((res) => {
        // Navigate to subscriptions page
        navigate("/admin/subscriptions", {
          state: { message: "Plan has been created successfully!" },
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Update A Subscription Plan
export const updateSubscriptionPlan =
  (
    planId: string,
    data: ICreatePlan,
    setIsLoading: SetIsLoadingType,
    setErrorHandlerObj: SetErrorHandlerType,
    navigate: NavigateFunction
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .patch(`${url}/plans/${planId}`, data)
      .then((res) => {
        // Navigate to subscriptions page
        navigate("/admin/subscriptions", {
          state: { message: "Plan has been updated successfully!" },
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

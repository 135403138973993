import moment from "moment";
import { IDeal } from "../../../../../Types";
import { Table } from "react-bootstrap";
import FEPagination from "../../../../../components/pagination/fe-pagination/FEPagination";
import isEmpty from "../../../../../validation/isEmpty";
import LoadingSpinner from "../../../../../components/loading-spinner/LoadingSpinner";
import pencilIcon from "../../../../../images/pencil-icon.svg";
import { Link } from "react-router-dom";

// Interface
interface IProps {
  allDeals: IDeal[] | null;
  currentDealsPosts: IDeal[] | null;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  rowsPerPage: number;
  indexOfLastPost: number;
  indexOfFirstPost: number;
  isLoading: boolean;
}

function DealsTable({
  allDeals,
  currentDealsPosts,
  currentPage,
  setCurrentPage,
  rowsPerPage,
  isLoading,
}: IProps) {
  // Functions and States

  // Functions
  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  return (
    <>
      {/* Users Table Container */}
      <div className="primary-table-container">
        <Table responsive="lg" className="primary-table">
          <thead
            style={{
              textAlign: "left",
            }}
          >
            <tr>
              {[
                "VOUCHER NAME",
                "CURRENCY",
                "AMOUNT",
                "MINIMUM AMOUNT",
                "MAXIMUM AMOUNT",
                "EXPIRY DATE",
                "",
              ].map((COLUMN, index) => (
                <th key={index + 1}>{COLUMN}</th>
              ))}
            </tr>
          </thead>

          <tbody>
            {currentDealsPosts?.map((deal, i) => (
              <tr key={i + 1}>
                {/* Vocuher Name Column */}
                <td className="td-primary">
                  <div className="td-primary--wrapper">
                    {deal?.voucher?.name || "NIL"}
                  </div>
                </td>

                {/* CURRENCY Column */}
                <td className="td-primary">
                  <div className="td-primary--wrapper">
                    {deal?.currency || "NGN"}
                  </div>
                </td>

                {/* Amount Column */}
                <td className="td-primary">
                  <div className="td-primary--wrapper">
                    {deal.amount?.toLocaleString()}
                  </div>
                </td>

                {/* Minimun Amount Column */}
                <td className="td-primary">
                  <div className="td-primary--wrapper">
                    {deal.voucher.minimum_value?.toLocaleString()}
                  </div>
                </td>

                {/* Maximum Amount Column */}
                <td className="td-primary">
                  <div className="td-primary--wrapper">
                    {deal.voucher.maximum_value?.toLocaleString()}
                  </div>
                </td>

                {/* Product Name Column */}
                {/* <td className="td-primary">
                  <div className="td-primary--wrapper">
                    {deal.categoryId.name}
                  </div>
                </td> */}

                {/* Product Name Column */}
                <td className="td-primary">
                  <div className="td-primary--wrapper">
                    {" "}
                    {moment(deal.expiryDate).format("Do MMM, YY")}
                  </div>
                </td>

                {/* Edit Deal Row Column */}
                <td className="td-edit-deal-row">
                  <div className="td-edit-deal-row--wrapper">
                    <Link
                      to={"/admin/deals/edit"}
                      state={{
                        deal,
                      }}
                    >
                      <img src={pencilIcon} alt="" />
                    </Link>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div className="below-table-loader-wrapper">
          {isLoading && <LoadingSpinner />}
        </div>
      </div>

      {allDeals && !isEmpty(currentDealsPosts) && (
        <FEPagination
          paginate={paginate}
          rowsPerPage={rowsPerPage}
          totalPosts={allDeals.length}
          currentPage={currentPage}
        />
      )}
    </>
  );
}

export default DealsTable;

import {
  GET_ALL_ORDERS,
  GET_ALL_ORDERS_AMOUNT,
  GET_ALL_ORDERS_PER_USER,
} from "../Constants";

const initialState = {
  orders: [],
  orderAmount: 0,
  ordersPerUser: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (
  state = initialState,
  action: { type?: string; payload?: any }
) {
  switch (action.type) {
    case GET_ALL_ORDERS:
      return {
        ...state,
        orders: action.payload,
      };
    case GET_ALL_ORDERS_AMOUNT:
      return {
        ...state,
        orderAmount: action.payload,
      };
    case GET_ALL_ORDERS_PER_USER:
      return {
        ...state,
        ordersPerUser: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
}

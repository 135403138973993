import React, { useState, MouseEvent } from "react";
import { useNavigate } from "react-router-dom";
import AuthNavbar from "../../components/auth-navbar/AuthNavbar";
import "./ForgotPassword.css";
import authWaveImage from "../../images/signin-wave-image.svg";
import TextInput from "../../components/input-components/text-input/TextInput";
import LoadingSpinner from "../../components/loading-spinner/LoadingSpinner";
import ErrorHandler from "../../components/error/ErrorHandler";
import { useAppDispatch } from "../../hooks";
import { forgotPassword } from "../../redux/actions/authActions";
import useDisplayMessage from "../../hooks/useDisplayMessage";
import SuccessHandler from "../../components/success/SuccessHandler";

function ForgotPassword() {
  // Functions, States and Variables
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  // Hooks
  const {
    errorHandlerObj,
    setErrorHandlerObj,
    successHandlerObj,
    setSuccessHandlerObj,
  } = useDisplayMessage();

  // States
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // Functions
  const handleSubmitFormDetails = function (e: MouseEvent<HTMLButtonElement>) {
    setErrorHandlerObj({ hasError: false, message: "" });
    if (email !== "") {
      e.preventDefault();

      const data = {
        email,
      };

      dispatch(
        forgotPassword(
          data,
          setErrorHandlerObj,
          setSuccessHandlerObj,
          setIsLoading,
          navigate
        )
      );
    }
  };

  return (
    <div className="signin-container">
      <AuthNavbar />

      {/* ForgotPassword container--inner  */}
      <div className="signin-container--inner">
        {/* ForgotPassword Left section  / Auth Left Section*/}
        <section className="auth-left-section signin-left-section">
          {/* ForgotPassword left section text section */}
          <div className="signin-left-section-text-wrapper">
            Make sales and your money is ensured
          </div>

          {/* Auth Left Image Wrapper */}
          <div className="left-auth-section-image-wrapper">
            <img src={authWaveImage} alt="" />
          </div>
        </section>

        {/* ForgotPassword Right section  / Auth Right Section*/}
        <section className="auth-right-section signin-right-section">
          <div className="signin-right-section--inner">
            {/* Error handler component */}
            <ErrorHandler
              errorHandlerObj={errorHandlerObj}
              className="auth-message-wrapper"
            />
            <SuccessHandler
              successHandlerObj={successHandlerObj}
              className="auth-message-wrapper"
            />

            {/* ForgotPassword right section header */}
            <div className="signin-right-section-header">
              {/* ForgotPassword right section header title */}
              <div className="signin-right-section-header-title">
                Forgot password
              </div>

              <div className="forgot-password-header-sub-title">
                Input your account email to get a reset password link!
              </div>
            </div>

            {/* ForgotPassword main form section */}
            <div className="signin-main-form-section">
              <form>
                {/* Email form group */}
                <div className="signin-email-form-group form-group">
                  <TextInput
                    type={"email"}
                    id={"signin-email-text-input"}
                    className={"signin-email-input"}
                    placeholder={""}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required={true}
                    label={"Email"}
                  />
                </div>

                {/* Submit form button wrapper */}
                <div className="log-in-button-wrapper">
                  {isLoading && <LoadingSpinner />}
                  <button onClick={handleSubmitFormDetails}>Submit</button>
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default ForgotPassword;

import { useContext, useEffect, useState } from "react";
import "./DealsTableSection.css";
import { IDeal } from "../../../../Types";
import { useNavigate } from "react-router-dom";
import { SearchContext } from "../../../../components/contexts/SearchContext";
import isEmpty from "../../../../validation/isEmpty";
import DealsTable from "./table/DealsTable";
import PrimaryButton from "../../../../components/buttons/primary-button/PrimaryButton";

interface IProps {
  deals: [] | IDeal[];
  isLoading: boolean;
}
function DealsTableSection({ deals, isLoading }: IProps) {
  // Functions, States and Variables
  const navigate = useNavigate();
  // States
  const { searchedValue }: any = useContext(SearchContext);
  const [allDeals, setAllDeals] = useState<IDeal[] | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [currentDealsPosts, setCurrentDealsPosts] = useState<IDeal[] | null>(
    null
  );
  const indexOfLastPost = currentPage * rowsPerPage;
  const indexOfFirstPost = indexOfLastPost - rowsPerPage;

  // Functions
  //   handle Filter Table
  const handleFilterTable = function () {
    if (!deals || isEmpty(deals)) return false;

    const allDealsData = deals?.filter(
      (deal) =>
        deal?.voucher?.name
          ?.toLowerCase()
          .includes(searchedValue.toLowerCase()) ||
        deal?.categoryId?.name
          .toLowerCase()
          .includes(searchedValue.toLowerCase())
    );
    setAllDeals(allDealsData);
    // Update table state to users rows
    if (!allDeals || !allDealsData) return false;
    const currentRows = allDealsData.slice(indexOfFirstPost, indexOfLastPost);
    setCurrentDealsPosts(currentRows);
  };

  const handleNavigateToAddNewDeal = function () {
    navigate("/admin/deals/create/select-voucher");
  };

  //   UseEffects
  useEffect(() => {
    if (deals) setAllDeals(deals);
  }, [deals]);

  useEffect(() => {
    if (allDeals) {
      const currentRows = allDeals?.slice(indexOfFirstPost, indexOfLastPost);
      setCurrentDealsPosts(currentRows);
    }
  }, [allDeals, currentPage]);

  useEffect(() => {
    handleFilterTable();
    setCurrentPage(1);
  }, [searchedValue]);

  return (
    <section className={`users-table-section`}>
      {/* Deals Table Top Wrapper */}
      <div className="deals-table--top-wrapper">
        {/* Add New category Button Wrapper */}
        <PrimaryButton
          className="add-new-course-btn-wrapper"
          placeholder="Add New Deal"
          onClick={() => {
            handleNavigateToAddNewDeal();
          }}
        />
      </div>

      {/* Users Table Container */}
      <section className={`users-table-wrapper`}>
        <DealsTable
          allDeals={allDeals}
          currentDealsPosts={currentDealsPosts}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          rowsPerPage={rowsPerPage}
          indexOfFirstPost={indexOfFirstPost}
          indexOfLastPost={indexOfLastPost}
          isLoading={isLoading}
        />
      </section>
    </section>
  );
}

export default DealsTableSection;

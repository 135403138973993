import React, { useContext, useEffect, useState } from "react";
import "./ProductCategoriesTable.css";
import { Table } from "react-bootstrap";
import DropdownInput from "../../../components/input-components/dropdown-input/DropdownInput";
import FEPagination from "../../../components/pagination/fe-pagination/FEPagination";
import isEmpty from "../../../validation/isEmpty";
import LoadingSpinner from "../../../components/loading-spinner/LoadingSpinner";
import { SearchContext } from "../../../components/contexts/SearchContext";
import { ICategories } from "../Products";
import moment from "moment";
import pencilIcon from "../../../images/pencil-icon.svg";

interface IProps {
  categories: [] | ICategories[];
  allCategories: ICategories[] | null;
  setAllCategories: React.Dispatch<React.SetStateAction<ICategories[] | null>>;
  currentCategoriesPosts: ICategories[] | null; // currentPage: number;
  setCurrentCategoriesPosts: React.Dispatch<
    React.SetStateAction<ICategories[] | null>
  >;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  rowsPerPage: number;
  indexOfFirstPost: number;
  indexOfLastPost: number;
  isLoading: boolean;
  isCreateEditProductActionModalOpened: boolean;
  setIsCreateEditProductActionModalOpened: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setModalActionType: React.Dispatch<React.SetStateAction<string>>;
  setSelectedCategoryForEdit: React.Dispatch<
    React.SetStateAction<ICategories | null>
  >;
}

const ProductCategoriesTable: React.FC<IProps> = function ({
  categories,
  allCategories,
  setAllCategories,
  currentCategoriesPosts,
  setCurrentCategoriesPosts,
  currentPage,
  setCurrentPage,
  rowsPerPage,
  indexOfFirstPost,
  indexOfLastPost,
  isLoading,
  isCreateEditProductActionModalOpened,
  setIsCreateEditProductActionModalOpened,
  setModalActionType,
  setSelectedCategoryForEdit,
}) {
  // Functions and States
  const { searchedValue }: any = useContext(SearchContext);
  // States
  const [merchantRequestsDateFilter, setMerchantRequestsDateFilter] =
    useState("");
  // Functions
  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  //   handle Filter Table
  const handleFilterTable = function () {
    if (!categories || isEmpty(categories)) return false;

    const categoriesData = categories?.filter((category) =>
      category.name.toLowerCase().includes(searchedValue.toLowerCase())
    );

    setAllCategories(categoriesData);

    // Update table state to categories rows
    if (!allCategories || !categoriesData) return false;
    const currentRows = categoriesData.slice(indexOfFirstPost, indexOfLastPost);

    setCurrentCategoriesPosts(currentRows);
  };

  useEffect(() => {
    handleFilterTable();
    setCurrentPage(1);
  }, [searchedValue]);

  useEffect(() => {
    // On Close of Modal
    if (!isCreateEditProductActionModalOpened) {
      setSelectedCategoryForEdit(null);
      setModalActionType("create");
    }
  }, [isCreateEditProductActionModalOpened]);

  return (
    <div className="product-categories-table-section--inner">
      {/* Table Section Top Wrapper */}
      <div className="product-categories-table--top-wrapper">
        {/* Product Categories Table Filter Wrapper */}
        <div className="primary-table-filter-option-wrapper">
          <div className="primary-table-filter-row-title">Filter:</div>
          {/* Filter by product categories creation date dropdown */}
          <div className="form-group">
            <DropdownInput
              className={"categories-date-filter-dropdown-input"}
              id={"categories-date-filter-input"}
              label={""}
              required={true}
              optionArray={[
                { key: "", value: "Date", default: "Default" },
                {
                  key: "yesterday",
                  value: "Yesterday",
                },
              ]}
              value={merchantRequestsDateFilter}
              onChange={(e) => setMerchantRequestsDateFilter(e.target.value)}
            />
          </div>
        </div>

        {/* Add New category Button Wrapper */}
        <div className="add-new-category-button-wrapper">
          <button
            onClick={() => {
              setIsCreateEditProductActionModalOpened(true);
              setModalActionType("create");
            }}
          >
            Add New
          </button>
        </div>
      </div>

      {/* All Product Categories Table Container */}
      <div className="primary-table-container">
        <Table
          responsive="lg"
          className="primary-table product-categories-table"
        >
          <thead
            style={{
              textAlign: "left",
            }}
          >
            <tr>
              {["CATEGORY", "DATE CREATED", ""].map((COLUMN, index) => (
                <th key={index + 1}>{COLUMN}</th>
              ))}
            </tr>
          </thead>

          <tbody>
            {currentCategoriesPosts?.map((category, i) => (
              <tr key={i + 1}>
                {/* Category Name Column */}
                <td className="td-name">
                  <div className="td-name--wrapper">{category.name}</div>
                </td>

                {/*  Date Created Column */}
                <td className="td-date-created">
                  <div className="td-date-created--wrapper">
                    {moment(category.createdAt).format("DD, MMMM YYYY")}
                  </div>
                </td>

                {/* Edit Btn Column*/}
                <td className="td-edit-button">
                  <div className="td-edit-button--wrapper">
                    <button
                      onClick={() => {
                        setIsCreateEditProductActionModalOpened(true);
                        setSelectedCategoryForEdit(category);
                        setModalActionType("edit");
                      }}
                    >
                      <img src={pencilIcon} alt="" />
                      Edit
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div className="below-table-loader-wrapper">
          {isLoading && <LoadingSpinner />}
        </div>
      </div>

      {allCategories && !isEmpty(currentCategoriesPosts) && (
        <FEPagination
          paginate={paginate}
          rowsPerPage={rowsPerPage}
          totalPosts={allCategories.length}
          currentPage={currentPage}
        />
      )}
    </div>
  );
};

export default ProductCategoriesTable;

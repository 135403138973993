import { useContext, useEffect, useState } from "react";
import "./DealsTableSection.css";
import { IProductDeal } from "../../../../Types";
import { useNavigate } from "react-router-dom";
import { SearchContext } from "../../../../components/contexts/SearchContext";
import isEmpty from "../../../../validation/isEmpty";
import DealsTable from "./table/DealsTable";
import PrimaryButton from "../../../../components/buttons/primary-button/PrimaryButton";
import SecondaryButton from "../../../../components/buttons/secondary-button/SecondaryButton";

interface IProps {
  deals: [] | IProductDeal[];
  isLoading: boolean;
}
function DealsTableSection({ deals, isLoading }: IProps) {
  // Functions, States and Variables
  const navigate = useNavigate();
  // States
  const { searchedValue }: any = useContext(SearchContext);
  const [allDeals, setAllDeals] = useState<IProductDeal[] | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentDealsPosts, setCurrentDealsPosts] = useState<
    IProductDeal[] | null
  >(null);
  const indexOfLastPost = currentPage * rowsPerPage;
  const indexOfFirstPost = indexOfLastPost - rowsPerPage;

  // Functions
  //   handle Filter Table
  const handleFilterTable = function () {
    if (!deals || isEmpty(deals)) return false;

    const allDealsData = deals?.filter(
      (deal) =>
        deal?.productName
          ?.toLowerCase()
          .includes(searchedValue.toLowerCase()) ||
        deal?.merchantName.toLowerCase().includes(searchedValue.toLowerCase())
    );
    setAllDeals(allDealsData);
    // Update table state to users rows
    if (!allDeals || !allDealsData) return false;
    const currentRows = allDealsData.slice(indexOfFirstPost, indexOfLastPost);
    setCurrentDealsPosts(currentRows);
  };

  const handleNavigateToAddNewDeal = function () {
    navigate("/admin/product-deals/create");
  };

  const handleNavigateToPurchaseDeals = function () {
    navigate("/admin/purchase-deals");
  };

  //   UseEffects
  // useEffect(() => {
  //   if (deals) setAllDeals(deals);
  // }, [deals]);

  // useEffect(() => {
  //   if (allDeals) {
  //     const currentRows = allDeals?.slice(indexOfFirstPost, indexOfLastPost);
  //     setCurrentDealsPosts(currentRows);
  //   }
  // }, [allDeals, currentPage]);

  useEffect(() => {
    handleFilterTable();
  }, [deals, searchedValue, currentPage]);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchedValue]);

  return (
    <section className={`users-table-section`}>
      {/* Deals Table Top Wrapper */}
      <div className="deals-table--top-wrapper">
        {/* Add New category Button Wrapper */}
        <PrimaryButton
          className="deals--top-btn-wrapper"
          placeholder="Add New Deal"
          onClick={() => {
            handleNavigateToAddNewDeal();
          }}
        />
        {/* Purchase Deals Button Wrapper */}
        <SecondaryButton
          className="deals--top-btn-wrapper"
          placeholder="View Purchase Deals"
          onClick={() => {
            handleNavigateToPurchaseDeals();
          }}
        />
      </div>

      {/* Users Table Container */}
      <section className={`users-table-wrapper`}>
        <DealsTable
          allDeals={allDeals}
          currentDealsPosts={currentDealsPosts}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          rowsPerPage={rowsPerPage}
          indexOfFirstPost={indexOfFirstPost}
          indexOfLastPost={indexOfLastPost}
          isLoading={isLoading}
        />
      </section>
    </section>
  );
}

export default DealsTableSection;

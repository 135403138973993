import React from "react";
import PrimaryBackButton from "../../../../components/primary-back-button/PrimaryBackButton";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../../../components/buttons/primary-button/PrimaryButton";

interface IProps {
  setIsStoreCategoryModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
}

function CourseCategoryTop({ setIsStoreCategoryModalOpened }: IProps) {
  // Functions, States and Varibles
  const navigate = useNavigate();

  return (
    <div className="course-category--top-wrapper">
      <div className="primary-back-button-container">
        {/* Back button Wrapper */}
        <PrimaryBackButton
          onClick={() => {
            navigate("/admin/wellness");
          }}
        />
        {/* Top Title */}
        <div className="primary-back-button-title">Course Category</div>
      </div>

      <div className="course-category--top-action-btns-container">
        {/* Add New Catory Button */}
        <PrimaryButton
          className="add-new-course-btn-wrapper"
          placeholder="Add New Category"
          onClick={() => {
            setIsStoreCategoryModalOpened(true);
          }}
        />
      </div>
    </div>
  );
}

export default CourseCategoryTop;

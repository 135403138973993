import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AddNewCourse from "../add-new-course-setup/AddNewCourse";

function EditCourse() {
  // Functions, States and Variables
  const location = useLocation();
  const navigate = useNavigate();
  const [pageType, setPageType] = useState("");

  //   UseEffect
  useEffect(() => {
    if (location.state) {
      const { courseId }: any = location.state;
      if (courseId) {
        setPageType("edit");
      }
    } else {
      navigate("/admin/wellness");
    }
  }, [location]);

  return <>{pageType === "edit" && <AddNewCourse pageType={pageType} />}</>;
}

export default EditCourse;

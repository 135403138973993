import "./SubscriptionTop.css";
import PrimaryButton from "../../../../components/buttons/primary-button/PrimaryButton";
import { useNavigate } from "react-router-dom";

function SubscriptionTop() {
  // Functions, states and variables
  const navigate = useNavigate();
  return (
    <div className="subscriptions--top-wrapper">
      {/* Title */}
      <div className="subscriptions--title-wrapper">All Subscription Plans</div>

      <span className="subscriptions--top-action-btns-container">
        {/* Add New Plan Button */}
        <PrimaryButton
          className="add-new-plan-btn-wrapper"
          placeholder="Add New Plan"
          onClick={() => {
            navigate("/admin/subscriptions/add-new");
          }}
        />
      </span>
    </div>
  );
}

export default SubscriptionTop;

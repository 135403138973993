import axios from "axios";
import { GET_TOTAL_AMOUNT_DISBURSED } from "../Constants";
import { api } from "../../api/config";
import { SetErrorHandlerType } from "../../Types";
import { handleAPIError } from "../../utils/handleAPIError";

const url = `${api}/api/v1/admin`;

const token: any = localStorage.getItem("jwtToken");
axios.defaults.headers.common["authorization"] = token;

// Get Total Amount Disbursed
export const getTotalAmountDisbursed =
  (
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setErrorHandlerObj: SetErrorHandlerType
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .get(
        `${url}/dashboard/loan-disbursed
      `
      )
      .then((res) => {
        dispatch({
          type: GET_TOTAL_AMOUNT_DISBURSED,
          payload: res.data.data,
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

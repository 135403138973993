export const formTab = [
  {
    id: 1,
    name: "Course Details",
    isFormFilled: true,
  },
  {
    id: 2,
    name: "Course Content",
    isFormFilled: false,
  },
  {
    id: 3,
    name: "Assessment Questions",
    isFormFilled: false,
  },
  {
    id: 4,
    name: "Assessment Details",
    isFormFilled: false,
  },
];

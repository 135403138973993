import { useState } from "react";
import PlanSetupForm from "../plan-setup-form/PlanSetupForm";
import Top from "../../../components/app-top/Top";
import Sidebar from "../../../components/app-sidebar/Sidebar";
import ErrorHandler from "../../../components/error/ErrorHandler";
import Main from "../../../components/layout/main-container/Main";
import useDisplayMessage from "../../../hooks/useDisplayMessage";
import { IPlanFormInputStates } from "../../../Types";

function AddNewPlan() {
  // Functions, states and variables
  //   States
  const [planFormInputStates, setPlanFormInputStates] =
    useState<IPlanFormInputStates>({
      name: "",
      cost: "",
      details: "",
    });
  //   Hooks
  // Handles Display the error and success message and scrolls to top
  const {
    errorHandlerObj,
    setErrorHandlerObj,
    successHandlerObj,
    setSuccessHandlerObj,
  } = useDisplayMessage();
  return (
    <div className="app-page-container">
      <Top />
      <Sidebar activeName={"Subscriptions"} />

      <Main>
        {/* Error handler component */}
        <ErrorHandler
          errorHandlerObj={errorHandlerObj}
          className="app--actions-handler-wrapper"
        />

        <section className={`app-main-section--inner`}>
          <PlanSetupForm
            planFormInputStates={planFormInputStates}
            setPlanFormInputStates={setPlanFormInputStates}
            setErrorHandlerObj={setErrorHandlerObj}
          />
        </section>
      </Main>
    </div>
  );
}

export default AddNewPlan;

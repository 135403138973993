import axios from "axios";
import { GET_ALL_AUDIT_TRAIL } from "../Constants";
import { api } from "../../api/config";
import { SetErrorHandlerType } from "../../Types";
import { handleAPIError } from "../../utils/handleAPIError";

const url = `${api}/api/v1/admin`;

const token: any = localStorage.getItem("jwtToken");
axios.defaults.headers.common["authorization"] = token;

// Get All Audit Trails
export const getAllAuditTrails =
  (
    pageNumberIndex: number = 1,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setErrorHandlerObj: SetErrorHandlerType
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .get(`${url}/audit?page=${pageNumberIndex}`)
      .then((res) => {
        // console.log(res.data.data);
        dispatch({
          type: GET_ALL_AUDIT_TRAIL,
          payload: res.data,
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

import axios from "axios";
import { api } from "../../api/config";
import {
  SetErrorHandlerType,
  SetIsLoadingType,
  SetSuccessHandlerType,
} from "../../Types";
import { handleAPIError } from "../../utils/handleAPIError";
import {
  GET_ALL_PRODUCT_DEALS,
  GET_ALL_PURCHASE_DEALS,
  GET_SEARCHED_PURCHASE_DEAL,
} from "../Constants";
import { NavigateFunction } from "react-router-dom";
import { Dispatch } from "redux";
// Types
interface IStoreDealData {
  productName: string;
  productDescription: string;
  merchantName: string;
  merchantAddress: string;
  productImage: string;
  dealCost: number;
  actualCost: number;
  quantity: number;
  expiryDate: string;
}

const url = `${api}/api/v1`;

const token: any = localStorage.getItem("jwtToken");
axios.defaults.headers.common["authorization"] = token;

// Get All Product Deals
export const getProductDeals =
  (setIsLoading: SetIsLoadingType, setErrorHandlerObj: SetErrorHandlerType) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .get(`${url}/deals/product`)
      .then((res) => {
        dispatch({
          type: GET_ALL_PRODUCT_DEALS,
          payload: res.data.data?.reverse(),
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Create Product Deal
export const createProductDeal =
  (
    data: IStoreDealData,
    setIsLoading: SetIsLoadingType,
    setErrorHandlerObj: SetErrorHandlerType,
    navigate: NavigateFunction
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .post(`${url}/deals/product`, data)
      .then((res) => {
        setIsLoading(false);
        navigate("/admin/product-deals", {
          state: { message: "Product Deal created successfully!" },
        });
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Update Product deal
export const updateProductDeal =
  (
    dealId: string,
    data: IStoreDealData,
    setIsLoading: SetIsLoadingType,
    setErrorHandlerObj: SetErrorHandlerType,
    setSuccessHandlerObj: SetSuccessHandlerType
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .patch(`${url}/deals/product/${dealId}`, data)
      .then((res) => {
        setSuccessHandlerObj({
          isSuccess: true,
          message: "Product Deal updated successfully!",
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Delete Product deal
export const deleteProductDeal =
  (
    dealId: string,
    setIsLoading: SetIsLoadingType,
    setErrorHandlerObj: SetErrorHandlerType,
    navigate: NavigateFunction,
    setIsDeleteDealModalOpened: React.Dispatch<React.SetStateAction<boolean>>
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .patch(`${url}/deals/remove-product-deal/${dealId}`)
      .then((res) => {
        // Navigate to deals table screen displaying the success message
        navigate("/admin/product-deals", {
          state: { message: "Product Deal deleted successfully!" },
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setIsDeleteDealModalOpened(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// PURCHASE DEALS
// Get All Purchase Deals
export const getPurchaseDeals =
  (
    pageNumberIndex: number = 1,
    setIsLoading: SetIsLoadingType,
    setErrorHandlerObj: SetErrorHandlerType
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .get(`${url}/deals/purhased-product-deals?page=${pageNumberIndex}`)
      .then((res) => {
        dispatch({
          type: GET_ALL_PURCHASE_DEALS,
          payload: res.data,
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Get searched purchase deal
export const getSearchedPurchaseDeal =
  (
    voucherCode: string,
    setIsLoading: SetIsLoadingType,
    setErrorHandlerObj: SetErrorHandlerType,
    setIsVoucherSearched: React.Dispatch<React.SetStateAction<boolean>>,
    setIsModalOpened: React.Dispatch<React.SetStateAction<boolean>>
  ) =>
  (dispatch: Dispatch) => {
    setIsLoading(true);
    axios
      .get(`${url}/deals/purhased-product-deals/${voucherCode}`)
      .then((res) => {
        // TODO If my analysis is right, pass the data into the all merchant vouchers with a cancel search button on search and recall the get all merchants API on search
        dispatch({
          type: GET_SEARCHED_PURCHASE_DEAL,
          payload: res.data.data,
        });
        // Trigger change in table data value to searched voucher by updating the sstate to true
        setIsVoucherSearched(true);
        // Close Modal
        setIsModalOpened(false);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        // Set the error handler state
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Redeem purchase deal
export const redeemPurchaseDeal =
  (
    voucherCode: string,
    setIsLoading: SetIsLoadingType,
    setErrorHandlerObj: SetErrorHandlerType,
    setSuccessHandlerObj: SetSuccessHandlerType,
    setIsModalOpened: React.Dispatch<React.SetStateAction<boolean>>
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .patch(`${url}/deals/redeemed-product-deals/${voucherCode}`)
      .then((res) => {
        setIsLoading(false);
        setIsModalOpened(false);
        setSuccessHandlerObj({
          isSuccess: true,
          message: "Product deal redeemed successfully!",
        });
        // Refectch All Purchase deals
        dispatch(getPurchaseDeals(1, setIsLoading, setErrorHandlerObj));
      })
      .catch((err) => {
        setIsLoading(false);
        // Set the error handler state
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

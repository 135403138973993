import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import "./OnDemandTable.css";
import { Table } from "react-bootstrap";
import DropdownInput from "../../../components/input-components/dropdown-input/DropdownInput";
import FEPagination from "../../../components/pagination/fe-pagination/FEPagination";
import { numberWithCommas } from "../../../utils/numberWithCommas";
import { IAllRequests, IUserLoanBalance } from "../Withdrawals";
import moment from "moment";
import isEmpty from "../../../validation/isEmpty";
import LoadingSpinner from "../../../components/loading-spinner/LoadingSpinner";
import closeIcon from "../../../images/close-icon.svg";
import tickIcon from "../../../images/tick-icon.svg";
import { SearchContext } from "../../../components/contexts/SearchContext";
import { useAppDispatch } from "../../../hooks";
import { getUserLoanBalance } from "../../../redux/actions/usersActions";

interface IProps {
  allOnDemandRequests: IAllRequests[] | null;
  currentOnDemandPosts: IAllRequests[] | null;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  selectedEmployeeRequest: IAllRequests | null;
  setSelectedEmployeeRequest: React.Dispatch<
    React.SetStateAction<IAllRequests | null>
  >;
  setCurrentOnDemandPosts: (
    value: React.SetStateAction<IAllRequests[] | null>
  ) => void;
  allRequests: IAllRequests[] | null;
  setAllOnDemandRequests: React.Dispatch<
    React.SetStateAction<IAllRequests[] | null>
  >;
  currentPage: number;
  rowsPerPage: number;
  indexOfLastPost: number;
  indexOfFirstPost: number;
  isLoading: boolean;
  setIsOTPModalOpened: (value: React.SetStateAction<boolean>) => void;
  setIsDeclineEmployeeRequestModalOpened: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setIsShowUserBalanceModalOpened: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setUserLoanBalance: React.Dispatch<
    React.SetStateAction<IUserLoanBalance | null>
  >;
  setErrorHandlerObj: React.Dispatch<
    React.SetStateAction<{
      hasError: boolean;
      message: string;
    }>
  >;
  setIsApproveRequestLoading: React.Dispatch<React.SetStateAction<boolean>>;
  isApproveRequestLoading: boolean;
  handleApproveWithdrawalRequest: (requestId: string) => void;
}

const OnDemandTable: React.FC<IProps> = function ({
  allOnDemandRequests,
  currentOnDemandPosts,
  setCurrentPage,
  selectedEmployeeRequest,
  setSelectedEmployeeRequest,
  setCurrentOnDemandPosts,
  allRequests,
  setAllOnDemandRequests,
  currentPage,
  rowsPerPage,
  indexOfFirstPost,
  indexOfLastPost,
  isLoading,
  setIsOTPModalOpened,
  setIsDeclineEmployeeRequestModalOpened,
  setIsShowUserBalanceModalOpened,
  setUserLoanBalance,
  setErrorHandlerObj,
  setIsApproveRequestLoading,
  isApproveRequestLoading,
  handleApproveWithdrawalRequest,
}) {
  // Functions and States
  const dispatch = useAppDispatch();
  const { searchedValue }: any = useContext(SearchContext);
  // States
  const [onDemandDateFilter, setOnDemandDateFilter] = useState("");
  // Functions
  const paginateForOnDemand = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleGetCurrentUser = function (employee: IAllRequests) {
    // Set the selected employee request state
    setSelectedEmployeeRequest(employee);
  };

  //   handle Filter Table
  const handleFilterTable = function () {
    if (!allRequests) return false;

    const onDemandData = allRequests
      ?.filter((request) => {
        return request.type === "onDemand";
      })
      ?.filter(
        (request) =>
          request.staffId?.firstName
            .toLowerCase()
            .includes(searchedValue.toLowerCase()) ||
          request.staffId?.lastName
            .toLowerCase()
            .includes(searchedValue.toLowerCase())
      );

    setAllOnDemandRequests(onDemandData);

    // Update table state to salary advance rows
    if (!allOnDemandRequests || !onDemandData) return false;
    const currentRows = onDemandData.slice(indexOfFirstPost, indexOfLastPost);

    setCurrentOnDemandPosts(currentRows);
  };

  // handle Get User Loan Balance
  const handleGetUserLoanBalance = function (request: IAllRequests) {
    setSelectedEmployeeRequest(request);
    if (request.userId._id) {
      // Call the API to get user's loan balance and open loan balance modal
      dispatch(
        getUserLoanBalance(
          request.userId._id,
          setIsApproveRequestLoading,
          setErrorHandlerObj,
          setIsShowUserBalanceModalOpened,
          setUserLoanBalance
        )
      );
    }
  };

  useEffect(() => {
    handleFilterTable();
    setCurrentPage(1);
  }, [searchedValue]);

  return (
    <div className="on-demand-requests-table-section--inner">
      {/* On Demand Table Filter Wrapper */}
      <div className="on-demand-requests-table-filter-option-wrapper">
        <div className="on-demand-requests-table-filter-row-title">Filter:</div>
        {/* Filter by on demand request application date dropdown */}
        <div className="on-demand-requests-date-filter-form-group form-group">
          <DropdownInput
            className={"on-demand-date-filter-dropdown-input"}
            id={"on-demand-date-filter-input"}
            label={""}
            required={true}
            optionArray={[
              { key: "", value: "Date", default: "Default" },
              {
                key: "yesterday",
                value: "Yesterday",
              },
            ]}
            value={onDemandDateFilter}
            onChange={(e) => setOnDemandDateFilter(e.target.value)}
          />
        </div>
      </div>

      {/* All On Demand Request Table Container */}
      <div className="primary-table-container">
        <Table responsive="lg" className="primary-table">
          <thead
            style={{
              textAlign: "left",
            }}
          >
            <tr>
              {[
                "NAME",
                "EMAIL",
                "COMPANY",
                "SALARY (N)",
                "REQUEST AMOUNT",
                "DATE",
                "BALANCE",
                "STATUS",
                "",
                "",
              ].map((COLUMN, index) => (
                <th key={index + 1}>{COLUMN}</th>
              ))}
            </tr>
          </thead>

          <tbody>
            {currentOnDemandPosts?.map((employee, i) => (
              <tr
                key={i + 1}
                onClick={() => {
                  handleGetCurrentUser(employee);
                }}
              >
                {/* Employee Name Column */}
                <td className="td-employee-name">
                  <div className="td-employee-name--wrapper">
                    {employee.userId?.firstName} {employee.userId?.lastName}
                  </div>
                </td>

                {/*  Employee Email Column */}
                <td className="td-email">
                  <div className="td-email--wrapper">
                    {employee.userId?.email}
                  </div>
                </td>

                {/*  Company Name Column */}
                <td className="td-company-name">
                  <div className="td-company-name--wrapper">
                    {employee.companyId?.companyName || ""}
                  </div>
                </td>

                {/*  Salary Column */}
                <td className="td-salary">
                  <div className="td-salary--wrapper">
                    &#8358;{numberWithCommas(employee.staffId?.salary || "")}
                  </div>
                </td>

                {/*  Request amount Column */}
                <td className="td-request-amount">
                  <div className="td-request-amount--wrapper">
                    &#8358;{numberWithCommas(employee?.amount || "")}
                  </div>
                </td>

                {/* Date Column*/}
                <td className="td-date">
                  <div className="td-date--wrapper">
                    {moment(employee?.createdAt || "").format("Do MMM, YY")}
                  </div>
                </td>

                {/* Show Balance Column*/}
                <td className="td-show-balance">
                  <div className="td-show-balance--wrapper">
                    <div className="show-balance-button-wrapper">
                      <button
                        onClick={() => {
                          handleGetUserLoanBalance(employee);
                        }}
                      >
                        Show balance
                      </button>
                    </div>
                  </div>
                </td>

                {/* Status Column*/}
                <td className="td-status">
                  <div className="td-status--wrapper">
                    {
                      <div
                        className={`td-request-status--wrapper  ${
                          employee.status === 8 || employee.status === 7
                            ? "request-completed-wrapper"
                            : employee.status === 6
                            ? "request-rejected-wrapper"
                            : employee.status === 5
                            ? "request-awaiting-otp-wrapper"
                            : employee.status === 4
                            ? "request-completed-wrapper"
                            : employee.status === 3
                            ? "request-rejected-wrapper"
                            : employee.status === 2
                            ? "request-completed-wrapper"
                            : "request-awaiting-approval-wrapper"
                        }`}
                      >
                        {employee.status === 8
                          ? "Settled"
                          : employee.status === 7
                          ? "Disbursed"
                          : employee.status === 6
                          ? "Rejected by Admin"
                          : employee.status === 5
                          ? "Awaiting OTP"
                          : employee.status === 4
                          ? "Approved by Admin"
                          : employee.status === 3
                          ? "Rejected by HR"
                          : employee.status === 2
                          ? "Approved by HR"
                          : "Awaiting HR approval"}
                      </div>
                    }
                  </div>
                </td>

                {/* Decline Request Btn Column*/}
                <td className="td-decline-request">
                  {/* If status is only (Approved by HR), display decline */}
                  {employee.status === 2 && (
                    <div className="td-decline-request--wrapper">
                      <button
                        onClick={() => {
                          setIsDeclineEmployeeRequestModalOpened(true);
                          setSelectedEmployeeRequest(employee);
                        }}
                      >
                        <img src={closeIcon} alt="" />
                        Decline
                      </button>
                    </div>
                  )}

                  {/* Reason of declination */}
                  {(employee.status === 6 || employee.status === 3) && (
                    <div className="td-request-rejection-reason--wrapper">
                      {employee.adminRejectionReason ||
                        employee.hrRejectionReason}
                    </div>
                  )}
                </td>

                {/* Approve Request Btn Column*/}
                <td className="td-approve-request">
                  {employee.status === 5 ? (
                    // Fill OTP Button /Middle Ground between OTP and an approved request
                    <div className="fill-otp-button-wrapper">
                      <button
                        onClick={() => {
                          setIsOTPModalOpened(true);
                          setSelectedEmployeeRequest(employee);
                        }}
                      >
                        Fill OTP
                      </button>
                    </div>
                  ) : employee.status === 4 ? (
                    // If status is only (Approved By CREDLEY/ADMIN), display reinitiate
                    <div className="td-approve-request--wrapper">
                      <button
                        onClick={() => {
                          handleApproveWithdrawalRequest(employee._id);
                          setSelectedEmployeeRequest(employee);
                        }}
                      >
                        <img src={tickIcon} alt="" />
                        {isApproveRequestLoading &&
                        selectedEmployeeRequest?._id === employee._id
                          ? "Reinitiating..."
                          : "Reinitiate"}
                      </button>
                    </div>
                  ) : (
                    employee.status === 2 && (
                      // If status is  only (Approved By HR), display approve
                      <div className="td-approve-request--wrapper">
                        <button
                          onClick={() => {
                            handleApproveWithdrawalRequest(employee._id);
                            setSelectedEmployeeRequest(employee);
                          }}
                        >
                          <img src={tickIcon} alt="" />
                          {isApproveRequestLoading &&
                          selectedEmployeeRequest?._id === employee._id
                            ? "Approving..."
                            : "Approve"}
                        </button>
                      </div>
                    )
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div className="below-table-loader-wrapper">
          {isLoading && <LoadingSpinner />}
        </div>
      </div>

      {allOnDemandRequests && !isEmpty(currentOnDemandPosts) && (
        <FEPagination
          paginate={paginateForOnDemand}
          rowsPerPage={rowsPerPage}
          totalPosts={allOnDemandRequests.length}
          currentPage={currentPage}
        />
      )}
    </div>
  );
};

export default OnDemandTable;

import axios from "axios";
import {
  GET_ALL_VOUCHERS,
  GET_SELECTED_VOUCHERS,
  GET_VOUCHER_COUNTRIES_AND_CATEGORIES,
} from "../Constants";
import { api } from "../../api/config";
import { handleAPIError } from "../../utils/handleAPIError";
import {
  ISingleVoucher,
  IVoucherFilterFormInputs,
  SetErrorHandlerType,
  SetIsLoadingType,
  SetSuccessHandlerType,
} from "../../Types";

// Types and Interfaces
interface ISelectedVoucher {
  voucher: ISingleVoucher;
}

// URL
const url = `${api}/api/v1/voucher`;

const token: any = localStorage.getItem("jwtToken");
axios.defaults.headers.common["authorization"] = token;

// Get all vouchers
export const getAllVouchers =
  (
    pageNumberIndex: number = 1,
    voucherFilterParam: IVoucherFilterFormInputs,
    setIsLoading: SetIsLoadingType,
    setErrorHandlerObj: SetErrorHandlerType
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .get(
        `${url}/all${
          voucherFilterParam.country && `/${voucherFilterParam.country}`
        }${
          voucherFilterParam.category && `/${voucherFilterParam.category}`
        }?search=${voucherFilterParam.searchParam}&page=${pageNumberIndex}`
      )
      .then((res) => {
        dispatch({
          type: GET_ALL_VOUCHERS,
          payload: res.data,
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        // Set the error handler state
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Get selected vouchers
export const getSelectedVouchers =
  (setIsLoading: SetIsLoadingType, setErrorHandlerObj: SetErrorHandlerType) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .get(`${url}`)
      .then((res) => {
        dispatch({
          type: GET_SELECTED_VOUCHERS,
          payload: res.data.data?.reverse(),
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        // Set the error handler state
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Get voucher countries and categories
export const getVoucherCountriesAndCategories =
  (setErrorHandlerObj: SetErrorHandlerType) => (dispatch: any) => {
    // setIsLoading(true);
    axios
      .get(`${url}/country-and-category`)
      .then((res) => {
        dispatch({
          type: GET_VOUCHER_COUNTRIES_AND_CATEGORIES,
          payload: res.data.data,
        });
        // setIsLoading(false);
      })
      .catch((err) => {
        // setIsLoading(false);
        // Set the error handler state
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Create Selected Vouchers
export const createSelectedVouchers =
  (
    data: ISelectedVoucher,
    setIsLoading: SetIsLoadingType,
    setErrorHandlerObj: SetErrorHandlerType,
    setSuccessHandlerObj: SetSuccessHandlerType
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .post(`${url}/create`, data)
      .then((res) => {
        setSuccessHandlerObj({
          isSuccess: true,
          message: "Voucher added to selected vouchers successfully!",
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Remove voucher from Selected Vouchers
export const removeVoucherFromSelectedVouchers =
  (
    voucherId: string,
    setIsLoading: SetIsLoadingType,
    setErrorHandlerObj: SetErrorHandlerType,
    setSuccessHandlerObj: SetSuccessHandlerType
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .patch(`${url}/remove-selected/${voucherId}`)
      .then((res) => {
        setSuccessHandlerObj({
          isSuccess: true,
          message: "Voucher removed from selected vouchers successfully!",
        });
        setIsLoading(false);
        // Refetch selected vouchers
        dispatch(getSelectedVouchers(setIsLoading, setErrorHandlerObj));
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

import React, { useContext, useEffect, useState } from "react";
import "./Wellness.css";
import Sidebar from "../../components/app-sidebar/Sidebar";
import Top from "../../components/app-top/Top";
import ErrorHandler from "../../components/error/ErrorHandler";
import useDisplayMessage from "../../hooks/useDisplayMessage";
import { SearchContext } from "../../components/contexts/SearchContext";
import { IWellnessCourse } from "../../Types";
import FEPagination from "../../components/pagination/fe-pagination/FEPagination";
import isEmpty from "../../validation/isEmpty";
import CourseCard from "./comps/course-card/CourseCard";
import PrimaryButton from "../../components/buttons/primary-button/PrimaryButton";
import CoursePreview from "./comps/course-preview/CoursePreview";
import { useLocation, useNavigate } from "react-router-dom";
import SuccessHandler from "../../components/success/SuccessHandler";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getAllCourses } from "../../redux/actions/wellnessActions";
import LoadingSpinner from "../../components/loading-spinner/LoadingSpinner";
import DropdownInput from "../../components/input-components/dropdown-input/DropdownInput";
import { courseFilterOptions } from "../../utils/courseFilterOptions";
import SecondaryButton from "../../components/buttons/secondary-button/SecondaryButton";
import Main from "../../components/layout/main-container/Main";

function Wellness() {
  // Functions, States and Variables
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { searchedValue }: any = useContext(SearchContext);
  const courses: IWellnessCourse[] | [] = useAppSelector(
    (state) => state.wellness.courses
  );
  //   States
  const [pageView, setPageView] = useState("main");
  const [allCourses, setAllCourses] = useState<IWellnessCourse[] | null>(null);
  const [currentCoursesPosts, setCurrentCoursesPosts] = useState<
    IWellnessCourse[] | null
  >(null);
  const [courseStatusFilterParam, setCourseStatusFilterParam] = useState("all");
  const [selectedCourseForPreview, setSelectedCourseForPreview] =
    useState<IWellnessCourse | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const indexOfLastPost = currentPage * rowsPerPage;
  const indexOfFirstPost = indexOfLastPost - rowsPerPage;
  const [isLoading, setIsLoading] = useState(false);
  // Functions
  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handlePreviewSelectedCourse = function (course: IWellnessCourse) {
    setPageView("preview");
    setSelectedCourseForPreview(course);
  };

  // Handle Filter through courses based on search value and publish status
  const handleFilterCoursesData = function () {
    const coursesData = courses
      ?.filter((course) =>
        courseStatusFilterParam === "all"
          ? course
          : courseStatusFilterParam === "published"
          ? course.isPublished
          : !course.isPublished
      )
      ?.filter((course) =>
        course.name.toLowerCase().includes(searchedValue.toLowerCase())
      );

    // handle update of courses state based on searched value
    if (coursesData) {
      setAllCourses(coursesData);
    }
  };

  //   Hooks
  // Handles Display the error and success message and scrolls to top
  const {
    errorHandlerObj,
    setErrorHandlerObj,
    successHandlerObj,
    setSuccessHandlerObj,
  } = useDisplayMessage();

  useEffect(() => {
    if (location.state) {
      const { message }: any = location.state;
      if (message) setSuccessHandlerObj({ isSuccess: true, message });
    }
  }, [location]);

  //   Useeffects
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    if (allCourses) {
      const currentRows = allCourses?.slice(indexOfFirstPost, indexOfLastPost);
      setCurrentCoursesPosts(currentRows);
    }
  }, [allCourses, currentPage]);

  useEffect(() => {
    // Set Current Page to 1 to avoid empty screen on search match bug
    setCurrentPage(1);
    handleFilterCoursesData();
  }, [courses, searchedValue, courseStatusFilterParam]);

  useEffect(() => {
    dispatch(getAllCourses(setIsLoading, setErrorHandlerObj));
  }, []);

  return (
    <div className="app-page-container">
      <Top />
      <Sidebar activeName={"Learning"} />

      <Main>
        {/* Error handler component */}
        <ErrorHandler
          errorHandlerObj={errorHandlerObj}
          className="app--actions-handler-wrapper"
        />
        {/* Success handler component */}
        <SuccessHandler
          successHandlerObj={successHandlerObj}
          className="app--actions-handler-wrapper"
        />

        {/* Main Container || Only shows when page view is "main"*/}
        <section
          className={`app-main-section--inner ${
            pageView === "main" ? "" : "none"
          }`}
        >
          {/* Top Wrapper */}
          <div className="wellness--top-wrapper">
            {/* Title */}
            <div className="wellness--title-wrapper">
              All Courses <span>({courses?.length})</span>
            </div>

            <span className="wellness--top-action-btns-container">
              {/* Add New Course Button */}
              <PrimaryButton
                className="add-new-course-btn-wrapper"
                placeholder="Add New Course"
                onClick={() => {
                  navigate("/admin/wellness/add-new-course");
                }}
              />

              {/* Course Category Button */}
              <SecondaryButton
                className="add-new-course-btn-wrapper"
                placeholder={"Course Category"}
                onClick={() => {
                  navigate("/admin/wellness/course-category");
                }}
              />
            </span>
          </div>

          <div className="course-publish-status-filter-container">
            {/* Course Publish Status Table Filter Wrapper */}
            <div className="primary-table-filter-option-wrapper">
              <div className="primary-table-filter-row-title">Filter:</div>
              <div className="primary-table-filter-form-group form-group">
                <DropdownInput
                  className={"categories-date-filter-dropdown-input"}
                  id={"categories-date-filter-input"}
                  label={""}
                  required={true}
                  optionArray={courseFilterOptions}
                  value={courseStatusFilterParam}
                  onChange={(e) => setCourseStatusFilterParam(e.target.value)}
                />
              </div>
            </div>
          </div>

          {/* Courses Container */}
          <CourseCard
            currentCoursesPosts={currentCoursesPosts}
            handlePreviewSelectedCourse={handlePreviewSelectedCourse}
          />

          {isLoading && (
            <div className="below-table-loader-wrapper">
              <LoadingSpinner />
            </div>
          )}
          {/* Pagination */}
          {allCourses && !isEmpty(currentCoursesPosts) && (
            <FEPagination
              paginate={paginate}
              rowsPerPage={rowsPerPage}
              totalPosts={allCourses.length}
              currentPage={currentPage}
            />
          )}
        </section>

        {/* Selected Course Preview*/}
        {pageView === "preview" && (
          <section className="app-main-section--inner">
            <CoursePreview
              selectedCourseForPreview={selectedCourseForPreview}
              setSelectedCourseForPreview={setSelectedCourseForPreview}
              setPageView={setPageView}
              setErrorHandlerObj={setErrorHandlerObj}
              setSuccessHandlerObj={setSuccessHandlerObj}
            />
          </section>
        )}
      </Main>
    </div>
  );
}

export default Wellness;

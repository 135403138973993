import React, { useState } from "react";
import { IDeal } from "../../../Types";
import DealsSetup from "../deals-setup/DealsSetup";
import Top from "../../../components/app-top/Top";

function DealCreate() {
  // Funtion, State and Variables
  // States
  const [currentDealObj, setCurrentDealObj] = useState<IDeal | null>(null);
  const [pageContent, setPageContent] = useState("deal-info");
  const [isLoading, setIsLoading] = useState(false);
  // Delete deal modal
  const [isDeleteDealModalOpened, setIsDeleteDealModalOpened] = useState(false);

  return (
    <div
      className={`dashboard-setup-container ${
        isDeleteDealModalOpened ? "overflow-hidden" : ""
      }`}
    >
      <Top />

      <div
        onClick={() => {
          setIsDeleteDealModalOpened(false);
        }}
        className={`gray-overlay ${isDeleteDealModalOpened ? "" : "none"}`}
      ></div>

      {/*Dashboard Setup Section */}
      <section className={`dashboard-setup-form-section`}>
        {/* {currentDealObj !== null && ( */}
        <DealsSetup
          currentDealObj={currentDealObj}
          pageType={"create"}
          pageContent={pageContent}
          isDeleteDealModalOpened={isDeleteDealModalOpened}
          setIsDeleteDealModalOpened={setIsDeleteDealModalOpened}
          setPageContent={setPageContent}
        />
        {/* )} */}
      </section>
    </div>
  );
}

export default DealCreate;

import React, { MouseEvent, useEffect, useState } from "react";
import "../../requests/modals/Modals.css";
import { IAllRequests, IUserLoanBalance } from "../Withdrawals";
import { useAppDispatch } from "../../../hooks";

interface IProps {
  isShowUserBalanceModalOpened: boolean;
  setIsShowUserBalanceModalOpened: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  userLoanBalance: IUserLoanBalance | null;
  setSuccessHandlerObj: React.Dispatch<
    React.SetStateAction<{
      isSuccess: boolean;
      message: string;
    }>
  >;
  setErrorHandlerObj: React.Dispatch<
    React.SetStateAction<{
      hasError: boolean;
      message: string;
    }>
  >;
  selectedEmployeeRequest: IAllRequests | null;
  pageContent: string;
}

function ShowUserBalanceModal({
  isShowUserBalanceModalOpened,
  setIsShowUserBalanceModalOpened,
  userLoanBalance,
  selectedEmployeeRequest,
  setErrorHandlerObj,
  setSuccessHandlerObj,
  pageContent,
}: IProps) {
  // Functions and states
  const dispatch = useAppDispatch();
  // States
  const [reasonOfDeclination, setReasonOfDeclination] = useState("");

  //   Functions;
  const handleConfirmDeclineRequest = function (
    e: MouseEvent<HTMLButtonElement>
  ) {};

  return (
    <div
      className={`decline-requests-modal-container ${
        isShowUserBalanceModalOpened ? "" : "none"
      }`}
    >
      <div className="decline-requests-modal-container--inner">
        <div className="decline-requests-modal-header">User's Loan Balance</div>

        <div className="loan-balance-sub-header-wrapper">
          Loan balance for{" "}
          <span>
            {selectedEmployeeRequest?.userId?.firstName}{" "}
            {selectedEmployeeRequest?.userId?.lastName}
          </span>
        </div>

        {/* Loan Balance display wrapper */}
        <div className="loan-balance-display-container">
          {/* On Demand */}
          <div className="loan-balance-display-wrapper">
            <div className="loan-balance-key">On Demand:</div>
            <div className="loan-balance-value">
              &#8358;{userLoanBalance?.onDemand?.toLocaleString() || 0}
            </div>
          </div>
          {/* Salary Advance */}
          <div className="loan-balance-display-wrapper">
            <div className="loan-balance-key">Salary Advance:</div>
            <div className="loan-balance-value">
              &#8358;{userLoanBalance?.salaryAdvance?.toLocaleString() || 0}
            </div>
          </div>
          {/* BNPL */}
          <div className="loan-balance-display-wrapper">
            <div className="loan-balance-key">BNPL:</div>
            <div className="loan-balance-value">
              &#8358;{userLoanBalance?.bnpl?.toLocaleString() || 0}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ShowUserBalanceModal;

import React from "react";
import "./AuditTrailTable.css";
import { Table } from "react-bootstrap";
import moment from "moment";
import LoadingSpinner from "../../../components/loading-spinner/LoadingSpinner";
import { ISingleAudit } from "../../../Types";

interface IProps {
  currentPosts: ISingleAudit[] | null;
  isLoading: boolean;
}

const AuditTrailTable = function ({ currentPosts, isLoading }: IProps) {
  // Functions and States
  return (
    <>
      {/* Audit Trail Table Container */}
      <div className="primary-table-container">
        <Table responsive="lg" className="primary-table">
          <thead
            style={{
              textAlign: "left",
            }}
          >
            <tr>
              {["USER", "MODULE", "ACTION", "DATE"].map((COLUMN, index) => (
                <th key={index + 1}>{COLUMN}</th>
              ))}
            </tr>
          </thead>

          <tbody>
            {currentPosts?.map((audit, i) => (
              <tr key={i + 1}>
                {/* User Name Column */}
                <td className="td-user-name">
                  <div className="td-user-name--wrapper">
                    {audit.user.firstName} {audit.user.lastName}{" "}
                    <span>{audit.user.isAdmin ? "Admin" : "User"}</span>
                  </div>
                </td>

                {/*  Module Column */}
                <td className="td-module">
                  <div className="td-module--wrapper">{audit.module}</div>
                </td>

                {/*  Action Column */}
                <td className="td-action">
                  <div className="td-action--wrapper">{audit.action}</div>
                </td>

                {/*  Date Added Column */}
                <td className="td-date-added">
                  <div className="td-date-added--wrapper">
                    {moment(audit.createdAt).format("Do MMM, YYYY")}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div className="below-table-loader-wrapper">
          {isLoading && <LoadingSpinner />}
        </div>
      </div>
    </>
  );
};

export default AuditTrailTable;

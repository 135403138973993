import React from "react";
import "./MerchantRegistrationSuccessful.css";
import successIcon from "../../images/successful-image.svg";
import { useNavigate } from "react-router-dom";

function MerchantRegistrationSuccessful() {
  // Functions, States and variables
  const navigate = useNavigate();
  return (
    <div className="user-registration-successful-container">
      <div className="user-registration-successful-container--inner">
        {/* Success Image Wrapper */}
        <div className="success-image-wrapper">
          <img src={successIcon} alt="" />
        </div>

        {/* Main Text */}
        <div className="user-registration-successful--main-text">
          Merchant account successfully registered
        </div>

        {/* Sub Text */}
        <div className="user-registration-successful--sub-text">
          The user would has recieve an email to set password and continue into
          their account
        </div>

        <div className="user-registration-successful--go-back-button">
          <button onClick={() => navigate("/admin/merchants")}>
            Go Back to Merchants
          </button>
        </div>
      </div>
    </div>
  );
}

export default MerchantRegistrationSuccessful;

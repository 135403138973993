import axios from "axios";
import { GET_ALL_USERS, GET_USER_ALL_LOAN_BALANCE } from "../Constants";
import { api } from "../../api/config";
import { IUserLoanBalance } from "../../pages/withdrawals/Withdrawals";
import { handleAPIError } from "../../utils/handleAPIError";
import {
  SetErrorHandlerType,
  SetIsLoadingType,
  SetSuccessHandlerType,
} from "../../Types";
import { IAdminData } from "../../components/account-setup/setup-layout/setup-pages/administrators/Administrators";

const url = `${api}/api/v1/admin/users`;

const token: any = localStorage.getItem("jwtToken");
axios.defaults.headers.common["authorization"] = token;

// Get all users
export const getAllUsers =
  (setIsLoading: SetIsLoadingType, setErrorHandlerObj: SetErrorHandlerType) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .get(`${url}`)
      .then((res) => {
        // console.log(res.data);
        dispatch({ type: GET_ALL_USERS, payload: res.data });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Get user all loan balance
export const getUserLoanBalance =
  (
    userId: string,
    setIsLoading: SetIsLoadingType,
    setErrorHandlerObj: SetErrorHandlerType,
    setIsShowUserBalanceModalOpened: React.Dispatch<
      React.SetStateAction<boolean>
    >,
    setUserLoanBalance: (
      value: React.SetStateAction<IUserLoanBalance | null>
    ) => void
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .get(`${url}/bnpl-salary-advance-and-pay-day-balance/${userId}`)
      .then((res) => {
        // console.log(res.data.data);
        dispatch({ type: GET_USER_ALL_LOAN_BALANCE, payload: res.data.data });
        setIsLoading(false);
        // Set The Loan Balance
        setUserLoanBalance(res.data.data);
        // Open The Loan Balance Modal
        setIsShowUserBalanceModalOpened(true);
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Create Admin
export const createNewAdmin =
  (
    data: IAdminData,
    setIsLoading: SetIsLoadingType,
    setErrorHandlerObj: SetErrorHandlerType,
    setSuccessHandlerObj: SetSuccessHandlerType,
    setIsNewAdminCreated: React.Dispatch<React.SetStateAction<boolean>>
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .post(`${url}/create-admin`, data)
      .then((res) => {
        setSuccessHandlerObj({
          isSuccess: true,
          message: "Admin created successfully!",
        });
        setIsLoading(false);
        setIsNewAdminCreated(true);
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Activate User
export const activateUser =
  (
    userId: string,
    setIsLoading: SetIsLoadingType,
    setErrorHandlerObj: SetErrorHandlerType,
    setSuccessHandlerObj: SetSuccessHandlerType
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .post(`${url}/activate/${userId}`)
      .then((res) => {
        setSuccessHandlerObj({
          isSuccess: true,
          message: "User activated successfully!",
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Delete User
export const deleteUser =
  (
    userId: string,
    setIsLoading: SetIsLoadingType,
    setErrorHandlerObj: SetErrorHandlerType,
    setSuccessHandlerObj: SetSuccessHandlerType
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .patch(`${url}/deactivate/${userId}`)
      .then((res) => {
        setSuccessHandlerObj({
          isSuccess: true,
          message: "User deleted successfully!",
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

import React, { MouseEvent, useEffect, useState } from "react";
import "./ProductDealSetup.css";
import ErrorHandler from "../../../components/error/ErrorHandler";
import useDisplayMessage from "../../../hooks/useDisplayMessage";
import SuccessHandler from "../../../components/success/SuccessHandler";
import angleLeftIcon from "../../../images/angle-left.svg";
import { useNavigate } from "react-router-dom";
import DealsSetupNav from "./setup-nav/SetupNav";
import DealsInfo from "./deals-info-form/DealsInfo";
import { IProductDeal, IProductDealFormInputState } from "../../../Types";
import { useAppDispatch } from "../../../hooks";
import DeleteDealModal from "./modals/DeleteDealModal";
import {
  createProductDeal,
  deleteProductDeal,
  updateProductDeal,
} from "../../../redux/actions/productDealActions";

interface IProps {
  currentDealObj: IProductDeal | null;
  pageType: string;
  pageContent: string;
  isDeleteDealModalOpened: boolean;
  setIsDeleteDealModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
  setPageContent: React.Dispatch<React.SetStateAction<string>>;
}

function ProductDealSetup({
  currentDealObj,
  pageType,
  pageContent,
  isDeleteDealModalOpened,
  setIsDeleteDealModalOpened,
  setPageContent,
}: IProps) {
  // Functions, States and variables
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  // Hooks
  // Handles Display the error and success message and scrolls to top
  const {
    errorHandlerObj,
    setErrorHandlerObj,
    successHandlerObj,
    setSuccessHandlerObj,
  } = useDisplayMessage();
  // States
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteDealLoading, setIsDeleteDealLoading] = useState(false);
  const [dealFormInputState, setDealFormInputState] =
    useState<IProductDealFormInputState>({
      productName: "",
      productDescription: "",
      merchantName: "",
      merchantAddress: "",
      productImage: "",
      dealCost: "",
      actualCost: "",
      quantity: "",
      expiryDate: "",
    });

  // Handle Delete Deal
  const handleDeleteDeal = function () {
    const dealId = currentDealObj?._id || "";
    //  Call the API to delete deal
    dispatch(
      deleteProductDeal(
        dealId,
        setIsDeleteDealLoading,
        setErrorHandlerObj,
        navigate,
        setIsDeleteDealModalOpened
      )
    );
  };

  //   Handle Create / Edit Deal
  const handleCreateOrEditDeal = function (e: MouseEvent<HTMLButtonElement>) {
    const isDealFormEmpty = Object.keys(dealFormInputState).some(
      (inputKeys) =>
        dealFormInputState[inputKeys as keyof typeof dealFormInputState] === ""
    );

    if (!isDealFormEmpty) {
      e.preventDefault();
      const data = {
        productName: dealFormInputState.productName,
        productDescription: dealFormInputState.productDescription,
        merchantName: dealFormInputState.merchantName,
        merchantAddress: dealFormInputState.merchantAddress,
        productImage: dealFormInputState.productImage,
        dealCost: Number(dealFormInputState.dealCost),
        quantity: Number(dealFormInputState.quantity),
        actualCost: Number(dealFormInputState.actualCost),
        expiryDate: dealFormInputState.expiryDate,
      };

      if (pageType === "create") {
        console.log(data);
        //  Call the API to create a product deal
        dispatch(
          createProductDeal(data, setIsLoading, setErrorHandlerObj, navigate)
        );
      } else {
        //  Call the API to edit a product deal
        dispatch(
          updateProductDeal(
            currentDealObj?._id || "",
            data,
            setIsLoading,
            setErrorHandlerObj,
            setSuccessHandlerObj
          )
        );
      }
    }
  };

  useEffect(() => {
    if (pageType === "edit") {
      setDealFormInputState((prevFormInputState) => {
        const dateTimeString = currentDealObj?.expiryDate;
        const formattedDateTime = new Date(dateTimeString || "")
          .toISOString()
          .slice(0, -1);
        const updatedFormInputStates: IProductDealFormInputState = {
          ...prevFormInputState,
          productName: currentDealObj?.productName || "",
          productDescription: currentDealObj?.productDescription || "",
          merchantName: currentDealObj?.merchantName || "",
          merchantAddress: currentDealObj?.merchantAddress || "",
          productImage: currentDealObj?.productImage || "",
          dealCost: currentDealObj?.dealCost?.toString() || "",
          actualCost: currentDealObj?.actualCost?.toString() || "",
          quantity: currentDealObj?.quantity?.toString() || "",
          expiryDate: formattedDateTime,
        };
        return updatedFormInputStates;
      });
    }
  }, [currentDealObj]);

  return (
    <>
      {/* Modals */}
      {pageType === "edit" && (
        <DeleteDealModal
          isModalOpened={isDeleteDealModalOpened}
          isLoading={isDeleteDealLoading}
          setIsModalOpened={setIsDeleteDealModalOpened}
          handleDeleteDeal={handleDeleteDeal}
        />
      )}

      <div className="dashboard-setup-layout-container">
        <div className="actions-handler-wrapper">
          {/* Error handler component */}
          <ErrorHandler errorHandlerObj={errorHandlerObj} />

          {/* Success handler component */}
          <SuccessHandler successHandlerObj={successHandlerObj} />
        </div>
        {/* Dashboard Setup Top */}
        <div
          className={`dashboard-setup-top-container ${
            successHandlerObj.isSuccess || errorHandlerObj.hasError
              ? "push-wrapper-down"
              : ""
          }`}
        >
          <div className="dashboard-setup-top--left-wrapper">
            {/* Back button Wrapper */}
            <div className="back-button-wrapper">
              <button
                onClick={() => {
                  navigate(-1);
                }}
              >
                <img src={angleLeftIcon} alt="" />
              </button>
            </div>
            {/* Setup Top Title */}
            <div className="dashboard-setup-top-title">
              {pageType === "create" ? "Add Product Deal" : "Edit Product Deal"}
            </div>
          </div>
        </div>

        {/* Setup Layout main Container */}
        <div className="dashboard-setup-layout-main-container">
          {/* Deals setup navigation container */}
          <DealsSetupNav
            pageType={pageType}
            pageContent={pageContent}
            setPageContent={setPageContent}
            setIsDeleteDealModalOpened={setIsDeleteDealModalOpened}
          />

          {/* Deals setup form container */}
          <div className="dashboard-setup-layout--right-section deals-setup-layout-form-container">
            <div className={`account-details-setup--inner`}>
              {/* Deals Info */}
              {pageContent === "deal-info" && (
                <DealsInfo
                  pageType={pageType}
                  currentDealObj={currentDealObj}
                  dealFormInputState={dealFormInputState}
                  isLoading={isLoading}
                  setDealFormInputState={setDealFormInputState}
                  setErrorHandlerObj={setErrorHandlerObj}
                  handleCreateOrEditDeal={handleCreateOrEditDeal}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductDealSetup;

import axios from "axios";
import { api } from "../../api/config";
import { SetErrorHandlerType, SetIsLoadingType } from "../../Types";
import { handleAPIError } from "../../utils/handleAPIError";
import { GET_ALL_ADMINISTRATORS } from "../Constants";
import { NavigateFunction } from "react-router-dom";
// Types
interface ICreateAdminData {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  password: string;
  role: string;
}

interface IUpdateAdminData {
  role: string;
}

const url = `${api}/api/v1/admin`;

const token: any = localStorage.getItem("jwtToken");
axios.defaults.headers.common["authorization"] = token;

// Get All Admins
export const getAllAdministrators =
  (setIsLoading: SetIsLoadingType, setErrorHandlerObj: SetErrorHandlerType) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .get(`${url}/all-admins`)
      .then((res) => {
        dispatch({
          type: GET_ALL_ADMINISTRATORS,
          payload: res.data?.reverse(),
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Create Admin
export const createAdministrator =
  (
    data: ICreateAdminData,
    setIsLoading: SetIsLoadingType,
    setErrorHandlerObj: SetErrorHandlerType,
    navigate: NavigateFunction
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .post(`${url}/users/create-admin`, data)
      .then((res) => {
        setIsLoading(false);
        navigate("/admin/administrators", {
          state: { message: "Administrator created successfully!" },
        });
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Update admin role
export const updateAdministratorRole =
  (
    adminId: string,
    data: IUpdateAdminData,
    setIsLoading: SetIsLoadingType,
    setErrorHandlerObj: SetErrorHandlerType,
    navigate: NavigateFunction
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .patch(`${url}/set-admin-role/${adminId}`, data)
      .then((res) => {
        navigate("/admin/administrators", {
          state: { message: "Administrator updated successfully!" },
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

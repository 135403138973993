import React, { useContext, useEffect, useState } from "react";
import "./AllProductsTable.css";
import { Table } from "react-bootstrap";
import DropdownInput from "../../../components/input-components/dropdown-input/DropdownInput";
import FEPagination from "../../../components/pagination/fe-pagination/FEPagination";
import isEmpty from "../../../validation/isEmpty";
import LoadingSpinner from "../../../components/loading-spinner/LoadingSpinner";
import { SearchContext } from "../../../components/contexts/SearchContext";
import { IProducts } from "../Products";
import moment from "moment";
import { useAppDispatch } from "../../../hooks";
import {
  makeProductHotDeal,
  removeProductFromHotDeal,
} from "../../../redux/actions/productActions";
import {
  SetErrorHandlerType,
  SetIsLoadingType,
  SetSuccessHandlerType,
} from "../../../Types";

interface IProps {
  products: [] | IProducts[];
  allProducts: IProducts[] | null;
  setAllProducts: React.Dispatch<React.SetStateAction<IProducts[] | null>>;
  currentProductsPosts: IProducts[] | null;
  setCurrentProductsPosts: React.Dispatch<
    React.SetStateAction<IProducts[] | null>
  >;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  rowsPerPage: number;
  indexOfFirstPost: number;
  indexOfLastPost: number;
  isLoading: boolean;
  setIsTableActionLoading: SetIsLoadingType;
  setErrorHandlerObj: SetErrorHandlerType;
  setSuccessHandlerObj: SetSuccessHandlerType;
}

const AllProductsTable: React.FC<IProps> = function ({
  products,
  allProducts,
  setAllProducts,
  currentProductsPosts,
  setCurrentProductsPosts,
  currentPage,
  setCurrentPage,
  rowsPerPage,
  indexOfFirstPost,
  indexOfLastPost,
  isLoading,
  setIsTableActionLoading,
  setErrorHandlerObj,
  setSuccessHandlerObj,
}) {
  // Functions and States
  const dispatch = useAppDispatch();
  const { searchedValue }: any = useContext(SearchContext);
  // States
  const [merchantRequestsDateFilter, setMerchantRequestsDateFilter] =
    useState("");
  // Functions
  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  //   handle Filter Table
  const handleFilterTable = function () {
    if (!products || isEmpty(products)) return false;

    const productsData = products?.filter((product) =>
      product.name.toLowerCase().includes(searchedValue.toLowerCase())
    );

    setAllProducts(productsData);

    // Update table state to products rows
    if (!allProducts || !productsData) return false;
    const currentRows = productsData.slice(indexOfFirstPost, indexOfLastPost);

    setCurrentProductsPosts(currentRows);
  };

  // Make product hot deal
  const handleMakeProductHotDeal = function (product: IProducts) {
    setErrorHandlerObj({ hasError: false, message: "" });
    setSuccessHandlerObj({ isSuccess: false, message: "" });
    if (product) {
      dispatch(
        makeProductHotDeal(
          product._id,
          setIsTableActionLoading,
          setErrorHandlerObj,
          setSuccessHandlerObj
        )
      );
    }
  };

  // Remove product from hot deal
  const handleRemoveProductFromHotDeal = function (product: IProducts) {
    setErrorHandlerObj({ hasError: false, message: "" });
    setSuccessHandlerObj({ isSuccess: false, message: "" });
    if (product) {
      dispatch(
        removeProductFromHotDeal(
          product._id,
          setIsTableActionLoading,
          setErrorHandlerObj,
          setSuccessHandlerObj
        )
      );
    }
  };

  useEffect(() => {
    handleFilterTable();
    setCurrentPage(1);
  }, [searchedValue]);

  return (
    <div className="products--table-section--inner">
      {/* Table Section Top Wrapper */}
      <div className="products-table--top-wrapper">
        {/* Product Categories Table Filter Wrapper */}
        <div className="primary-table-filter-option-wrapper">
          <div className="primary-table-filter-row-title">Filter:</div>
          {/* Filter by product categories creation date dropdown */}
          <div className="products--date-filter-form-group form-group">
            <DropdownInput
              className={"categories-date-filter-dropdown-input"}
              id={"categories-date-filter-input"}
              label={""}
              required={true}
              optionArray={[
                { key: "", value: "Date", default: "Default" },
                {
                  key: "yesterday",
                  value: "Yesterday",
                },
              ]}
              value={merchantRequestsDateFilter}
              onChange={(e) => setMerchantRequestsDateFilter(e.target.value)}
            />
          </div>
        </div>
      </div>

      {/* All Product Categories Table Container */}
      <div className="primary-table-container">
        <Table
          responsive="lg"
          className="primary-table product-categories-table"
        >
          <thead
            style={{
              textAlign: "left",
            }}
          >
            <tr>
              {[
                "PRODUCT NAME",
                "QUANTITY",
                "VARIANT",
                "CATEGORY",
                "PRICE(\u20A6)",
                "DATE ADDED",
                "",
              ].map((COLUMN, index) => (
                <th key={index + 1}>{COLUMN}</th>
              ))}
            </tr>
          </thead>

          <tbody>
            {currentProductsPosts?.map((product, i) => (
              <tr key={i + 1}>
                {/* Product Name Column */}
                <td className="td-name">
                  <div className="td-name--wrapper">
                    {/* Stock image wrapper */}
                    <div className="profile-image-wrapper">
                      <img src={product.image} alt="" />
                    </div>
                    {product.name}
                  </div>
                </td>

                {/*  Variant Column */}
                <td className="td-quantity">
                  <div className="td-quantity--wrapper">{product.quantity}</div>
                </td>

                {/*  Quantity Column */}
                <td className="td-variant">
                  <div className="td-variant--wrapper">
                    {isEmpty(product.variants)
                      ? "-"
                      : product.variants?.length.toLocaleString()}
                  </div>
                </td>

                {/*  Category Column */}
                <td className="td-category">
                  <div className="td-category--wrapper">
                    {product.category.name}
                  </div>
                </td>

                {/*  Price Column */}
                <td className="td-price">
                  <div className="td-price--wrapper">
                    &#8358;{product.price.toLocaleString()}
                  </div>
                </td>

                {/*  Date Added Column */}
                <td className="td-date-added">
                  <div className="td-date-added--wrapper">
                    {moment(product.createdAt).format("DD, MMMM YYYY")}
                  </div>
                </td>

                {/*  Toggle Hot deal Column */}
                <td className="td-hot-deal">
                  <div className="td-hot-deal-button--wrapper">
                    {product?.isHotDeal ? (
                      <div className="remove-from-hot-deal-button-wrapper">
                        <button
                          onClick={() =>
                            handleRemoveProductFromHotDeal(product)
                          }
                        >
                          Remove from hot deal
                        </button>
                      </div>
                    ) : (
                      <div className="make-hot-deal-button-wrapper">
                        <button
                          onClick={() => handleMakeProductHotDeal(product)}
                        >
                          Make hot deal
                        </button>
                      </div>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div className="below-table-loader-wrapper">
          {isLoading && <LoadingSpinner />}
        </div>
      </div>

      {allProducts && !isEmpty(currentProductsPosts) && (
        <FEPagination
          paginate={paginate}
          rowsPerPage={rowsPerPage}
          totalPosts={allProducts.length}
          currentPage={currentPage}
        />
      )}
    </div>
  );
};

export default AllProductsTable;

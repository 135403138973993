import React, { MouseEvent, useEffect, useState } from "react";
import "./PlanDetailsForm.css";
import TextInput from "../../../../components/input-components/text-input/TextInput";
import PrimaryButton from "../../../../components/buttons/primary-button/PrimaryButton";
import LoadingSpinner from "../../../../components/loading-spinner/LoadingSpinner";
import { IPlanFormInputStates } from "../../../../Types";
import { TextEditorWithToolbar } from "../../../../components/text-editor/TextEditorWithTooolbar";

// Interfaces
interface IProps {
  isLoading: boolean;
  planFormInputStates: IPlanFormInputStates;
  setPlanFormInputStates: React.Dispatch<
    React.SetStateAction<IPlanFormInputStates>
  >;
  handleSavePlan: (e: MouseEvent<HTMLButtonElement>) => void;
}

function PlanDetailsForm({
  isLoading,
  planFormInputStates,
  setPlanFormInputStates,
  handleSavePlan,
}: IProps) {
  // Functions, States and variables
  // States
  const [textEditorNote, setTextEditorNote] = useState<any>(null);

  // Functions
  //   Handle Update Plan Form Input State
  const handleUpdatePlanFormInputState = function (
    fieldName: string,
    fieldValue: string
  ) {
    const updatedPlanFormObject: IPlanFormInputStates = {
      ...planFormInputStates,
      [fieldName]: fieldValue,
    };
    setPlanFormInputStates(updatedPlanFormObject);
  };

  // Useeffects
  useEffect(() => {
    if (textEditorNote !== null) {
      handleUpdatePlanFormInputState("details", textEditorNote);
    }
  }, [textEditorNote]);

  useEffect(() => {
    if (planFormInputStates.details !== textEditorNote)
      setTextEditorNote(planFormInputStates.details);
  }, []);

  return (
    <section className="plan-details-form-section">
      <div className="form-section-title-label">Plan Information</div>

      <div className="plan-details--form-wrapper">
        <div className="plan-details--form-wrapper--grid">
          {/* Plan Name form group */}
          <div className="form-group">
            <TextInput
              type={"text"}
              className={"plan-detail-input"}
              value={planFormInputStates.name}
              onChange={(e) =>
                handleUpdatePlanFormInputState("name", e.target.value)
              }
              required={true}
              label={"Plan Name"}
            />
          </div>
          {/* Plan Price form group */}
          <div className="form-group">
            <TextInput
              type={"number"}
              className={"plan-detail-input"}
              value={planFormInputStates.cost}
              onChange={(e) => {
                handleUpdatePlanFormInputState("cost", e.target.value);
              }}
              required={true}
              label={" Price(per employee per month)"}
              subLabel={"(in ₦)"}
              min="0"
            />
          </div>
        </div>
        <div className="form-group plan-details-form-group">
          <label>Plan Details List</label>
          <TextEditorWithToolbar
            value={textEditorNote}
            setValue={setTextEditorNote}
            placeholder={"e.g Perks and Benefits"}
          />
        </div>
      </div>

      {/* Submit Button */}
      <div className="plan-form-action-btn-container">
        <div className="primary-button-container">
          {isLoading && <LoadingSpinner />}
          <PrimaryButton
            className="plan-form--action-btn"
            placeholder="Submit Plan"
            onClick={(e) => handleSavePlan(e)}
          />
        </div>
      </div>
    </section>
  );
}

export default PlanDetailsForm;

import axios from "axios";
import { GET_ALL_CATEGORIES } from "../Constants";
import { api } from "../../api/config";
import { SetErrorHandlerType, SetSuccessHandlerType } from "../../Types";
import { handleAPIError } from "../../utils/handleAPIError";

const url = `${api}/api/v1/category`;

const token: any = localStorage.getItem("jwtToken");
axios.defaults.headers.common["authorization"] = token;

interface INewCategory {
  name: string;
  imageUrl: string;
}
// Get all Categories
export const getAllCategories =
  (
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setErrorHandlerObj: SetErrorHandlerType
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .get(`${url}`)
      .then((res) => {
        // // console.log(res.data);
        dispatch({ type: GET_ALL_CATEGORIES, payload: res.data });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

//   Create a category
export const createACategory =
  (
    data: INewCategory,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setErrorHandlerObj: SetErrorHandlerType,
    setSuccessHandlerObj: SetSuccessHandlerType,
    setIsCreateEditProductActionModalOpened: React.Dispatch<
      React.SetStateAction<boolean>
    >
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .post(`${url}`, data)
      .then((res) => {
        // Close confirmation modal
        setIsCreateEditProductActionModalOpened(false);
        // Set Success
        setSuccessHandlerObj({
          isSuccess: true,
          message: "Category created successfully!",
        });
        setIsLoading(false);
      })
      .catch((err) => {
        // Close confirmation modal
        setIsCreateEditProductActionModalOpened(false);
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

//   Update a category
export const updateACategory =
  (
    data: INewCategory,
    categoryId: string,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setErrorHandlerObj: SetErrorHandlerType,
    setSuccessHandlerObj: SetSuccessHandlerType,
    setIsCreateEditProductActionModalOpened: React.Dispatch<
      React.SetStateAction<boolean>
    >
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .put(`${url}/${categoryId}`, data)
      .then((res) => {
        // // console.log(res.data);

        // Close confirmation modal
        setIsCreateEditProductActionModalOpened(false);
        // Set Success
        setSuccessHandlerObj({
          isSuccess: true,
          message: "Category updated successfully!",
        });
        setIsLoading(false);
      })
      .catch((err) => {
        // Close confirmation modal
        setIsCreateEditProductActionModalOpened(false);
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

//   Delete a category
export const deleteACategory =
  (
    categoryId: string,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setErrorHandlerObj: SetErrorHandlerType,
    setSuccessHandlerObj: SetSuccessHandlerType
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .patch(`${url}/${categoryId}`)
      .then((res) => {
        // // console.log(res.data);

        // Set Success
        setSuccessHandlerObj({
          isSuccess: true,
          message: "Category deleted successfully!",
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

import React, { useState, useEffect, MouseEvent } from "react";
import { useNavigate } from "react-router-dom";
import angleLeftIcon from "../../../images/angle-left.svg";
import ErrorHandler from "../../error/ErrorHandler";
import SuccessHandler from "../../success/SuccessHandler";
import { useAppDispatch } from "../../../hooks";
import { logoutUser } from "../../../redux/actions/authActions";
import Administrators from "./setup-pages/administrators/Administrators";
import useDisplayMessage from "../../../hooks/useDisplayMessage";
import SetupNav from "./setup-nav/SetupNav";
import CurrencyRates from "./setup-pages/currency-rates/CurrencyRates";

interface IProps {
  pageContent: string;
  setPageContent: React.Dispatch<React.SetStateAction<string>>;
  pageType: string;
  pageStateObj: {} | null;
}

const SetupLayout: React.FC<IProps> = function ({
  pageContent,
  setPageContent,
  pageType,
  pageStateObj,
}) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  // Functions, States and Variables

  // States
  const [isLoading, setIsLoading] = useState(false);

  // handle Log Out User
  const handleLogOutUser = function (e: MouseEvent<HTMLButtonElement>) {
    e.preventDefault();

    dispatch(logoutUser(navigate));
  };

  // Hooks
  // Handles Display the error and success message and scrolls to top
  const {
    errorHandlerObj,
    setErrorHandlerObj,
    successHandlerObj,
    setSuccessHandlerObj,
  } = useDisplayMessage();

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [pageContent]);

  return (
    <div className="dashboard-setup-layout-container">
      <div className="actions-handler-wrapper">
        {/* Error handler component */}
        <ErrorHandler errorHandlerObj={errorHandlerObj} />

        {/* Success handler component */}
        <SuccessHandler successHandlerObj={successHandlerObj} />
      </div>
      {/* Dashboard Setup Top */}
      <div
        className={`dashboard-setup-top-container ${
          successHandlerObj.isSuccess || errorHandlerObj.hasError
            ? "push-wrapper-down"
            : ""
        }`}
      >
        <div className="dashboard-setup-top--left-wrapper">
          {/* Back button Wrapper */}
          <div className="back-button-wrapper">
            <button
              onClick={() => {
                if (pageType === "settings") {
                  navigate(-1);
                } else {
                  setPageContent("main");
                }
              }}
            >
              <img src={angleLeftIcon} alt="" />
            </button>
          </div>
          {/* Setup Top Title */}
          <div className="dashboard-setup-top-title">
            {pageType === "settings" ? "Settings" : "Set up"}
          </div>
        </div>
      </div>

      {/* Setup Layout main Container */}
      <div className="dashboard-setup-layout-main-container">
        {/* Dasboard setup Left Section / Dashboard setup navigation container */}
        <SetupNav
          pageType={pageType}
          pageContent={pageContent}
          setPageContent={setPageContent}
        />

        {/* Dasboard Setup Right Section / Dashboard setup form container */}
        <div className="dashboard-setup-layout--right-section dashboard-setup-layout-form-container">
          {/* Administrators Setup Inner Container */}
          <div className={`account-details-setup--inner`}>
            {/* Administrators */}
            {pageContent === "administrators" && (
              <Administrators
                setErrorHandlerObj={setErrorHandlerObj}
                setSuccessHandlerObj={setSuccessHandlerObj}
              />
            )}

            {/* Currency Rates */}
            {pageContent === "currency-rates" && (
              <CurrencyRates
                setErrorHandlerObj={setErrorHandlerObj}
                setSuccessHandlerObj={setSuccessHandlerObj}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetupLayout;

import { Link } from "react-router-dom";
import moment from "moment";
import { IProductDeal } from "../../../../../Types";
import { Table } from "react-bootstrap";
import FEPagination from "../../../../../components/pagination/fe-pagination/FEPagination";
import isEmpty from "../../../../../validation/isEmpty";
import LoadingSpinner from "../../../../../components/loading-spinner/LoadingSpinner";
import pencilIcon from "../../../../../images/pencil-icon.svg";

// Interface
interface IProps {
  allDeals: IProductDeal[] | null;
  currentDealsPosts: IProductDeal[] | null;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  rowsPerPage: number;
  indexOfLastPost: number;
  indexOfFirstPost: number;
  isLoading: boolean;
}

function DealsTable({
  allDeals,
  currentDealsPosts,
  currentPage,
  setCurrentPage,
  rowsPerPage,
  isLoading,
}: IProps) {
  // Functions and States

  // Functions
  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  return (
    <>
      {/* Users Table Container */}
      <div className="primary-table-container">
        <Table responsive="lg" className="primary-table">
          <thead
            style={{
              textAlign: "left",
            }}
          >
            <tr>
              {[
                "PRODUCT NAME",
                "MERCHANT NAME",
                "CURRENCY",
                "INITIAL AMOUNT",
                "DEAL AMOUNT",
                "QUANTITY",
                "EXPIRY DATE",
                "",
              ].map((COLUMN, index) => (
                <th key={index + 1}>{COLUMN}</th>
              ))}
            </tr>
          </thead>

          <tbody>
            {currentDealsPosts?.map((deal, i) => (
              <tr key={i + 1}>
                {/* Product Name Column */}
                <td className="td-name">
                  <div className="td-name--wrapper">
                    {/* Stock image wrapper */}
                    <div className="profile-image-wrapper">
                      <img src={deal.productImage} alt="" />
                    </div>
                    {deal.productName}
                  </div>
                </td>

                {/* MERCHANT Name Column */}
                <td className="td-primary">
                  <div className="td-primary--wrapper">{deal.merchantName}</div>
                </td>

                {/* CURRENCY Column */}
                <td className="td-primary">
                  <div className="td-primary--wrapper">
                    {deal?.currency || "NGN"}
                  </div>
                </td>

                {/* INITIAL Amount Column */}
                <td className="td-primary">
                  <div className="td-primary--wrapper">
                    {deal.actualCost?.toLocaleString()}
                  </div>
                </td>

                {/* DEAL Amount Column */}
                <td className="td-primary">
                  <div className="td-primary--wrapper">
                    {deal.dealCost?.toLocaleString()}
                  </div>
                </td>

                {/* Product Quantity Column */}
                <td className="td-primary">
                  <div className="td-primary--wrapper">
                    {deal.quantity?.toLocaleString()}
                  </div>
                </td>

                {/* Exoiry date Column */}
                <td className="td-primary">
                  <div className="td-primary--wrapper">
                    {" "}
                    {moment(deal.expiryDate).format("Do MMM, YY")}
                  </div>
                </td>

                {/* Edit Deal Row Column */}
                <td className="td-edit-deal-row">
                  <div className="td-edit-deal-row--wrapper">
                    <Link
                      to={"/admin/product-deals/edit"}
                      state={{
                        deal,
                      }}
                    >
                      <img src={pencilIcon} alt="" />
                    </Link>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div className="below-table-loader-wrapper">
          {isLoading && <LoadingSpinner />}
        </div>
      </div>

      {allDeals && !isEmpty(currentDealsPosts) && (
        <FEPagination
          paginate={paginate}
          rowsPerPage={rowsPerPage}
          totalPosts={allDeals.length}
          currentPage={currentPage}
        />
      )}
    </>
  );
}

export default DealsTable;

import React, { ChangeEvent, MouseEvent, useState } from "react";
import uploadIcon from "../../../../../../../images/upload-icon-secondary.svg";
import plusIcon from "../../../../../../../images/plus-icon.svg";
import trashIcon from "../../../../../../../images/trash-icon-secondary.svg";
import LoadingSpinner from "../../../../../../../components/loading-spinner/LoadingSpinner";
import {
  IVideoCourseInputState,
  SetErrorHandlerType,
} from "../../../../../../../Types";
import TextInput from "../../../../../../../components/input-components/text-input/TextInput";
import { truncateString } from "../../../../../../../utils/truncateString";
import { handleGetVideoDuration } from "../../../../../../../utils/getVideoDuration";
import axios from "axios";
import { api } from "../../../../../../../api/config";

// Interfaces
interface IProps {
  courseContentInputStates: IVideoCourseInputState[];
  setCourseContentInputStates: React.Dispatch<
    React.SetStateAction<IVideoCourseInputState[]>
  >;
  setErrorHandlerObj: SetErrorHandlerType;
}

function VideoCourseForm({
  courseContentInputStates,
  setCourseContentInputStates,
  setErrorHandlerObj,
}: IProps) {
  // Functions, States and Variables
  //   States
  const [isCourseVideoUploadLoading, setIsCourseVideoUploadLoading] =
    useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  // Functions
  const handleUploadCourseVideo = function (
    e: ChangeEvent<HTMLInputElement>,
    index: number
  ) {
    if (e.target.files) {
      const data = "videoUrl";

      if (data) {
        //  Call the API fucntion to upload file and get file link
        handleUploadVideo(e, index);
      }
    }
  };

  //   Handle Upload and Set Video URl
  const handleUploadVideo = async function (
    e: ChangeEvent<HTMLInputElement>,
    index: number
  ) {
    if (e.target.files) {
      const token = localStorage.getItem("jwtToken");

      if (token) {
        axios.defaults.headers.common["authorization"] = token;

        try {
          const selectedFile = e.target.files[0];
          const formData = new FormData();
          formData.append("file", selectedFile);

          setIsCourseVideoUploadLoading(true);
          const { data } = await axios.post(
            `${api}/api/v1/admin/course/upload`,
            formData
          );

          // Get and Set Course Video Duration and Size
          handleGetVideoDuration(e, index, setCourseContentInputStates);
          if (data) {
            setCourseContentInputStates((prevStateInputs) => {
              const updatedFormData = [...prevStateInputs];
              const updatedFileObj: IVideoCourseInputState = {
                ...prevStateInputs[index],
                videoUrl: data,
              };
              // Splice the modified file object into the file arr
              updatedFormData.splice(index, 1, updatedFileObj);
              return updatedFormData;
            });
          }

          setIsCourseVideoUploadLoading(false);
        } catch (ex) {
          setIsCourseVideoUploadLoading(false);
          setErrorHandlerObj({
            hasError: true,
            message: "Upload failed!",
          });
        }
      }
    }
  };

  //   Handle Update Course Content Input State
  const handleUpdateCourseContentInputState = function (
    fieldName: string,
    fieldValue: string,
    index: number
  ) {
    const courseContentInputStatesCopy = [...courseContentInputStates];
    const updatedFieldObjectForIndex: IVideoCourseInputState = {
      ...courseContentInputStates[index],
      [fieldName]: fieldValue,
    };
    courseContentInputStatesCopy.splice(index, 1, updatedFieldObjectForIndex);
    setCourseContentInputStates(courseContentInputStatesCopy);
  };

  // Handle trigger of input click on change button click
  const handleTriggerInputClick = function (
    e: MouseEvent<HTMLButtonElement>,
    index: number
  ) {
    e.preventDefault();
    const currentInput = document.getElementById(`video-upload-input-${index}`);
    currentInput?.click();
  };

  // handle Add New Video Course Object
  const handleAddNewVideoCourse = function (e: MouseEvent<HTMLButtonElement>) {
    const isVideoCourseObjectEmpty = courseContentInputStates?.some(
      (courseContent) =>
        courseContent.videoTitle === "" || courseContent.videoUrl === ""
    );

    // Check if no input state is empty
    if (!isVideoCourseObjectEmpty) {
      e.preventDefault();
      const newCourseContentObject = {
        videoTitle: "",
        videoUrl: "",
        size: "",
        duration: "",
      };
      const updatedCourseContentInputStates = [
        ...courseContentInputStates,
        newCourseContentObject,
      ];
      setCourseContentInputStates(updatedCourseContentInputStates);
    }
  };

  // Handle Remove Course From Course Course Content Input States
  const handleRemoveCourseVideo = function (
    e: MouseEvent<HTMLButtonElement>,
    index: number
  ) {
    e.preventDefault();
    // Remove object from videos array
    setCourseContentInputStates((prevStateInputs) => {
      const updatedFormData = [...prevStateInputs];
      // Splice out the selected file object out of the file arr
      updatedFormData.splice(index, 1);
      return updatedFormData;
    });
  };

  return (
    <div className="course-file-form-group-container">
      {courseContentInputStates?.map((courseContent, index) => (
        <div key={index + 1} className="course-file-form-group-wrapper">
          {/* Form header container */}
          <div className="form-header-container">
            <div className="form-header-action-wrapper">Video {index + 1}</div>
            {/* Delete Form Object Wrapper */}
            {courseContentInputStates.length > 1 &&
              index !== courseContentInputStates.length && (
                <button
                  onClick={(e) => handleRemoveCourseVideo(e, index)}
                  className="delete-form-object-wrapper"
                >
                  <img src={trashIcon} alt="" />
                  Delete Video
                </button>
              )}
          </div>

          {/* Course Content Title */}
          <div className="form-group">
            <TextInput
              type={"text"}
              id={`course-content-title-input-${index}`}
              className={"course-content-title-input"}
              value={courseContent?.videoTitle || ""}
              onChange={(e) =>
                handleUpdateCourseContentInputState(
                  "videoTitle",
                  e.target.value,
                  index
                )
              }
              required={true}
              label={"Video Title"}
            />
          </div>

          {/* Upload box container */}
          <div
            className="upload-course-content-box-container"
            onFocus={() => setActiveIndex(index)}
          >
            {/* File Input View */}
            {courseContent.videoUrl === "" && (
              <div className="course-file-form-group">
                <img src={uploadIcon} alt="" />

                <div className="input-title">Upload Course Content</div>
                <div className="input-sub-title">
                  Drag and drop file here or browse your computer
                </div>

                <div className="browse-local-button-wrapper">
                  <button>Browse</button>
                  {activeIndex === index && isCourseVideoUploadLoading && (
                    <LoadingSpinner />
                  )}
                </div>

                <input
                  type="file"
                  accept="video/mp4,video/x-m4v,video/*"
                  onChange={(e) => handleUploadCourseVideo(e, index)}
                  required
                />
              </div>
            )}

            {/* Uploaded File View */}
            {courseContent.videoUrl !== "" && (
              <div className="course-file-uploaded-wrapper">
                <div className="course-file-uploaded--top-wrapper">
                  {/* /Course Title  */}
                  <div className="file-uploaded--course-title">
                    {truncateString(courseContent.videoTitle, 28) || ""}
                  </div>
                </div>

                {/* Course Duration  */}
                <div className="file-uploaded--course-duration">
                  {courseContent.duration}
                </div>

                {/* Course Size  */}
                <div className="file-uploaded--course-size">
                  {courseContent.size}
                </div>

                <div className="browse-local-button-wrapper">
                  <button onClick={(e) => handleTriggerInputClick(e, index)}>
                    Change
                  </button>
                  {activeIndex === index && isCourseVideoUploadLoading && (
                    <LoadingSpinner />
                  )}
                </div>

                {/* Hidden Input Being triggered on change */}
                <input
                  hidden
                  id={`video-upload-input-${index}`}
                  type="file"
                  accept="video/mp4,video/x-m4v,video/*"
                  onChange={(e) => handleUploadCourseVideo(e, index)}
                />
              </div>
            )}
          </div>
        </div>
      ))}

      <div className="">
        <button
          className="form-header-action-wrapper"
          onClick={handleAddNewVideoCourse}
          disabled={isCourseVideoUploadLoading}
        >
          <img src={plusIcon} alt="" /> Add Video
        </button>
      </div>
    </div>
  );
}

export default VideoCourseForm;

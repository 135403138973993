import { MouseEvent, useEffect, useState } from "react";
import "./AdminFormSetup.css";
import { useNavigate } from "react-router-dom";
import { IAdministrator } from "../../../Types";
import { useAppDispatch } from "../../../hooks";
import {
  createAdministrator,
  updateAdministratorRole,
} from "../../../redux/actions/administratorsActions";
import useDisplayMessage from "../../../hooks/useDisplayMessage";
import ErrorHandler from "../../../components/error/ErrorHandler";
import Main from "../../../components/layout/main-container/Main";
import Sidebar from "../../../components/app-sidebar/Sidebar";
import Top from "../../../components/app-top/Top";
import FormSection from "./FormSection";
import PrimaryBackButton from "../../../components/primary-back-button/PrimaryBackButton";
import isEmpty from "../../../validation/isEmpty";

// Interfaces
export interface IAdminData {
  firstName: string;
  lastName: string;
  role: string;
  phoneNumber: string;
  email: string;
  password: string;
}

interface IProps {
  pageType: string;
  currentAdminData?: IAdministrator;
}
function AdminFormSetup({ pageType, currentAdminData }: IProps) {
  // Functions, states and variables
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  //   States
  const [adminFormInputStates, setAdminFormInputStates] = useState<IAdminData>({
    firstName: "",
    lastName: "",
    role: "",
    phoneNumber: "",
    email: "",
    password: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  //   Hooks
  // Handles Display the error and success message and scrolls to top
  const {
    errorHandlerObj,
    setErrorHandlerObj,
    successHandlerObj,
    setSuccessHandlerObj,
  } = useDisplayMessage();

  //   Functions
  const handleCreateNewAdmin = function (e: MouseEvent<HTMLButtonElement>) {
    setErrorHandlerObj({ hasError: false, message: "" });
    const isAdminFormEmpty = Object.keys(adminFormInputStates)?.some(
      (inputKeys) => {
        return (
          adminFormInputStates[
            inputKeys as keyof typeof adminFormInputStates
          ] === ""
        );
      }
    );
    if (!isAdminFormEmpty) {
      e.preventDefault();
      if (pageType === "create") {
        // Call the API to create a new admin
        dispatch(
          createAdministrator(
            adminFormInputStates,
            setIsLoading,
            setErrorHandlerObj,
            navigate
          )
        );
      } else {
        // Call the API to update admin role
        const data = { role: adminFormInputStates.role };
        if (currentAdminData) {
          dispatch(
            updateAdministratorRole(
              currentAdminData._id,
              data,
              setIsLoading,
              setErrorHandlerObj,
              navigate
            )
          );
        }
      }
    }
  };

  //   Useeffect
  useEffect(() => {
    // If Current Admin Data exists, i.e, it has being updated by the data passed through the "edit admin" link state
    if (!isEmpty(currentAdminData)) {
      setAdminFormInputStates({
        firstName: currentAdminData?.firstName || "",
        lastName: currentAdminData?.lastName || "",
        role: currentAdminData?.role || "",
        phoneNumber: currentAdminData?.phoneNumber || "",
        email: currentAdminData?.email || "",
        password: currentAdminData?.password || "",
      });
    }
  }, [currentAdminData]);

  return (
    <div className="app-page-container">
      <Top />
      <Sidebar activeName={"Administrators"} />

      <Main>
        {/* Error handler component */}
        <ErrorHandler
          errorHandlerObj={errorHandlerObj}
          className="app--actions-handler-wrapper"
        />

        <section className={`app-main-section--inner`}>
          {/* Top Wrapper */}
          <div className="admin-form--top-wrapper">
            <div className="primary-back-button-container">
              {/* Back button Wrapper */}
              <PrimaryBackButton
                onClick={() => {
                  navigate("/admin/administrators");
                }}
              />
              {/* Top Title */}
              <div className="primary-back-button-title">
                {pageType === "edit" ? "Edit" : "Add New"} Administrator
              </div>
            </div>
          </div>
          {/* Administrators Form Section */}
          <FormSection
            pageType={pageType}
            adminFormInputStates={adminFormInputStates}
            isLoading={isLoading}
            setAdminFormInputStates={setAdminFormInputStates}
            handleCreateNewAdmin={handleCreateNewAdmin}
          />
        </section>
      </Main>
    </div>
  );
}

export default AdminFormSetup;

import React, { useContext, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { SearchContext } from "../../../components/contexts/SearchContext";
import {
  ISelectedVoucher,
  ISingleVoucher,
  SetErrorHandlerType,
  SetIsLoadingType,
  SetSuccessHandlerType,
  SuccessHandlerType,
} from "../../../Types";
import isEmpty from "../../../validation/isEmpty";
import {
  getSelectedVouchers,
  removeVoucherFromSelectedVouchers,
} from "../../../redux/actions/vouchersActions";
import { Table } from "react-bootstrap";
import LoadingSpinner from "../../../components/loading-spinner/LoadingSpinner";
import FEPagination from "../../../components/pagination/fe-pagination/FEPagination";
import { truncateString } from "../../../utils/truncateString";

// Interfaces
interface IProps {
  isLoading: boolean;
  rowsPerPage: number;
  successHandlerObj: SuccessHandlerType;
  setIsLoading: SetIsLoadingType;
  setIsTableActionLoading: SetIsLoadingType;
  setErrorHandlerObj: SetErrorHandlerType;
  setSuccessHandlerObj: SetSuccessHandlerType;
}

function SelectedVouchersTable({
  isLoading,
  rowsPerPage,
  successHandlerObj,
  setIsLoading,
  setIsTableActionLoading,
  setErrorHandlerObj,
  setSuccessHandlerObj,
}: IProps) {
  // Functions, States and Variables
  const dispatch = useAppDispatch();
  const { searchedValue }: any = useContext(SearchContext);
  const selectedVouchers: ISelectedVoucher[] | [] = useAppSelector(
    (state) => state.vouchers.selectedVouchers
  );
  //   States
  //   const [vouchersDateFilter, setVouchersDateFilter] = useState("");
  const [allSelectedVouchers, setAllSelectedVouchers] = useState<
    ISelectedVoucher[] | null
  >(null);
  const [currentSelectedVouchersPosts, setCurrentSelectedVouchersPosts] =
    useState<ISelectedVoucher[] | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastPost = currentPage * rowsPerPage;
  const indexOfFirstPost = indexOfLastPost - rowsPerPage;

  //   Functions
  // Functions
  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  //   handle Filter Table
  const handleFilterTable = function () {
    if (!selectedVouchers || isEmpty(selectedVouchers)) return false;
    // Search through voucher name and merchant shop name
    const vouchersData = selectedVouchers?.filter(
      (voucher) =>
        voucher.voucher?.business_name
          ?.toLowerCase()
          .includes(searchedValue.toLowerCase()) ||
        voucher.voucher?.source
          ?.toLowerCase()
          .includes(searchedValue.toLowerCase())
    );
    setAllSelectedVouchers(vouchersData);
    // Update table state to products rows
    if (!vouchersData) return false;
    const currentRows = vouchersData.slice(indexOfFirstPost, indexOfLastPost);
    setCurrentSelectedVouchersPosts(currentRows);
  };

  // Handle Remove Selected Voucher
  const handleRemoveFromSelectedVouchers = function (voucherId: string) {
    setErrorHandlerObj({ hasError: false, message: "" });

    // Call the API to add to selected vouchers
    dispatch(
      removeVoucherFromSelectedVouchers(
        voucherId,
        setIsTableActionLoading,
        setErrorHandlerObj,
        setSuccessHandlerObj
      )
    );
  };

  //   UseEffects
  useEffect(() => {
    if (isEmpty(selectedVouchers)) {
      dispatch(getSelectedVouchers(setIsLoading, setErrorHandlerObj));
    }
  }, []);

  useEffect(() => {
    if (selectedVouchers) setAllSelectedVouchers(selectedVouchers);
  }, [selectedVouchers]);

  // For Categories
  useEffect(() => {
    if (allSelectedVouchers) {
      const currentRows = allSelectedVouchers?.slice(
        indexOfFirstPost,
        indexOfLastPost
      );
      setCurrentSelectedVouchersPosts(currentRows);
    }
  }, [allSelectedVouchers, currentPage]);

  useEffect(() => {
    handleFilterTable();
    setCurrentPage(1);
  }, [searchedValue]);

  useEffect(() => {
    if (successHandlerObj.isSuccess) setCurrentPage(1);
  }, [successHandlerObj]);

  return (
    <div>
      {/* Table Section Top Wrapper */}
      <div className="product-categories-table--top-wrapper">
        {/* Product Categories Table Filter Wrapper */}
        {/* <div className="primary-table-filter-option-wrapper">
          <div className="primary-table-filter-row-title">Filter:</div>
          <div className="products--date-filter-form-group form-group">
            <DropdownInput
              className={"voucher-date-filter-dropdown-input"}
              id={"voucher-date-filter-input"}
              label={""}
              required={true}
              optionArray={[
                { key: "", value: "Date", default: "Default" },
                {
                  key: "yesterday",
                  value: "Yesterday",
                },
              ]}
              value={vouchersDateFilter}
              onChange={(e) => setVouchersDateFilter(e.target.value)}
            />
          </div>
        </div> */}
      </div>

      <div className="primary-table-container">
        <Table responsive="lg" className="primary-table vocuhers-table">
          <thead
            style={{
              textAlign: "left",
            }}
          >
            <tr>
              {[
                "SOURCE",
                "VOUCHER NAME",
                "CURRENCY CODE",
                "MINIMUM AMOUNT",
                "MAXIMUM AMOUNT",
                "",
              ].map((COLUMN, index) => (
                <th key={index + 1}>{COLUMN}</th>
              ))}
            </tr>
          </thead>

          <tbody>
            {currentSelectedVouchersPosts?.map((voucherObj, index) => (
              <tr key={index + 1}>
                {/*  Source Column */}
                <td className="td-voucher">
                  <div className="td-voucher--wrapper">
                    {voucherObj.voucher.source}
                  </div>
                </td>

                {/* Voucher Name Column */}
                <td className="td-name">
                  <div className="td-name--wrapper">
                    {voucherObj.voucher?.name
                      ? truncateString(voucherObj.voucher.name, 30)
                      : "NIL"}
                  </div>
                </td>

                {/* Currency Code Column */}
                <td className="td-voucher">
                  <div className="td-voucher--wrapper">
                    {voucherObj.voucher.currency_code}
                  </div>
                </td>

                {/*  Minimum Amount Column */}
                <td className="td-amount">
                  <div className="td-amount--wrapper">
                    {voucherObj.voucher.minimum_value.toLocaleString()}
                  </div>
                </td>

                {/*  Maximum Amount Column */}
                <td className="td-amount">
                  <div className="td-amount--wrapper">
                    {voucherObj.voucher.maximum_value.toLocaleString()}
                  </div>
                </td>

                {/*  Add to selected vouchers Column */}
                <td className="td-hot-deal">
                  <div className="td-hot-deal-button--wrapper">
                    <div className="remove-selected-voucher-button-wrapper">
                      <button
                        onClick={() =>
                          handleRemoveFromSelectedVouchers(voucherObj._id)
                        }
                      >
                        Remove from selected vouchers
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div className="below-table-loader-wrapper">
          {isLoading && <LoadingSpinner />}
        </div>
      </div>
      {allSelectedVouchers && !isEmpty(currentSelectedVouchersPosts) && (
        <FEPagination
          paginate={paginate}
          rowsPerPage={rowsPerPage}
          totalPosts={allSelectedVouchers.length}
          currentPage={currentPage}
        />
      )}
    </div>
  );
}

export default SelectedVouchersTable;

import axios from "axios";
import { GET_ALL_BRANDS } from "../Constants";
import { api } from "../../api/config";
import {
  SetErrorHandlerType,
  SetIsLoadingType,
  SetSuccessHandlerType,
} from "../../Types";
import { handleAPIError } from "../../utils/handleAPIError";

const url = `${api}/api/v1/admin/brand`;

const token: any = localStorage.getItem("jwtToken");
axios.defaults.headers.common["authorization"] = token;

interface INewBrand {
  name: string;
}
// Get all Brands
export const getAllBrands =
  (setIsLoading: SetIsLoadingType, setErrorHandlerObj: SetErrorHandlerType) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .get(`${api}/api/v1/brands`)
      .then((res) => {
        const allBrands = res.data.data;
        const allActiveBrands = allBrands?.filter(
          (brand: any) => brand.isActive === true
        );
        dispatch({
          type: GET_ALL_BRANDS,
          payload: allActiveBrands,
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

//   Create a brand
export const createABrand =
  (
    data: INewBrand,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setErrorHandlerObj: SetErrorHandlerType,
    setSuccessHandlerObj: SetSuccessHandlerType,
    setIsCreateEditProductActionModalOpened: (
      value: React.SetStateAction<boolean>
    ) => void
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .post(`${url}`, data)
      .then((res) => {
        // console.log(res.data);

        // Set Success
        setSuccessHandlerObj({
          isSuccess: true,
          message: "Brand created successfully!",
        });
        setIsLoading(false);
        // Close confirmation modal
        setIsCreateEditProductActionModalOpened(false);
      })
      .catch((err) => {
        // Close confirmation modal
        setIsCreateEditProductActionModalOpened(false);
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

//   Delete a Brand
export const deleteABrand =
  (
    brandId: string,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setErrorHandlerObj: SetErrorHandlerType,
    setSuccessHandlerObj: SetSuccessHandlerType,
    setIsDeleteBrandModalOpened: (value: React.SetStateAction<boolean>) => void
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .patch(`${url}/delete/${brandId}`)
      .then((res) => {
        // console.log(res.data);

        // close confirmation modal
        setIsDeleteBrandModalOpened(false);
        // Set Success
        setSuccessHandlerObj({
          isSuccess: true,
          message: "Brand deleted successfully!",
        });
        setIsLoading(false);
      })
      .catch((err) => {
        // close confirmation modal
        setIsDeleteBrandModalOpened(false);
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

import { MouseEvent, useState } from "react";
import { useAppDispatch } from "../../../../hooks";
import { logoutUser } from "../../../../redux/actions/authActions";
import { useNavigate } from "react-router-dom";

// Interfaces
interface IProps {
  pageType: string;
  pageContent: string;
  setPageContent: React.Dispatch<React.SetStateAction<string>>;
}

function SetupNav({ pageType, pageContent, setPageContent }: IProps) {
  // Functions, States and Variables
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  // States
  const [tabOptions, setTabOptions] = useState([
    // {
    //   key: "administrators",
    //   value: "Administrators",
    // },
    {
      key: "currency-rates",
      value: "Currency Rates",
    },
  ]);
  // Functions
  // handle Log Out User
  const handleLogOutUser = function (e: MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    dispatch(logoutUser(navigate));
  };

  return (
    <div className="dashboard-setup-layout--left-section dashboard-setup-layout-navigation-container">
      {/* Account Setup / Settings Nav Link */}
      {tabOptions.map((tabOption, index) => (
        <div key={index + 1} className="form-navigation-link">
          <button
            className={`${
              pageContent === tabOption.key && "active-form-nav-link"
            }`}
            onClick={() => setPageContent(tabOption.key)}
          >
            {tabOption.value}
          </button>
        </div>
      ))}

      {/* Log out btn wrapper */}
      {pageType === "settings" && (
        <div className="log-out-btn-wrapper">
          <button onClick={(e) => handleLogOutUser(e)}>Log out</button>
        </div>
      )}
    </div>
  );
}

export default SetupNav;

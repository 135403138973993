import { useEffect, useState } from "react";
import Top from "../../components/app-top/Top";
import Sidebar from "../../components/app-sidebar/Sidebar";
import Main from "../../components/layout/main-container/Main";
import ErrorHandler from "../../components/error/ErrorHandler";
import SuccessHandler from "../../components/success/SuccessHandler";
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks";
import useDisplayMessage from "../../hooks/useDisplayMessage";
import { getAllAdministrators } from "../../redux/actions/administratorsActions";
import AdminsTop from "./comps/admins-top/AdminsTop";
import AdminsTableSection from "./comps/admins-table-section/AdminsTableSection";

function Administrators() {
  // Functions, States and Variables
  const location = useLocation();
  const dispatch = useAppDispatch();
  const admins = useAppSelector((state) => state.administrators.admins);

  //   States
  const [isLoading, setIsLoading] = useState(false);
  //   Hooks
  // Handles Display the error and success message and scrolls to top
  const {
    errorHandlerObj,
    setErrorHandlerObj,
    successHandlerObj,
    setSuccessHandlerObj,
  } = useDisplayMessage();

  // useEffects
  useEffect(() => {
    dispatch(getAllAdministrators(setIsLoading, setErrorHandlerObj));
  }, []);

  useEffect(() => {
    if (location.state) {
      const { message }: any = location.state;
      if (message) setSuccessHandlerObj({ isSuccess: true, message });

      if (message) {
        // Cleanup function that clears location state
        return () => {
          window.history.replaceState({}, document.title);
        };
      }
    }
  }, [location]);

  return (
    <div className="app-page-container">
      <Top />
      <Sidebar activeName={"Administrators"} />

      <Main>
        {/* Error handler component */}
        <ErrorHandler
          errorHandlerObj={errorHandlerObj}
          className="app--actions-handler-wrapper"
        />
        {/* Success handler component */}
        <SuccessHandler
          successHandlerObj={successHandlerObj}
          className="app--actions-handler-wrapper"
        />
        <section className={`app-main-section--inner`}>
          {/* Administrators top container */}
          <AdminsTop adminsCount={admins?.length || 0} />

          {/* Administrators Table  */}
          <AdminsTableSection admins={admins} isLoading={isLoading} />
        </section>
      </Main>
    </div>
  );
}

export default Administrators;

import {
  GET_ALL_PRODUCT_DEALS,
  GET_ALL_PURCHASE_DEALS,
  GET_SEARCHED_PURCHASE_DEAL,
} from "../Constants";

const initialState = {
  deals: [],
  purchaseDeals: [],
  searchedPurchaseDeal: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (
  state = initialState,
  action: { type?: string; payload?: any }
) {
  switch (action.type) {
    case GET_ALL_PRODUCT_DEALS:
      return {
        ...state,
        deals: action.payload,
      };
    case GET_ALL_PURCHASE_DEALS:
      return {
        ...state,
        purchaseDeals: action.payload,
      };
    case GET_SEARCHED_PURCHASE_DEAL:
      return {
        ...state,
        searchedPurchaseDeal: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
}

import React from "react";
import "./PurchasesTable.css";
import { Table } from "react-bootstrap";
import FEPagination from "../../../components/pagination/fe-pagination/FEPagination";
import moment from "moment";
import isEmpty from "../../../validation/isEmpty";
import LoadingSpinner from "../../../components/loading-spinner/LoadingSpinner";
import { ISingleVoucherPurchase } from "../../../Types";

interface IProps {
  allPurchases: ISingleVoucherPurchase[] | null;
  currentPurchasesPosts: ISingleVoucherPurchase[] | null;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  rowsPerPage: number;
  isLoading: boolean;
}

const PurchasesTable = function ({
  allPurchases,
  currentPurchasesPosts,
  currentPage,
  setCurrentPage,
  rowsPerPage,
  isLoading,
}: IProps) {
  // Functions and States

  // Functions
  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  return (
    <>
      {/* Orders Table Container */}
      <div className="primary-table-container">
        <Table responsive="lg" className="primary-table">
          <thead
            style={{
              textAlign: "left",
            }}
          >
            <tr>
              {["USER", "ITEM", "MERCHANT", "COMPANY", "AMOUNT", "DATE"].map(
                (COLUMN, index) => (
                  <th key={index + 1}>{COLUMN}</th>
                )
              )}
            </tr>
          </thead>

          <tbody>
            {currentPurchasesPosts?.map((purchase, i) => (
              <tr key={i + 1}>
                {/* User Name Column */}
                <td className="td-user-name">
                  <div className="td-user-name--wrapper">
                    {/* {purchase.userId?.firstName} {purchase.userId?.lastName} */}
                    Faruq Adeyeye
                  </div>
                </td>

                {/*  ITEMS Column */}
                <td className="td-items">
                  <div className="td-items--wrapper">
                    {/* {purchase.items.productId?.name} */}
                    Amazon voucher
                  </div>
                </td>

                {/*  Merchant Column */}
                <td className="td-merchant">
                  <div className="td-merchant--wrapper">
                    {purchase.items.merchantId?.shopName || "ABC Store"}
                  </div>
                </td>

                {/*  Company Column */}
                <td className="td-company">
                  <div className="td-company--wrapper">
                    {/* {purchase.items.merchantId?.shopName || "--"} */}
                    Paystack Limited
                  </div>
                </td>

                {/*  Amount Column */}
                <td className="td-amount">
                  <div className="td-amount--wrapper">
                    {purchase.amountPaid.toLocaleString()}
                  </div>
                </td>

                {/*  Date Added Column */}
                <td className="td-date-added">
                  <div className="td-date-added--wrapper">
                    {moment(purchase.createdAt).format("Do MMM, YYYY")}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div className="below-table-loader-wrapper">
          {isLoading && <LoadingSpinner />}
        </div>
      </div>

      {allPurchases && !isEmpty(currentPurchasesPosts) && (
        <FEPagination
          paginate={paginate}
          rowsPerPage={rowsPerPage}
          totalPosts={allPurchases.length}
          currentPage={currentPage}
        />
      )}
    </>
  );
};

export default PurchasesTable;

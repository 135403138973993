import dashboardIcon from "../images/sidebar-icons/dashboard-icon.svg";
import dashboardIconActive from "../images/sidebar-icons/dashboard-icon-active.svg";
import requestsIcon from "../images/sidebar-icons/requests-icon.svg";
import requestsIconActive from "../images/sidebar-icons/requests-icon-active.svg";
import withdrawalsIcon from "../images/sidebar-icons/withdrawals-icon.svg";
import withdrawalsIconActive from "../images/sidebar-icons/withdrawals-icon-active.svg";
import merchantsIcon from "../images/sidebar-icons/merchants-icon.svg";
import merchantsIconActive from "../images/sidebar-icons/merchants-icon-active.svg";
import employersIcon from "../images/sidebar-icons/employers-icon.svg";
import employersIconActive from "../images/sidebar-icons/employers-icon-active.svg";
import usersIcon from "../images/sidebar-icons/users-icon.svg";
import usersIconActive from "../images/sidebar-icons/users-icon-active.svg";
import productsIcon from "../images/sidebar-icons/products-icon.svg";
import productsIconActive from "../images/sidebar-icons/products-icon-active.svg";
import wellnessIcon from "../images/sidebar-icons/wellness-icon.svg";
import wellnessIconActive from "../images/sidebar-icons/wellness-icon-active.svg";
import settlementsIcon from "../images/sidebar-icons/settlements-icon.svg";
import settlementsIconActive from "../images/sidebar-icons/settlements-icon-active.svg";
import purchasesAndOrdersIcon from "../images/sidebar-icons/purchases-and-orders-icon.svg";
import purchasesAndOrdersIconActive from "../images/sidebar-icons/purchases-and-orders-icon-active.svg";
import dealIcon from "../images/sidebar-icons/deals-icon.svg";
import dealIconActive from "../images/sidebar-icons/deals-icon-active.svg";
import subscriptionsIcon from "../images/sidebar-icons/subscription-icon.svg";
import subscriptionsIconActive from "../images/sidebar-icons/subscription-icon-active.svg";
import administratorsIcon from "../images/sidebar-icons/admin-icon.svg";
import administratorsIconActive from "../images/sidebar-icons/admin-icon-active.svg";
import auditTrailIcon from "../images/sidebar-icons/audit-trail-icon.svg";
import auditTrailIconActive from "../images/sidebar-icons/audit-trail-icon-active.svg";
import notificationsIcon from "../images/sidebar-icons/notifications-icon.svg";
import notificationsIconActive from "../images/sidebar-icons/notifications-icon-active.svg";
import productDealsIcon from "../images/sidebar-icons/product-deals-icon.svg";
import productDealsIconActive from "../images/sidebar-icons/product-deals-icon-active.svg";

export interface IAllNavLinks {
  name: string;
  active: boolean;
  icon: string;
  activeIcon: string;
  link: string;
}

export const allNavLinks = [
  {
    name: "Dashboard",
    active: false,
    icon: dashboardIcon,
    activeIcon: dashboardIconActive,
    link: "/admin/dashboard",
  },
  {
    name: "Requests",
    active: false,
    icon: requestsIcon,
    activeIcon: requestsIconActive,
    link: "/admin/requests",
  },
  {
    name: "Withdrawals",
    active: false,
    icon: withdrawalsIcon,
    activeIcon: withdrawalsIconActive,
    link: "/admin/withdrawals",
  },
  {
    name: "Merchants",
    active: false,
    icon: merchantsIcon,
    activeIcon: merchantsIconActive,
    link: "/admin/merchants",
  },
  {
    name: "Employers",
    active: false,
    icon: employersIcon,
    activeIcon: employersIconActive,
    link: "/admin/employers",
  },
  {
    name: "Users",
    active: false,
    icon: usersIcon,
    activeIcon: usersIconActive,
    link: "/admin/users",
  },
  {
    name: "Products",
    active: false,
    icon: productsIcon,
    activeIcon: productsIconActive,
    link: "/admin/products",
  },
  {
    name: "Learning",
    active: false,
    icon: wellnessIcon,
    activeIcon: wellnessIconActive,
    link: "/admin/wellness",
  },
  {
    name: "Purchases",
    active: false,
    icon: purchasesAndOrdersIcon,
    activeIcon: purchasesAndOrdersIconActive,
    link: "/admin/purchases",
  },
  {
    name: "Voucher Deals",
    active: false,
    icon: dealIcon,
    activeIcon: dealIconActive,
    link: "/admin/deals",
  },
  {
    name: "Product Deals",
    active: false,
    icon: productDealsIcon,
    activeIcon: productDealsIconActive,
    link: "/admin/product-deals",
  },
  {
    name: "Settlements",
    active: false,
    icon: settlementsIcon,
    activeIcon: settlementsIconActive,
    link: "/admin/settlements",
  },
  {
    name: "Administrators",
    active: false,
    icon: administratorsIcon,
    activeIcon: administratorsIconActive,
    link: "/admin/administrators",
  },
  {
    name: "Subscriptions",
    active: false,
    icon: subscriptionsIcon,
    activeIcon: subscriptionsIconActive,
    link: "/admin/subscriptions",
  },
  {
    name: "Notifications",
    active: false,
    icon: notificationsIcon,
    activeIcon: notificationsIconActive,
    link: "/admin/notifications",
  },
  {
    name: "Audit Trail",
    active: false,
    icon: auditTrailIcon,
    activeIcon: auditTrailIconActive,
    link: "/admin/audit-trail",
  },
];

import useDisplayMessage from "../../hooks/useDisplayMessage";
import Top from "../../components/app-top/Top";
import Sidebar from "../../components/app-sidebar/Sidebar";
import Main from "../../components/layout/main-container/Main";
import ErrorHandler from "../../components/error/ErrorHandler";
import SuccessHandler from "../../components/success/SuccessHandler";
import NotificationsFormSetup from "./comps/notifications-form-setup/NotificationsFormSetup";

function Notifications() {
  // Functions, States and Variables
  //   Hooks
  // Handles Display the error and success message and scrolls to top
  const {
    errorHandlerObj,
    setErrorHandlerObj,
    successHandlerObj,
    setSuccessHandlerObj,
  } = useDisplayMessage();

  return (
    <div className="app-page-container">
      <Top />
      <Sidebar activeName={"Notifications"} />

      <Main>
        {/* Error handler component */}
        <ErrorHandler
          errorHandlerObj={errorHandlerObj}
          className="app--actions-handler-wrapper"
        />
        {/* Success handler component */}
        <SuccessHandler
          successHandlerObj={successHandlerObj}
          className="app--actions-handler-wrapper"
        />
        <section className={`app-main-section--inner`}>
          {/* Notifications List Section  */}
          <NotificationsFormSetup
            setErrorHandlerObj={setErrorHandlerObj}
            setSuccessHandlerObj={setSuccessHandlerObj}
          />
        </section>
      </Main>
    </div>
  );
}

export default Notifications;

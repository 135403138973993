import React, { useEffect, useState } from "react";
import "./SubscriptionList.css";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { ISubscriptionPlan, SetErrorHandlerType } from "../../../../Types";
import { getAllSubscriptionPlans } from "../../../../redux/actions/subscriptionActions";
import LoadingSpinner from "../../../../components/loading-spinner/LoadingSpinner";
import SubscriptionPlanCard from "./subscription-plan-card/SubscriptionPlanCard";

// Interfaces
interface IProps {
  setErrorHandlerObj: SetErrorHandlerType;
}

function SubscriptionList({ setErrorHandlerObj }: IProps) {
  // Functions, States and Variables
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const subscriptionPlans = useAppSelector(
    (state) => state.subscription.subscriptionPlans
  );
  //   States
  const [currentActivePlanIndex, setCurrentActivePlanIndex] = useState(1);
  const [allSubscriptionPlans, setAllSubscriptionPlans] = useState<
    ISubscriptionPlan[] | []
  >([]);
  const [isLoading, setIsLoading] = useState(false);

  // UseEffects
  useEffect(() => {
    dispatch(getAllSubscriptionPlans(setIsLoading, setErrorHandlerObj));
  }, []);

  useEffect(() => {
    setAllSubscriptionPlans(subscriptionPlans);
  }, [subscriptionPlans]);

  return (
    <section className="subscription-list-section">
      {/* Sub Plan Choices Container */}
      <div className="subscription-plan-choices-container">
        {/* Plan Choice Wrapper */}
        {allSubscriptionPlans?.map((plan, index) => (
          <React.Fragment key={plan._id}>
            <SubscriptionPlanCard
              index={index}
              plan={plan}
              currentActivePlanIndex={currentActivePlanIndex}
              setCurrentActivePlanIndex={setCurrentActivePlanIndex}
            />
          </React.Fragment>
        ))}
      </div>
      {isLoading && (
        <div className="below-table-loader-wrapper ">
          <LoadingSpinner />
        </div>
      )}
    </section>
  );
}

export default SubscriptionList;

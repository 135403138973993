import React, { useEffect, useState } from "react";
import AdminFormSetup from "../admin-form-setup/AdminFormSetup";
import { IAdministrator } from "../../../Types";
import { useLocation, useNavigate } from "react-router-dom";

function EditAdmin() {
  // Functions, States and Variabes
  const location = useLocation();
  const navigate = useNavigate();
  // States
  const [currentAdminData, setCurrentAdminData] =
    useState<IAdministrator | null>(null);

  // UseEffects
  useEffect(() => {
    if (location.state) {
      const { currentAdmin }: any = location.state;
      if (currentAdmin) setCurrentAdminData(currentAdmin);
    } else {
      navigate(-1);
    }
  }, [location]);

  return (
    <>
      {currentAdminData && (
        <AdminFormSetup pageType="edit" currentAdminData={currentAdminData} />
      )}
    </>
  );
}

export default EditAdmin;

import { useEffect, useState } from "react";
import "./Deals.css";
import { useLocation } from "react-router-dom";
import Top from "../../components/app-top/Top";
import Sidebar from "../../components/app-sidebar/Sidebar";
import ErrorHandler from "../../components/error/ErrorHandler";
import SuccessHandler from "../../components/success/SuccessHandler";
import useDisplayMessage from "../../hooks/useDisplayMessage";
import DealsTop from "./comps/deals-top/DealsTop";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { IDeal } from "../../Types";
import { getDeals } from "../../redux/actions/dealActions";
import DealsTableSection from "./comps/deals-table-section/DealsTableSection";
import Main from "../../components/layout/main-container/Main";

function Deals() {
  // Functions, States and Variables
  const location = useLocation();
  const dispatch = useAppDispatch();
  const deals: IDeal[] | [] = useAppSelector((state) => state.deals.deals);

  //   States
  const [isLoading, setIsLoading] = useState(false);
  //   Hooks
  // Handles Display the error and success message and scrolls to top
  const {
    errorHandlerObj,
    setErrorHandlerObj,
    successHandlerObj,
    setSuccessHandlerObj,
  } = useDisplayMessage();

  // useEffects
  useEffect(() => {
    dispatch(getDeals(setIsLoading, setErrorHandlerObj));
  }, []);

  useEffect(() => {
    if (location.state) {
      const { message }: any = location.state;
      if (message) setSuccessHandlerObj({ isSuccess: true, message });

      if (message) {
        // Cleanup function that clears location state
        return () => {
          window.history.replaceState({}, document.title);
        };
      }
    }
  }, [location]);

  return (
    <div className="app-page-container">
      <Top />
      <Sidebar activeName={"Voucher Deals"} />

      <Main>
        {/* Error handler component */}
        <ErrorHandler
          errorHandlerObj={errorHandlerObj}
          className="app--actions-handler-wrapper"
        />
        {/* Success handler component */}
        <SuccessHandler
          successHandlerObj={successHandlerObj}
          className="app--actions-handler-wrapper"
        />

        <section className={`app-main-section--inner`}>
          {/* Deals top container */}
          <DealsTop dealsCount={deals?.length || 0} />

          {/* Deals Table Section */}
          <DealsTableSection deals={deals} isLoading={isLoading} />
        </section>
      </Main>
    </div>
  );
}

export default Deals;

import React, { MouseEvent, useEffect, useState } from "react";
import TextInput from "../../../../../components/input-components/text-input/TextInput";
import PrimaryButton from "../../../../../components/buttons/primary-button/PrimaryButton";
import LoadingSpinner from "../../../../../components/loading-spinner/LoadingSpinner";
import {
  IAssessmentDetailsInputStates,
  IAssessmentQuestionInputStates,
  SetErrorHandlerType,
  SetSuccessHandlerType,
} from "../../../../../Types";
import { useAppDispatch } from "../../../../../hooks";
import {
  createCourseAssessment,
  updateCourseAssessment,
} from "../../../../../redux/actions/wellnessActions";

// Interfaces
interface IProps {
  courseId: string;
  currentCourseAssessmentId: string;
  assessmentDetailsInputStates: IAssessmentDetailsInputStates;
  assessmentQuestionsInputStates: IAssessmentQuestionInputStates[];
  setAssessmentDetailsInputStates: React.Dispatch<
    React.SetStateAction<IAssessmentDetailsInputStates>
  >;
  setErrorHandlerObj: SetErrorHandlerType;
  setSuccessHandlerObj: SetSuccessHandlerType;
}

function AssessmentDetailsForm({
  courseId,
  currentCourseAssessmentId,
  assessmentDetailsInputStates,
  assessmentQuestionsInputStates,
  setAssessmentDetailsInputStates,
  setErrorHandlerObj,
  setSuccessHandlerObj,
}: IProps) {
  // Functions, States and variables
  const dispatch = useAppDispatch();
  // States
  const [isLoading, setIsLoading] = useState(false);
  // Functions

  //   Handle Update Form Input State
  const handleUpdateFormInputState = function (
    fieldName: string,
    fieldValue: string
  ) {
    let updatedFieldObjectForIndex = {
      ...assessmentDetailsInputStates,
    };

    if (fieldName === "scorePerQuestion") {
      const totalScore =
        Number(assessmentDetailsInputStates.noOfQuestions) * Number(fieldValue);
      if (!Number.isNaN(totalScore)) {
        updatedFieldObjectForIndex = {
          ...assessmentDetailsInputStates,
          totalScore: fieldValue === "" ? "0" : totalScore.toString() ?? "0",
          [fieldName]: fieldValue,
        };
      }
    } else {
      updatedFieldObjectForIndex = {
        ...assessmentDetailsInputStates,
        [fieldName]: fieldValue,
      };
    }
    setAssessmentDetailsInputStates(updatedFieldObjectForIndex);
  };

  // Handle Save Assessment Details
  const handleSaveAssessmentDetails = function (
    e: MouseEvent<HTMLButtonElement>
  ) {
    const isFormEmpty = Object.keys(assessmentDetailsInputStates).some(
      (inputKeys) =>
        assessmentDetailsInputStates[
          inputKeys as keyof typeof assessmentDetailsInputStates
        ] === ""
    );

    if (!isFormEmpty) {
      e.preventDefault();

      const data = {
        courseId,
        noOfQuestions: Number(assessmentDetailsInputStates.noOfQuestions),
        duration: assessmentDetailsInputStates.duration,
        scorePerQuestion: Number(assessmentDetailsInputStates.scorePerQuestion),
        totalScore: Number(assessmentDetailsInputStates.totalScore),
        passingScore: Number(assessmentDetailsInputStates.passingScore),
        questions: assessmentQuestionsInputStates,
      };
      // TODO Call the API to save course assessment
      console.log(data);

      if (courseId && currentCourseAssessmentId) {
        // Call the API to edit assessment
        dispatch(
          updateCourseAssessment(
            currentCourseAssessmentId,
            data,
            setIsLoading,
            setErrorHandlerObj,
            setSuccessHandlerObj
          )
        );
      } else {
        // Call the API to create assessment
        dispatch(
          createCourseAssessment(
            data,
            setIsLoading,
            setErrorHandlerObj,
            setSuccessHandlerObj
          )
        );
      }
    }
  };

  useEffect(() => {
    handleUpdateFormInputState(
      "noOfQuestions",
      assessmentQuestionsInputStates.length?.toString()
    );
  }, []);

  return (
    <section className="course-details-form-section">
      <div className="form-section-title-label">Assessment Details</div>

      <div className="course-details--form-wrapper course-details--form-wrapper--grid">
        {/* Number of Questions form group */}
        <div className="form-group">
          <TextInput
            type={"text"}
            className={"assessment-detail-input"}
            value={assessmentDetailsInputStates.noOfQuestions}
            onChange={(e) => {
              handleUpdateFormInputState("noOfQuestions", e.target.value);
            }}
            required={true}
            label={"Number of Questions"}
            readonly={true}
          />
        </div>

        {/* Duration form group */}
        <div className="form-group">
          <TextInput
            type={"text"}
            className={"assessment-detail-input"}
            value={assessmentDetailsInputStates.duration}
            onChange={(e) => {
              handleUpdateFormInputState("duration", e.target.value);
            }}
            required={true}
            label={"Duration(in minutes)"}
            placeholder="e.g 10 Minutes"
          />
        </div>

        {/* Score per Question form group */}
        <div className="form-group">
          <TextInput
            type={"number"}
            className={"assessment-detail-input"}
            value={assessmentDetailsInputStates.scorePerQuestion}
            onChange={(e) => {
              handleUpdateFormInputState("scorePerQuestion", e.target.value);
            }}
            required={true}
            label={"Score per Question"}
            placeholder="e.g 10"
          />
        </div>

        {/* Total Score form group */}
        <div className="form-group">
          <TextInput
            type={"text"}
            className={"assessment-detail-input"}
            value={assessmentDetailsInputStates.totalScore}
            onChange={(e) => {}}
            required={true}
            label={"Total Score"}
            readonly={true}
            placeholder="e.g 100"
          />
        </div>

        {/* Passing Score form group */}
        <div className="form-group">
          <TextInput
            type={"text"}
            className={"assessment-detail-input"}
            value={assessmentDetailsInputStates.passingScore}
            onChange={(e) => {
              handleUpdateFormInputState("passingScore", e.target.value);
            }}
            required={true}
            label={"Passing Score"}
            placeholder="e.g 70"
          />
        </div>
      </div>

      {/* Submit Button */}
      <div className="primary-button-container course-form--submit-btn-wrapper">
        <PrimaryButton
          className="course-content-form--action-btn"
          placeholder="Save Assessment"
          onClick={(e) => handleSaveAssessmentDetails(e)}
        />

        {isLoading && <LoadingSpinner />}
      </div>
    </section>
  );
}

export default AssessmentDetailsForm;

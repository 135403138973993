import axios from "axios";
import {
  GET_ALL_COURSES,
  GET_ALL_COURSE_CATEGORIES,
  GET_ALL_UNPUBLISHED_COURSES,
  GET_A_COURSE,
  GET_COURSE_ASSESSMENT_FOR_COURSE,
  GET_COURSE_CONTENT_FOR_COURSE,
  GET_COURSE_CURRICULUM,
  GET_COURSE_PROGRESS,
} from "../Constants";
import { api } from "../../api/config";
import { handleAPIError } from "../../utils/handleAPIError";
import {
  ICreateCourse,
  ICreateCourseAssessments,
  ICreateCourseContents,
  IUpdateCourseContents,
  SetErrorHandlerType,
  SetIsLoadingType,
  SetSuccessHandlerType,
} from "../../Types";

// Types and Interfaces
interface IRestrictAccessData {
  companies: {
    companyId: string;
  }[];
}

interface ICreateCourseCategory {
  name: string;
}
// URL
const url = `${api}/api/v1/admin`;

const token: any = localStorage.getItem("jwtToken");
axios.defaults.headers.common["authorization"] = token;

// Get all courses
export const getAllCourses =
  (setIsLoading: SetIsLoadingType, setErrorHandlerObj: SetErrorHandlerType) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .get(`${url}/course`)
      .then((res) => {
        dispatch({
          type: GET_ALL_COURSES,
          payload: res.data.data?.reverse(),
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        // Set the error handler state
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Get one course
export const getACourse =
  (
    courseId: string,
    setIsLoading: SetIsLoadingType,
    setErrorHandlerObj: SetErrorHandlerType
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .get(`${api}/api/v1/course/${courseId}`)
      .then((res) => {
        dispatch({
          type: GET_A_COURSE,
          payload: res.data.data,
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        // Set the error handler state
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Get Course Curriculum
export const getCourseCurriculum =
  (
    courseId: string,
    setIsLoading: SetIsLoadingType,
    setErrorHandlerObj: SetErrorHandlerType
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .get(`${api}/api/v1/course/curriculum/${courseId}`)
      .then((res) => {
        dispatch({
          type: GET_COURSE_CURRICULUM,
          payload: res.data.data,
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        // Set the error handler state
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Get Course Content for Selected Course
export const getCourseContentForCourse =
  (
    courseId: string,
    setIsLoading: SetIsLoadingType,
    setErrorHandlerObj: SetErrorHandlerType
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .get(`${url}/course/contents/${courseId}`)
      .then((res) => {
        dispatch({
          type: GET_COURSE_CONTENT_FOR_COURSE,
          payload: res.data.data,
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        // Set the error handler state
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Get Course Assessment for Selected Course
export const getCourseAssessmentForCourse =
  (
    courseId: string,
    setIsLoading: SetIsLoadingType,
    setErrorHandlerObj: SetErrorHandlerType
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .get(`${url}/course/assessment/${courseId}`)
      .then((res) => {
        dispatch({
          type: GET_COURSE_ASSESSMENT_FOR_COURSE,
          payload: res.data.data,
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        // Set the error handler state
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Get all unpublished courses
export const getAllUnpublishedCourses =
  (setIsLoading: SetIsLoadingType, setErrorHandlerObj: SetErrorHandlerType) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .get(`${url}/course/unpublish`)
      .then((res) => {
        dispatch({
          type: GET_ALL_UNPUBLISHED_COURSES,
          payload: res.data.data?.reverse(),
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        // Set the error handler state
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Get course progress
export const getCourseProgress =
  (
    courseId: string,
    setIsLoading: SetIsLoadingType,
    setErrorHandlerObj: SetErrorHandlerType
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .get(`${url}/course/progress/${courseId}`)
      .then((res) => {
        dispatch({
          type: GET_COURSE_PROGRESS,
          payload: res.data.data?.reverse(),
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        // Set the error handler state
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Get course categories
export const getCourseCategories =
  (setIsLoading: SetIsLoadingType, setErrorHandlerObj: SetErrorHandlerType) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .get(`${url}/course/category`)
      .then((res) => {
        dispatch({
          type: GET_ALL_COURSE_CATEGORIES,
          payload: res.data.data?.reverse(),
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        // Set the error handler state
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Create Course
export const createCourse =
  (
    data: ICreateCourse,
    setIsLoading: SetIsLoadingType,
    setErrorHandlerObj: SetErrorHandlerType,
    setSuccessHandlerObj: SetSuccessHandlerType,
    setActivePageNumber: (value: React.SetStateAction<number>) => void,
    setCurrentCourseId: React.Dispatch<React.SetStateAction<string>>
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .post(`${url}/course/create`, data)
      .then((res) => {
        // Move to course contents
        setActivePageNumber(2);
        // Get and Set Course Id
        setCurrentCourseId(res.data.data._id);
        setSuccessHandlerObj({
          isSuccess: true,
          message: "Course has been created successfully!",
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Update Course
export const updateCourse =
  (
    courseId: string,
    data: ICreateCourse,
    setIsLoading: SetIsLoadingType,
    setErrorHandlerObj: SetErrorHandlerType,
    setSuccessHandlerObj: SetSuccessHandlerType,
    setActivePageNumber: (value: React.SetStateAction<number>) => void
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .post(`${url}/course/update/${courseId}`, data)
      .then((res) => {
        // Move to course contents
        setActivePageNumber(2);
        setSuccessHandlerObj({
          isSuccess: true,
          message: "Course has been updated successfully!",
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Delete Course
export const deleteCourse =
  (
    courseId: string,
    setIsLoading: SetIsLoadingType,
    setErrorHandlerObj: SetErrorHandlerType,
    setSuccessHandlerObj: SetSuccessHandlerType,
    setPageView: React.Dispatch<React.SetStateAction<string>>
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .post(`${url}/course/delete/${courseId}`)
      .then((res) => {
        setPageView("main");
        setSuccessHandlerObj({
          isSuccess: true,
          message: "Course deleted successfully!",
        });
        setIsLoading(false);
        // Refectch all courses
        dispatch(getAllCourses(setIsLoading, setErrorHandlerObj));
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Create Course Contents
export const createCourseContents =
  (
    data: ICreateCourseContents,
    setIsLoading: SetIsLoadingType,
    setErrorHandlerObj: SetErrorHandlerType,
    setSuccessHandlerObj: SetSuccessHandlerType,
    setActivePageNumber: (value: React.SetStateAction<number>) => void
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .post(`${url}/course/content/create`, data)
      .then((res) => {
        setActivePageNumber(3);
        setSuccessHandlerObj({
          isSuccess: true,
          message: "Course contents has been created successfully!",
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Update Course Contents
export const updateCourseContents =
  (
    data: IUpdateCourseContents,
    setIsLoading: SetIsLoadingType,
    setErrorHandlerObj: SetErrorHandlerType,
    setSuccessHandlerObj: SetSuccessHandlerType,
    setActivePageNumber: (value: React.SetStateAction<number>) => void
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .post(`${url}/course/content/update`, data)
      .then((res) => {
        setActivePageNumber(3);
        setSuccessHandlerObj({
          isSuccess: true,
          message: "Course contents has been updated successfully!",
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Delete Course Contents
export const deleteCourseContents =
  (
    courseContentId: string,
    setIsLoading: SetIsLoadingType,
    setErrorHandlerObj: SetErrorHandlerType,
    setSuccessHandlerObj: SetSuccessHandlerType
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .post(`${url}/course/content/delete/${courseContentId}`)
      .then((res) => {
        setSuccessHandlerObj({
          isSuccess: true,
          message: "Course contents has been deleted successfully!",
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Create Course Assessment
export const createCourseAssessment =
  (
    data: ICreateCourseAssessments,
    setIsLoading: SetIsLoadingType,
    setErrorHandlerObj: SetErrorHandlerType,
    setSuccessHandlerObj: SetSuccessHandlerType
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .post(`${url}/course/assessment/create`, data)
      .then((res) => {
        setSuccessHandlerObj({
          isSuccess: true,
          message: "Course assessment has been created successfully!",
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Update Course Assessment
export const updateCourseAssessment =
  (
    courseAssessmentId: string,
    data: ICreateCourseAssessments,
    setIsLoading: SetIsLoadingType,
    setErrorHandlerObj: SetErrorHandlerType,
    setSuccessHandlerObj: SetSuccessHandlerType
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .post(`${url}/course/assessment/update/${courseAssessmentId}`, data)
      .then((res) => {
        setSuccessHandlerObj({
          isSuccess: true,
          message: "Course assessment has been updated successfully!",
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Delete Course Assessment
export const deleteCourseAssessment =
  (
    courseAssessmentId: string,
    setIsLoading: SetIsLoadingType,
    setErrorHandlerObj: SetErrorHandlerType,
    setSuccessHandlerObj: SetSuccessHandlerType
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .post(`${url}/course/assessment/delete/${courseAssessmentId}`)
      .then((res) => {
        setSuccessHandlerObj({
          isSuccess: true,
          message: "Course assessment has been deleted successfully!",
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Publish A Course
export const publishACourse =
  (
    courseId: string,
    setIsLoading: SetIsLoadingType,
    setErrorHandlerObj: SetErrorHandlerType,
    setSuccessHandlerObj: SetSuccessHandlerType,
    setPageView: React.Dispatch<React.SetStateAction<string>>
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .patch(`${url}/course/publish/${courseId}`)
      .then((res) => {
        setSuccessHandlerObj({
          isSuccess: true,
          message: "Course has been published successfully!",
        });
        setIsLoading(false);
        // Set Page content to main
        setPageView("main");
        // Refectch courses
        dispatch(getAllCourses(setIsLoading, setErrorHandlerObj));
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Unpublish A Course
export const unpublishACourse =
  (
    courseId: string,
    setIsLoading: SetIsLoadingType,
    setErrorHandlerObj: SetErrorHandlerType,
    setSuccessHandlerObj: SetSuccessHandlerType,
    setPageView: React.Dispatch<React.SetStateAction<string>>
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .patch(`${url}/course/unpublish/${courseId}`)
      .then((res) => {
        setSuccessHandlerObj({
          isSuccess: true,
          message: "Course has been unpublished successfully!",
        });
        setIsLoading(false);
        // Set Page content to main
        setPageView("main");
        // Refectch courses
        dispatch(getAllCourses(setIsLoading, setErrorHandlerObj));
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Restrict Course Access
export const restrictCourseAccess =
  (
    courseId: string,
    data: IRestrictAccessData,
    setIsLoading: SetIsLoadingType,
    setErrorHandlerObj: SetErrorHandlerType,
    setSuccessHandlerObj: SetSuccessHandlerType
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .patch(`${url}/course/company-access/${courseId}`, data)
      .then(() => {
        setSuccessHandlerObj({
          isSuccess: true,
          message: "Course access updated successfully!",
        });
        setIsLoading(false);
        // Refectch all courses
        dispatch(getAllCourses(setIsLoading, setErrorHandlerObj));
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Course Category
// Create Course Category
export const createCourseCategory =
  (
    data: ICreateCourseCategory,
    setIsLoading: SetIsLoadingType,
    setErrorHandlerObj: SetErrorHandlerType,
    setSuccessHandlerObj: SetSuccessHandlerType,
    setIsModalOpened: React.Dispatch<React.SetStateAction<boolean>>
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .post(`${url}/course/category/create`, data)
      .then((res) => {
        // Close Modal
        setIsModalOpened(false);
        setSuccessHandlerObj({
          isSuccess: true,
          message: "Course category has been created successfully!",
        });
        setIsLoading(false);
        // Refetch Course Categories
        dispatch(getCourseCategories(setIsLoading, setErrorHandlerObj));
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Update Course Category
export const updateCourseCategory =
  (
    courseCategoryId: string,
    data: ICreateCourseCategory,
    setIsLoading: SetIsLoadingType,
    setErrorHandlerObj: SetErrorHandlerType,
    setSuccessHandlerObj: SetSuccessHandlerType,
    setIsModalOpened: React.Dispatch<React.SetStateAction<boolean>>
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .post(`${url}/course/category/update/${courseCategoryId}`, data)
      .then((res) => {
        // Close Modal
        setIsModalOpened(false);
        setSuccessHandlerObj({
          isSuccess: true,
          message: "Course category has been updated successfully!",
        });
        setIsLoading(false);
        // Refetch Course Categories
        dispatch(getCourseCategories(setIsLoading, setErrorHandlerObj));
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Delete Course Category
export const deleteCourseCategory =
  (
    courseCategoryId: string,
    setIsLoading: SetIsLoadingType,
    setErrorHandlerObj: SetErrorHandlerType,
    setSuccessHandlerObj: SetSuccessHandlerType
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .post(`${url}/course/category/delete/${courseCategoryId}`)
      .then((res) => {
        setSuccessHandlerObj({
          isSuccess: true,
          message: "Course category has been deleted successfully!",
        });
        setIsLoading(false);
        // Refetch Course Categories
        dispatch(getCourseCategories(setIsLoading, setErrorHandlerObj));
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

import { useContext, useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { SearchContext } from "../../../../components/contexts/SearchContext";
import FEPagination from "../../../../components/pagination/fe-pagination/FEPagination";
import { numberWithCommas } from "../../../../utils/numberWithCommas";
import isEmpty from "../../../../validation/isEmpty";
import "./CompanyStaffTable.css";
import { ISingleCompany, ISingleEmployee } from "../../../../Types";

// Interfaces
interface IProps {
  pageContent: string;
  selectedCompanyForPreview: ISingleCompany | null;
}

interface ICompanyStaffObj {
  staffId: ISingleEmployee;
  _id: string;
}

function CompanyStaffTable({ pageContent, selectedCompanyForPreview }: IProps) {
  // Functions, States and Variables
  const { searchedValue }: any = useContext(SearchContext);
  const [allCompanyStaff, setAllCompanyStaff] = useState<
    ICompanyStaffObj[] | null
  >(null);
  // States
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [currentCompanyStaffPosts, setCurrentCompanyStaffPosts] = useState<
    ICompanyStaffObj[] | null
  >(null);
  const indexOfLastPost = currentPage * rowsPerPage;
  const indexOfFirstPost = indexOfLastPost - rowsPerPage;

  // Functions

  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  //   handle Filter Table
  const handleFilterTable = function () {
    if (!selectedCompanyForPreview || isEmpty(selectedCompanyForPreview.staff))
      return false;

    const allCompanyStaffData = selectedCompanyForPreview.staff.filter(
      (staff) =>
        staff.staffId?.firstName
          .toLowerCase()
          .includes(searchedValue.toLowerCase()) ||
        staff.staffId?.lastName
          .toLowerCase()
          .includes(searchedValue.toLowerCase())
    );

    setAllCompanyStaff(allCompanyStaffData);
    // Update table state to company staff rows
    if (!allCompanyStaffData) return false;
    const currentRows = allCompanyStaffData.slice(
      indexOfFirstPost,
      indexOfLastPost
    );

    // console.log(allCompanyStaffData, "heree");
    setCurrentCompanyStaffPosts(currentRows);
  };

  // UseEffects
  useEffect(() => {
    handleFilterTable();
    setCurrentPage(1);
  }, [searchedValue, selectedCompanyForPreview]);

  useEffect(() => {
    if (allCompanyStaff) {
      const currentRows = allCompanyStaff?.slice(
        indexOfFirstPost,
        indexOfLastPost
      );

      setCurrentCompanyStaffPosts(currentRows);
    }
  }, [currentPage]);

  useEffect(() => {
    // If pagecontent should go to main from preview
    if (pageContent === "main") {
      setAllCompanyStaff(null);
      setCurrentCompanyStaffPosts(null);
    }
  }, [pageContent]);

  return (
    <div className="company-staff-table-container">
      <div className="company-staff-table-container--inner"></div>{" "}
      <div className="primary-table-container">
        <Table responsive="lg" className="primary-table">
          <thead
            style={{
              textAlign: "left",
            }}
          >
            <tr>
              {[
                "NAME",
                "EMAIL",
                "DEPARTMENT",
                "PHONE NUMBER",
                "SALARY (N)",
              ].map((COLUMN, index) => (
                <th key={index + 1}>{COLUMN}</th>
              ))}
            </tr>
          </thead>

          <tbody>
            {currentCompanyStaffPosts?.map((employee, i) => (
              <tr key={i + 1}>
                {/* Employee Name Column */}
                <td className="td--name">
                  <div className="td--name--wrapper">
                    {/* profile image wrapper */}
                    {/* <div className="profile-image-wrapper">
                      <img src={employee?.imgURL} alt="" />
                    </div> */}
                    {employee.staffId.firstName} {employee.staffId.lastName}
                  </div>
                </td>

                {/*  Employee Email Column */}
                <td className="td-email">
                  <div className="td-email--wrapper">
                    {employee.staffId.email}
                  </div>
                </td>

                {/*  Department Column */}
                <td className="td-company-name">
                  <div className="td-company-name--wrapper">
                    {employee.staffId?.department || "---"}
                  </div>
                </td>

                {/*  Phone Number Column */}
                <td className="td-phone-number">
                  <div className="td-phone-number--wrapper">
                    {employee.staffId?.phoneNumber || "---"}
                  </div>
                </td>

                {/*  Salary Column */}
                <td className="td-salary">
                  <div className="td-salary--wrapper">
                    &#8358;{numberWithCommas(employee.staffId.salary)}
                  </div>
                </td>

                {/* Date Column*/}
                {/* <td className="td-date">
                  <div className="td-date--wrapper">
                    {moment(employee.createdAt).format("Do MMM, YY")}
                  </div>
                </td> */}
              </tr>
            ))}
          </tbody>
        </Table>
        {/* <div className="below-table-loader-wrapper">
          {isLoading && <SubstituteLoadingSpinner />}
        </div> */}
      </div>
      {allCompanyStaff && !isEmpty(currentCompanyStaffPosts) && (
        <FEPagination
          paginate={paginate}
          rowsPerPage={rowsPerPage}
          totalPosts={allCompanyStaff.length}
          currentPage={currentPage}
        />
      )}
    </div>
  );
}

export default CompanyStaffTable;

import React, { useState } from "react";
import { IProductDeal } from "../../../Types";
import Top from "../../../components/app-top/Top";
import ProductDealSetup from "../deals-setup/ProductDealSetup";

function ProductDealCreate() {
  // Funtion, State and Variables
  // States
  const [currentDealObj, setCurrentDealObj] = useState<IProductDeal | null>(
    null
  );
  const [pageContent, setPageContent] = useState("deal-info");
  const [isLoading, setIsLoading] = useState(false);
  // Delete deal modal
  const [isDeleteDealModalOpened, setIsDeleteDealModalOpened] = useState(false);

  return (
    <div
      className={`dashboard-setup-container ${
        isDeleteDealModalOpened ? "overflow-hidden" : ""
      }`}
    >
      <Top />

      <div
        onClick={() => {
          setIsDeleteDealModalOpened(false);
        }}
        className={`gray-overlay ${isDeleteDealModalOpened ? "" : "none"}`}
      ></div>

      {/*Dashboard Setup Section */}
      <section className={`dashboard-setup-form-section`}>
        <ProductDealSetup
          currentDealObj={currentDealObj}
          pageType={"create"}
          pageContent={pageContent}
          isDeleteDealModalOpened={isDeleteDealModalOpened}
          setIsDeleteDealModalOpened={setIsDeleteDealModalOpened}
          setPageContent={setPageContent}
        />
      </section>
    </div>
  );
}

export default ProductDealCreate;

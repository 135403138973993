import React, { useEffect, useState } from "react";
import percentIncreaseIcon from "../../../images/percent-increase-icon.svg";
import percentDecreaseIcon from "../../../images/percent-decrease-icon.svg";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { getTotalAmountDisbursed } from "../../../redux/actions/dashboardActions";
import { SetErrorHandlerType } from "../../../Types";

// Interfaces
interface IProps {
  setErrorHandlerObj: SetErrorHandlerType;
}

interface ITotalDisbursedAmount {
  onDemand: number;
  salaryAdvance: number;
}
function DashboardRowOne({ setErrorHandlerObj }: IProps) {
  // Function, states and variables
  const dispatch = useAppDispatch();
  const disbursedAmount: ITotalDisbursedAmount = useAppSelector(
    (state) => state.dashboard.totalAmountDisbursed
  );
  // States
  const [totalAmountDisbursed, setTotalAmountDisbursed] = useState(0);
  const [amountTransactedPercentChange, setAmountTransactedPercentChange] =
    useState(-5);
  const [totalOnDemandAmount, setTotalOnDemandAmount] = useState(0);
  const [totalSalaryAdvanceAmount, setTotalSalaryAdvanceAmount] = useState(0);
  // New
  const [totalVouchersCount, setTotalVouchersCount] = useState(1000);
  const [totalPurchasesCount, setTotalPurchasesCount] = useState(3);
  const [totalPurchasesAmount, setTotalPurchasesAmount] = useState(255000);
  const [totalCoursesCount, setTotalCoursesCount] = useState(12);
  const [isLoading, setIsLoading] = useState(false);

  // UseEffects
  useEffect(() => {
    dispatch(getTotalAmountDisbursed(setIsLoading, setErrorHandlerObj));
  }, []);

  useEffect(() => {
    setTotalOnDemandAmount(disbursedAmount.onDemand);
    setTotalSalaryAdvanceAmount(disbursedAmount.salaryAdvance);
    setTotalAmountDisbursed(
      disbursedAmount.salaryAdvance + disbursedAmount.onDemand
    );
  }, [disbursedAmount]);

  return (
    <div className="dashboard-row--one-section--inner">
      {/* Box Container && TOTAL AMOUNT TRANSACTED */}
      <div className="dashboard-row-one-box-container">
        <div className="dashboard-box-title-wrapper">
          TOTAL AMOUNT TRANSACTED
        </div>

        {/* Bottom Wrapper */}
        <div className="dashboard-row-one-box--bottom-wrapper">
          <div className="dahboard-box-value-wrapper">
            &#8358;{totalAmountDisbursed?.toLocaleString()}
          </div>

          {/* percent change wrapper */}
          {/* <div
            className={`row--one-percent-change-wrapper ${
              amountTransactedPercentChange < 0
                ? "percent-decrease-wrapper"
                : "percent-increase-wrapper"
            }`}
          >
            {amountTransactedPercentChange.toString()}%{" "}
            <img
              src={
                amountTransactedPercentChange < 0
                  ? percentDecreaseIcon
                  : percentIncreaseIcon
              }
              alt=""
            />
          </div> */}
        </div>
      </div>

      {/* Box Container && BNPL (TAS) */}
      <div className="dashboard-row-one-box-container">
        <div className="dashboard-box-title-wrapper">ON DEMAND</div>

        {/* Bottom Wrapper */}
        <div className="dashboard-row-one-box--bottom-wrapper">
          <div className="dahboard-box-value-wrapper">
            &#8358;{totalOnDemandAmount?.toLocaleString()}
          </div>
        </div>
      </div>

      {/* Box Container && SALARY ADVANCE */}
      <div className="dashboard-row-one-box-container">
        <div className="dashboard-box-title-wrapper">SALARY ADVANCE</div>

        {/* Bottom Wrapper */}
        <div className="dashboard-row-one-box--bottom-wrapper">
          <div className="dahboard-box-value-wrapper">
            &#8358;{totalSalaryAdvanceAmount?.toLocaleString()}
          </div>
        </div>
      </div>

      {/* Box Container && VOUCHERS COUNT */}
      <div className="dashboard-row-one-box-container">
        <div className="dashboard-box-title-wrapper">
          TOTAL NUMBER OF VOUCHERS
        </div>

        {/* Bottom Wrapper */}
        <div className="dashboard-row-one-box--bottom-wrapper">
          <div className="dahboard-box-value-wrapper">
            {totalVouchersCount?.toLocaleString()}
          </div>
        </div>
      </div>

      {/* Box Container && PURCHASES COUNT */}
      <div className="dashboard-row-one-box-container">
        <div className="dashboard-box-title-wrapper">
          TOTAL NUMBER OF PURCHASES
        </div>

        {/* Bottom Wrapper */}
        <div className="dashboard-row-one-box--bottom-wrapper">
          <div className="dahboard-box-value-wrapper">
            {totalPurchasesCount?.toLocaleString()}
          </div>
        </div>
      </div>

      {/* Box Container && PURCHASES AMOUNT */}
      <div className="dashboard-row-one-box-container">
        <div className="dashboard-box-title-wrapper">
          TOTAL AMOUNT OF PURCHASES
        </div>

        {/* Bottom Wrapper */}
        <div className="dashboard-row-one-box--bottom-wrapper">
          <div className="dahboard-box-value-wrapper">
            &#8358;{totalPurchasesAmount?.toLocaleString()}
          </div>
        </div>
      </div>

      {/* Box Container && COURSES COUNT  */}
      <div className="dashboard-row-one-box-container">
        <div className="dashboard-box-title-wrapper">
          TOTAL NUMBER OF COURSES
        </div>

        {/* Bottom Wrapper */}
        <div className="dashboard-row-one-box--bottom-wrapper">
          <div className="dahboard-box-value-wrapper">
            {totalCoursesCount?.toLocaleString()}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardRowOne;

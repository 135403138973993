import React, { MouseEvent, useState } from "react";
import "./CourseContentForm.css";
import PrimaryButton from "../../../../../components/buttons/primary-button/PrimaryButton";
import LoadingSpinner from "../../../../../components/loading-spinner/LoadingSpinner";
import ContentTypeOptions from "./content-type-options/ContentTypeOptions";
import VideoCourseForm from "./form/video-course-form/VideoCourseForm";
import SlidesCourseForm from "./form/slides-course-form/SlidesCourseForm";
import NoteCourseForm from "./form/note-course-form/NoteCourseForm";
import SecondaryButton from "../../../../../components/buttons/secondary-button/SecondaryButton";
import {
  INoteCourseInputState,
  ISlideCourseInputState,
  IVideoCourseInputState,
  SetErrorHandlerType,
  SetSuccessHandlerType,
} from "../../../../../Types";
import { useAppDispatch } from "../../../../../hooks";
import {
  createCourseContents,
  updateCourseContents,
} from "../../../../../redux/actions/wellnessActions";

// Interfaces
interface IProps {
  currentCourseId: string;
  currentCourseContentId: string;
  courseContentType: string;
  courseContentInputStates: any;
  setCourseContentType: React.Dispatch<React.SetStateAction<string>>;
  setCourseContentInputStates: any;
  setActivePageNumber: React.Dispatch<React.SetStateAction<number>>;
  setSuccessHandlerObj: SetSuccessHandlerType;
  setErrorHandlerObj: SetErrorHandlerType;
}

function CourseContentForm({
  currentCourseId,
  currentCourseContentId,
  courseContentType,
  courseContentInputStates,
  setCourseContentType,
  setCourseContentInputStates,
  setActivePageNumber,
  setSuccessHandlerObj,
  setErrorHandlerObj,
}: IProps) {
  // Functions, States and Variables
  const dispatch = useAppDispatch();
  //   States
  const [isLoading, setIsLoading] = useState(false);

  // Functions

  // Handle Upload Course
  const handleUploadCourse = function (e: MouseEvent<HTMLButtonElement>) {
    const isVideoCourseObjectEmpty = courseContentInputStates?.some(
      (courseContent: IVideoCourseInputState) =>
        courseContent.videoTitle === "" || courseContent.videoUrl === ""
    );

    const isNoteCourseObjectEmpty = courseContentInputStates?.some(
      (courseContent: INoteCourseInputState) => courseContent.noteTitle === ""
    );

    const isSlideCourseObjectEmpty = courseContentInputStates?.some(
      (courseContent: ISlideCourseInputState) =>
        courseContent.slideTitle === "" || courseContent.slideUrl === ""
    );

    const isCourseContentFormEmpty =
      courseContentType === "video_course"
        ? isVideoCourseObjectEmpty
        : courseContentType === "note_course"
        ? isNoteCourseObjectEmpty
        : isSlideCourseObjectEmpty;

    if (!isCourseContentFormEmpty) {
      e.preventDefault();

      //  Call the API to create or update course content
      if (currentCourseContentId) {
        const allCourseContents = courseContentInputStates?.map(
          (courseContent: any, index: number) => {
            // Get Course Content Value
            const courseContentValue =
              courseContentType === "video_course"
                ? courseContent.videoUrl
                : courseContentType === "note_course"
                ? courseContent.noteValue
                : [{ content: courseContent.slideUrl, slideNo: 1 }];
            return {
              courseId: currentCourseId,
              courseContentId: currentCourseContentId,
              rank: index + 1,
              title:
                courseContentType === "video_course"
                  ? courseContent.videoTitle
                  : courseContentType === "note_course"
                  ? courseContent.noteTitle
                  : courseContent.slideTitle,
              [courseContentType === "video_course"
                ? "video"
                : courseContentType === "note_course"
                ? "note"
                : "slide"]: courseContentValue,
            };
          }
        );
        const data = {
          contents: allCourseContents,
        };
        // console.log(data);
        //  Call the API to update course content
        dispatch(
          updateCourseContents(
            data,
            setIsLoading,
            setErrorHandlerObj,
            setSuccessHandlerObj,
            setActivePageNumber
          )
        );
      } else {
        const allCourseContents = courseContentInputStates?.map(
          (courseContent: any, index: number) => {
            // Get Course Content Value
            const courseContentValue =
              courseContentType === "video_course"
                ? courseContent.videoUrl
                : courseContentType === "note_course"
                ? courseContent.noteValue
                : [{ content: courseContent.slideUrl, slideNo: 1 }];
            return {
              courseId: currentCourseId,
              rank: index + 1,
              title:
                courseContentType === "video_course"
                  ? courseContent.videoTitle
                  : courseContentType === "note_course"
                  ? courseContent.noteTitle
                  : courseContent.slideTitle,
              [courseContentType === "video_course"
                ? "video"
                : courseContentType === "note_course"
                ? "note"
                : "slide"]: courseContentValue,
            };
          }
        );

        const data = {
          contents: allCourseContents,
        };
        // console.log(data);
        //  Call the API to create course content
        dispatch(
          createCourseContents(
            data,
            setIsLoading,
            setErrorHandlerObj,
            setSuccessHandlerObj,
            setActivePageNumber
          )
        );
      }
    }
  };

  return (
    <section
      // id="first-section"
      className="course-details-form-section course---form-section"
    >
      {/* Course Content Type Options Section */}
      <ContentTypeOptions
        courseContentType={courseContentType}
        setCourseContentType={setCourseContentType}
      />

      {/* Video Course Form */}
      {courseContentType === "video_course" && (
        <VideoCourseForm
          courseContentInputStates={courseContentInputStates}
          setCourseContentInputStates={setCourseContentInputStates}
          setErrorHandlerObj={setErrorHandlerObj}
        />
      )}

      {/* Slides Course Form */}
      {courseContentType === "slides_course" && (
        <SlidesCourseForm
          courseContentInputStates={courseContentInputStates}
          setCourseContentInputStates={setCourseContentInputStates}
          setErrorHandlerObj={setErrorHandlerObj}
        />
      )}

      {/* Note Course Form */}
      {courseContentType === "note_course" && (
        <NoteCourseForm
          courseContentInputStates={courseContentInputStates}
          setCourseContentInputStates={setCourseContentInputStates}
        />
      )}

      <div className="course-content-action-btn-container">
        <SecondaryButton
          className="course-content-form--action-btn"
          placeholder="Assessment Questions"
          onClick={(e) => {
            // Move to course contents
            setActivePageNumber(3);
          }}
        />

        <div className="primary-button-container">
          <PrimaryButton
            className="course-content-form--action-btn"
            placeholder="Create Course Content"
            onClick={(e) => handleUploadCourse(e)}
          />
          {isLoading && <LoadingSpinner />}
        </div>
      </div>
    </section>
  );
}

export default CourseContentForm;

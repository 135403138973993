import React from "react";
import "./PrimaryButton.css";

// Interfaces
interface IProps {
  className?: string;
  placeholder: string;
  disabled?: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
  children?: React.ReactElement;
}

function PrimaryButton({
  className,
  placeholder,
  disabled,
  onClick,
  children,
}: IProps) {
  return (
    <div className={`primary-button-wrapper ${className}`}>
      <button onClick={onClick} disabled={disabled}>
        {placeholder}
        {children}
      </button>
    </div>
  );
}

export default PrimaryButton;

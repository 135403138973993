import React, { MouseEvent, useEffect, useState } from "react";
import "../../requests/modals/Modals.css";
import SubstituteLoadingSpinner from "../../../components/substitute-loading-spinner/SubstituteLoadingSpinner";
import { IAllRequests } from "../../withdrawals/Withdrawals";
import { useAppDispatch } from "../../../hooks";
import { declineWithdrawalRequest } from "../../../redux/actions/withdrawalRequestsActions";

interface IProps {
  setSuccessHandlerObj: React.Dispatch<
    React.SetStateAction<{
      isSuccess: boolean;
      message: string;
    }>
  >;
  setErrorHandlerObj: React.Dispatch<
    React.SetStateAction<{
      hasError: boolean;
      message: string;
    }>
  >;
  setIsDeclineEmployeeRequestModalOpened: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  isDeclineEmployeeRequestModalOpened: boolean;
  selectedEmployeeRequest: IAllRequests | null;
  setIsDeclined: React.Dispatch<React.SetStateAction<boolean>>;
  pageContent: string;
}

function DeclineRequestsModal({
  selectedEmployeeRequest,
  setIsDeclineEmployeeRequestModalOpened,
  isDeclineEmployeeRequestModalOpened,
  setErrorHandlerObj,
  setSuccessHandlerObj,
  setIsDeclined,
  pageContent,
}: IProps) {
  // Functions and states
  const dispatch = useAppDispatch();
  // States
  const [reasonOfDeclination, setReasonOfDeclination] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  //   Functions;
  const handleConfirmDeclineRequest = function (
    e: MouseEvent<HTMLButtonElement>
  ) {
    if (reasonOfDeclination !== "" && selectedEmployeeRequest) {
      e.preventDefault();
      setIsDeclined(false);

      const data = {
        reason: reasonOfDeclination,
      };
      // Call the dispatch function to decline an employee's request
      dispatch(
        declineWithdrawalRequest(
          data,
          selectedEmployeeRequest._id,
          setIsLoading,
          setErrorHandlerObj,
          setSuccessHandlerObj,
          setIsDeclined,
          setIsDeclineEmployeeRequestModalOpened
        )
      );
    }
  };

  useEffect(() => {
    if (!isDeclineEmployeeRequestModalOpened) setReasonOfDeclination("");
  }, [isDeclineEmployeeRequestModalOpened]);

  return (
    <div
      className={`decline-requests-modal-container ${
        isDeclineEmployeeRequestModalOpened ? "" : "none"
      }`}
    >
      <div className="decline-requests-modal-container--inner">
        <div className="decline-requests-modal-header">
          Decline Request {isLoading && <SubstituteLoadingSpinner />}
        </div>

        {/* Group name form wrapper */}
        <div className="reason-for-declination-form-wrapper">
          <form>
            {/* Reason for declination form group */}
            <div className="reason-for-declination-form-group form-group">
              <label htmlFor="reason-for-declination-textarea-input">
                Kindly input a comment as to why you are declining this employee{" "}
                <span>
                  {selectedEmployeeRequest?.userId?.firstName}{" "}
                  {selectedEmployeeRequest?.userId?.lastName}
                </span>
                's request
              </label>
              <textarea
                id={"reason-for-declination-textarea-input"}
                className={"reason-for-declination-input"}
                placeholder={""}
                value={reasonOfDeclination}
                onChange={(e) => setReasonOfDeclination(e.target.value)}
                required={true}
              />
            </div>

            <div className="decline-requests-modal-action-buttons-wrapper">
              {/* Submit Create Group Btn Wrapper */}
              <div className="submit-decline-requests-btn-wrapper">
                <button onClick={(e) => handleConfirmDeclineRequest(e)}>
                  Done
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default DeclineRequestsModal;

import React, { MouseEvent, useEffect, useState } from "react";
import "./AddNewCompany.css";
import { useNavigate } from "react-router-dom";
import authWaveImage from "../../images/add-new-user-wave-image.svg";
import ErrorHandler from "../error/ErrorHandler";
import angleLeft from "../../images/angle-left.svg";
import TextInput from "../input-components/text-input/TextInput";
import PhoneInput from "../input-components/phone-input/PhoneInput";
import eyeOpenIcon from "../../images/eye-open-icon.svg";
import eyeCloseIcon from "../../images/eye-close-icon.svg";
import LoadingSpinner from "../loading-spinner/LoadingSpinner";

function AddNewCompany() {
  // Functions, States and Variables
  const navigate = useNavigate();
  // States
  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const [errorHandlerObj, setErrorHandlerObj] = useState({
    hasError: false,
    message: "",
  });
  const [successHandlerObj, setSuccessHandlerObj] = useState({
    isSuccess: true,
    message: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  //   Functions
  const handleRegisterNewCompany = function (e: MouseEvent<HTMLButtonElement>) {
    if (
      companyName !== "" &&
      email !== "" &&
      phoneNumber !== "" &&
      password !== ""
    ) {
      // TODO Call the Register Company API with
      navigate("/admin/employers/account-registered");
    }
  };

  //   UseEffects
  useEffect(() => {
    if (errorHandlerObj.hasError || successHandlerObj.isSuccess) {
      //scroll to page top
      window.scrollTo({ top: 0 });
    }
  }, [errorHandlerObj, successHandlerObj]);

  // If response is failure, remove success message
  useEffect(() => {
    if (errorHandlerObj.hasError) {
      setSuccessHandlerObj({ isSuccess: false, message: "" });
    }
  }, [errorHandlerObj]);

  // If response is success, remove failure message
  useEffect(() => {
    if (successHandlerObj.isSuccess) {
      setErrorHandlerObj({ hasError: false, message: "" });
    }
  }, [successHandlerObj]);

  return (
    <div className="add-new-company-container">
      <div className="add-new-company-container--inner">
        {/* Add New User left section*/}
        <section className="add-new-user-left-section">
          {/* Error handler component */}
          <ErrorHandler
            errorHandlerObj={errorHandlerObj}
            className="auth-message-wrapper"
          />
          <div className="add-new-user-left-section--inner">
            {/* Back Button Wrapper */}
            <div className="add-new-user-back-button-wrapper">
              <button onClick={() => navigate(-1)}>
                <img src={angleLeft} alt="" />
              </button>
            </div>
            <div className="add-new-user-left-section-header">
              Onboard New Employer
            </div>
            <div className="add-new-user-left-section-sub-header">
              Create Employer Account
            </div>

            {/* Form Wrapper */}
            <div className="add-new-user-form-wrapper">
              <form autoComplete="off">
                {/* Dummy Input to switch off autocomplete */}
                <input
                  type="text"
                  autoComplete="false"
                  name="hidden"
                  style={{ display: "none" }}
                />
                {/* Company Name form group */}
                <div className="employer-company-name-form-group form-group">
                  <TextInput
                    type={"text"}
                    id={"employer-company-name-text-input"}
                    className={"employer-company-name-input"}
                    placeholder={""}
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                    required={true}
                    label={"Company Name"}
                  />
                </div>

                {/*  Email form group */}
                <div className="employer-email-form-group form-group">
                  <TextInput
                    type={"email"}
                    id={"employer-email-text-input"}
                    className={"employer-email-input"}
                    placeholder={""}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required={true}
                    label={"Email"}
                    subLabel={"Preferably Company Mail"}
                    autoComplete="off"
                  />
                </div>

                {/* Phone Number form group */}
                <div className="employer-phone-number-form-group form-group">
                  <PhoneInput
                    type={"tel"}
                    id={"merchant-phone-number-one-text-input"}
                    className={"merchant-phone-number-input"}
                    placeholder={""}
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    required={true}
                    label={"Phone Number"}
                    maxLength={11}
                  />
                </div>

                {/* Password form group */}
                <div className="employer-signup-password-form-group form-group">
                  <img
                    src={isPasswordVisible ? eyeOpenIcon : eyeCloseIcon}
                    alt=""
                    className="password-icon-wrapper"
                    onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                  />

                  <label htmlFor={"employer-signup-password-input"}>
                    Password
                  </label>
                  <input
                    type={isPasswordVisible ? "text" : "password"}
                    id={"employer-signup-password-input"}
                    className={"employer-signup-password-text-input"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required={true}
                    autoComplete="new-password"
                  />
                </div>

                {/* Add New User Submit Button Wrapper */}
                <div className="add-new-user-submit-button-wrapper">
                  {isLoading && <LoadingSpinner />}
                  <button onClick={(e) => handleRegisterNewCompany(e)}>
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </section>

        {/* Add New User Right Section */}
        <section className="add-new-user-right-section">
          {/* Auth Left Image Wrapper */}
          <div className="right-add-new-user-section-image-wrapper">
            <img src={authWaveImage} alt="" />
          </div>
        </section>
      </div>
    </div>
  );
}

export default AddNewCompany;

import React from "react";
import { IWellnessCourse } from "../../../../Types";
import coinsImage from "../../../../images/coins-image.svg";
import profileUserIcon from "../../../../images/profile-user-icon.svg";
import moneyIcon from "../../../../images/money-icon.svg";
import timerIcon from "../../../../images/timer-icon.svg";
import { handleShortenNumber } from "../../../../utils/handleShortenNumber";

// Interfaces
interface IProps {
  currentCoursesPosts: IWellnessCourse[] | null;
  handlePreviewSelectedCourse: (course: IWellnessCourse) => void;
}

function CourseCard({
  currentCoursesPosts,
  handlePreviewSelectedCourse,
}: IProps) {
  return (
    <div className="wellness-courses-container">
      {currentCoursesPosts?.map((course, index) => (
        <button
          key={index + 1}
          onClick={() => handlePreviewSelectedCourse(course)}
          className="wellness-courses-wrapper"
        >
          {/* Coin Images */}
          <div className="course-image-wrapper">
            <img src={course.image} alt="" />
          </div>
          {/* Course Details */}
          <div className="course-box--lower">
            <div className="course-box--name">{course.name}</div>
            <div className="course-card-footer">
              {/* Course Participant Count */}
              <div className="course-box--footer-count">
                <img src={profileUserIcon} alt="" />
                {course.participants?.toLocaleString()}
              </div>
              {/* Course Cost  */}
              <div className="course-box--footer-count">
                <img src={moneyIcon} alt="" />
                {(course.price < 1)? course.price: handleShortenNumber(course.price)}
              </div>
              {/* Course Time Length  */}
              <div className="course-box--footer-count">
                <img src={timerIcon} alt="" />
                {course.duration}
              </div>
            </div>
          </div>
        </button>
      ))}
    </div>
  );
}

export default CourseCard;

import React, { MouseEvent, useEffect, useState } from "react";
import "./DealsSetup.css";
import ErrorHandler from "../../../components/error/ErrorHandler";
import useDisplayMessage from "../../../hooks/useDisplayMessage";
import SuccessHandler from "../../../components/success/SuccessHandler";
import angleLeftIcon from "../../../images/angle-left.svg";
import { useLocation, useNavigate } from "react-router-dom";
import DealsSetupNav from "./setup-nav/SetupNav";
import DealsInfo from "./deals-info-form/DealsInfo";
import { IDeal, IVoucherFilterFormInputs } from "../../../Types";
import { useAppDispatch } from "../../../hooks";
import { getAllCategories } from "../../../redux/actions/categoryActions";
import DeleteDealModal from "./modals/DeleteDealModal";
import {
  createDeal,
  deleteDeal,
  updateDeal,
} from "../../../redux/actions/dealActions";
import { getAllVouchers } from "../../../redux/actions/vouchersActions";

interface IProps {
  currentDealObj: IDeal | null;
  pageType: string;
  pageContent: string;
  isDeleteDealModalOpened: boolean;
  setIsDeleteDealModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
  setPageContent: React.Dispatch<React.SetStateAction<string>>;
}

export interface IDealFormInputState {
  voucher: any;
  voucherId: string;
  expiryDate: string;
  amount: string;
  initialAmount: string;
  // categoryId: string;
}

function DealsSetup({
  currentDealObj,
  pageType,
  pageContent,
  isDeleteDealModalOpened,
  setIsDeleteDealModalOpened,
  setPageContent,
}: IProps) {
  // Functions, States and variables
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  // Hooks
  // Handles Display the error and success message and scrolls to top
  const {
    errorHandlerObj,
    setErrorHandlerObj,
    successHandlerObj,
    setSuccessHandlerObj,
  } = useDisplayMessage();
  // States
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteDealLoading, setIsDeleteDealLoading] = useState(false);
  const [isCategoryAndVouchersLoading, setIsCategoryAndVouchersLoading] =
    useState(false);
  const [dealFormInputState, setDealFormInputState] =
    useState<IDealFormInputState>({
      voucher: "",
      voucherId: "",
      // categoryId: "",
      expiryDate: "",
      amount: "",
      initialAmount: "",
    });

  const [voucherFilterFormInputStates, setVoucherFilterFormInputStates] =
    useState<IVoucherFilterFormInputs>({
      country: "",
      category: "",
      searchParam: "",
    });

  // Pagination
  const [pageNumberIndex, setPageNumberIndex] = useState(1);

  // Handle Delete Deal
  const handleDeleteDeal = function () {
    const dealId = currentDealObj?._id || "";
    //  Call the API to delete deal
    dispatch(
      deleteDeal(
        dealId,
        setIsDeleteDealLoading,
        setErrorHandlerObj,
        navigate,
        setIsDeleteDealModalOpened
      )
    );
  };

  //   Handle Create / Edit Deal
  const handleCreateOrEditDeal = function (e: MouseEvent<HTMLButtonElement>) {
    const isDealFormEmpty = Object.keys(dealFormInputState).some(
      (inputKeys) =>
        dealFormInputState[inputKeys as keyof typeof dealFormInputState] === ""
    );

    if (!isDealFormEmpty) {
      e.preventDefault();
      if (pageType === "create") {
        const data = {
          voucher: dealFormInputState.voucher,
          expiryDate: dealFormInputState.expiryDate,
          // categoryId: dealFormInputState.categoryId,
          currency: dealFormInputState.voucher?.currency_code || "NGN",
          amount: Number(dealFormInputState.amount),
          initialAmount: Number(dealFormInputState.initialAmount),
        };
        console.log(data);
        //  Call the API to create a deal
        dispatch(createDeal(data, setIsLoading, setErrorHandlerObj, navigate));
      } else {
        const data = {
          amount: Number(dealFormInputState.amount),
          initialAmount: Number(dealFormInputState.initialAmount),
          expiryDate: dealFormInputState.expiryDate,
        };
        //  Call the API to edit a deal
        dispatch(
          updateDeal(
            currentDealObj?._id || "",
            data,
            setIsLoading,
            setErrorHandlerObj,
            setSuccessHandlerObj
          )
        );
      }
    }
  };

  useEffect(() => {
    const voucherFilterParam = voucherFilterFormInputStates;
    if (pageType === "create") {
      dispatch(
        getAllCategories(setIsCategoryAndVouchersLoading, setErrorHandlerObj)
      );
      dispatch(
        getAllVouchers(
          pageNumberIndex,
          voucherFilterParam,
          setIsCategoryAndVouchersLoading,
          setErrorHandlerObj
        )
      );
    }
  }, []);

  useEffect(() => {
    if (pageType === "edit") {
      setDealFormInputState((prevFormInputState) => {
        const dateTimeString = currentDealObj?.expiryDate;
        const formattedDateTime = new Date(dateTimeString || "")
          .toISOString()
          .slice(0, -1);
        const updatedFormInputStates: IDealFormInputState = {
          ...prevFormInputState,
          voucherId: currentDealObj?._id || "",
          voucher: currentDealObj?.voucher,
          amount: currentDealObj?.amount.toString() || "",
          expiryDate: formattedDateTime,
          // categoryId: currentDealObj?.categoryId._id || "",
          initialAmount: currentDealObj?.initialAmount.toString() || "",
        };
        return updatedFormInputStates;
      });
    }
  }, [currentDealObj]);

  useEffect(() => {
    // If page is create, admin should have come from a page where they have to select a voucher to make a deal, else navigate admin back
    if (location.state) {
      const { voucherObj }: any = location.state;
      if (pageType === "create") {
        if (voucherObj) {
          setDealFormInputState({
            ...dealFormInputState,
            voucherId: voucherObj._id,
            voucher: voucherObj.voucher,
          });
        } else {
          navigate(-1);
        }
      }
    }
  }, [location]);

  return (
    <>
      {/* Modals */}
      {pageType === "edit" && (
        <DeleteDealModal
          isModalOpened={isDeleteDealModalOpened}
          isLoading={isDeleteDealLoading}
          setIsModalOpened={setIsDeleteDealModalOpened}
          handleDeleteDeal={handleDeleteDeal}
        />
      )}

      <div className="dashboard-setup-layout-container">
        <div className="actions-handler-wrapper">
          {/* Error handler component */}
          <ErrorHandler errorHandlerObj={errorHandlerObj} />

          {/* Success handler component */}
          <SuccessHandler successHandlerObj={successHandlerObj} />
        </div>
        {/* Dashboard Setup Top */}
        <div
          className={`dashboard-setup-top-container ${
            successHandlerObj.isSuccess || errorHandlerObj.hasError
              ? "push-wrapper-down"
              : ""
          }`}
        >
          <div className="dashboard-setup-top--left-wrapper">
            {/* Back button Wrapper */}
            <div className="back-button-wrapper">
              <button
                onClick={() => {
                  navigate(-1);
                }}
              >
                <img src={angleLeftIcon} alt="" />
              </button>
            </div>
            {/* Setup Top Title */}
            <div className="dashboard-setup-top-title">
              {pageType === "create" ? "Add Deal" : "Edit Deal"}
            </div>
          </div>
        </div>

        {/* Setup Layout main Container */}
        <div className="dashboard-setup-layout-main-container">
          {/* Deals setup navigation container */}
          <DealsSetupNav
            pageType={pageType}
            pageContent={pageContent}
            setPageContent={setPageContent}
            setIsDeleteDealModalOpened={setIsDeleteDealModalOpened}
          />

          {/* Deals setup form container */}
          <div className="dashboard-setup-layout--right-section deals-setup-layout-form-container">
            <div className={`account-details-setup--inner`}>
              {/* Deals Info */}
              {pageContent === "deal-info" && (
                <DealsInfo
                  pageType={pageType}
                  currentDealObj={currentDealObj}
                  dealFormInputState={dealFormInputState}
                  isLoading={isLoading}
                  setDealFormInputState={setDealFormInputState}
                  handleCreateOrEditDeal={handleCreateOrEditDeal}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DealsSetup;

import axios from "axios";
import { GET_ALL_MERCHANTS, GET_ALL_UNAPPROVED_MERCHANTS } from "../Constants";
import { api } from "../../api/config";
import { SetErrorHandlerType, SetSuccessHandlerType } from "../../Types";
import { handleAPIError } from "../../utils/handleAPIError";

const url = `${api}/api/v1/merchant`;

const token: any = localStorage.getItem("jwtToken");
axios.defaults.headers.common["authorization"] = token;

// Get all merchants
export const getAllMerchants =
  (
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setErrorHandlerObj: SetErrorHandlerType
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .get(`${url}`)
      .then((res) => {
        // console.log(res.data);
        dispatch({ type: GET_ALL_MERCHANTS, payload: res.data?.reverse() });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Get all unapproved merchant
export const getAllUnapprovedMerchants =
  (
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setErrorHandlerObj: SetErrorHandlerType
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .get(`${url}/un-approved`)
      .then((res) => {
        // console.log(res.data);
        dispatch({
          type: GET_ALL_UNAPPROVED_MERCHANTS,
          payload: res.data,
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Hold Merchant
export const holdMerchantAccount =
  (
    merchantId: string,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setErrorHandlerObj: SetErrorHandlerType,
    setSuccessHandlerObj: SetSuccessHandlerType,
    setIsPreviewedMerchantUpdated: (
      value: React.SetStateAction<boolean>
    ) => void
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .patch(`${url}/hold/${merchantId}`)
      .then((res) => {
        setIsPreviewedMerchantUpdated(true);
        setSuccessHandlerObj({
          isSuccess: true,
          message: "Merchant account held successfully!",
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Unhold Merchant
export const unholdMerchantAccount =
  (
    merchantId: string,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setErrorHandlerObj: SetErrorHandlerType,
    setSuccessHandlerObj: SetSuccessHandlerType,
    setIsPreviewedMerchantUpdated: (
      value: React.SetStateAction<boolean>
    ) => void
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .patch(`${url}/un-hold/${merchantId}`)
      .then((res) => {
        setIsPreviewedMerchantUpdated(true);
        setSuccessHandlerObj({
          isSuccess: true,
          message: "Merchant account unheld successfully!",
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

import React, { useEffect, useState } from "react";
import "./CourseCategory.css";
import Top from "../../../components/app-top/Top";
import Sidebar from "../../../components/app-sidebar/Sidebar";
import ErrorHandler from "../../../components/error/ErrorHandler";
import SuccessHandler from "../../../components/success/SuccessHandler";
import useDisplayMessage from "../../../hooks/useDisplayMessage";
import CourseCategoryTop from "./course-category-top/CourseCategoryTop";
import CategoriesTableSection from "./category-table-section/CategoryTableSection";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { getCourseCategories } from "../../../redux/actions/wellnessActions";
import { ICourseCategory } from "../../../Types";
import StoreCourseCategoryModal from "./modals/StoreCourseCategoryModal";
import Main from "../../../components/layout/main-container/Main";

function CourseCategory() {
  // Functions, States and Variables
  const dispatch = useAppDispatch();
  const courseCategories = useAppSelector(
    (state) => state.wellness.courseCategories
  );
  // States
  const [isLoading, setIsLoading] = useState(false);
  const [isStoreCategoryModalOpened, setIsStoreCategoryModalOpened] =
    useState(false);
  const [selectedCategoryForEditting, setSelectedCategoryForEditting] =
    useState<ICourseCategory | null>(null);

  //
  //   Hooks
  // Handles Display the error and success message and scrolls to top
  const {
    errorHandlerObj,
    setErrorHandlerObj,
    successHandlerObj,
    setSuccessHandlerObj,
  } = useDisplayMessage();

  // useEffects
  useEffect(() => {
    dispatch(getCourseCategories(setIsLoading, setErrorHandlerObj));
  }, []);

  return (
    <div
      className={`app-page-container ${
        isStoreCategoryModalOpened ? "overflow-hidden" : ""
      }`}
    >
      <Top />
      <Sidebar activeName={"Learning"} />

      {/* Gray Overlay */}
      <div
        onClick={() => {
          setIsStoreCategoryModalOpened(false);
        }}
        className={`gray-overlay ${isStoreCategoryModalOpened ? "" : "none"}`}
      ></div>

      {/* Store Course Category Brand Modal  */}
      <StoreCourseCategoryModal
        isModalOpened={isStoreCategoryModalOpened}
        selectedCategoryForEdit={selectedCategoryForEditting}
        setIsModalOpened={setIsStoreCategoryModalOpened}
        setSelectedCategoryForEdit={setSelectedCategoryForEditting}
        setErrorHandlerObj={setErrorHandlerObj}
        setSuccessHandlerObj={setSuccessHandlerObj}
      />

      <Main>
        {/* Error handler component */}
        <ErrorHandler
          errorHandlerObj={errorHandlerObj}
          className="app--actions-handler-wrapper"
        />
        {/* Success handler component */}
        <SuccessHandler
          successHandlerObj={successHandlerObj}
          className="app--actions-handler-wrapper"
        />

        <section className="app-main-section--inner">
          {/* Course Category Top */}
          <CourseCategoryTop
            setIsStoreCategoryModalOpened={setIsStoreCategoryModalOpened}
          />

          {/* Categories Table */}
          <CategoriesTableSection
            isLoading={isLoading}
            courseCategories={courseCategories}
            setIsStoreCategoryModalOpened={setIsStoreCategoryModalOpened}
            setSelectedCategoryForEditting={setSelectedCategoryForEditting}
            setErrorHandlerObj={setErrorHandlerObj}
            setSuccessHandlerObj={setSuccessHandlerObj}
          />
        </section>
      </Main>
    </div>
  );
}

export default CourseCategory;

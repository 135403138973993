import React, { MouseEvent, useEffect, useState } from "react";
import TextInput from "../../../../components/input-components/text-input/TextInput";
import { IDeal, IDropdownOptions, IVoucherObject } from "../../../../Types";
import { IDealFormInputState } from "../DealsSetup";
import DropdownInput from "../../../../components/input-components/dropdown-input/DropdownInput";
import { useAppSelector } from "../../../../hooks";
import LoadingSpinner from "../../../../components/loading-spinner/LoadingSpinner";
import { ICategories } from "../../../products/Products";
import PrimaryButton from "../../../../components/buttons/primary-button/PrimaryButton";

// Interfaces
interface IProps {
  pageType: string;
  currentDealObj: IDeal | null;
  dealFormInputState: IDealFormInputState;
  isLoading: boolean;
  setDealFormInputState: React.Dispatch<
    React.SetStateAction<IDealFormInputState>
  >;
  handleCreateOrEditDeal: (e: MouseEvent<HTMLButtonElement>) => void;
}

function DealsInfo({
  pageType,
  currentDealObj,
  dealFormInputState,
  isLoading,
  setDealFormInputState,
  handleCreateOrEditDeal,
}: IProps) {
  // Functions, States and Variables
  const categories: ICategories[] | [] = useAppSelector(
    (state) => state.categories.categories
  );
  const vouchers: IVoucherObject[] | [] = useAppSelector(
    (state) => state.vouchers.vouchers.data
  );
  // States
  const [categoryOptionsArr, setCategoryOptionsArr] = useState<
    IDropdownOptions[] | []
  >([]);

  // Functions
  //   Functions
  const handleUpdateDealsFormInputStates = function (
    fieldName: string,
    fieldValue: any
  ) {
    const updatedFormInputStates: IDealFormInputState = {
      ...dealFormInputState,
      [fieldName]: fieldValue,
    };
    if (fieldName === "voucherId") {
      const currentVoucherObj = vouchers?.find(
        (voucher: any) =>
          voucher.uniqueId === fieldValue || voucher._id === fieldValue
      );
      console.log(currentVoucherObj);
      updatedFormInputStates.voucher = currentVoucherObj;
    }
    // console.log(fieldName, fieldValue, updatedFormInputStates);
    setDealFormInputState(updatedFormInputStates);
  };

  // useEffects
  useEffect(() => {
    if (categories) {
      const updatedCategoryOptions: IDropdownOptions[] = categories?.map(
        (category) => {
          return {
            key: category._id,
            value: category.name,
          };
        }
      );
      updatedCategoryOptions.unshift({
        key: "",
        value: "",
        default: "default",
      });
      setCategoryOptionsArr(updatedCategoryOptions);
    }
  }, [categories]);

  return (
    <div className="deals-container">
      <section className="deals-form-container">
        <form>
          <div className="form-header-wrapper">
            {pageType === "create" ? "Create a new" : "Edit current"} deal
          </div>

          <div className="form-group-wrapper">
            {/* Voucher form group */}
            {pageType === "create" && (
              <div className="form-group">
                <TextInput
                  type={"text"}
                  id={"deals-voucher-input"}
                  className={"deals-create-edit-input"}
                  value={dealFormInputState.voucher.name}
                  onChange={(e) => {
                    // handleUpdateDealsFormInputStates("amount", e.target.value);
                  }}
                  required={true}
                  label={"Voucher"}
                  readonly={true}
                />
              </div>
            )}

            {/* Category group */}
            {/* {pageType === "create" && (
              <div className="form-group">
                <DropdownInput
                  id={"deals-category-input"}
                  className={"deals-create-edit-input"}
                  label={"Category"}
                  required={true}
                  optionArray={categoryOptionsArr}
                  value={dealFormInputState.categoryId}
                  onChange={(e) => {
                    handleUpdateDealsFormInputStates(
                      "categoryId",
                      e.target.value
                    );
                  }}
                />
              </div>
            )} */}

            {/* Initial Amount form group */}
            <div className="form-group">
              <TextInput
                type={"number"}
                className={"deals-create-edit-input"}
                value={dealFormInputState.initialAmount}
                onChange={(e) => {
                  handleUpdateDealsFormInputStates(
                    "initialAmount",
                    e.target.value
                  );
                }}
                required={true}
                label={"Initial Price"}
              />
            </div>

            {/* Amount form group */}
            <div className="form-group">
              <TextInput
                type={"number"}
                className={"deals-create-edit-input"}
                value={dealFormInputState.amount}
                onChange={(e) => {
                  handleUpdateDealsFormInputStates("amount", e.target.value);
                }}
                required={true}
                label={"Deal Amount"}
              />
            </div>

            {/* Expiry Date form group */}
            <div className="expiry-date-form-group form-group">
              <TextInput
                type={"datetime-local"}
                className={"deals-create-edit-input"}
                value={dealFormInputState.expiryDate}
                onChange={(e) => {
                  handleUpdateDealsFormInputStates(
                    "expiryDate",
                    e.target.value
                  );
                }}
                required={true}
                label={"Expiry Date"}
              />
            </div>

            <div className="deal-form-primary-btn-container primary-button-container">
              {isLoading && <LoadingSpinner />}
              <PrimaryButton
                className="deal-setup-form--action-btn"
                placeholder="Submit"
                onClick={(e) => handleCreateOrEditDeal(e)}
              />
            </div>
          </div>
        </form>
      </section>
    </div>
  );
}

export default DealsInfo;

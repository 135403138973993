import { GET_ALL_USERS, GET_USER_ALL_LOAN_BALANCE } from "../Constants";

const initialState = {
  users: [],
  userLoanBalance: {
    bnpl: 0,
    salaryAdvance: 0,
    onDemand: 0,
  },
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (
  state = initialState,
  action: { type?: string; payload?: any }
) {
  switch (action.type) {
    case GET_ALL_USERS:
      return {
        ...state,
        users: action.payload,
      };
    case GET_USER_ALL_LOAN_BALANCE:
      return {
        ...state,
        usersLoanBalance: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
}

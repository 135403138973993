import axios from "axios";
import {
  GET_ALL_ORDERS,
  GET_ALL_ORDERS_AMOUNT,
  GET_ALL_ORDERS_PER_USER,
} from "../Constants";
import { api } from "../../api/config";
import { SetErrorHandlerType } from "../../Types";
import { handleAPIError } from "../../utils/handleAPIError";

const url = `${api}/api/v1/orders`;

const token: any = localStorage.getItem("jwtToken");
axios.defaults.headers.common["authorization"] = token;

// Get all orders
export const getAllOrders =
  (
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setErrorHandlerObj: SetErrorHandlerType
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .get(`${url}`)
      .then((res) => {
        dispatch({ type: GET_ALL_ORDERS, payload: res.data.data });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Get all orders amount
export const getAllOrdersAmount =
  (
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setErrorHandlerObj: SetErrorHandlerType
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .get(`${url}/amount`)
      .then((res) => {
        dispatch({ type: GET_ALL_ORDERS_AMOUNT, payload: res.data.data });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Get all orders per User
export const getAllOrdersPerUser =
  (
    userId: string,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setErrorHandlerObj: SetErrorHandlerType
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .get(`${url}/user/admin/${userId}`)
      .then((res) => {
        // console.log(res.data);
        dispatch({ type: GET_ALL_ORDERS_PER_USER, payload: res.data });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

import React, { MouseEvent, useEffect, useState } from "react";
import "./CurrencyRates.css";
import {
  IRates,
  SetErrorHandlerType,
  SetSuccessHandlerType,
} from "../../../../../Types";
import TextInput from "../../../../input-components/text-input/TextInput";
import { useAppDispatch, useAppSelector } from "../../../../../hooks";
import {
  getRates,
  updateRates,
} from "../../../../../redux/actions/rateActions";
import LoadingSpinner from "../../../../loading-spinner/LoadingSpinner";
import isEmpty from "../../../../../validation/isEmpty";

// Interfaces
interface IProps {
  setErrorHandlerObj: SetErrorHandlerType;
  setSuccessHandlerObj: SetSuccessHandlerType;
}

function CurrencyRates({ setErrorHandlerObj, setSuccessHandlerObj }: IProps) {
  // Functions,  States and Variables
  const dispatch = useAppDispatch();
  const rates = useAppSelector((state) => state.rates.rates);
  //   States
  const [isLoading, setIsLoading] = useState(false);
  const [ratesFormInputStates, setRatesFormInputStates] = useState<IRates>({
    USD: "0",
    CAD: "0",
    GBP: "0",
  });

  //   Functions
  //   Handle Update rates form input state
  const handleUpdateRatesFormInputState = function (
    fieldName: string,
    fieldValue: string
  ) {
    setRatesFormInputStates((prevFormInputState) => {
      const updatedFormInputStates = {
        ...prevFormInputState,
        [fieldName]: fieldValue,
      };
      return updatedFormInputStates;
    });
  };

  const handleUpdateCurrencyRates = function (
    e: MouseEvent<HTMLButtonElement>
  ) {
    const isFormEmpty = Object.keys(ratesFormInputStates)?.some(
      (inputKeys) =>
        ratesFormInputStates[inputKeys as keyof typeof ratesFormInputStates] ===
        ""
    );

    if (!isFormEmpty) {
      e.preventDefault();

      const data = {
        rate: {
          USD: Number(ratesFormInputStates.USD),
          CAD: Number(ratesFormInputStates.CAD),
          GBP: Number(ratesFormInputStates.GBP),
        },
      };

      //   Call the API to update rates
      dispatch(
        updateRates(
          data,
          setIsLoading,
          setErrorHandlerObj,
          setSuccessHandlerObj
        )
      );
    }
  };
  //   UseEffects
  useEffect(() => {
    dispatch(getRates(setErrorHandlerObj));
  }, []);

  useEffect(() => {
    if (!isEmpty(rates)) {
      setRatesFormInputStates(rates);
    }
  }, [rates]);
  return (
    <div className="admin-settings-container">
      <section className="admin-settings-form-container">
        <form>
          <div className="form-header-wrapper">Updates Currency Rates</div>
          {/* USD Form group */}
          <div className="form-group">
            <TextInput
              type={"number"}
              className={"currency-rates-input"}
              value={ratesFormInputStates.USD}
              onChange={(e) => {
                handleUpdateRatesFormInputState("USD", e.target.value);
              }}
              required={true}
              label={"USD"}
            />
          </div>

          {/* CAD Form group */}
          <div className="form-group">
            <TextInput
              type={"number"}
              className={"currency-rates-input"}
              value={ratesFormInputStates.CAD}
              onChange={(e) => {
                handleUpdateRatesFormInputState("CAD", e.target.value);
              }}
              required={true}
              label={"CAD"}
            />
          </div>

          {/* GBP Form group */}
          <div className="form-group">
            <TextInput
              type={"number"}
              className={"currency-rates-input"}
              value={ratesFormInputStates.GBP}
              onChange={(e) => {
                handleUpdateRatesFormInputState("GBP", e.target.value);
              }}
              required={true}
              label={"GBP"}
            />
          </div>

          {/* Submit form button */}
          <div className="submit-setup-form-button-wrapper">
            {isLoading && <LoadingSpinner />}
            <button onClick={handleUpdateCurrencyRates}>Submit</button>
          </div>
        </form>
      </section>
    </div>
  );
}

export default CurrencyRates;

import { MouseEvent, useState } from "react";
import "./NotificationsFormSetup.css";
import FormSection from "./FormSection";
import { useAppDispatch } from "../../../../hooks";
import { SetErrorHandlerType, SetSuccessHandlerType } from "../../../../Types";
import {
  INotifyData,
  notifyAllUsers,
} from "../../../../redux/actions/notificationsActions";

// Interfaces
interface IProps {
  setErrorHandlerObj: SetErrorHandlerType;
  setSuccessHandlerObj: SetSuccessHandlerType;
}

function NotificationsFormSetup({
  setErrorHandlerObj,
  setSuccessHandlerObj,
}: IProps) {
  // Functions, states and variables
  const dispatch = useAppDispatch();
  //   States
  const [notifyFormInputStates, setNotifyFormInputStates] =
    useState<INotifyData>({
      details: "",
    });
  const [isLoading, setIsLoading] = useState(false);

  //   Functions
  const handleNotifiyAllUsers = function (e: MouseEvent<HTMLButtonElement>) {
    setErrorHandlerObj({ hasError: false, message: "" });

    if (notifyFormInputStates.details !== "") {
      e.preventDefault();

      // Call the API to notify all users
      dispatch(
        notifyAllUsers(
          notifyFormInputStates,
          setIsLoading,
          setErrorHandlerObj,
          setSuccessHandlerObj,
          handleEmptyFormInputStates
        )
      );
    }
  };

  const handleEmptyFormInputStates = () =>
    setNotifyFormInputStates({ details: "" });

  return (
    <section className={`notifications-form-setion`}>
      {/* Top Wrapper */}
      <div className="notifications-form--top-wrapper">
        <div className="primary-back-button-container">
          {/* Top Title */}
          <div className="primary-back-button-title">Notify All Users</div>
        </div>
      </div>
      {/* Administrators Form Section */}
      <FormSection
        isLoading={isLoading}
        notifyFormInputStates={notifyFormInputStates}
        setNotifyFormInputStates={setNotifyFormInputStates}
        handleNotifiyAllUsers={handleNotifiyAllUsers}
      />
    </section>
  );
}

export default NotificationsFormSetup;

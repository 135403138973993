import { GET_TOTAL_AMOUNT_DISBURSED } from "../Constants";

const initialState = {
  totalAmountDisbursed: { onDemand: 0, salaryAdvance: 0 },
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (
  state = initialState,
  action: { type?: string; payload?: any }
) {
  switch (action.type) {
    case GET_TOTAL_AMOUNT_DISBURSED:
      return {
        ...state,
        totalAmountDisbursed: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
}

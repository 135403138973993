import React, { MouseEvent, useEffect, useState } from "react";
import "./VoucherFilter.css";
import searchIcon from "../../../../images/search-icon.svg";
import DropdownInput from "../../../../components/input-components/dropdown-input/DropdownInput";
import {
  IDropdownOptions,
  IVoucherCountriesAndCategories,
  IVoucherFilterFormInputs,
  SetErrorHandlerType,
} from "../../../../Types";
import PrimaryButton from "../../../../components/buttons/primary-button/PrimaryButton";
// import LoadingSpinner from "../../../../components/loading-spinner/LoadingSpinner";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { getVoucherCountriesAndCategories } from "../../../../redux/actions/vouchersActions";

// Interfaces
interface IProps {
  voucherFilterFormInputStates: IVoucherFilterFormInputs;
  setVoucherFilterFormInputStates: React.Dispatch<
    React.SetStateAction<IVoucherFilterFormInputs>
  >;
  setErrorHandlerObj: SetErrorHandlerType;
  handleFetchVouchers: () => void;
  setPageNumberIndex: React.Dispatch<React.SetStateAction<number>>;
}

function VoucherFilter({
  voucherFilterFormInputStates,
  setVoucherFilterFormInputStates,
  handleFetchVouchers,
  setPageNumberIndex,
  setErrorHandlerObj,
}: IProps) {
  // Functions, States and Variables
  const dispatch = useAppDispatch();
  const countriesAndCategories: IVoucherCountriesAndCategories[] | [] =
    useAppSelector((state) => state.vouchers.countriesAndCategories);
  //   States
  const [countryOptions, setCountryOptions] = useState<IDropdownOptions[] | []>(
    []
  );
  const [categoryOptions, setCategoryOptions] = useState<
    IDropdownOptions[] | []
  >([]);
  const [canAdminSubmitForm, setCanAdminSubmitForm] = useState(false);
  //   Functions
  const handleUpdateVoucherFilterInputStates = function (
    fieldName: string,
    fieldValue: string
  ) {
    let updatedFormInputStates = {
      ...voucherFilterFormInputStates,
      [fieldName]: fieldValue,
    };
    // If country field is being updated or changed, clear out the categories field
    if (fieldName === "country") {
      updatedFormInputStates = {
        ...updatedFormInputStates,
        category: "",
      };
    }
    setVoucherFilterFormInputStates(updatedFormInputStates);
  };

  // Handle Update category options based on selected country
  const handleUpdateCategoryOptionsArrBasedOnCountry = function (
    country: string
  ) {
    const selectedCountryObject = countriesAndCategories?.find(
      (countryObj) => countryObj.country === country
    );

    if (selectedCountryObject) {
      const categoryOptionsArr = selectedCountryObject.categories?.map(
        (categoryName) => {
          return {
            key: categoryName,
            value: categoryName,
          };
        }
      );
      setCategoryOptions(categoryOptionsArr);
    }
  };

  // Handle Filter vouchers
  const handleFilterVouchers = function (e: MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    setPageNumberIndex(1);
    handleFetchVouchers();
  };
  //   UseEffects
  useEffect(() => {
    dispatch(getVoucherCountriesAndCategories(setErrorHandlerObj));
  }, []);

  useEffect(() => {
    const countryOptionsArr = countriesAndCategories?.map((countryObj) => {
      return {
        key: countryObj.country,
        value: countryObj.country,
      };
    });
    setCountryOptions(countryOptionsArr);
  }, [countriesAndCategories]);

  useEffect(() => {
    setCanAdminSubmitForm(false);
    let voucherFilterParams = voucherFilterFormInputStates;
    const isFormFieldFilled = Object.keys(voucherFilterParams)?.some(
      (inputKeys) =>
        voucherFilterParams[inputKeys as keyof typeof voucherFilterParams] !==
        ""
    );
    if (isFormFieldFilled) setCanAdminSubmitForm(true);
  }, [voucherFilterFormInputStates]);

  return (
    <section className="voucher-filter-section">
      {/* Vouchers Table Filter Wrapper */}
      <div className="primary-table-filter-option-wrapper voucher-filter-options-wrapper">
        <div className="primary-table-filter-row-title">Filter:</div>

        <form>
          <div className="voucher-filter-form-group-wrapper">
            <div className="form-group">
              <DropdownInput
                className={"admin-voucher-filter-input"}
                id={"voucher-filter--country-input"}
                label={""}
                optionArray={[
                  { key: "", value: "Select country", default: "Default" },
                  ...countryOptions,
                ]}
                value={voucherFilterFormInputStates.country}
                onChange={(e) => {
                  handleUpdateVoucherFilterInputStates(
                    "country",
                    e.target.value
                  );
                  handleUpdateCategoryOptionsArrBasedOnCountry(e.target.value);
                }}
              />
            </div>
            <div className="form-group">
              <DropdownInput
                className={"admin-voucher-filter-input"}
                id={"voucher-filter--category-input"}
                label={""}
                optionArray={[
                  { key: "", value: "Select category", default: "Default" },
                  ...categoryOptions,
                ]}
                value={voucherFilterFormInputStates.category}
                onChange={(e) =>
                  handleUpdateVoucherFilterInputStates(
                    "category",
                    e.target.value
                  )
                }
              />
            </div>
            <div className="voucher-filter-search-bar-container">
              <div className="search-icon-wrapper">
                <img src={searchIcon} alt="" />
              </div>
              <input
                type="search"
                id="app-top-search-bar-input"
                className="top-search-input"
                placeholder="Search"
                value={voucherFilterFormInputStates.searchParam}
                onChange={(e) =>
                  handleUpdateVoucherFilterInputStates(
                    "searchParam",
                    e.target.value
                  )
                }
              />
            </div>
            <div className="primary-button-container voucher-filter--submit-btn-wrapper">
              <PrimaryButton
                placeholder="Filter"
                onClick={(e) => {
                  handleFilterVouchers(e);
                }}
                disabled={!canAdminSubmitForm}
              />
              {/* {isLoading && <LoadingSpinner />} */}
            </div>
          </div>
        </form>
      </div>
    </section>
  );
}

export default VoucherFilter;

import {
  GET_ALL_VOUCHERS,
  GET_SELECTED_VOUCHERS,
  GET_VOUCHER_COUNTRIES_AND_CATEGORIES,
} from "../Constants";

const initialState = {
  vouchers: [],
  selectedVouchers: [],
  countriesAndCategories: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (
  state = initialState,
  action: { type?: string; payload?: any }
) {
  switch (action.type) {
    case GET_ALL_VOUCHERS:
      return {
        ...state,
        vouchers: action.payload,
      };
    case GET_SELECTED_VOUCHERS:
      return {
        ...state,
        selectedVouchers: action.payload,
      };
    case GET_VOUCHER_COUNTRIES_AND_CATEGORIES:
      return {
        ...state,
        countriesAndCategories: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
}
